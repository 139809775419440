import React, { useState, useEffect } from "react"
import axios from "axios"
import { Container, Row, Col, Modal, FormGroup, Input, Alert } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { getCurrentUser, setCurrentUser } from "../../helpers/Utils"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useHistory } from "react-router-dom"
import Loader from "../Loader/loader.js"

export default function MigrationModal(props) {
  const [loading, setLoading] = useState(false)
  const [loader, setLoader] = useState(<Loader />)

  const submitForm = (e, value) => {
    setLoading(true)
    setLoader(<Loader />)
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/update-database`,
        {
          email: value.email,
          migration_key: value.migration_key,
          company_name: value.company_name,
        },
        {
          headers: {
            Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLoader("")
          props.setmigration_modal(false)
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          logout()
        } else {
          setLoading(false)
          setLoader("")
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  const history = useHistory()

  const logout = () => {
    axios.get(
      `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
        "company_name"
      )}/logout`,
      {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token }`,
        },
      }
    )
    setCurrentUser("")
    localStorage.setItem("DashboardData", "")
    localStorage.setItem("LastUpdated", "")
    localStorage.setItem("NavCounters", "")
    history.push("/login")
  }

  useEffect(() => {
    const close = e => {
      if (e.key === "Escape") {
        props.setmigration_modal(false)
      }
    }
    window.addEventListener("keydown", close)
    return () => window.removeEventListener("keydown", close)
  }, [])

  return (
    <>
      <Modal isOpen={props.migration_modal} centered={true}>
        <AvForm
          className="modal-title mt-0"
          id="myModalLabel"
          onValidSubmit={(e, v) => {
            submitForm(e, v)
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myLargeModalLabel">
              Migration Form
            </h5>
            <button
              type="button"
              className="cross__btn"
              onClick={() => props.setmigration_modal(false)}
              aria-hidden="true"
            >
              &times;
            </button>
          </div>
          <div className="modal-body">
            <div className="mb-3">
              <AvField
                label="Email"
                type="email"
                name="email"
                placeholder="Enter your Email"
              />
            </div>
            <div className="mb-3">
              <AvField
                label="Migration Key"
                type="text"
                name="migration_key"
                placeholder="Enter Migration Key"
              />
            </div>
            <div className="mb-3">
              <AvField
                label="Domain Name"
                type="text"
                name="company_name"
                placeholder="Enter Domain name"
              />
            </div>
            {loading ? loader : ""}
            {/* <div className="row"> */}
            <div className="modal-footer">
              <div className="text-end">
                {loading ? (
                  <button
                    className="btn btn-primary disabled"
                    type="button"
                    disabled
                  >
                    Loading
                  </button>
                ) : (
                  <button
                    className="btn btn-primary"
                    type="submit"
                    // onClick={() => props.setmigration_modal(false)}
                  >
                    Submit
                  </button>
                )}
              </div>
            </div>
          </div>
        </AvForm>
      </Modal>
    </>
  )
}
