import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import axios from "axios"
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"
import { getCurrentUser } from "../../helpers/Utils"
import FetchStaff from '../../components/Admin/Fetch-From-Email';


const Fetch = () => {

	return (
		<React.Fragment>
		<div className="page-content">
			<MetaTags>
				<title>Fetch From Email | KlikCRM</title>
			</MetaTags>
				<Container fluid>
					<FetchStaff/>
				</Container>
			</div>
		</React.Fragment>
	)
}

export default Fetch;
