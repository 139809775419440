import React, { useState, useEffect } from "react"
import {
   Modal,
} from "reactstrap"
import axios from "axios"
import {
   AvForm,
   AvField,
} from "availity-reactstrap-validation"
import { getCurrentUser } from "../../helpers/Utils"


const LeadsReportsFilter = props => {
   const [loading, setLoading] = useState(props.loading)
   const [allFollowup, setAllFollowup] = useState([])
   const [allMature, setAllMature] = useState([])
   const [allDead, setAllDead] = useState([])

   const onFieldChange = (e) => {
      if (e !== undefined) {
         if (e.target.name === "followup_status_id") {
            localStorage.setItem("followupStatus", e.target.value)
         } else if (e.target.name === "mature_status_id") {
            localStorage.setItem("matureStatus", e.target.value)
         } else if (e.target.name === "dead_reason_id") {
            localStorage.setItem("deadReason", e.target.value)
         } else if (e.target.name === "transfer_request") {
            localStorage.setItem(
               "leadTransfer",
               e.target.checked === true ? "1" : "0"
            )
         }
      } else {
      }
   }

   const filterLeads = () => {
      setTimeout(() => {
         props.getSearchedLeads()
      }, 500)
   }

   const clearFilter = () => {
      localStorage.setItem(
         "leadCreated_at",
         JSON.stringify({
            startDate: "",
            endDate: "",
         })
      )
      localStorage.setItem("leadFirmName", "")
      localStorage.setItem("leadUpdateSince", "")
      localStorage.setItem("leadOrderSince", "")
      localStorage.setItem("followupStatus", "")
      localStorage.setItem("matureStatus", "")
      localStorage.setItem("deadReason", "")
      localStorage.setItem("leadStatus", "")
      localStorage.setItem("leadFlag", "")
      localStorage.setItem("leadTransfer", "")
      localStorage.setItem("leadfields_filter", JSON.stringify({ data: "" }))
      setTimeout(() => {
         props.getSearchedLeads()
      }, 500)
   }

   const getAllFollowup = () => {
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/followup-status?per_page=5000`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setAllFollowup(res.data.data.data)
            } else {
            }
            getAllDead()
         })
         .catch(function (err) {
            getAllDead()
         })
   }

   const getAllDead = () => {
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/dead-status?per_page=5000`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setAllDead(res.data.data.data)
            } else {
            }
            getAllMature()
         })
         .catch(function (err) {
            getAllMature()
         })
   }

   const getAllMature = () => {
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/mature-status?per_page=5000`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setAllMature(res.data.data.data)
               // getAllFields()
            } else {
               // getAllFields()
            }
         })
   }

   // const getAllFields = () => {
   //    axios
   //       .get(
   //          `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
   //             "company_name"
   //          )}/lead-field`,
   //          {
   //             headers: {
   //                "content-type": "application/json",
   //                Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
   //             },
   //          }
   //       )
   //       .then(function (res) {
   //          if (res.data.status) {
   //             setAllFields(res.data.data)
   //          } else {
   //          }
   //       })
   // }

   useEffect(() => {
      getAllFollowup()
   }, [])

   return (
      <div>
         <Modal isOpen={props.lead_filter} centered={true}>
            <AvForm
               className="modal-title mt-0"
               id="myModalLabel"
               onValidSubmit={() => {
                  filterLeads()
               }}
            >
               <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myLargeModalLabel">
                     Filter Leads
                  </h5>
                  <button
                     type="button"
                     className="cross__btn"
                     onClick={() => props.setlead_filter(false)}
                     aria-hidden="true"
                  >
                     &times;
                  </button>
               </div>
               <div className="modal-body">
                  <div className="mb-3">
                     <AvField
                        className="select-arrow"
                        label="Followup Stages"
                        type="select"
                        name="followup_status_id"
                        value={localStorage.getItem("followupStatus") && localStorage.getItem("followupStatus")}
                        onChange={e => onFieldChange(e)}
                     >
                        <option value="">Select</option>
                        {allFollowup && allFollowup.map((option, i) =>
                           <option value={option.id} key={i}>{option.name}</option>
                        )}
                     </AvField>
                  </div>
                  <div className="mb-3">
                     <AvField
                        className="select-arrow"
                        label="Mature Stages"
                        type="select"
                        name="mature_status_id"
                        value={localStorage.getItem("matureStatus") && localStorage.getItem("matureStatus")}
                        onChange={e => onFieldChange(e)}
                     >
                        <option value="">Select</option>
                        {allMature && allMature.map((option, i) =>
                           <option value={option.id} key={i}>{option.name}</option>
                        )}
                     </AvField>
                  </div>
                  <div className="mb-3">
                     <AvField
                        className="select-arrow"
                        label="Dead Reason"
                        type="select"
                        name="dead_reason_id"
                        value={localStorage.getItem("deadReason") && localStorage.getItem("deadReason")}
                        onChange={e => onFieldChange(e)}
                     >
                        <option value="">Select</option>
                        {allDead && allDead.map((option, i) =>
                           <option value={option.id} key={i}>{option.reason}</option>
                        )}
                     </AvField>
                  </div>

                  <div className="mb-3">
                     <AvField
                        label="Assigned"
                        type="checkbox"
                        name="assigned"
                        defaultChecked={
                           localStorage.getItem("leadAssigned") &&
                              localStorage.getItem("leadAssigned") == "1"
                              ? true
                              : false
                        }
                        onChange={e => onFieldChange(e)}
                     />
                  </div>
               </div>
               <div className="modal-footer">
                  <div className="text-center">
                     <button
                        className="btn btn-primary me-2"
                        type="button"
                        onClick={() => clearFilter()}
                     >
                        Clear Filters
                     </button>
                     {loading ? (
                        <button
                           className="btn btn-primary disabled"
                           type="button"
                           disabled
                        >
                           Loading
                        </button>
                     ) : (
                        <button className="btn btn-primary" type="submit">
                           Submit
                        </button>
                     )}
                  </div>
               </div>
            </AvForm>
         </Modal>
      </div >
   )
}

export default LeadsReportsFilter
