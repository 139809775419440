import React, { useState, useEffect } from "react"
import { Modal, Button } from "reactstrap"
import axios from "axios"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { getCurrentUser } from "../../helpers/Utils"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Select from "react-select"

let selectedUserId = []

const AssignLabels = props => {
   const [loading, setLoading] = useState(false)

   const options = []

   if (JSON.parse(localStorage.getItem("AllLabels"))) {
      JSON.parse(localStorage.getItem("AllLabels")).map(item => {
         options.push({
            value: item.id,
            label: item.name,
         })
      })
   }

   const getSelectedId = selectedItem => {
      selectedUserId = []
      selectedItem && selectedItem.map(item => selectedUserId.push(item.value))
   }

   const assignLabel = (e, value) => {
      setLoading(true)
      const api = axios.create();
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      api.interceptors.request.use(config => {
         config.cancelToken = source.token;
         return config;
      });

      const timeout = 120000; // 2 minute

      setTimeout(() => {
         source.cancel('Timeout');
      }, timeout);
      axios
         .post(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/assign-label`,
            {
               ids: props.leadsSelectedIds,
               lead_label_id: selectedUserId,
            },
            {
               headers: {
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               props.getAllLeads()
               props.setLeadsSelectedIds([])
               selectedUserId = []
               props.setAssignLabel(false)
               toast.success(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
               setLoading(false)
            } else {
               setLoading(false)
               toast.error(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            }
         })
   }

   useEffect(() => {
      const close = e => {
         if (e.key === "Escape") {
            props.setAssignLabel(false)
         }
      }
      window.addEventListener("keydown", close)
      return () => window.removeEventListener("keydown", close)
   }, [])

   return (
      <div>
         <Modal isOpen={props.assignLabel} centered={true}>
            <AvForm
               className="modal-title mt-0"
               id="myModalLabel"
               onValidSubmit={(e, v) => {
                  assignLabel(e, v)
               }}
            >
               <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myLargeModalLabel">
                     Assign Labels
                  </h5>
                  <button
                     type="button"
                     className="cross__btn"
                     onClick={() => props.setAssignLabel(false)}
                     aria-hidden="true"
                  >
                     &times;
                  </button>
               </div>
               <div className="modal-body">
                  <div className="mb-3">
                     <label>Lead Label</label>
                     <Select
                        isMulti
                        isClearable="true"
                        name="lead_label_id"
                        options={options}
                        onChange={e => getSelectedId(e)}
                     />
                  </div>
               </div>
               <div className="modal-footer">
                  <div className="text-center">
                     {loading ? (
                        <button
                           className="btn btn-primary disabled"
                           type="button"
                           disabled
                        >
                           Loading
                        </button>
                     ) : (
                        <button
                           className="btn btn-primary"
                           type="submit"
                        // onClick={() => setModal2(false)}
                        >
                           Submit
                        </button>
                     )}
                  </div>
               </div>
            </AvForm>
         </Modal>
      </div>
   )
}

export default AssignLabels
