import React, { useState, useEffect } from "react"
import { Row, Col, Alert, Card, CardBody, Container } from "reactstrap"
import axios from "axios"

import MetaTags from 'react-meta-tags';
import { Link } from "react-router-dom"
import { AvForm, AvField } from "availity-reactstrap-validation"

// import ReactDOM from 'react-dom';
import bg from "../../assets/images/bg.jpg";
import logoSm from "../../assets/images/klikcrm-logo.png"

import { setCurrentUser } from "../../helpers/Utils"
import { useHistory } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

let subDomain


import CarouselPage from "./CarouselPage"
const Recoverpw2 = () => {
   const [loading, setLoading] = useState(false)
   const [companyName, setCompanyName] = useState("")
   const history = useHistory()

   useEffect(() => {
      subDomain = window.location.host.split('.')[0]
      if (subDomain !== "login") {
         setCompanyName(subDomain)
      }
   }, [])

   const handleValidSubmit = (event, value) => {
      localStorage.setItem("company_name", companyName)
      if (companyName !== "") {
         setLoading(true)
         axios
            .post(
               `${process.env.REACT_APP_API_URL}/${companyName}/auth/forget-password`,
               {
                  email: value.email,
                  company_name: subDomain,
               }
            )
            .then(res => {
               if (res.data.status) {
                  setLoading(false)
                  toast.success(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               } else {
                  setLoading(false)
                  toast.error(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               }
            })
      }
      else {
         toast.error("Enter Company Name", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
         })
      }
   }

   return (
      <React.Fragment>
         <MetaTags>
            <title>Forgot Password | KlikCRM</title>
         </MetaTags>
         <div
            className="accountbg"
            style={{
               backgroundSize: "cover",
               backgroundPosition: "center",
               backgroundImage: `url(${bg})`
            }}
         ></div>

         <div className="wrapper-page account-page-full">
            <Card className="shadow-none">
               <div className="card-block">
                  <div className="account-box">
                     <div className="card-box shadow-none p-4">
                        <div className="p-2">
                           <div className="text-center mt-4">
                              <img src={logoSm} height="42" alt="logo" />
                           </div>
                           <h4 className="font-size-18 mt-5 text-center">
                              Forgot Password
                           </h4>

                           <AvForm
                              className="form-horizontal mt-4"
                              onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                           >
                              {subDomain == "login" ?
                                 <div className="mb-3">
                                    <label>Domain Name</label>
                                    <div className="input-group mb-3">
                                       <input
                                          onChange={(e) => setCompanyName(e.target.value)}
                                          type="text"
                                          className="form-control"
                                          placeholder="Enter Domain Name"
                                          aria-label="Recipient's username"
                                          aria-describedby="basic-addon2"
                                          required
                                       />
                                       <span className="input-group-text" id="basic-addon2">
                                          .klikcrm.com
                                       </span>
                                    </div>
                                 </div>
                                 :
                                 ""
                              }
                              <div className="mb-3">
                                 <AvField
                                    name="email"
                                    label="Email"
                                    className="form-control"
                                    placeholder="Enter email"
                                    type="email"
                                    required
                                 />
                              </div>
                              <Row className="mb-3">
                                 <Col className="text-end">
                                    {loading ? (
                                       <button

                                          className="btn btn-primary w-md waves-effect waves-light"
                                          type="button"
                                          disabled
                                       >
                                          Loading
                                       </button>
                                    ) : (
                                       <button
                                          className="btn btn-primary w-md waves-effect waves-light"
                                          type="submit"
                                       >
                                          Reset
                                       </button>
                                    )}
                                 </Col>
                              </Row>
                           </AvForm>

                           <div className="mt-5 pt-4 text-center">
                              <p>
                                 Remember It ?{" "}
                                 <Link to="/login"
                                    className="fw-medium text-primary"
                                 >
                                    {" "}
                                    Log In here{" "}
                                 </Link>{" "}
                              </p>
                              <p>
                                 © {new Date().getFullYear()}{" "} KlikCRM.com,{" "} {/* <i className=""></i>  */} all rights reserved.
                              </p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </Card>
         </div>
      </React.Fragment>
   )
}

export default Recoverpw2
