import React, { useState, useEffect } from "react"
import ReactApexChart from 'react-apexcharts';
import Loader from "../../../components/Loader/loader.js"
import {
   Row,
   Col,
} from "reactstrap"
import axios from "axios"
import { getCurrentUser } from "../../../helpers/Utils"

let graphSeries = []
let graphLabels = []

const Salesdonut = () => {
   const [loader, setLoader] = useState(<Loader />)
   const [chartData, setChartData] = useState('')

   const getGraphData = () => {
      setLoader(<Loader />)
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/followup-statuses`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               graphSeries = res.data.data.map(item => item.count)
               graphLabels = res.data.data.map(item => item.name)
               setChartData({
                  options: {
                     chart: {
                        // width: 50,
                        type: 'donut',
                     },
                     dataLabels: {
                        enabled: true,
                     },
                     fill: {
                        type: 'gradient',
                     },
                     legend: {
                        show: false
                     },
                     plotOptions: {
                        pie: {
                           donut: {
                              size: '40%'
                           }
                        }
                     },
                     // colors: ['rgb(32, 178, 170)', '#f75808', '#f8b425'],
                     labels: graphLabels,
                  },
                  series: graphSeries,
               })
               setLoader("")
            } else { }
         })
   }

   useEffect(() => {
      getGraphData()
   }, [])
   return (
      <>
         {loader ? loader
            : <React.Fragment>
               <ReactApexChart options={chartData.options} series={chartData.series} type="donut" height="220" />
            </React.Fragment>}
      </>
   )
}
export default Salesdonut