import React, { useState, useEffect } from "react"
import axios from "axios"
import { Container, Row, Col, Modal, FormGroup, Input, Alert } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { getCurrentUser } from "../../../helpers/Utils"
import ToggleSwitch from "../../Toggle-Switch"
import Select from "react-select"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import MultipleValueTextInput from 'react-multivalue-text-input';

let dValues = []

export default function FieldModal(props) {
   const [loading, setLoading] = useState(false)
   const [error, setError] = useState("")
   const [success, setSuccess] = useState("")
   const [field, setField] = useState("")
   const [newsletter, setNewsletter] = useState(props.field?.is_required === 1 ? true : false)
   const [checkbox, setCheckbox] = useState(false)
   const [radio, setRadio] = useState(false)
   const [input, setInput] = useState(false)
   const [select, setSelect] = useState(false)
   const [allCheck, setAllCheck] = useState([])
   // const [file, setFile] = useState(false)

   const submitField = (e, value) => {
      setLoading(true)
      const api = axios.create();
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      api.interceptors.request.use(config => {
         config.cancelToken = source.token;
         return config;
      });

      const timeout = 120000; // 2 minute

      setTimeout(() => {
         source.cancel('Timeout');
      }, timeout);
      if (props.field !== undefined) {
         axios
            .put(
               `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
                  "company_name"
               )}/lead-field/${props.field.id}`,
               {
                  label: value.label,
                  type: value.type,
                  value: allCheck,
                  required: newsletter
               },
               {
                  headers: {
                     Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
                  },
               }
            )
            .then(function (res) {
               if (res.data.status) {
                  setLoading(false)
                  props.setField(res.data.data)
                  props.setmodal_standard(false)
                  toast.success(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               } else {
                  setLoading(false)
                  toast.error(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               }
            })
      } else {
         axios
            .post(
               `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
                  "company_name"
               )}/lead-field`,
               {
                  label: value.label,
                  type: value.type,
                  value: allCheck,
                  required: newsletter
               },
               {
                  headers: {
                     Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
                  },
               }
            )
            .then(function (res) {
               if (res.data.status) {
                  setLoading(false)
                  props.getAllFields()
                  props.setmodal_standard(false)
                  toast.success(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               } else {
                  setLoading(false)
                  toast.error(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               }
            })
      }
   }

   const showNextOption = (e) => {
      setAllCheck('')
      dValues = []
      if (e === "Checkbox") {
         setCheckbox(true)
         setRadio(false)
         setInput(false)
         setSelect(false)
         // setFile(false)
      } else if (e === "Radio") {
         setCheckbox(false)
         setRadio(true)
         setInput(false)
         setSelect(false)
         // setFile(false)
      } else if (e === "Input") {
         setCheckbox(false)
         setRadio(false)
         setInput(true)
         setSelect(false)
         // setFile(false)
      } else if (e === "Dropdown") {
         setCheckbox(false)
         setRadio(false)
         setInput(false)
         setSelect(true)
         // setFile(false)
      }
      // else if (e === "file") {
      // 	setCheckbox(false)
      // 	setRadio(false)
      // 	setInput(false)
      // 	setSelect(false)
      // 	setFile(true)
      // }
      else {
         setCheckbox(false)
         setRadio(false)
         setInput(false)
         setSelect(false)
         // setFile(false)
      }
   }

   const onNewsletterChange = checked => {
      if (checked) {
         setNewsletter(1)
      } else {
         setNewsletter(0)
      }
   }

   useEffect(() => {
      if (props.field !== undefined) {
         showNextOption(props.field.type)
         dValues = []
         props.field.fieldvalues?.map((item) => {
            dValues.push(item.value)
         })
         setAllCheck(dValues)
      }
   }, [])

   useEffect(() => {
      const close = (e) => {
         if (e.key === 'Escape') {
            props.setmodal_standard(false)
         }
      }
      window.addEventListener('keydown', close)
      return () => window.removeEventListener('keydown', close)
   }, [])

   return (
      <>
         <Modal isOpen={props.modal_standard} centered={true}>
            <AvForm
               className="modal-title mt-0"
               id="myModalLabel"
               onValidSubmit={(e, v) => {
                  submitField(e, v)
               }}
            >
               <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myLargeModalLabel">
                     {props.field !== undefined ? "Edit Field" : "Create Field"}
                  </h5>
                  <button
                     type="button"
                     className="cross__btn"
                     onClick={() => props.setmodal_standard(false)}
                     aria-hidden="true"
                  >
                     &times;
                  </button>
               </div>
               <div className="modal-body">
                  <div className="mb-3">
                     <AvField
                        label="Field Name"
                        type="text"
                        name="label"
                        placeholder="Enter field Name"
                        value={props.field && props.field.label}
                     />
                  </div>
                  <div className="mb-3">
                     <AvField
                        className="select-arrow"
                        label="Field Type"
                        type="select"
                        name="type"
                        value={props.field && props.field.type}
                        onChange={(e) => showNextOption(e.target.value)}
                     >
                        <option value="" hidden>Select type</option>
                        <option value="Checkbox">Checkbox</option>
                        <option value="Radio">Radio Button</option>
                        <option value="Input">Input Field</option>
                        <option value="Text Area">Text Area</option>
                        <option value="Flip Button">Flip Button</option>
                        <option value="Dropdown">Dropdown</option>
                        {/* <option value="File">File</option> */}
                     </AvField>
                  </div>
                  {checkbox ?
                     <>
                        <div className="mb-3">
                           <MultipleValueTextInput
                              required
                              onItemAdded={(item, allItems) => console.log(`Item added: ${item, allItems}`,
                                 setAllCheck(allItems)
                              )}
                              onItemDeleted={(item, allItems) => console.log(`Item removed: ${item}`,
                                 setAllCheck(allItems)
                              )}
                              label="Names of checkboxes"
                              name="value"
                              className="form-control"
                              placeholder="Enter names you want"
                              values={dValues}
                           />
                           <small className="fst-italic">Use comma (,) to insert a value.</small>
                        </div>
                     </>
                     : ''}
                  {radio ?
                     <>
                        <div className="mb-3">
                           <MultipleValueTextInput
                              required
                              onItemAdded={(box, allBoxes) => console.log(`box added: ${box, allBoxes}`,
                                 setAllCheck(allBoxes)
                              )}
                              onItemDeleted={(box, allBoxes) => console.log(`box removed: ${box}`,
                                 setAllCheck(allBoxes)
                              )}
                              label="Names of Radiobuttons"
                              name="value"
                              placeholder="Enter names you want"
                              values={dValues}
                           />
                           <small className="fst-italic">Use comma (,) to insert a value.</small>
                        </div>
                     </>
                     : ''}
                  {/* {input ?  */}
                  {/* 	<> */}
                  {/* 		<div className="mb-3"> */}
                  {/* 			<AvField */}
                  {/* 				label="Choose type of Input" */}
                  {/* 				type="select" */}
                  {/* 				name="value" */}
                  {/* 				onChange={(e) => setAllCheck(e.target.value)} */}
                  {/* 			> */}
                  {/* 				<option value="" hidden>Select Type</option> */}
                  {/* 				<option value="text">Text</option> */}
                  {/* 				<option value="number">Number</option> */}
                  {/* 				<option value="email">Email</option> */}
                  {/* 			</AvField> */}
                  {/* 		</div> */}
                  {/* 	</>  */}
                  {/* : ''} */}
                  {select ?
                     <>
                        <div className="mb-3">
                           <MultipleValueTextInput
                              required
                              onItemAdded={(option, allOption) => console.log(`option added: ${option, allOption}`,
                                 setAllCheck(allOption)
                              )}
                              onItemDeleted={(option, allOption) => console.log(`option removed: ${option}`,
                                 setAllCheck(allOption)
                              )}
                              label="Add Names of Options"
                              name="value"
                              placeholder="Enter names you want"
                              values={dValues}
                           />
                           <small className="fst-italic">Use comma (,) to insert a value.</small>
                        </div>
                     </>
                     : ''}
                  <div className="mb-3">
                     <label htmlFor="newsletter" className="me-3">
                        Required*
                     </label>
                     <ToggleSwitch
                        id="newsletter"
                        checked={newsletter}
                        onChange={onNewsletterChange}
                        name="latest_leads_only"
                        defaultValue={props.fetch && props.fetch.latest_leads_only}
                     />
                  </div>
                  <div className="modal-footer">
                     <div className="text-end">
                        {loading ? (
                           <button
                              className="btn btn-primary disabled"
                              type="button"
                              disabled
                           >
                              Loading
                           </button>
                        ) : (
                           <button
                              className="btn btn-primary"
                              type="submit"
                           >
                              {props.field !== undefined ? "Submit" : "Create"}
                           </button>
                        )}
                     </div>
                  </div>
               </div>
            </AvForm>
         </Modal>
      </>
   )
}
