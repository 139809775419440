import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Media,
  Button,
  Label,
  FormGroup
} from "reactstrap"


// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import avatar from "../../assets/images/users/user-1.jpg"
// actions
import { editProfile, resetProfileFlag } from "../../store/actions"
import {getCurrentUser} from "../../helpers/Utils"

const NewUser = props => {
  const [image, setImage] = useState("")
  const [file, setFile] = useState("")
  const [email, setemail] = useState("")
  const [name, setname] = useState("")
  const [idx, setidx] = useState(1)

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        setname(obj.displayName)
        setemail(obj.email)
        setidx(obj.uid)
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        setname(obj.username)
        setemail(obj.email)
        setidx(obj.uid)
      }
      setTimeout(() => {
        props.resetProfileFlag();
      }, 3000);
    }
  }, [props.success])

  function handleValidSubmit(event, values) {
    props.editProfile(values)
  }

  const imagePreview = (e) => {
    setFile(e.target.files[0]);
    setImage(URL.createObjectURL(e.target.files[0]));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Profile | KlikCRM</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="KlikCRM" breadcrumbItem="Profile" />

          <Row>
            <Col lg="12">
              {props.error && props.error ? (
                <Alert color="danger">{props.error}</Alert>
              ) : null}
              {props.success? (
                <Alert color="success">{props.success}</Alert>
              ) : null}

              <Card>
                <CardBody>
                  <div className="d-flex">
                    {/* <div className="ms-3"> */}
                    {/*   <input */}
                    {/*     onChange={imagePreview} */}
                    {/*     name="image" */}
                    {/*     type="file" */}
                    {/*     id="image" */}
                    {/*     alt="" */}
                    {/*     className="avatar-md rounded-circle img-thumbnail" */}
                    {/*   /> */}
                    {/* </div> */}
                    <Label className="profile_update">
                        {getCurrentUser().image ? (
                          <img className="mw-100 mb-1 profile_update" src={image} alt="" />
                          ) : (
                              <img className="mw-100 mb-1 profile_update" src={avatar} alt="" />
                          )}
                          <FormGroup className="text-center">
                            Update Profile
                            <input
                              id="image"
                              name="image"
                              type="file"
                              className="ms-1"
                              onChange={imagePreview}
                              style={{ opacity: '0' }}
                            />
                          </FormGroup>
                        </Label>
                    <div className="align-self-center flex-1">
                      <div className="text-muted ms-2">
                        <h5>{getCurrentUser().name}</h5>
                        <p className="mb-1">{getCurrentUser().email}</p>
                        <p className="mb-0">Id no:{getCurrentUser().id}</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h4 className="card-title mb-4">User Profile</h4>

          <Card>
            <CardBody>
              <AvForm
                className="form-horizontal"
                onValidSubmit={(e, v) => {
                  handleValidSubmit(e, v)
                }}
              >
              <Row>
              <Col md="6" lg="4">
                <div className="mb-3">
                  <AvField
                    name="username"
                    label="User Name"
                    value={name || ''}
                    className="form-control"
                    placeholder="Enter User Name"
                    type="text"
                    required
                  />
                  <AvField name="idx" value={idx || ''} type="hidden" />
                </div>
                </Col>
                <Col md="6" lg="4">
                <div className="mb-3">
                  <AvField
                    name="username"
                    label="User Name"
                    value={name || ''}
                    className="form-control"
                    placeholder="Enter User Name"
                    type="text"
                    required
                  />
                  <AvField name="idx" value={idx || ''} type="hidden" />
                </div>
                </Col>
                <Col md="6" lg="4">
                <div className="mb-3">
                  <AvField
                    name="username"
                    label="User Name"
                    value={name || ''}
                    className="form-control"
                    placeholder="Enter User Name"
                    type="text"
                    required
                  />
                  <AvField name="idx" value={idx || ''} type="hidden" />
                </div>
                </Col>
                <Col md="6" lg="4">
                <div className="mb-3">
                  <AvField
                    name="username"
                    label="User Name"
                    value={name || ''}
                    className="form-control"
                    placeholder="Enter User Name"
                    type="text"
                    required
                  />
                  <AvField name="idx" value={idx || ''} type="hidden" />
                </div>
                </Col>
                <Col md="6" lg="4">
                <div className="mb-3">
                  <AvField
                    name="username"
                    label="User Name"
                    value={name || ''}
                    className="form-control"
                    placeholder="Enter User Name"
                    type="text"
                    required
                  />
                  <AvField name="idx" value={idx || ''} type="hidden" />
                </div>
                </Col>
                <Col md="6" lg="4">
                <div className="mb-3">
                  <AvField
                    name="username"
                    label="User Name"
                    value={name || ''}
                    className="form-control"
                    placeholder="Enter User Name"
                    type="text"
                    required
                  />
                  <AvField name="idx" value={idx || ''} type="hidden" />
                </div>
                </Col>
                <div className="text-center mt-4">
                  <Button type="submit" color="danger" className="submit__button">
                    Edit User Name
                  </Button>
                </div>
                </Row>
              </AvForm>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

NewUser.propTypes = {
  editProfile: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, { editProfile, resetProfileFlag })(NewUser)
)
