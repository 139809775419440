import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { setCurrentUser } from "../../../helpers/Utils"
import { useHistory } from "react-router-dom"
import {
   Dropdown,
   DropdownToggle,
   DropdownMenu,
   DropdownItem,
} from "reactstrap"
import { getCurrentUser } from "../../../helpers/Utils"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import axios from "axios"
// users
import user1 from "../../../assets/images/users/user-4.jpg"

const ProfileMenu = props => {
   // Declare a new state variable, which we'll call "menu"
   const [menu, setMenu] = useState(false)
   const [username, setusername] = useState("Admin")

   useEffect(() => {
      if (localStorage.getItem("authUser")) {
         if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
            const obj = JSON.parse(localStorage.getItem("authUser"))
            setusername(obj.displayName)
         } else if (
            process.env.REACT_APP_DEFAULTAUTH === "fake" ||
            process.env.REACT_APP_DEFAULTAUTH === "jwt"
         ) {
            const obj = JSON.parse(localStorage.getItem("authUser"))
            setusername(obj.username)
         }
      }
   }, [props.success])

   const history = useHistory()

   const logout = () => {
      axios.get(`${process.env.REACT_APP_API_URL}/${localStorage.getItem('company_name')}/logout`, {
         headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
         },
      })
      setCurrentUser("")
      localStorage.setItem("DashboardData", "")
      localStorage.setItem("LastUpdated", "")
      localStorage.setItem("NavCounters", "")
      history.push("/login")
   }

   return (
      <React.Fragment>
         <Dropdown
            isOpen={menu}
            toggle={() => setMenu(!menu)}
            className="d-inline-block"
         >
            <DropdownToggle
               className="btn header-item waves-effect"
               id="page-header-user-dropdown"
               tag="button"
               style={{
                  display: 'flex',
                  alignItems: 'center'
               }}
            >
               <span className="d-none d-md-inline-block pe-2 long-name">
                  {getCurrentUser() && getCurrentUser().name}{" "}
               </span>
               <img
                  className="rounded-circle header-profile-user"
                  src={getCurrentUser() && getCurrentUser().photo}
                  alt="Header Avatar"
               />
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
               <Link to="/profile">
                  <DropdownItem>
                     {" "}
                     <i className="bx bx-user font-size-16 align-middle me-1" />
                     {props.t("Profile")}{" "}
                  </DropdownItem>
               </Link>
               <div className="dropdown-divider" />
               {/* <Link to="/payment-history">
                        <DropdownItem>
                           {" "}
                           <i className="bx bx-user font-size-16 align-middle me-1" />
                           {props.t("Payment History")}{" "}
                        </DropdownItem>
                     </Link> */}
               {/* <div className="dropdown-divider" /> */}
               {/* <DropdownItem tag="a" href="auth-lock-screen"> */}
               {/*   <i className="bx bx-lock-open font-size-16 align-middle me-1" /> */}
               {/*   {props.t("Lock screen")} */}
               {/* </DropdownItem> */}
               <button onClick={() => logout()} className="dropdown-item">
                  <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
                  <span>Logout</span>
               </button>
            </DropdownMenu>
         </Dropdown>
      </React.Fragment>
   )
}

ProfileMenu.propTypes = {
   success: PropTypes.any,
   t: PropTypes.any,
}

const mapStatetoProps = state => {
   const { error, success } = state.Profile
   return { error, success }
}

export default withRouter(
   connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)