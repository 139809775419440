import React, { useState, useEffect } from "react"
import { Modal, Button } from "reactstrap"
import axios from "axios"
import { AvForm, AvField, AvRadio, AvRadioGroup } from "availity-reactstrap-validation"
import { getCurrentUser } from "../../../helpers/Utils"
import moment from "moment"
import { addDays } from "date-fns"
import DatePicker from "react-datepicker"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"


const ResendCampaign = (props) => {
   const [loading, setLoading] = useState(false)
   const [showDate, setShowDate] = useState("1")
   const [messages, setMessages] = useState([])
   const [selectedMessage, setSelectedMessage] = useState(props.campaign?.message)
   const [file, setFile] = useState(props.campaign?.attachment)
   const [startDate, setStartDate] = useState(addDays(new Date(), 1))


   const getMessages = () => {
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/message-templates?per_page=5000`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setMessages(res.data.data.data)
            } else {
            }
         })
   }

   const handleSelectChange = (event) => {
      const newSelectedId = parseInt(event.target.value);
      const selectedObject = messages?.find(item => item.id === newSelectedId);
      const message = selectedObject ? selectedObject.message : "";
      setSelectedMessage(message)
   };

   const getFile = e => {
      setFile(e.target.files[0])
   }

   const createCampaign = (e, value) => {
      setLoading(true)
      const api = axios.create();
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      api.interceptors.request.use(config => {
         config.cancelToken = source.token;
         return config;
      });

      const timeout = 120000; // 2 minute

      setTimeout(() => {
         source.cancel('Timeout');
      }, timeout);

      axios
         .put(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/campaigns/${props.campaign.id}`,
            {
               lead_ids: props.campaign.lead_ids,
               name: value.name,
               template_id: value.template_id,
               message: value.message,
               attachment: file,
               send_now: value.send_now,
               scheduled_date_time: e.target.scheduled_date_time !== undefined ? e.target.scheduled_date_time.value : "",
               status: "resending"
            },
            {
               headers: {
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               props.setCampaign(res.data.data)
               props.setcampaign_model(false)
               props.setIsOpen(false)
               toast.success(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
               setLoading(false)
            } else {
               setLoading(false)
               toast.error(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            }
         })
   }

   useEffect(() => {
      getMessages()
   }, [])

   useEffect(() => {
      const close = e => {
         if (e.key === "Escape") {
            props.setcampaign_model(false)
         }
      }
      window.addEventListener("keydown", close)
      return () => window.removeEventListener("keydown", close)
   }, [])

   return (
      <div>
         <Modal isOpen={props.campaign_model} centered={true}>
            <AvForm
               className="modal-title mt-0"
               id="myModalLabel"
               onValidSubmit={(e, v) => {
                  createCampaign(e, v)
               }}
            >
               <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myLargeModalLabel">
                     Resend Campaign
                  </h5>
                  <button
                     type="button"
                     className="cross__btn"
                     onClick={() => props.setcampaign_model(false)}
                     aria-hidden="true"
                  >
                     &times;
                  </button>
               </div>
               <div className="modal-body">
                  <div className="mb-3">
                     <AvField
                        type="text"
                        placeholder="Enter campaign name"
                        name="name"
                        label="Campaign Name"
                        value={props.campaign?.name}
                     />
                  </div>
                  <div className="mb-3">
                     <AvField
                        type="select"
                        className="select-arrow"
                        name="template_id"
                        label="Select Message"
                        onChange={(e) => handleSelectChange(e)}
                        value={props.campaign?.template_id}
                     >
                        <option value="">Select</option>
                        {messages?.map((item) =>
                           <option value={item.id}>{item.name}</option>
                        )}
                     </AvField>
                  </div>
                  <div className="mb-3">
                     <label>Select Attachment</label>
                     <AvField
                        type="file"
                        name="attachment"
                        accept=".xls,.xlsx,.pdf,.png,.jpg,.jpeg,.txt,.csv,.docx"
                        onChange={e => getFile(e)}
                     />
                  </div>
                  <div className="mb-3">
                     <AvField
                        type="textarea"
                        placeholder="Enter message"
                        name="message"
                        label="Message"
                        value={selectedMessage}
                     />
                     <span className="fst-normal">{`Use these {customer_name}, {order_id}, {order_date}, {matured_date} variables to create message.`}</span>
                  </div>
                  <div className="mb-3">
                     <AvRadioGroup inline name="send_now" defaultValue="1" onChange={(e) => setShowDate(e.target.value) }>
                        <AvRadio label="Schedule Message" value="0" />
                        <AvRadio label="Send Now" value="1" />
                     </AvRadioGroup>
                     {showDate === "0" ?
                        <DatePicker
                           className="w-100 form-control"
                           showTimeSelect
                           dateFormat="yyyy-MM-dd h:mm aa"
                           name="scheduled_date_time"
                           selected={startDate}
                           minDate={moment().toDate()}
                           onChange={date => setStartDate(date)}
                        />
                        :
                        ""
                     }
                  </div>
               </div>
               <div className="modal-footer">
                  <div className="text-center">
                     {loading ? (
                        <button
                           className="btn btn-primary disabled"
                           type="button"
                           disabled
                        >
                           Loading
                        </button>
                     ) : (
                        <button
                           className="btn btn-primary"
                           type="submit"
                        // onClick={() => setModal2(false)}
                        >
                           Submit
                        </button>
                     )}
                  </div>
               </div>
            </AvForm>
         </Modal>
      </div>
   )
}

export default ResendCampaign
