import React, { useState, useEffect } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import { getCurrentUser, getPermission } from "../../../helpers/Utils"
import axios from "axios"
import ProductsModal from "./ProductsModal"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

let selectedRoleId = []

const ProductsList = props => {
   const [product, setProduct] = useState(props.data)
   const [product2, setProduct2] = useState(props.data)
   delete product2.last_order
   const [productValues, setProductValues] = useState(Object.values(product2))
   const [modal_product, setmodal_product] = useState(false)
   const [isOpen, setIsOpen] = useState(false)
   const [confirm_alert, setconfirm_alert] = useState(false)
   const [success_dlg, setsuccess_dlg] = useState(false)
   const [dynamic_title, setdynamic_title] = useState("")
   const [dynamic_description, setdynamic_description] = useState("")

   const deleteProduct = e => {
      axios
         .delete(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/product/${e}`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setProduct("")
               setdynamic_description(res.data.message)
               toast.success(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            }
         })
   }

   const editProduct = e => {
      setIsOpen(true)
      setmodal_product(true)
   }

   useEffect(() => {
      selectedRoleId = []
   }, [])

   const getSelectedId = (e, check) => {
      if (check.target.checked) {
         selectedRoleId.push(e)
         const uniqueID = selectedRoleId.filter((val, id, array) => {
            return array.indexOf(val) == id
         })
         selectedRoleId = uniqueID
         props.setLeadsSelectedIds(selectedRoleId)
      } else {
         selectedRoleId = props.leadsSelectedIds
         var array = selectedRoleId
         var index = array.indexOf(e)
         if (index !== -1) {
            array.splice(index, 1)
         }
         props.setLeadsSelectedIds(selectedRoleId)
      }
      if (selectedRoleId.length == 0) {
         props.setBlur(false)
      } else {
         props.setBlur(true)
      }
   }

   if (product === "") {
      return ""
   } else
      return (
         <>
            {isOpen ? (
               <ProductsModal
                  modal_product={modal_product}
                  setmodal_product={setmodal_product}
                  product={product}
                  setProduct={setProduct}
                  setIsOpen={setIsOpen}
                  getProducts={props.getProducts}
               />
            ) : null}
            <tr key={props.i}>
               <td>
                  <input
                     onChange={e => getSelectedId(product.id, e)}
                     name="chk"
                     value={product.id}
                     className="p-0 d-inline-block"
                     type="checkbox"
                  />
               </td>
               <td>{props.counter + props.i}</td>
               {productValues?.map((item, i) => (
                  <td key={i}>
                     <p
                        dangerouslySetInnerHTML={{
                           __html: item,
                        }}
                     />
                  </td>
               ))}
               {/* <td>{product.name}</td>
               <td>{product.mrp}</td>
               <td>{product.min_price}</td>
               <td>{product.max_price}</td>
               <td>{product.gst}%</td>
               <td>{product.mfr}</td>
               <td>{product.expiry_date}</td> */}
               <td>
                  <a
                     className="text-warning"
                     onClick={() => {
                        editProduct(product.id)
                     }}
                  >
                     <i className="fas fa-pen" />
                  </a>
                  {getPermission().product.product.delete.is_checked === "yes" ?
                     <a
                        className="text-danger ms-2"
                        onClick={() => {
                           setconfirm_alert(true)
                        }}
                     >
                        <i className="fas fa-trash-alt" />
                     </a>
                     :
                     ""
                  }
                  {confirm_alert ? (
                     <SweetAlert
                        title="Are you sure?"
                        warning
                        showCancel
                        confirmButtonText="Yes, delete it!"
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={() => {
                           setconfirm_alert(false)
                           setsuccess_dlg(true)
                           deleteProduct(product.id)
                           setdynamic_title("Deleted")
                           setdynamic_description("Your file has been deleted.")
                        }}
                        onCancel={() => setconfirm_alert(false)}
                     >
                        You won't be able to revert this!
                     </SweetAlert>
                  ) : null}
               </td>
            </tr>
         </>
      )
}
export default ProductsList
