import PropTypes from "prop-types"
import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import { Container, Row, Col } from "reactstrap"
import HostDomain from "../../components/Admin/profileComps/HostDomain"
const hostDomain = () => {
   return (
      <React.Fragment>
         <div className="page-content">
            <MetaTags>
               <title>Host on your Domain | KlikCRM</title>
            </MetaTags>
            <Container fluid>
               <HostDomain />
            </Container>
         </div>
      </React.Fragment>
   )
}
export default hostDomain
