import React, { useState, useEffect } from "react"
import axios from "axios"
import { Modal } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import Loader from "../../Loader/loader.js"
import { getCurrentUser } from "../../../helpers/Utils"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"


let packingNames = [];

export default function PackingModal(props) {
   const [loading, setLoading] = useState(false)
   const [loader, setLoader] = useState('')
   const [loader2, setLoader2] = useState('')
   const [category, setCategory] = useState([])
   const [subCate, setSubCate] = useState([])
   const [showSubCate, setShowSubCate] = useState(false)
   const [cateName, setCateName] = useState({ formValues: [{ name: "" }], })


   const getAllCate = () => {
      axios
         .get(`${process.env.REACT_APP_API_URL}/${localStorage.getItem('company_name')}/category?per_page=5000`, {
            headers: {
               "content-type": "application/json",
               Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
            },
         })
         .then(function (res) {
            if (res.data.status) {
               setCategory(res.data.data.data)
            } else { }
         })
   }

   const cateBasis = (e) => {
      setLoader(<Loader />)
      axios
         .get(`${process.env.REACT_APP_API_URL}/${localStorage.getItem('company_name')}/category-basis?category_id=${e.target.value}`, {
            headers: {
               "content-type": "application/json",
               Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
            },
         })
         .then(function (res) {
            if (res.data.status) {
               setSubCate(res.data.data)
               setShowSubCate(true)
            } else { }
            setLoader('')
         })
   }

   const handleChange = (i, e) => {
      let formValues = cateName.formValues;
      formValues[i][e.target.name] = e.target.value;
      setCateName({ formValues });
   }

   const addFormFields = () => {
      setCateName({
         formValues: [...cateName.formValues, { name: "" }],
      });
   }

   const removeFormFields = (i) => {
      setLoader2(<Loader />)
      let formValues = cateName.formValues;
      formValues.splice(i, 1);
      setTimeout(() => {
         setCateName({ formValues });
         setLoader2("")
      }, 500)
   }

   const submitPacking = (e, value) => {
      if (cateName.formValues[0].name !== "") {
         packingNames = cateName.formValues.map((form) => form.name)
      }
      setLoading(true)
      const api = axios.create();
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      api.interceptors.request.use(config => {
         config.cancelToken = source.token;
         return config;
      });

      const timeout = 120000; // 2 minute

      setTimeout(() => {
         source.cancel('Timeout');
      }, timeout);
      if (props.packing !== undefined) {
         axios
            .put(
               `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
                  "company_name"
               )}/packing/${props.packing.id}?category_id=${value.category_id}&sub_category_id=${value.sub_category_id
               }&packing=${value.packing}`,
               {},
               {
                  headers: {
                     Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
                  },
               }
            )
            .then(function (res) {
               if (res.data.status) {
                  setLoading(false)
                  props.setIsOpen(false)
                  props.setmodal_standard(false)
                  props.setPacking(res.data.data)
                  toast.success(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               } else {
                  setLoading(false)
                  toast.error(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               }
            })
      } else {
         axios
            .post(
               `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
                  "company_name"
               )}/packing`,
               {
                  category_id: value.category_id,
                  sub_category_id: value.sub_category_id,
                  packings: packingNames,
               },
               {
                  headers: {
                     Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
                  },
               }
            )
            .then(function (res) {
               if (res.data.status) {
                  setLoading(false)
                  props.getAllPackings()
                  props.setmodal_standard(false)
                  setCateName({ formValues: [{ name: "" }], })
                  toast.success(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               } else {
                  setLoading(false)
                  toast.error(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               }
            })
      }
   }

   useEffect(() => {
      getAllCate()
   }, [])

   useEffect(() => {
      const close = (e) => {
         if (e.key === 'Escape') {
            props.setmodal_standard(false)
            setCateName({ formValues: [{ name: "" }], })
         }
      }
      window.addEventListener('keydown', close)
      return () => window.removeEventListener('keydown', close)
   }, [])

   return (
      <>
         <Modal isOpen={props.modal_standard} centered={true}>
            <AvForm
               className="modal-title mt-0"
               id="myModalLabel"
               onValidSubmit={(e, v) => {
                  submitPacking(e, v)
               }}
            >
               <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myLargeModalLabel">
                     {props.packing !== undefined ? "Edit Packing" : "Create Packing"}
                  </h5>
                  <button
                     type="button"
                     className="cross__btn"
                     onClick={() => {props.setmodal_standard(false), setCateName({ formValues: [{ name: "" }], })}}
                     aria-hidden="true"
                  >
                     &times;
                  </button>
               </div>
               <div className="modal-body">
                  <div className="mb-3">
                     <AvField
                        label="Category"
                        className="select-arrow"
                        type="select"
                        name="category_id"
                        onChange={(e) => cateBasis(e)}
                        value={props.packing && props.packing.category_id}
                     >
                        <option value="" hidden>Select category</option>
                        {category && category.map((item, i) => (
                           <option value={item.id} key={i}>{item.name}</option>
                        ))}
                     </AvField>
                  </div>
                  {loader}
                  {showSubCate ?
                     <div className="mb-3">
                        <AvField
                           label="Sub-Category"
                           className="select-arrow"
                           type="select"
                           name="sub_category_id"
                           value={props.packing && props.packing.sub_category_id}
                        >
                           <option value="" hidden>Select sub-category</option>
                           {subCate && subCate.map((item, i) => (
                              <option value={item.id} key={i}>{item.sub_category}</option>
                           ))}
                        </AvField>
                     </div>
                     : ""}
                  {props.packing !== undefined ?
                     <div className="mb-3">
                        <AvField
                           label="Packing"
                           type="text"
                           name="packing"
                           value={props.packing && props.packing.packing}
                        />
                     </div>
                     :
                     <>
                        <div className="d-flex justify-content-between mb-3 mt-2">
                           <label>Packing</label>
                           <div className="button-section">
                              <button
                                 className="btn btn-success p-1"
                                 type="button"
                                 onClick={() => addFormFields()}
                              >
                                 <i className="fa fa-plus p-1"></i>
                              </button>
                           </div>
                        </div>
                        {loader2 !== "" ?
                           loader2
                           :
                           <>
                              {cateName.formValues.map((element, index) => (
                                 <div className="mb-3 d-flex justify-content-between" key={index}>
                                    <div style={{ width: 'calc(100% - 40px)' }}>
                                       <AvField
                                          type="text"
                                          name="name"
                                          placeholder="Name"
                                          onChange={(e) => handleChange(index, e)}
                                          value={element.name || ""}
                                       />
                                    </div>
                                    {index ? (
                                       <span
                                          className="btn btn-danger"
                                          onClick={() => removeFormFields(index)}
                                       >
                                          &times;
                                       </span>
                                    ) : null}
                                 </div>
                              ))}
                           </>
                        }
                     </>
                  }
                  <div className="modal-footer">
                     <div className="text-end">
                        {loading ? (
                           <button
                              className="btn btn-primary disabled"
                              type="button"
                              disabled
                           >
                              Loading
                           </button>
                        ) : (
                           <button
                              className="btn btn-primary"
                              type="submit"
                           // onClick={() => props.setmodal_standard(false)}
                           >
                              {props.packing !== undefined
                                 ? "Submit"
                                 : "Create"}
                           </button>
                        )}
                     </div>
                  </div>
               </div>
            </AvForm>
         </Modal>
      </>
   )
}
