import React, { useState } from "react"
import {
   Container,
   Row,
   Col,
   Dropdown,
   DropdownToggle,
   DropdownItem,
   DropdownMenu,
   NavLink,
} from "reactstrap"
import { Link } from "react-router-dom"
import logolightImg from "../../assets/images/support.png"
import { sendWhatsAppMessage } from "../../helpers/Utils"

const Footer = () => {
   const [dropdownOpen, setDropdownOpen] = useState(false)

   const toggle = () => setDropdownOpen(prevState => !prevState)

   return (
      <React.Fragment>
         <footer className="footer">
            <div className="klikcrm_support">
               <Dropdown isOpen={dropdownOpen} toggle={toggle} direction={"up"}>
                  {/* <DropdownToggle caret className="bg-transparent border-0">
              <img src={logolightImg} width="200" />
            </DropdownToggle> */}
                  <DropdownMenu className="py-2">
                     <DropdownItem className="py-2">
                        <a
                           href={sendWhatsAppMessage("8054805705")}
                           target="_blank"
                           style={{
                              fontSize: "15px",
                              color: "rgb(75, 173, 248)",
                           }}
                        >
                           <i className="fab fa-whatsapp" />
                           &nbsp; Drop a message
                        </a>
                     </DropdownItem>
                     <DropdownItem className="py-2">
                        <a
                           href="tel:+918054805705"
                           target="_blank"
                           style={{
                              fontSize: "15px",
                              color: "rgb(75, 173, 248)",
                           }}
                        >
                           <i className="fas fa-phone-volume" />
                           &nbsp; 978 1239 781
                        </a>
                     </DropdownItem>
                  </DropdownMenu>
               </Dropdown>
            </div>
            <Container fluid={true}>
               <Row>
                  <div
                     className="col-12 d-flex justify-content-center"
                     style={{ pointer: "cursor" }}
                  >
                     <Link to="/privacy-policy" className="text-info me-3">
                        Privacy Policy
                     </Link>
                     <Link to="/terms-conditions" className="text-info ms-3">
                        Terms and Conditions
                     </Link>
                     <Link to="/return-policy" className="text-info ms-3">
                        Return Policy
                     </Link>
                  </div>
                  {/* <div className="col-8"></div> */}
               </Row>
               <Row>
                  <div className="col-12">
                     © {new Date().getFullYear()}{" "}
                     <span className="d-none d-sm-inline-block">
                        {" "}
                        klikcrm.com. {/* <i className=""></i> */}
                        All rights reserved.
                     </span>
                  </div>
               </Row>
            </Container>
         </footer>
      </React.Fragment>
   )
}

export default Footer
