import React, { useState, useEffect } from "react"

const LongText = props => {
  const [showAll, setShowAll] = useState(false)
  const [limit, setLimit] = useState(50)

  const toShow = props.content && props.content.substring(0, limit) + ""

  return (
    <div>
      {props.content && props.content.length <= limit ? (
        <p
          dangerouslySetInnerHTML={{
            __html: props.content,
          }}
        />
      ) : (
        <>
          {showAll ? (
            <>
              <span
                dangerouslySetInnerHTML={{
                  __html: props.content,
                }}
              />
              <button
                className="btn btn-link p-0"
                onClick={() => setShowAll(false)}
              >
                Read less
              </button>
            </>
          ) : (
            <div>
              {toShow ? (
                <>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: toShow,
                    }}
                  />
                  <button
                    className="btn btn-link p-0"
                    onClick={() => setShowAll(true)}
                  >
                    ... Read more
                  </button>
                </>
              ) : (
                ""
              )}
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default LongText
