import React, { useState, useEffect } from "react"
import { Container, Row, Col, Modal, FormGroup, Input } from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert"
import { getCurrentUser } from "../../../helpers/Utils"
import { Link } from "react-router-dom"
import axios from "axios"
import Paginations from "../../Pagination"
import Loader from "../../Loader/loader.js"
import LocationList from "./LocationList"
import LocationModal from "./LocationModal"

const Location = () => {
  const [modal_standard, setmodal_standard] = useState(false)
  const [location, setLocation] = useState([])
  const [error, setError] = useState("")
  const [loader, setLoader] = useState(<Loader />)
  const [allPages, setAllPages] = useState([])
  const [counter, setCounter] = useState("")

  const getLocation = () => {
    setLoader(<Loader />)
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/location`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLoader("")
          setError(res.data.message)
          setCounter(res.data.data.from)
          setLocation(res.data.data)
          setAllPages(res.data.data)
        } else {
          setLoader("")
          setError(res.data.message)
        }
      })
  }

  // const paginate = (e) => {
  // 	setLocation([])
  // 	setAllPages([])
  // 	setCounter('')
  // 	setLoader(<Loader />)
  // 	axios
  // 		.get(
  // 			`${e}`,
  // 			{
  // 				headers: {
  // 					"content-type": "application/json",
  // 					Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token }`,
  // 				},
  // 			}
  // 		)
  // 		.then(function (res) {
  // 			if (res.data.status) {
  // setLoader("")
  // setCounter(res.data.data.from)
  // setAllPages(res.data.data)
  // 			setLocation(res.data.data.data)
  //  } else {
  //    setError(res.data.message)
  //    setLoader("")
  //  }
  // 		})
  // }

  useEffect(() => {
    getLocation()
  }, [])

  return (
    <>
      <LocationModal
        modal_standard={modal_standard}
        setmodal_standard={setmodal_standard}
        getLocation={getLocation}
      />
      <div className="page-title-box">
        <Row className="align-items-center">
          <Col md={6}>
            <h6 className="page-title">Locations</h6>
          </Col>
          <Col md={6}>
            <h6 className="page-title float-end">
              <button
                className="btn btn-success"
                type="button"
                onClick={() => {
                  setmodal_standard(true)
                }}
              >
                Add New
              </button>
            </h6>
          </Col>
        </Row>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="lead_table table-responsive">
            <table style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <th>#</th>
                  <th>State</th>
                  <th>District</th>
                  <th>City</th>
                  <th>Info</th>
                  <th>Pin code</th>
                  <th>Action</th>
                </tr>
                {location &&
                  location.map((item, obj) => (
                    <LocationList
                      key={obj}
                      data={item}
                      i={obj}
                      counter={counter}
                    />
                  ))}
              </tbody>
            </table>
            {error ? (
              <span className="text-danger mt-3 d-table m-auto" role="alert">
                {error}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {loader}
      {/* {allPages ?
				<Paginations data={allPages} paginate={paginate} />
			: ""} */}
    </>
  )
}
export default Location
