import React, { useState, useEffect } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import { getCurrentUser } from "../../../helpers/Utils"
import { Link } from "react-router-dom"
import axios from "axios"
import MessageModal from "./messageModal"
import LongText from "../../MessageField"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

let selectedRoleId = []

const MessageList = props => {
   const [message, setMessage] = useState(props.data)
   const [confirm_alert, setconfirm_alert] = useState(false)
   const [success_dlg, setsuccess_dlg] = useState(false)
   const [dynamic_title, setdynamic_title] = useState("")
   const [dynamic_description, setdynamic_description] = useState("")
   const [isOpen, setIsOpen] = useState(false)
   const [modal_messages, setmodal_messages] = useState(false)

   const deleteMessage = e => {
      axios
         .delete(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/message-templates/${e} `,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setMessage("")
               toast.success(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            }
         })
   }

   const editMessage = () => {
      setIsOpen(true)
      setmodal_messages(true)
   }

   useEffect(() => {
      selectedRoleId = []
   }, [])

   const getSelectedId = (e, check) => {
      if (check.target.checked) {
         selectedRoleId.push(e)
         const uniqueID = selectedRoleId.filter((val, id, array) => {
            return array.indexOf(val) == id
         })
         selectedRoleId = uniqueID
         props.setLeadsSelectedIds(selectedRoleId)
      } else {
         selectedRoleId = props.leadsSelectedIds
         var array = selectedRoleId
         var index = array.indexOf(e)
         if (index !== -1) {
            array.splice(index, 1)
         }
         props.setLeadsSelectedIds(selectedRoleId)
      }
      if (selectedRoleId.length == 0) {
         props.setBlur(false)
      } else {
         props.setBlur(true)
      }
   }

   if (message === "") {
      return ""
   } else
      return (
         <>
            {isOpen ? (
               <MessageModal
                  modal_messages={modal_messages}
                  setmodal_messages={setmodal_messages}
                  message={message}
                  setMessage={setMessage}
                  setIsOpen={setIsOpen}
               />
            ) : null}
            <tr key={props.i}>
               <td>
                  <input
                     onChange={e => getSelectedId(message.id, e)}
                     name="chk"
                     value={message.id}
                     className="p-0 d-inline-block"
                     type="checkbox"
                  />
               </td>
               <td>{props.counter + props.i}</td>
               <td>{message.name}</td>
               <td>
                  {message.events === "lead_create" ?
                     "Lead Create"
                     :
                     message.events === "lead_matured" ?
                        "Lead Matured"
                        :
                        message.events === "followup_reminder" ?
                           "Followup Reminder"
                           :
                           message.events === "new_proforma" ?
                              "New Proforma"
                              :
                              message.events === "new_order" ?
                                 "New Order"
                                 :
                                 message.events === "update_order" ?
                                    "Update Order"
                                    :
                                    message.events === "update_proforma" ?
                                       "Update Proforma"
                                       :
                                       message.events === "payment_update" ?
                                          "Payment Update"
                                          :
                                          message.events === "payment_request" ?
                                             "Payment Request"
                                             :
                                             message.events === "dispatch_details" ?
                                                "Dispatch Details"
                                                :
                                                message.events === "new_products/services" ?
                                                   "New Products/Services"
                                                   :
                                                   message.events === "product_purchase_reminder" ?
                                                      "Product Purchase Reminder"
                                                      :
                                                      message.events === "purchase_order" ?
                                                         "Purchase Order"
                                                         :
                                                         ""
                  }
               </td>
               <td>
                  <LongText content={message.message} />
               </td>
               <td>
                  <a
                     className="text-warning"
                     onClick={() => {
                        editMessage(message.id)
                     }}
                  >
                     <i className="fas fa-pen" />
                  </a>
                  <a
                     className="text-danger ms-2"
                     onClick={() => {
                        setconfirm_alert(true)
                     }}
                  >
                     <i className="fas fa-trash-alt" />
                  </a>
                  {confirm_alert ? (
                     <SweetAlert
                        title="Are you sure?"
                        warning
                        showCancel
                        confirmButtonText="Yes, delete it!"
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={() => {
                           setconfirm_alert(false)
                           setsuccess_dlg(true)
                           deleteMessage(message.id)
                           setdynamic_title("Deleted")
                           setdynamic_description("Your file has been deleted.")
                        }}
                        onCancel={() => setconfirm_alert(false)}
                     >
                        You won't be able to revert this!
                     </SweetAlert>
                  ) : null}
               </td>
            </tr>
         </>
      )
}
export default MessageList
