import React from "react"
import $ from "jquery"

const IdleTime = () => {
  var time = new Date().getTime()
  $(document.body).bind("mousemove keypress", function (e) {
    time = new Date().getTime()
  })

  function refresh() {
    if (new Date().getTime() - time >= 600000) window.location.reload(true)
    else setTimeout(refresh, 10000)
  }

  setTimeout(refresh, 10000)

  return <div></div>
}

export default IdleTime
