import React, { useState, useEffect } from "react"
import ChartistGraph from "react-chartist"
import Loader from "../../../components/Loader/loader.js"
import { Row, Col, Modal } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import axios from "axios"
import { getCurrentUser } from "../../../helpers/Utils"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

let graphSeries = []

const lineareachart = () => {
   const [loader, setLoader] = useState(<Loader />)
   const [modal, setModal] = useState(false)
   const [loading, setLoading] = useState(false)
   const [allUsers, setAllUsers] = useState([])
   const [allSource, setAllSource] = useState([])

   const getGraphData = (e, value) => {
      if (e == undefined) {
         setLoader(<Loader />)
         axios
            .get(`${process.env.REACT_APP_API_URL}/${localStorage.getItem('company_name')}/dashboard-graph`, {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token }`,
               },
            })
            .then(function (res) {
               if (res.data.status) {
                  graphSeries = res.data.data.map(item => item.lead_count)
                  setLoader("")
               } else { }
            })
      } else {
         setLoading(true)
         // graphSeries = []
         axios
            .get(`${process.env.REACT_APP_API_URL}/${localStorage.getItem('company_name')}/dashboard-graph?leadholder_id=${value.leadholder_id}&leadsource_id=${value.leadsource_id}`, {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token }`,
               },
            })
            .then(function (res) {
               if (res.data.status) {
                  graphSeries = res.data.data.map(item => item.lead_count)
                  setLoading(false)
                  setModal(false)
               } else {
                  setLoading(false)
                  toast.error(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
                  setModal(false)
               }
            })
      }
   }

   const getAllUsers = () => {
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/get-lead-holders?per_page=5000`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setAllUsers(res.data.data.data)
               setModal(true)
            } else {
            }
         })
   }

   const getAllSource = () => {
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/lead/source?per_page=5000`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setAllSource(res.data.data.data)
               setModal(true)
            } else {
            }
         })
   }

   var lineChartData = {
      labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      series: [graphSeries],
   }

   var lineChartOptions = {
      low: 0,
      showArea: true
   };

   useEffect(() => {
      getGraphData()
   }, [])
   return (
      <React.Fragment>
         <div className="d-flex justify-content-end">
            <button
               className="btn btn-sm btn-primary me-2"
               onClick={(e) => { getAllSource(), getAllUsers() }}
            >
               <i className="fa fa-filter me-2"></i>
               Filter
            </button>
            <button
               className="btn btn-sm btn-primary"
               onClick={() => getGraphData()}
            >
               <i className="fa fa-undo me-2"></i>
               Clear Filter
            </button>
         </div>
         <Row>
            <Col lg={12}>
               <ChartistGraph
                  style={{ height: "300px" }}
                  data={lineChartData}
                  options={lineChartOptions}
                  type={"Line"}
               />
            </Col>
            {/* <Col lg={2}>
              <div className="text-center">
                <p className="text-muted mb-4">This month</p>
                <h3>$34,252</h3>
                <p className="text-muted mb-5">
                  It will be as simple as in fact it will be
                  occidental.
                </p>
              </div>
            </Col> */}
            {loader}
         </Row>
         <Modal isOpen={modal} centered={true}>
            <AvForm
               className="modal-title mt-0"
               id="myModalLabel"
               onValidSubmit={(e, v) =>
                  getGraphData(e, v)
               }
            >
               <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myLargeModalLabel">
                     Filter by User or Leadsource
                  </h5>
                  <button
                     type="button"
                     className="cross__btn"
                     onClick={() => setModal(false)}
                     aria-hidden="true"
                  >
                     &times;
                  </button>
               </div>
               <div className="modal-body">
                  <Row>
                     <Col md={6}>
                        {/* <div className="mb-3">
                           <AvField
                              className="select-arrow"
                              label="Lead Holder"
                              type="select"
                              name="leadholder_id"
                           >
                              <option value="" hidden>Select Leadholder</option>
                              {allUsers && allUsers.map((option, i) => (
                                 <option value={option.id} key={i}>{option.name}</option>
                              ))}
                           </AvField>
                        </div> */}
                        {getCurrentUser() && getCurrentUser().role_name == "Executive" ?
                           <div className="">
                              <AvField
                                 className="select-arrow"
                                 label="Lead Holder"
                                 type="select"
                                 name="leadholder_id"
                                 value={getCurrentUser().id}
                              >
                                 <option value="" hidden>Select Leadholder</option>
                                 {allUsers && allUsers.map((option, i) => (
                                    <option value={option.id} key={i} hidden>{option.name}</option>
                                 ))}
                              </AvField>
                           </div>
                           : getCurrentUser() && getCurrentUser().role_name == "Team Leader" ?
                              <div className="mb-3">
                                 <AvField
                                    className="select-arrow"
                                    label="Lead Holder"
                                    type="select"
                                    name="leadholder_id"
                                 >
                                    <option value="" hidden>Select Leadholder</option>
                                    {allUsers && allUsers.map((option, i) => option.role_name !== 'Administrator' ?
                                       <option value={option.id} key={i}>{option.name}</option>
                                       : "")}
                                 </AvField>
                              </div>
                              :
                              <div className="mb-3">
                                 <AvField
                                    className="select-arrow"
                                    label="Lead Holder"
                                    type="select"
                                    name="leadholder_id"
                                 >
                                    <option value="" hidden>Select Leadholder</option>
                                    {allUsers && allUsers.map((option, i) => (
                                       <option value={option.id} key={i}>{option.name}</option>
                                    ))}
                                 </AvField>
                              </div>
                        }
                     </Col>
                     <Col md={6}>
                        <div className="mb-3">
                           <AvField
                              className="select-arrow"
                              label="Lead Source"
                              type="select"
                              name="leadsource_id"
                           >
                              <option value="" hidden>Select Leadsource</option>
                              {allSource && allSource.map((option, i) => (
                                 <option value={option.id} key={i}>{option.name}</option>
                              ))}
                           </AvField>
                        </div>
                     </Col>
                  </Row>

               </div>
               <div className="modal-footer">
                  <div className="text-center">
                     {loading ? (
                        <button
                           className="btn btn-primary disabled"
                           type="button"
                           disabled
                        >
                           Loading
                        </button>
                     ) : (
                        <button
                           className="btn btn-primary"
                           type="submit"
                        // onClick={() => props.setmodal_standard(false)}
                        >
                           Filter
                        </button>
                     )}
                  </div>
               </div>
            </AvForm>
         </Modal>
      </React.Fragment>
   )
}

export default lineareachart
