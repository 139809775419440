import React, { useState, useEffect } from "react"
import {
   Row,
   Col,
   Modal,
} from "reactstrap"
import axios from "axios"
import {
   AvForm,
   AvField,
} from "availity-reactstrap-validation"
import { DateRange } from "react-date-range"
import { getCurrentUser } from "../../../helpers/Utils"
import Select from "react-select"


let fieldValues = []
let fieldText = []
let fieldSelect = []
let fieldFlipButton = []
const followupOptions = []
const deadOptions = []
const matureOptions = []

const StatsFilter = props => {
   const [dFilter, setDFilter] = useState(false)
   const [allFollowup, setAllFollowup] = useState([])
   const [allMature, setAllMature] = useState([])
   const [allDead, setAllDead] = useState([])
   const [selectedType, setSelectedType] = useState(localStorage.getItem("leadStatus") && localStorage.getItem("leadStatus"))
   const [dateFilter, setDateFilter] = useState([
      {
         startDate: new Date(),
         endDate: null,
         key: "selection",
      },
   ])

   const formatDate = date => {
      let d = new Date(date)
      let month = (d.getMonth() + 1).toString()
      let day = d.getDate().toString()
      let year = d.getFullYear()
      if (month.length < 2) {
         month = "0" + month
      }
      if (day.length < 2) {
         day = "0" + day
      }
      return [year, month, day].join("-")
   }

   const onFieldChange = (e) => {
      if (e !== undefined) {
         if (e.target.name === "name") {
            localStorage.setItem("leadName", e.target.value)
         } else if (e.target.name === "contact") {
            localStorage.setItem("leadContact", e.target.value)
         } else if (e.target.name === "leadholder_id") {
            localStorage.setItem("leadHolder", e.target.value)
         } else if (e.target.name === "lead_label_id") {
            localStorage.setItem("leadLabel", e.target.value)
         } else if (e.target.name === "lead_source_id") {
            localStorage.setItem("leadSource", e.target.value)
         } else if (e.target.name === "tag_id") {
            localStorage.setItem("leadTag", e.target.value)
         } else if (e.target.name === "firm_name") {
            localStorage.setItem("leadFirmName", e.target.value)
         } else if (e.target.name === "no_updation_since") {
            localStorage.setItem("leadUpdateSince", e.target.value)
         } else if (e.target.name === "no_order_since") {
            localStorage.setItem("leadOrderSince", e.target.value)
         } else if (e.target.name === "followup_status_id") {
            localStorage.setItem("followupStatus", e.target.value)
         } else if (e.target.name === "mature_status_id") {
            localStorage.setItem("matureStatus", e.target.value)
         } else if (e.target.name === "dead_reason_id") {
            localStorage.setItem("deadReason", e.target.value)
         } else if (e.target.name === "type") {
            localStorage.setItem("leadStatus", e.target.value)
            setSelectedType(e.target.value)
            localStorage.setItem("deadReason", "")
            localStorage.setItem("followupStatus", "")
            localStorage.setItem("matureStatus", "")
         } else if (e.target.name === "flag") {
            localStorage.setItem("leadFlag", e.target.checked === true ? "1" : "0")
         } else if (e.target.name === "assigned") {
            localStorage.setItem("leadAssigned", e.target.checked === true ? "1" : "0")
         } else if (e.target.name === "transfer_request") {
            localStorage.setItem(
               "leadTransfer",
               e.target.checked === true ? "1" : "0"
            )
         }
      } else {
         setDFilter(true)
      }
   }

   const filterLeads = () => {
      if (dFilter) {
         localStorage.setItem(
            "leadCreated_at",
            JSON.stringify({
               startDate: formatDate(dateFilter[0].startDate),
               endDate: formatDate(dateFilter[0].endDate),
            })
         )
      }
      fieldText.map(item => {
         fieldValues.push(item)
      })
      fieldSelect.map(item => {
         fieldValues.push(item)
      })
      fieldFlipButton.map(item => {
         fieldValues.push(item)
      })
      localStorage.setItem(
         "leadfields_filter",
         JSON.stringify({ data: fieldValues })
      )
      setTimeout(() => {
         props.getSearchedLeads()
      }, 500)
   }

   const clearFilter = () => {
      localStorage.setItem(
         "leadCreated_at",
         JSON.stringify({
            startDate: "",
            endDate: "",
         })
      )
      localStorage.setItem("leadName", "")
      localStorage.setItem("leadContact", "")
      localStorage.setItem("leadHolder", "")
      localStorage.setItem("leadLabel", "")
      localStorage.setItem("leadSource", "")
      localStorage.setItem("leadTag", "")
      localStorage.setItem("leadFirmName", "")
      localStorage.setItem("leadUpdateSince", "")
      localStorage.setItem("leadOrderSince", "")
      localStorage.setItem("followupStatus", "")
      localStorage.setItem("matureStatus", "")
      localStorage.setItem("deadReason", "")
      localStorage.setItem("leadStatus", "")
      localStorage.setItem("leadFlag", "")
      localStorage.setItem("leadTransfer", "")
      localStorage.setItem("leadAssigned", "")
      localStorage.setItem("leadfields_filter", JSON.stringify({ data: "" }))
      setTimeout(() => {
         props.getSearchedLeads()
      }, 500)
   }

   const getAllFollowup = () => {
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/followup-status?per_page=5000`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setAllFollowup(res.data.data.data)
               res.data.data.data?.map(item => {
                  followupOptions.push({
                     value: item.id,
                     label: item.name,
                  })
               })
            } else {
            }
            getAllDead()
         })
         .catch(function (err) {
            getAllDead()
         })
   }

   const getAllDead = () => {
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/dead-status?per_page=5000`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setAllDead(res.data.data.data)
               res.data.data.data?.map(item => {
                  deadOptions.push({
                     value: item.id,
                     label: item.reason,
                  })
               })
            } else {
            }
            getAllMature()
         })
         .catch(function (err) {
            getAllMature()
         })
   }  

   const getAllMature = () => {
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/mature-status?per_page=5000`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setAllMature(res.data.data.data)
               res.data.data.data?.map(item => {
                  matureOptions.push({
                     value: item.id,
                     label: item.name,
                  })
               })
            } else {
            }
         })
   }

   const leadOptions = [
      { value: "new", label: "Untouched" },
      { value: "followup", label: "Followup" },
      { value: "matured", label: "Matured" },
      { value: "dead", label: "Dead" },
      { value: "dead_request", label: "Dead Request" },
      { value: "repeated", label: "Repeated" },
   ]

   const getSelectedType = selectedType => {
      let leadType = []
      selectedType && selectedType.map(item => leadType.push({
         value: item.value,
         label: item.label,
      }))
      setSelectedType(selectedType && selectedType.map(item => item.value))
      localStorage.setItem("leadStatus", JSON.stringify(leadType))
      localStorage.setItem("deadReason", "")
      localStorage.setItem("followupStatus", "")
      localStorage.setItem("matureStatus", "")
   }

   const getSelectedFollowup = selectedFollowup => {
      let followups = []
      selectedFollowup && selectedFollowup.map(item => followups.push({
         value: item.value,
         label: item.label,
      }))
      localStorage.setItem("followupStatus", JSON.stringify(followups))
   }

   const getSelectedMature = selectedMature => {
      let mature = []
      selectedMature && selectedMature.map(item => mature.push({
         value: item.value,
         label: item.label,
      }))
      localStorage.setItem("matureStatus", JSON.stringify(mature))
   }

   const getSelectedDead = selectedDead => {
      let dead = []
      selectedDead && selectedDead.map(item => dead.push({
         value: item.value,
         label: item.label,
      }))
      localStorage.setItem("deadReason", JSON.stringify(dead))
   }

   const labelOptions = []

   if (JSON.parse(localStorage.getItem("AllLabels"))) {
      JSON.parse(localStorage.getItem("AllLabels")).map(item => {
         labelOptions.push({
            value: item.id,
            label: item.name,
         })
      })
   }

   const getSelectedLabel = selectedLabel => {
      let labels = []
      selectedLabel && selectedLabel.map(item => labels.push({
         value: item.value,
         label: item.label,
      }))
      localStorage.setItem("leadLabel", JSON.stringify(labels))
   }

   const sourceOptions = []

   if (JSON.parse(localStorage.getItem("AllSource"))) {
      JSON.parse(localStorage.getItem("AllSource")).map(item => {
         sourceOptions.push({
            value: item.id,
            label: item.name,
         })
      })
   }

   const getSelectedSource = selectedSource => {
      let sources = []
      selectedSource && selectedSource.map(item => sources.push({
         value: item.value,
         label: item.label,
      }))
      localStorage.setItem("leadSource", JSON.stringify(sources))
   }

   const userOptions = []

   if (JSON.parse(localStorage.getItem("AllUsers"))) {
      JSON.parse(localStorage.getItem("AllUsers")).map(item => {
         userOptions.push({
            value: item.id,
            label: item.name,
         })
      })
   }

   const getSelectedUser = selectedUser => {
      let users = []
      selectedUser && selectedUser.map(item => users.push({ label: item.label, value: item.value }))
      localStorage.setItem("leadHolder", JSON.stringify(users))
   }

   useEffect(() => {
      getAllFollowup()
      fieldValues = []
      fieldText = []
      fieldSelect = []
      fieldFlipButton = []
   }, [])

   return (
      <div>
         <Modal isOpen={props.lead_filter} centered={true} size="xl">
            <AvForm
               className="modal-title mt-0"
               id="myModalLabel"
               onValidSubmit={() => {
                  filterLeads()
               }}
            >
               <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myLargeModalLabel">
                     Filter Leads
                  </h5>
                  <button
                     type="button"
                     className="cross__btn"
                     onClick={() => props.setlead_filter(false)}
                     aria-hidden="true"
                  >
                     &times;
                  </button>
               </div>
               <div className="modal-body">
                  <Row>
                     <Col md={4}>
                        <div className="mb-3">
                           <AvField
                              label="Filter by Name"
                              type="text"
                              name="name"
                              defaultValue={
                                 localStorage.getItem("leadName") &&
                                 localStorage.getItem("leadName")
                              }
                              placholder="Enter firm name"
                              onChange={e => onFieldChange(e)}
                           />
                        </div>
                        <div className="mb-3">
                           <AvField
                              label="Filter by email, number or address"
                              type="text"
                              name="contact"
                              defaultValue={
                                 localStorage.getItem("leadContact") &&
                                 localStorage.getItem("leadContact")
                              }
                              onChange={e => onFieldChange(e)}
                           />
                        </div>
                        <div className="mb-3">
                           <label>Lead Type</label>
                           <Select
                              isMulti
                              isClearable="true"
                              name="type"
                              options={leadOptions}
                              defaultValue={localStorage.getItem("leadStatus") ? JSON.parse(localStorage.getItem("leadStatus")) : []}
                              onChange={e => getSelectedType(e)}
                           />
                           {/* <AvField
                              className="select-arrow"
                              label="Lead Type"
                              type="select"
                              name="type"
                              onChange={e => onFieldChange(e)}
                              value={localStorage.getItem("leadStatus") && localStorage.getItem("leadStatus")}
                           >
                              <option value="">Select</option>
                              <option value="new">Untouched</option>
                              <option value="followup">Followup</option>
                              <option value="matured">Matured</option>
                              <option value="dead">Dead</option>
                              <option value="dead_request">Dead Request</option>
                              <option value="repeated">Repeated</option>
                           </AvField> */}
                        </div>
                        {selectedType?.includes("matured") ?
                           <div className="mb-3">
                              <label>Mature Stages</label>
                              <Select
                                 isMulti
                                 isClearable="true"
                                 name="mature_status_id"
                                 options={matureOptions}
                                 defaultValue={localStorage.getItem("matureStatus") ? JSON.parse(localStorage.getItem("matureStatus")) : []}
                                 onChange={e => getSelectedMature(e)}
                              />
                              {/* <AvField
                                 className="select-arrow"
                                 label="Mature Stages"
                                 type="select"
                                 name="mature_status_id"
                                 value={localStorage.getItem("matureStatus") && localStorage.getItem("matureStatus")}
                                 onChange={e => onFieldChange(e)}
                              >
                                 <option value="">Select</option>
                                 {allMature && allMature.map((option, i) =>
                                    <option value={option.id} key={i}>{option.name}</option>
                                 )}
                              </AvField> */}
                           </div>
                           :
                           ""
                        }
                        {selectedType?.includes("followup") ?
                           <div className="mb-3">
                              <label>Followup Stages</label>
                              <Select
                                 isMulti
                                 isClearable="true"
                                 name="followup_status_id"
                                 options={followupOptions}
                                 defaultValue={localStorage.getItem("followupStatus") ? JSON.parse(localStorage.getItem("followupStatus")) : []}
                                 onChange={e => getSelectedFollowup(e)}
                              />
                              {/* <AvField
                                 className="select-arrow"
                                 label="Followup Stages"
                                 type="select"
                                 name="followup_status_id"
                                 value={localStorage.getItem("followupStatus") && localStorage.getItem("followupStatus")}
                                 onChange={e => onFieldChange(e)}
                              >
                                 <option value="">Select</option>
                                 {allFollowup && allFollowup.map((option, i) =>
                                    <option value={option.id} key={i}>{option.name}</option>
                                 )}
                              </AvField> */}
                           </div>
                           :
                           ""
                        }
                        {selectedType?.includes("dead") || selectedType?.includes("dead_request") ?
                           <div className="mb-3">
                              <label>Dead Reason</label>
                              <Select
                                 isMulti
                                 isClearable="true"
                                 name="dead_reason_id"
                                 options={deadOptions}
                                 defaultValue={localStorage.getItem("deadReason") ? JSON.parse(localStorage.getItem("deadReason")) : []}
                                 onChange={e => getSelectedDead(e)}
                              />
                              {/* <AvField
                                 className="select-arrow"
                                 label="Dead Reason"
                                 type="select"
                                 name="dead_reason_id"
                                 value={localStorage.getItem("deadReason") && localStorage.getItem("deadReason")}
                                 onChange={e => onFieldChange(e)}
                              >
                                 <option value="">Select</option>
                                 {allDead && allDead.map((option, i) =>
                                    <option value={option.id} key={i}>{option.reason}</option>
                                 )}
                              </AvField> */}
                           </div>
                           :
                           ""
                        }
                        <div className="mb-3">
                           <label>Leadholder</label>
                           <Select
                              isMulti
                              isClearable="true"
                              name="leadholder_id"
                              options={userOptions}
                              defaultValue={localStorage.getItem("leadHolder")
                                 ? JSON.parse(localStorage.getItem("leadHolder")) : []}
                              onChange={e => getSelectedUser(e)}
                           />
                           {/* <AvField
                              className="select-arrow"
                              label="Leadholder"
                              type="select"
                              name="leadholder_id"
                              value={localStorage.getItem("leadHolder") && localStorage.getItem("leadHolder")}
                              onChange={e => onFieldChange(e)}
                           >
                              <option value="">Select</option>
                              {JSON.parse(localStorage.getItem("AllUsers")) &&
                                 JSON.parse(localStorage.getItem("AllUsers")).map((option, i) =>
                                    <option value={option.id} key={i}>{option.name}</option>
                                 )}
                           </AvField> */}
                        </div>
                        <div className="mb-3">
                           <label>Lead Label</label>
                           <Select
                              isMulti
                              isClearable="true"
                              name="lead_label_id"
                              options={labelOptions}
                              defaultValue={localStorage.getItem("leadLabel")
                                 ? JSON.parse(localStorage.getItem("leadLabel")) : []}
                              onChange={e => getSelectedLabel(e)}
                           />
                        </div>
                        {/* <div className="mb-3">
                           <AvField
                              className="select-arrow"
                              label="Lead Label"
                              type="select"
                              name="lead_label_id"
                              value={localStorage.getItem("leadLabel") && localStorage.getItem("leadLabel")}
                              onChange={e => onFieldChange(e)}
                           >
                              <option value="">Select</option>
                              {JSON.parse(localStorage.getItem("AllLabels")) &&
                                 JSON.parse(localStorage.getItem("AllLabels")).map((option, i) =>
                                    <option value={option.id} key={i}>{option.name}</option>
                                 )}
                           </AvField>
                        </div> */}
                     </Col>
                     <Col md={4}>
                        <div className="mb-3">
                           <label>Lead Source</label>
                           <Select
                              isMulti
                              isClearable="true"
                              name="lead_source_id"
                              options={sourceOptions}
                              defaultValue={localStorage.getItem("leadSource")
                                 ? JSON.parse(localStorage.getItem("leadSource")) : []}
                              onChange={e => getSelectedSource(e)}
                           />
                           {/* <AvField
                              className="select-arrow"
                              label="Lead Source"
                              type="select"
                              name="lead_source_id"
                              value={localStorage.getItem("leadSource") && localStorage.getItem("leadSource")}
                              onChange={e => onFieldChange(e)}
                           >
                              <option value="">Select</option>
                              {JSON.parse(localStorage.getItem("AllSource")) &&
                                 JSON.parse(localStorage.getItem("AllSource")).map((option, i) =>
                                    <option value={option.id} key={i}>{option.name}</option>
                                 )}
                           </AvField> */}
                        </div>
                        {getCurrentUser() && getCurrentUser().tag == 1 ? (
                           <div className="mb-3">
                              <AvField
                                 className="select-arrow"
                                 label="Lead Tags"
                                 type="select"
                                 name="tag_id"
                                 value={localStorage.getItem("leadTag") && localStorage.getItem("leadTag")}
                                 onChange={e => onFieldChange(e)}
                              >
                                 <option value="" hidden>
                                    Select Tag
                                 </option>
                                 {JSON.parse(localStorage.getItem("AllTags")) &&
                                    JSON.parse(localStorage.getItem("AllTags")).map((option, i) => (
                                       <option value={option.id} key={i}>
                                          {option.name}
                                       </option>
                                    ))}
                              </AvField>
                           </div>
                        ) : (
                           ""
                        )}
                        <div className="mb-3">
                           <AvField
                              label="No Updation Since"
                              type="date"
                              name="no_updation_since"
                              onChange={e => onFieldChange(e)}
                           />
                        </div>
                        <div className="mb-3">
                           <AvField
                              label="No Order Since"
                              type="date"
                              name="no_order_since"
                              onChange={e => onFieldChange(e)}
                           />
                        </div>
                        <div className="mb-3">
                           <AvField
                              label="Firm Name"
                              type="text"
                              name="firm_name"
                              defaultValue={
                                 localStorage.getItem("leadFirmName") &&
                                 localStorage.getItem("leadFirmName")
                              }
                              placholder="Enter firm name"
                              onChange={e => onFieldChange(e)}
                           />
                        </div>
                        <div className="d-flex justify-content-between mt-2">
                           <div className="mb-3">
                              <AvField
                                 label="Transfer Request"
                                 type="checkbox"
                                 name="transfer_request"
                                 defaultChecked={
                                    localStorage.getItem("leadTransfer") &&
                                       localStorage.getItem("leadTransfer") == "1"
                                       ? true
                                       : false
                                 }
                                 onChange={e => onFieldChange(e)}
                              />
                           </div>
                           <div className="mb-3 me-3">
                              <AvField
                                 label="Star"
                                 type="checkbox"
                                 name="flag"
                                 defaultChecked={
                                    localStorage.getItem("leadFlag") &&
                                       localStorage.getItem("leadFlag") == "1"
                                       ? true
                                       : false
                                 }
                                 onChange={e => onFieldChange(e)}
                              />
                           </div>
                        </div>
                        <div className="mb-3">
                           <AvField
                              label="Assigned"
                              type="checkbox"
                              name="assigned"
                              defaultChecked={
                                 localStorage.getItem("leadAssigned") &&
                                    localStorage.getItem("leadAssigned") == "1"
                                    ? true
                                    : false
                              }
                              onChange={e => onFieldChange(e)}
                           />
                        </div>
                     </Col>
                     <Col md={4}>
                        <div className="mb-3">
                           <label>Created at</label>
                           <DateRange
                              editableDateInputs={true}
                              onChange={item => {
                                 setDateFilter([item.selection]), onFieldChange()
                              }}
                              moveRangeOnFirstSelection={false}
                              ranges={dateFilter}
                           />
                        </div>
                     </Col>
                  </Row>
               </div>
               <div className="modal-footer">
                  <div className="text-center">
                     <button
                        className="btn btn-primary me-2"
                        type="button"
                        onClick={() => clearFilter()}
                     >
                        Clear Filters
                     </button>
                     {props.loading ? (
                        <button
                           className="btn btn-primary disabled"
                           type="button"
                           disabled
                        >
                           Loading
                        </button>
                     ) : (
                        <button className="btn btn-primary" type="submit">
                           Submit
                        </button>
                     )}
                  </div>
               </div>
            </AvForm>
         </Modal>
      </div >
   )
}

export default StatsFilter
