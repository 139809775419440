import React from "react"
import { Pagination, PaginationItem, PaginationLink } from "reactstrap"

const Paginations = props => {
   return (
      <>
         {props.data.from !== undefined ? (
            <div className="d-md-flex justify-content-between align-items-center">
               <span className="">
                  Showing
                  <span className="fw-700"> {props.data.from} </span>
                  to
                  <span className="fw-700"> {props.data.to} </span>
                  of
                  <span className="fw-700"> {props.data.total} </span>
                  entries
                  <br />
               </span>
               <span className="text-muted me-3">
                  Show{" "}
                  <select
                     className=""
                     defaultValue={
                        localStorage.getItem("PerPage") !== null
                           ? localStorage.getItem("PerPage")
                           : "15"
                     }
                     name="per_page"
                     onChange={e => {
                        localStorage.setItem("PerPage", e.target.value),
                           props.getTodayFollowup !== undefined ? props.getTodayFollowup("refresh") :  props.perPage()
                     }}
                  >
                     <option value="10">10</option>
                     <option value="15">15</option>
                     <option value="20">20</option>
                     <option value="50">50</option>
                     <option value="100">100</option>
                  </select>{" "}
                  entries
               </span>
               <Pagination
                  aria-label="Page navigation example"
                  listClassName="paginationSm justify-content-end"
               >
                  {props.local !== undefined ?
                     <>
                        <PaginationItem
                           // active={props.data.prev_page_url !== null}
                           disabled={props.data.prev_page_url == null}
                        >
                           <PaginationLink
                              onClick={e => props.paginate(props.data.prev_page_url)}
                           //   href="javascript:void(0)"
                           >
                              Previous
                           </PaginationLink>
                        </PaginationItem>
                        <PaginationItem
                           // active={props.data.next_page_url !== null}
                           disabled={props.data.next_page_url == null}
                        >
                           <PaginationLink
                              onClick={e => props.paginate(props.data.next_page_url)}
                           //   href="javascript:void(0)"
                           >
                              Next
                           </PaginationLink>
                        </PaginationItem>

                     </>
                     :
                     <>
                        {props.data.links &&
                           props.data.links.map((item, i) => (
                              <PaginationItem
                                 active={item.active}
                                 disabled={item.url == null}
                                 key={i}
                              >
                                 <PaginationLink
                                    onClick={e => props.paginate(item.url)}
                                 //   href="javascript:void(0)"
                                 >
                                    {item.label}
                                 </PaginationLink>
                              </PaginationItem>
                           ))}
                     </>
                  }
               </Pagination>
            </div>
         ) : (
            ""
         )}
      </>
   )
}
export default Paginations
