import React, { useState, useEffect } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import { getCurrentUser } from "../../../helpers/Utils"
import { Link } from "react-router-dom"
import axios from "axios"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const CQuotationsList = props => {
   const [quotation, setQuotation] = useState(props.data)
   const [confirm_alert, setconfirm_alert] = useState(false)
   const [success_dlg, setsuccess_dlg] = useState(false)
   const [dynamic_title, setdynamic_title] = useState("")
   const [dynamic_description, setdynamic_description] = useState("")

   const deleteQuotation = e => {
      axios
         .delete(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/quotation/${e}`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setQuotation("")
               setdynamic_description(res.data.message)
               toast.success(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            }
         })
   }

   if (quotation === "") {
      return ""
   } else
      return (
         <>
            <tr key={props.i}>
               <td>{props.counter + props.i}</td>
               <td>
                  {quotation.id}
               </td>
               <td>
                  <div className="lead_table table-bordered" style={{ minHeight: "20px" }}>
                     <table style={{ width: "100%" }}>
                        <tbody>
                           <tr>
                              <th>Product</th>
                              <th>Price</th>
                              <th>MOQ</th>
                           </tr>
                           {quotation.products?.map((item) => (
                              <tr key={item.id}>
                                 <td>{item.name}</td>
                                 <td>{item.special_price}</td>
                                 <td>{item.quantity}</td>
                              </tr>
                           ))}
                        </tbody>
                     </table>
                  </div>
               </td>
               <td>
                  {quotation.message}
               </td>
               <td>
                  <a
                     className="text-danger ms-2"
                     onClick={() => {
                        setconfirm_alert(true)
                     }}
                  >
                     <i className="fas fa-trash-alt" />
                  </a>
                  {confirm_alert ? (
                     <SweetAlert
                        title="Are you sure?"
                        warning
                        showCancel
                        confirmButtonText="Yes, delete it!"
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={() => {
                           setconfirm_alert(false)
                           setsuccess_dlg(true)
                           deleteQuotation(quotation.id)
                           setdynamic_title("Deleted")
                           setdynamic_description("Your file has been deleted.")
                        }}
                        onCancel={() => setconfirm_alert(false)}
                     >
                        You won't be able to revert this!
                     </SweetAlert>
                  ) : null}
               </td>
            </tr>
         </>
      )
}
export default CQuotationsList
