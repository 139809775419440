import React from "react"
// import Loading from "../../assets/images/Loading-icon.gif";
const Loader = () => {
  return (
    <div className="lds-ripple">
      <div></div>
      <div></div>
    </div>
  )
}
export default Loader
