import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import {
   Container,
   Row,
   Col,
   Card,
   CardBody,
   Modal,
} from "reactstrap"
import { getCurrentUser } from "../../helpers/Utils"
import axios from "axios"
import Loader from "../Loader/loader"
import PaymentList from "./paymentList"


const PaymentHistory = () => {
   const [payments, setPayments] = useState([])
   const [loader, setLoader] = useState("")
   const [error, setError] = useState("")

   const getPayments = () => {
      setPayments([])
      setLoader(<Loader />)
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/get-invoices`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setPayments(res.data.data)
               setError(res.data.message)
            } else {
               setError(res.data.message)
            }
            setLoader("")
         })
   }

   useEffect(() => {
      getPayments()
   }, [])

  return (
   <React.Fragment>
   <div className="page-content">
      <MetaTags>
         <title>Payment History | KlikCRM</title>
      </MetaTags>
      <Container fluid>
         <div className="page-title-box">
            <Row className="align-items-center">
               <Col md={6}>
                  <h4 className="page-title">Payment History</h4>
               </Col>
               <Col md={6}>
                  <h6 className="page-title float-end"></h6>
               </Col>
            </Row>
         </div>
         <div className="card">
            <div className="card-body">
               <div className="lead_table table table-sm table-bordered table-responsive">
                  <table style={{ width: "100%" }}>
                     <tbody>
                        <tr className="static-header">
                           <th>Invoice id</th>
                           <th>Customer name</th>
                           <th>Amount</th>
                           <th>Payment date</th>
                           <th>Action</th>
                        </tr>
                        {payments &&
                           payments.map((item, obj) => (
                              <PaymentList
                                 key={obj}
                                 data={item}
                                 i={obj}
                              />
                           ))}
                     </tbody>
                  </table>
                  {error ? (
                     <span className="text-danger mt-3 d-table m-auto" role="alert">
                        {error}
                     </span>
                  ) : (
                     ""
                  )}
               </div>
            </div>
         </div>
         {loader}
      </Container>
   </div>
</React.Fragment>
  )
}

export default PaymentHistory
