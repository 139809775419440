import React, { useState, useEffect } from "react"
import Loader from "../../Loader/loader.js"
import { Row, Col, Card, CardBody, Modal } from "reactstrap"
import axios from "axios"
import {
  AvForm,
  AvField,
  AvRadio,
  AvRadioGroup,
} from "availity-reactstrap-validation"
import {
  getCurrentUser,
  getPermission,
  getFilterDate,
  setFilterDate,
} from "../../../helpers/Utils"
import { Link } from "react-router-dom"
import LeadModal from "../leadmodal"
import StatsFilter from "./filter"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import SweetAlert from "react-bootstrap-sweetalert"
import AssignLabels from "../../Assign-Labels"
import CampaignModel from "../../Campaigns"
import Select from "react-select"
import AttachmentModal from "../../attachmentModal"

let selectedUserId = []

const AllStatsTable = () => {
  const [modal_standard, setmodal_standard] = useState(false)
  const [attachment_model, setattachment_model] = useState(false)
  const [assignLabel, setAssignLabel] = useState(false)
  const [searchedCount, setSearchedCount] = useState("")
  const [modal2, setModal2] = useState(false)
  const [allLeads, setAllLeads] = useState([])
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const [loading2, setLoading2] = useState(false)
  const [loader, setLoader] = useState(<Loader />)
  const [allData, setAllData] = useState([])
  const [leadsSelectedIds, setLeadsSelectedIds] = useState([])
  const [isData, setData] = useState(false)
  const [success_dlg, setsuccess_dlg] = useState(false)
  const [dynamic_title, setdynamic_title] = useState("")
  const [dynamic_description, setdynamic_description] = useState("")
  const [confirm_alert, setconfirm_alert] = useState(false)
  const [confirm_alert2, setconfirm_alert2] = useState(false)
  const [confirm_alert3, setconfirm_alert3] = useState(false)
  const [check, setCheck] = useState(0)
  const [transferNew, setTransferNew] = useState("0")
  const [hideHistory, setHideHistory] = useState("0")
  const [lead_filter, setlead_filter] = useState(false)
  const [campaign_model, setcampaign_model] = useState(false)

  const getAllLeads = () => {
    setLoader(<Loader />)
    setAllLeads([])
    let url = `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
      "company_name"
    )}/all-statistics`
    axios
      .get(`${url}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${
            getCurrentUser().token == null ? "" : getCurrentUser().token
          }`,
        },
      })
      .then(function (res) {
        if (res.data.status) {
          setLoader("")
          setAllLeads(res.data.data.counters)
        } else {
          setLoader("")
          setError(res.data.message)
        }
        setData(false)
      })
  }

  const getSearchedLeads = () => {
    setError("")
    setSearchedCount("")
    setAllData([])
    setLoading(true)
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/all-statistics?type=${
          localStorage.getItem("leadStatus")
            ? JSON.parse(localStorage.getItem("leadStatus")).map(
                item => item.value
              )
            : ""
        }
         &leadholder_id=${
           localStorage.getItem("leadHolder")
             ? JSON.parse(localStorage.getItem("leadHolder")).map(
                 item => item.value
               )
             : ""
         }
         &contact=${
           localStorage.getItem("leadContact") !== null
             ? localStorage.getItem("leadContact")
             : ""
         } 
         &name=${
           localStorage.getItem("leadName") !== null
             ? localStorage.getItem("leadName")
             : ""
         }
         &lead_label_id=${
           localStorage.getItem("leadLabel")
             ? JSON.parse(localStorage.getItem("leadLabel")).map(
                 item => item.value
               )
             : ""
         }
         &tag_id=${
           localStorage.getItem("leadTag") !== null
             ? localStorage.getItem("leadTag")
             : ""
         }
         &followup_status_id=${
           localStorage.getItem("followupStatus")
             ? JSON.parse(localStorage.getItem("followupStatus")).map(
                 item => item.value
               )
             : ""
         }
         &mature_status_id=${
           localStorage.getItem("matureStatus")
             ? JSON.parse(localStorage.getItem("matureStatus")).map(
                 item => item.value
               )
             : ""
         }
         &dead_reason_id=${
           localStorage.getItem("deadReason")
             ? JSON.parse(localStorage.getItem("deadReason")).map(
                 item => item.value
               )
             : ""
         }
         &lead_source_id=${
           localStorage.getItem("leadSource")
             ? JSON.parse(localStorage.getItem("leadSource")).map(
                 item => item.value
               )
             : ""
         }
         &followup_start=${
           getFilterDate() && getFilterDate().from !== null
             ? getFilterDate().from
             : ""
         }
         &followup_to=${
           getFilterDate() && getFilterDate().to !== null
             ? getFilterDate().to
             : ""
         }
         &lastmessage=${
           localStorage.getItem("leadMessage") !== null
             ? localStorage.getItem("leadMessage")
             : ""
         }
         &firm_name=${
           localStorage.getItem("leadFirmName") !== null
             ? localStorage.getItem("leadFirmName")
             : ""
         }
         &no_updation_since=${
           localStorage.getItem("leadUpdateSince") !== null
             ? localStorage.getItem("leadUpdateSince")
             : ""
         }
         &no_order_since=${
           localStorage.getItem("leadOrderSince") !== null
             ? localStorage.getItem("leadOrderSince")
             : ""
         }
         &flag=${
           localStorage.getItem("leadFlag") !== null
             ? localStorage.getItem("leadFlag")
             : ""
         }&company_pool=${
          localStorage.getItem("CompanyPool") !== null
            ? localStorage.getItem("CompanyPool")
            : ""
        }
         &assigned=${
           localStorage.getItem("leadAssigned") !== null
             ? localStorage.getItem("leadAssigned")
             : ""
         }
         &transfer_request=${
           localStorage.getItem("leadTransfer") !== null
             ? localStorage.getItem("leadTransfer")
             : ""
         }
         &from_date=${
           localStorage.getItem("leadCreated_at") !== ""
             ? JSON.parse(localStorage.getItem("leadCreated_at")).startDate
             : ""
         }
         &to_date=${
           localStorage.getItem("leadCreated_at") !== ""
             ? JSON.parse(localStorage.getItem("leadCreated_at")).endDate
             : ""
         }
            &mature_start_date=${
              localStorage.getItem("leadMatured_at") !== ""
                ? JSON.parse(localStorage.getItem("leadMatured_at")).startDate
                : ""
            }
         &mature_end_date=${
           localStorage.getItem("leadMatured_at") !== ""
             ? JSON.parse(localStorage.getItem("leadMatured_at")).endDate
             : ""
         }
         `,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLoader("")
          setAllData(res.data.data.counters)
          setSearchedCount(res.data.data.counters.all_leads)
          setLeadsSelectedIds(res.data.data.counters?.lead_ids)
          setlead_filter(false)
          setData(true)
        } else {
          setError(res.data.message)
          setlead_filter(false)
        }
        setLoading(false)
      })
  }

  const getCheckValue = check => {
    if (check.checked) {
      setTransferNew("1")
    } else {
      setTransferNew("0")
      setHideHistory("0")
    }
  }

  const getCheckValue2 = check => {
    if (check.checked) {
      setHideHistory("1")
    } else {
      setHideHistory("0")
    }
  }

  const options = []
  if (JSON.parse(localStorage.getItem("All-User"))) {
    options.push({ value: "0", label: "Company pool" })
    JSON.parse(localStorage.getItem("All-User")).map(item => {
      options.push({
        value: item.id,
        label: item.name,
      })
    })
  }

  const getSelectedId = e => {
    selectedUserId = []
    e?.map(item => selectedUserId.push(item.value))
  }

  const transferLeads = (e, value) => {
    setLoading2(true)
    const api = axios.create()
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    api.interceptors.request.use(config => {
      config.cancelToken = source.token
      return config
    })

    const timeout = 120000 // 2 minute

    setTimeout(() => {
      source.cancel("Timeout")
    }, timeout)
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/lead-transfer`,
        {
          user_id: selectedUserId,
          transfer_as_new: transferNew,
          hide_lead_history: hideHistory,
          type: value.type,
          lead_ids: leadsSelectedIds,
          // type: localStorage.getItem("leadStatus") ? JSON.parse(localStorage.getItem("leadStatus")).map(item => item.value)
          //    : ""
          // ,
          // leadholder_id: localStorage.getItem("leadHolder") ? JSON.parse(localStorage.getItem("leadHolder")).map(item => item.value)
          //    : ""
          // ,
          // contact: localStorage.getItem("leadContact") !== null
          //    ? localStorage.getItem("leadContact")
          //    : ""
          // ,
          // name: localStorage.getItem("leadName") !== null
          //    ? localStorage.getItem("leadName")
          //    : ""
          // ,
          // lead_label_id: localStorage.getItem("leadLabel") ? JSON.parse(localStorage.getItem("leadLabel")).map(item => item.value)
          //    : ""
          // ,
          // tag_id: localStorage.getItem("leadTag") !== null
          //    ? localStorage.getItem("leadTag")
          //    : ""
          // ,
          // lead_source_id: localStorage.getItem("leadSource") ? JSON.parse(localStorage.getItem("leadSource")).map(item => item.value)
          //    : ""
          // ,
          // followup_status_id: localStorage.getItem("followupStatus") ? JSON.parse(localStorage.getItem("followupStatus")).map(item => item.value)
          //    : ""
          // ,
          // mature_status_id: localStorage.getItem("matureStatus") ? JSON.parse(localStorage.getItem("matureStatus")).map(item => item.value)
          //    : ""
          // ,
          // dead_reason_id: localStorage.getItem("deadReason") ? JSON.parse(localStorage.getItem("deadReason")).map(item => item.value)
          //    : ""
          // ,
          // followup_start: getFilterDate() && getFilterDate().from !== null
          //    ? getFilterDate().from
          //    : ""
          // ,
          // followup_to: getFilterDate() && getFilterDate().to !== null
          //    ? getFilterDate().to
          //    : ""
          // ,
          // lastmessage: localStorage.getItem("leadMessage") !== null
          //    ? localStorage.getItem("leadMessage")
          //    : ""
          // ,
          // firm_name: localStorage.getItem("leadFirmName") !== null
          //    ? localStorage.getItem("leadFirmName")
          //    : ""
          // ,
          // no_updation_since: localStorage.getItem("leadUpdateSince") !== null
          //    ? localStorage.getItem("leadUpdateSince")
          //    : ""
          // ,
          // no_order_since: localStorage.getItem("leadOrderSince") !== null
          //    ? localStorage.getItem("leadOrderSince")
          //    : ""
          // ,
          // flag: localStorage.getItem("leadFlag") !== null
          //    ? localStorage.getItem("leadFlag")
          //    : ""
          // ,
          // assigned: localStorage.getItem("leadAssigned") !== null
          //    ? localStorage.getItem("leadAssigned")
          //    : ""
          // ,
          // transfer_request: localStorage.getItem("leadTransfer") !== null
          //    ? localStorage.getItem("leadTransfer")
          //    : ""
          // ,
          // from_date: localStorage.getItem("leadCreated_at") !== ""
          //    ? JSON.parse(localStorage.getItem("leadCreated_at")).startDate
          //    : ""
          // ,
          // to_date: localStorage.getItem("leadCreated_at") !== ""
          //    ? JSON.parse(localStorage.getItem("leadCreated_at")).endDate
          //    : ""
          // ,
        },
        {
          headers: {
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          // setLeadsSelectedIds([])
          // getSearchedLeads()
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          setModal2(false)
        } else {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
        setLoading2(false)
      })
  }

  const deleteLeads = () => {
    const api = axios.create()
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    api.interceptors.request.use(config => {
      config.cancelToken = source.token
      return config
    })

    const timeout = 120000 // 2 minute

    setTimeout(() => {
      source.cancel("Timeout")
    }, timeout)
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/multiple-deleted`,
        {
          ids: leadsSelectedIds,
          is_deleted: check,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setCheck(0)
          // setLeadsSelectedIds([])
          // getSearchedLeads()
          setdynamic_description(res.data.message)
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        } else {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  const updateFlag = () => {
    const api = axios.create()
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    api.interceptors.request.use(config => {
      config.cancelToken = source.token
      return config
    })

    const timeout = 120000 // 2 minute

    setTimeout(() => {
      source.cancel("Timeout")
    }, timeout)
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/update-flag`,
        {
          ids: leadsSelectedIds,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          // setLeadsSelectedIds([])
          // getSearchedLeads()
          setdynamic_description(res.data.message)
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        } else {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  const moveToDead = () => {
    const api = axios.create()
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    api.interceptors.request.use(config => {
      config.cancelToken = source.token
      return config
    })

    const timeout = 120000 // 2 minute

    setTimeout(() => {
      source.cancel("Timeout")
    }, timeout)
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/move-to-dead`,
        {
          ids: leadsSelectedIds,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          // setLeadsSelectedIds([])
          // getSearchedLeads()
          setdynamic_description(res.data.message)
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        } else {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  const exportLead = e => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/all-statistics?type=${
          localStorage.getItem("leadStatus")
            ? JSON.parse(localStorage.getItem("leadStatus")).map(
                item => item.value
              )
            : ""
        }
      &leadholder_id=${
        localStorage.getItem("leadHolder")
          ? JSON.parse(localStorage.getItem("leadHolder")).map(
              item => item.value
            )
          : ""
      }
      &contact=${
        localStorage.getItem("leadContact") !== null
          ? localStorage.getItem("leadContact")
          : ""
      } 
      &name=${
        localStorage.getItem("leadName") !== null
          ? localStorage.getItem("leadName")
          : ""
      }
            &lead_label_id=${
              localStorage.getItem("leadLabel")
                ? JSON.parse(localStorage.getItem("leadLabel")).map(
                    item => item.value
                  )
                : ""
            }
      &tag_id=${
        localStorage.getItem("leadTag") !== null
          ? localStorage.getItem("leadTag")
          : ""
      }
      &followup_status_id=${
        localStorage.getItem("followupStatus")
          ? JSON.parse(localStorage.getItem("followupStatus")).map(
              item => item.value
            )
          : ""
      }
      &mature_status_id=${
        localStorage.getItem("matureStatus")
          ? JSON.parse(localStorage.getItem("matureStatus")).map(
              item => item.value
            )
          : ""
      }
      &dead_reason_id=${
        localStorage.getItem("deadReason")
          ? JSON.parse(localStorage.getItem("deadReason")).map(
              item => item.value
            )
          : ""
      }
      &lead_source_id=${
        localStorage.getItem("leadSource")
          ? JSON.parse(localStorage.getItem("leadSource")).map(
              item => item.value
            )
          : ""
      }
      &followup_start=${
        getFilterDate() && getFilterDate().from !== null
          ? getFilterDate().from
          : ""
      }
      &followup_to=${
        getFilterDate() && getFilterDate().to !== null ? getFilterDate().to : ""
      }
      &lastmessage=${
        localStorage.getItem("leadMessage") !== null
          ? localStorage.getItem("leadMessage")
          : ""
      }
      &firm_name=${
        localStorage.getItem("leadFirmName") !== null
          ? localStorage.getItem("leadFirmName")
          : ""
      }
      &no_updation_since=${
        localStorage.getItem("leadUpdateSince") !== null
          ? localStorage.getItem("leadUpdateSince")
          : ""
      }
      &no_order_since=${
        localStorage.getItem("leadOrderSince") !== null
          ? localStorage.getItem("leadOrderSince")
          : ""
      }
      &flag=${
        localStorage.getItem("leadFlag") !== null
          ? localStorage.getItem("leadFlag")
          : ""
      }
      &assigned=${
        localStorage.getItem("leadAssigned") !== null
          ? localStorage.getItem("leadAssigned")
          : ""
      }
      &transfer_request=${
        localStorage.getItem("leadTransfer") !== null
          ? localStorage.getItem("leadTransfer")
          : ""
      }
      &from_date=${
        localStorage.getItem("leadCreated_at") !== ""
          ? JSON.parse(localStorage.getItem("leadCreated_at")).startDate
          : ""
      }
      &to_date=${
        localStorage.getItem("leadCreated_at") !== ""
          ? JSON.parse(localStorage.getItem("leadCreated_at")).endDate
          : ""
      }
         &mature_start_date=${
           localStorage.getItem("leadMatured_at") !== ""
             ? JSON.parse(localStorage.getItem("leadMatured_at")).startDate
             : ""
         }
      &mature_end_date=${
        localStorage.getItem("leadMatured_at") !== ""
          ? JSON.parse(localStorage.getItem("leadMatured_at")).endDate
          : ""
      }&export=1
      `,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          window.location.href = res.data.file_url
          setTimeout(() => {
            dFileName(res.data.file_name)
          }, 5000)
        } else {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  const dFileName = e => {
    const api = axios.create()
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    api.interceptors.request.use(config => {
      config.cancelToken = source.token
      return config
    })

    const timeout = 120000 // 2 minute

    setTimeout(() => {
      source.cancel("Timeout")
    }, timeout)
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/delete-file`,
        {
          file_name: e,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
        } else {
        }
      })
  }

  const clearLocal = () => {
    localStorage.setItem("leadHolder", "")
    localStorage.setItem("leadLabel", "")
    localStorage.setItem("leadStatus", "")
    localStorage.setItem("leadTag", "")
    localStorage.setItem("leadName", "")
    localStorage.setItem("leadContact", "")
    localStorage.setItem("leadSource", "")
    localStorage.setItem("leadAmount", "")
    setFilterDate({
      from: "",
      to: "",
    })
    localStorage.setItem("leadMessage", "")
    localStorage.setItem("followupStatus", "")
    localStorage.setItem("matureStatus", "")
    localStorage.setItem("deadReason", "")
    localStorage.setItem("deadStatus", "")
    localStorage.setItem("latestMessage", "")
    localStorage.setItem("leadRole", "")
    localStorage.setItem("leadEmail", "")
    localStorage.setItem("leadStatus2", "")
    localStorage.setItem("leadDay", "")
    localStorage.setItem("leadStatus3", "")
    localStorage.setItem("leadmaturedby", "")
    localStorage.setItem("ledgerType", "")
    localStorage.setItem("offerType", "")
    localStorage.setItem("PrductGST", "")
    localStorage.setItem("OrderStatus", "")
    localStorage.setItem("OrderPstatus", "")
    localStorage.setItem("leadSort", "")
    localStorage.setItem("leadID", "")
    localStorage.setItem("leadTransfer", "")
    localStorage.setItem("leadFlag", "")
    localStorage.setItem("leadUpdateSince", "")
    localStorage.setItem("leadOrderSince", "")
    localStorage.setItem("leadFirmName", "")
    localStorage.setItem("leadCreated_at", "")
    localStorage.setItem("leadMatured_at", "")
    localStorage.setItem(
      "leadAssigned_at",
      JSON.stringify({
        startDate: "",
        endDate: "",
      })
    )
    localStorage.setItem("current_page", "")
    localStorage.setItem("leadAssigned", "")
    localStorage.setItem("CompanyPool", "")
  }

  useEffect(() => {
    getAllLeads()
  }, [])

  return (
    <>
      {modal_standard ? (
        <LeadModal
          modal_standard={modal_standard}
          setmodal_standard={setmodal_standard}
          getAllLeads={getAllLeads}
        />
      ) : (
        ""
      )}
      {assignLabel ? (
        <AssignLabels
          assignLabel={assignLabel}
          setAssignLabel={setAssignLabel}
          leadsSelectedIds={leadsSelectedIds}
          setLeadsSelectedIds={setLeadsSelectedIds}
          getAllLeads={getSearchedLeads}
        />
      ) : (
        ""
      )}
      {attachment_model ? (
        <AttachmentModal
          attachment_model={attachment_model}
          setattachment_model={setattachment_model}
          leadsSelectedIds={leadsSelectedIds}
          // setLeadsSelectedIds={setLeadsSelectedIds}
          // getAllLeads={getSearchedLeads}
        />
      ) : (
        ""
      )}
      {campaign_model ? (
        <CampaignModel
          campaign_model={campaign_model}
          setcampaign_model={setcampaign_model}
          leadsSelectedIds={leadsSelectedIds}
          setLeadsSelectedIds={setLeadsSelectedIds}
          getAllLeads={getSearchedLeads}
        />
      ) : (
        ""
      )}
      {lead_filter ? (
        <StatsFilter
          lead_filter={lead_filter}
          setlead_filter={setlead_filter}
          getSearchedLeads={getSearchedLeads}
          loading={loading}
        />
      ) : (
        ""
      )}
      {confirm_alert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmButtonText="Yes, delete it!"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setconfirm_alert(false)
            setsuccess_dlg(true)
            deleteLeads()
            setdynamic_title("Deleted")
            setdynamic_description("Your file has been deleted.")
          }}
          onCancel={() => setconfirm_alert(false)}
        >
          <input
            onChange={() => setCheck(1)}
            type="checkbox"
            name="is_deleted"
          />
          {"  "}
          <span className="text-danger">Delete it from server as well?</span>
          <br />
          <span className="text-normal">
            It will be automatically deleted after 7 days.
          </span>
        </SweetAlert>
      ) : null}
      {confirm_alert2 ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmButtonText="Yes, delete it!"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setconfirm_alert2(false)
            setsuccess_dlg(true)
            updateFlag()
            setdynamic_title("Deleted")
            setdynamic_description("Your file has been deleted.")
          }}
          onCancel={() => setconfirm_alert2(false)}
        >
          <span className="text-danger">
            Do you want to update star on selected leads?
          </span>
        </SweetAlert>
      ) : null}
      {confirm_alert3 ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmButtonText="Yes, delete it!"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setconfirm_alert3(false)
            setsuccess_dlg(true)
            moveToDead()
            setdynamic_title("Deleted")
            setdynamic_description("Your file has been deleted.")
          }}
          onCancel={() => setconfirm_alert3(false)}
        >
          <span className="text-danger">
            Do you want to move filtered leads to Dead?
          </span>
        </SweetAlert>
      ) : null}
      <div className="page-title-box">
        <Row className="align-items-center">
          <Col md={2}>
            <h6 className="page-title">All Statistics</h6>
          </Col>
          <Col md={10}>
            <h6 className="page-title float-end">
              {searchedCount !== "" ? (
                <button
                  className="btn btn-primary submit__button me-2 mb-2"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setSearchedCount(""), getAllLeads(), clearLocal()
                  }}
                >
                  {" "}
                  Clear Filters
                </button>
              ) : (
                ""
              )}
              <button
                className="btn btn-primary submit__button me-2 mb-2"
                onClick={() => {
                  setlead_filter(true)
                }}
              >
                <i className="fa fa-filter me-2"></i>
                Filter
              </button>
              {getPermission().lead.lead.create.is_checked === "yes" ? (
                <button
                  className="btn btn-success mb-2"
                  onClick={() => {
                    setmodal_standard(true)
                  }}
                >
                  Add New
                </button>
              ) : (
                ""
              )}
            </h6>
          </Col>
        </Row>
      </div>
      <Modal isOpen={modal2} centered={true}>
        <AvForm
          className="modal-title mt-0"
          id="myModalLabel"
          onValidSubmit={(e, v) => {
            transferLeads(e, v)
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myLargeModalLabel">
              Change Leadholder
            </h5>
            <button
              type="button"
              className="cross__btn"
              onClick={() => setModal2(false)}
              aria-hidden="true"
            >
              &times;
            </button>
          </div>
          <div className="modal-body">
            {/* <div className="mb-3">
                     <AvField
                        className="select-arrow"
                        label="Users"
                        type="select"
                        name="user_id"
                     >
                        <option value="" hidden>
                           Select User
                        </option>
                        <option value="0">Company Pool</option>
                        {JSON.parse(localStorage.getItem("AllUsers")) &&
                           JSON.parse(localStorage.getItem("AllUsers")).map((item, i) => (
                              <option value={item.id} key={i}>
                                 {item.name}
                              </option>
                           ))}
                     </AvField>
                  </div> */}
            <div className="form-group mb-3">
              <label>Select User To Assign</label>
              <Select
                isMulti
                isClearable="true"
                name="assignees"
                options={options}
                onChange={e => getSelectedId(e)}
              />
            </div>
            <div className="d-flex">
              <div className="mb-3 me-2">
                <AvField
                  label="Transfer as New"
                  type="checkbox"
                  name="transfer_as_new"
                  onClick={e => getCheckValue(e.target)}
                />
              </div>
              {transferNew == "1" ? (
                <div className="mb-3 ms-4">
                  <AvField
                    label="Hide Lead History"
                    type="checkbox"
                    name="hide_lead_history"
                    onClick={e => getCheckValue2(e.target)}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="mb-3">
              <label>Select Option:</label>
              <AvRadioGroup inline name="type">
                <AvRadio label="Today's Priority" value="priority" />
                <AvRadio label="Tomorrow's Followup" value="followup" />
              </AvRadioGroup>
            </div>
            <div className="modal-footer">
              <div className="text-center">
                {loading2 ? (
                  <button
                    className="btn btn-primary disabled"
                    type="button"
                    disabled
                  >
                    Loading
                  </button>
                ) : (
                  <button
                    className="btn btn-primary"
                    type="submit"
                    // onClick={() => setModal2(false)}
                  >
                    Submit
                  </button>
                )}
              </div>
            </div>
          </div>
        </AvForm>
      </Modal>
      {error ? (
        <span className="text-danger mt-3 d-table m-auto" role="alert">
          {error}
        </span>
      ) : (
        ""
      )}
      {isData ? (
        <div className="card">
          <div className="card-body">
            <Row className="d-flex align-items-center justify-content-between">
              <div className="col-md-10">
                You filtered{" "}
                <span className="text-info">
                  <Link to="/leads?statistics=1">{allData.all_leads}</Link>
                </span>{" "}
                Leads.
              </div>
              <div className="col-md-2 float-end">
                <div className="btn-group me-2 mb-2">
                  <button type="button" className="btn btn-primary">
                    Action
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="fa fa-caret-down"></i>
                  </button>
                  <ul className="dropdown-menu">
                    {getCurrentUser()?.role_name === "Administrator" ? (
                      <>
                        <li>
                          <span
                            className="dropdown-item"
                            style={{ cursor: "pointer" }}
                            onClick={() => setconfirm_alert(true)}
                          >
                            Batch delete
                          </span>
                        </li>
                        <li>
                          <span
                            className="dropdown-item"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              exportLead()
                            }}
                          >
                            Export
                          </span>
                        </li>
                      </>
                    ) : (
                      <>
                        <li>
                          <span
                            className="dropdown-item disabled"
                            style={{ cursor: "pointer" }}
                            onClick={() => setconfirm_alert(true)}
                          >
                            Batch delete
                          </span>
                        </li>
                        <li>
                          <span
                            className="dropdown-item disabled"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              exportLead()
                            }}
                          >
                            Export
                          </span>
                        </li>
                      </>
                    )}
                    <li>
                      <span
                        className="dropdown-item"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setModal2(true)
                        }}
                      >
                        Change Leadholder
                      </span>
                    </li>
                    {/* <li>
                                          <span
                                             className="dropdown-item"
                                             style={{ cursor: "pointer" }}
                                             onClick={() => {
                                                setconfirm_alert2(true)
                                             }}
                                          >
                                             Update Star
                                          </span>
                                       </li> */}
                    <li>
                      <span
                        className="dropdown-item"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setconfirm_alert3(true)
                        }}
                      >
                        Move to dead
                      </span>
                    </li>

                    <li>
                      <span
                        className="dropdown-item"
                        style={{ cursor: "pointer" }}
                        onClick={() => setcampaign_model(true)}
                      >
                        Send Whatsapp
                      </span>
                    </li>
                    <li>
                      <span
                        className="dropdown-item"
                        style={{ cursor: "pointer" }}
                        onClick={() => setattachment_model(true)}
                      >
                        Send Attachment
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </Row>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="card">
        <div className="card-body">
          <h2 className="card-title mb-4 border-bottom pb-2">Leads Counter</h2>
          <Row>
            <Col xl={3} md={4} sm={6}>
              <Link to="/leads?id=">
                <Card className="mini-stat bg-primary text-white">
                  <CardBody className="text-center">
                    <h2 className="fw-medium fs-1">
                      {allLeads && allLeads.all_leads}{" "}
                    </h2>
                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                      All Leads
                    </h5>
                  </CardBody>
                </Card>
              </Link>
            </Col>
            <Col xl={3} md={4} sm={6}>
              <Link to="/leads?type=new">
                <Card className="mini-stat bg-primary text-white">
                  <CardBody className="text-center">
                    <h2 className="fw-medium fs-1">
                      {allLeads && allLeads.new}{" "}
                    </h2>
                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                      Untouched Leads
                    </h5>
                  </CardBody>
                </Card>
              </Link>
            </Col>
            <Col xl={3} md={4} sm={6}>
              <Link to="/leads?type=dead">
                <Card className="mini-stat bg-primary text-white">
                  <CardBody className="text-center">
                    <h2 className="fw-medium fs-1">
                      {allLeads && allLeads.dead}{" "}
                    </h2>
                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                      Dead
                    </h5>
                  </CardBody>
                </Card>
              </Link>
            </Col>
            <Col xl={3} md={4} sm={6}>
              <Link to="/leads?type=dead_request">
                <Card className="mini-stat bg-primary text-white">
                  <CardBody className="text-center">
                    <h2 className="fw-medium fs-1">
                      {allLeads && allLeads.dead_request}{" "}
                    </h2>
                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                      Dead Request
                    </h5>
                  </CardBody>
                </Card>
              </Link>
            </Col>
            <Col xl={3} md={4} sm={6}>
              <Link to="/leads?type=matured">
                <Card className="mini-stat bg-primary text-white">
                  <CardBody className="text-center">
                    <h2 className="fw-medium fs-1">
                      {allLeads && allLeads.matured}{" "}
                    </h2>
                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                      Matured Leads
                    </h5>
                  </CardBody>
                </Card>
              </Link>
            </Col>
            <Col xl={3} md={4} sm={6}>
              <Link to="/transfer-request">
                <Card className="mini-stat bg-primary text-white">
                  <CardBody className="text-center">
                    <h2 className="fw-medium fs-1">
                      {allLeads && allLeads.transfer_request}{" "}
                    </h2>
                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                      Transfer Request
                    </h5>
                  </CardBody>
                </Card>
              </Link>
            </Col>
            <Col xl={3} md={4} sm={6}>
              <Link to="/leads?type=followup">
                <Card className="mini-stat bg-primary text-white">
                  <CardBody className="text-center">
                    <h2 className="fw-medium fs-1">
                      {allLeads && allLeads.followup}{" "}
                    </h2>
                    <h5 className="font-size-16 mt-0 text-uppercase text-white-50">
                      FollowUps
                    </h5>
                  </CardBody>
                </Card>
              </Link>
            </Col>
            <Col xl={3} md={4} sm={6}>
              <Link to="/repeated-leads">
                <Card className="mini-stat bg-primary text-white">
                  <CardBody className="text-center">
                    <h2 className="fw-medium fs-1">
                      {allLeads && allLeads.repeated}{" "}
                    </h2>
                    <h5 className="font-size-16 mt-0 text-uppercase text-white-50">
                      Repeated
                    </h5>
                  </CardBody>
                </Card>
              </Link>
            </Col>
            {loader}
          </Row>
        </div>
      </div>
    </>
  )
}
export default AllStatsTable
