import React, { useState, useEffect } from "react"
import { Container, Row, Col, Modal, FormGroup, Input } from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert"
import {
   getCurrentUser,
   getPermission,
   getFilterDate,
   setFilterDate,
} from "../../../helpers/Utils"
import { Link } from "react-router-dom"
import axios from "axios"
import Paginations from "../../Pagination"
import Loader from "../../Loader/loader.js"
import LogList from "./logList"
import InputFilter from "../../Filter/SearchFilter"
import DateFilter from "../../Filter/DateFilter"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {
   AvForm,
   AvField,
   AvCheckboxGroup,
   AvCheckbox,
} from "availity-reactstrap-validation"

const ActivityLogTable = () => {
   const [allLogs, setAllLogs] = useState([])
   const [error, setError] = useState("")
   const [loading, setLoading] = useState(false)
   const [filter_modal, setfilter_modal] = useState(false)
   const [name_filter, setname_filter] = useState(false)
   const [date_filter, setdate_filter] = useState(false)
   const [sort, setSort] = useState(false)
   const [sort2, setSort2] = useState(false)
   const [loader, setLoader] = useState(<Loader />)
   const [allPages, setAllPages] = useState([])
   const [counter, setCounter] = useState("")
   const [searchedCount, setSearchedCount] = useState("")
   const [leadsSelectedIds, setLeadsSelectedIds] = useState([])

   const getAllLogs = () => {
      setAllLogs([])
      setLoader(<Loader />)
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/activity-log?per_page=${localStorage.getItem("PerPage") !== null
               ? localStorage.getItem("PerPage")
               : ""
            }`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setLoader("")
               setError(res.data.message)
               setCounter(res.data.data.from)
               setAllLogs(res.data.data.data)
               setAllPages(res.data.data)
            } else {
               setLoader("")
               setError(res.data.message)
            }
         })
   }

   const paginate = e => {
      setAllLogs([])
      setCounter("")
      setAllPages([])
      setLoader(<Loader />)
      axios
         .get(
            `${e}&from_created_at=${getFilterDate() && getFilterDate().from !== null
               ? getFilterDate().from
               : ""
            }
            &to_created_at=${getFilterDate() && getFilterDate().to !== null
               ? getFilterDate().to
               : ""
            }
            &message=${localStorage.getItem("leadName") !== null
               ? localStorage.getItem("leadName")
               : ""
            }
            &sort=${localStorage.getItem("leadSort") !== null
               ? localStorage.getItem("leadSort")
               : ""
            }`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setLoader("")
               setCounter(res.data.data.from)
               setAllPages(res.data.data)
               setAllLogs(res.data.data.data)
            } else {
               setError(res.data.message)
               setLoader("")
            }
         })
   }

   const getSearchedLeads = (e, type) => {
      setError("")
      if (type == "name") {
         localStorage.setItem("leadName", e)
      }
      if (type == "date") {
         setFilterDate(e)
      }
      if (type == "sort") {
         localStorage.setItem("leadSort", e)
      }
      setSearchedCount("")
      setAllLogs([])
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/activity-log?from_created_at=${getFilterDate() && getFilterDate().from !== null
               ? getFilterDate().from
               : ""
            }
         &to_created_at=${getFilterDate() && getFilterDate().to !== null
               ? getFilterDate().to
               : ""
            }
         &message=${localStorage.getItem("leadName") !== null
               ? localStorage.getItem("leadName")
               : ""
            }
         &type=${localStorage.getItem("logType") !== null
               ? localStorage.getItem("logType")
               : ""
            }
         &is_undo=${localStorage.getItem("leadRestored") !== null
               ? localStorage.getItem("leadRestored")
               : ""
            }
         &user_id=${localStorage.getItem("leadHolder") !== null
               ? localStorage.getItem("leadHolder")
               : ""
            }
         &sort=${localStorage.getItem("leadSort") !== null
               ? localStorage.getItem("leadSort")
               : ""
            }
         &per_page=${localStorage.getItem("PerPage") !== null
               ? localStorage.getItem("PerPage")
               : ""
            }
         `,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setLoader("")
               setCounter(res.data.data.from)
               setAllLogs(res.data.data.data)
               setAllPages(res.data.data)
               setSearchedCount(res.data.data.total)
               setname_filter(false)
               setdate_filter(false)
               setfilter_modal(false)
            } else {
               setError(res.data.message)
            }
         })
   }

   const selectAllCheck = check => {
      if (check.checked) {
         var ele = document.getElementsByName("chk")
         for (var i = 0; i < ele.length; i++) {
            if (ele[i].type == "checkbox") {
               ele[i].checked = true
               leadsSelectedIds.push(parseInt(ele[i].value))
            }
         }
      } else {
         var ele = document.getElementsByName("chk")
         for (var i = 0; i < ele.length; i++) {
            if (ele[i].type == "checkbox") {
               ele[i].checked = false
               var array = leadsSelectedIds
               var index = array.indexOf(parseInt(ele[i].value))
               if (index !== -1) {
                  array.splice(index, 1)
               }
            }
         }
      }
   }

   const exportLead = () => {
      axios
         .post(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/activity-log-export`,
            {
               ids: leadsSelectedIds,
            },

            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setLeadsSelectedIds([])
               window.location.href = res.data.file_url
               setTimeout(() => {
                  dFileName(res.data.file_name)
               }, 5000)
            } else {
               toast.error(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            }
         })
   }

   const dFileName = e => {
      axios
         .post(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/delete-log-file`,
            {
               file_name: e,
            },
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
            } else {
            }
         })
   }

   const onFieldChange = (e) => {
      if (e !== undefined) {
         if (e.target.name === "leadholder_id") {
            localStorage.setItem("leadHolder", e.target.value)
         } else if (e.target.name === "log_type") {
            localStorage.setItem("logType", e.target.value)
         } else if (e.target.name === "dead_reason_id") {
            localStorage.setItem("deadReason", e.target.value)
         } else if (e.target.name === "restored") {
            localStorage.setItem(
               "leadRestored",
               e.target.checked === true ? "1" : "0"
            )
         }
      } else {
      }
   }

   const clearFilter = () => {
      localStorage.setItem("leadHolder", "")
      localStorage.setItem("leadRestored", "")
      localStorage.setItem("logType", "")
      setTimeout(() => {
         getSearchedLeads()
      }, 500)
   }

   useEffect(() => {
      getAllLogs()
   }, [])

   return (
      <>
         <div className="page-title-box">
            <Row className="align-items-center">
               <Col md={6}>
                  <h6 className="page-title">Activity Log</h6>
               </Col>
               <Col md={6}>
                  <h6 className="page-title float-end">
                     <button
                        className="btn btn-primary submit__button me-2 mb-2"
                        onClick={() => {
                           setfilter_modal(true)
                        }}
                     >
                        <i className="fa fa-filter me-2"></i>
                        Filter
                     </button>
                     {getPermission() &&
                        getPermission().lead_export.lead_export.view.is_checked ===
                        "yes" ? (
                        <button
                           className="btn btn-primary submit__button me-2 mb-2"
                           onClick={() => {
                              exportLead()
                           }}
                        >
                           Export
                        </button>
                     ) : (
                        ""
                     )}
                  </h6>
               </Col>
            </Row>
            <Row>
               <Col>
                  {searchedCount !== "" ? (
                     <h6 className="">
                        Searched Results
                        <span> ({searchedCount})</span>
                     </h6>
                  ) : (
                     ""
                  )}
               </Col>
            </Row>
         </div>
         <div className="card">
            <div className="card-body">
               <div className="lead_table table table-sm table-bordered table-responsive">
                  <table style={{ width: "100%" }}>
                     <tbody>
                        <tr className="static-header">
                           <th>
                              <input
                                 onClick={e => selectAllCheck(e.target)}
                                 className="p-0 d-inline-block"
                                 type="checkbox"
                              />
                           </th>
                           <th>#</th>
                           <th>
                              <div
                                 className="position-relative"
                                 style={{ whiteSpace: "nowrap" }}
                              >
                                 Message
                                 {name_filter ? (
                                    <>
                                       <span
                                          style={{ cursor: "pointer", color: "orange" }}
                                          onClick={() => setname_filter(false)}
                                       >
                                          <i className="fa fa-times fa-lg ms-2"></i>
                                       </span>
                                    </>
                                 ) : (
                                    <>
                                       {localStorage.getItem("leadName") == "" ? (
                                          <span
                                             className=""
                                             style={{ cursor: "pointer" }}
                                             onClick={() => setname_filter(true)}
                                          >
                                             <i className="fa fa-filter ms-2"></i>
                                          </span>
                                       ) : (
                                          <span
                                             className=""
                                             style={{ cursor: "pointer", color: "orange" }}
                                             onClick={() => setname_filter(true)}
                                          >
                                             <i className="fa fa-filter ms-2"></i>
                                          </span>
                                       )}
                                    </>
                                 )}
                                 <div className="first-filter">
                                    <InputFilter
                                       name_filter={name_filter}
                                       setname_filter={setname_filter}
                                       getSearchedLeads={getSearchedLeads}
                                    />
                                 </div>
                              </div>
                           </th>
                           <th>
                              <div
                                 className="position-relative"
                                 style={{ whiteSpace: "nowrap" }}
                              >
                                 Created at
                                 {date_filter ? (
                                    <>
                                       <span
                                          style={{ cursor: "pointer", color: "orange" }}
                                          onClick={() => setdate_filter(false)}
                                       >
                                          <i className="fa fa-times fa-lg ms-2"></i>
                                       </span>
                                    </>
                                 ) : (
                                    <>
                                       {getFilterDate() && getFilterDate().from == "" ? (
                                          <span
                                             className=""
                                             style={{ cursor: "pointer" }}
                                             onClick={() => setdate_filter(true)}
                                          >
                                             <i className="fa fa-filter ms-2"></i>
                                          </span>
                                       ) : (
                                          <span
                                             className=""
                                             style={{ cursor: "pointer", color: "orange" }}
                                             onClick={() => setdate_filter(true)}
                                          >
                                             <i className="fa fa-filter ms-2"></i>
                                          </span>
                                       )}
                                    </>
                                 )}
                                 {sort ? (
                                    <>
                                       <span
                                          className="fs-5"
                                          style={{ cursor: "pointer", color: "orange" }}
                                          onClick={() => {
                                             setSort2(true),
                                                setSort(false),
                                                getSearchedLeads("created_at,asc", "sort")
                                          }}
                                       >
                                          <i className="fa fa-sort-up ms-2"></i>
                                       </span>
                                       <span
                                          className="fs-5"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                             setSort2(true),
                                                setSort(false),
                                                getSearchedLeads("created_at,asc", "sort")
                                          }}
                                       >
                                          <i
                                             className="fa fa-sort-down"
                                             style={{
                                                position: "absolute",
                                                left: "0",
                                                bottom: "0",
                                                transform: "translate(867%, -3px)",
                                             }}
                                          ></i>
                                       </span>
                                    </>
                                 ) : (
                                    <>
                                       <span
                                          className="fs-5"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                             setSort(true),
                                                setSort2(false),
                                                getSearchedLeads("created_at,asc", "sort")
                                          }}
                                       >
                                          <i className="fa fa-sort-up ms-2"></i>
                                       </span>
                                       <span
                                          className="fs-5"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                             setSort2(true),
                                                setSort(false),
                                                getSearchedLeads("created_at,desc", "sort")
                                          }}
                                       >
                                          <i
                                             className="fa fa-sort-down"
                                             style={{
                                                position: "absolute",
                                                left: "0",
                                                bottom: "0",
                                                transform: "translate(867%, -3px)",
                                             }}
                                          ></i>
                                       </span>
                                    </>
                                 )}
                                 {sort2 ? (
                                    <>
                                       <span
                                          className="fs-5"
                                          style={{ cursor: "pointer", color: "orange" }}
                                          onClick={() => {
                                             setSort2(false),
                                                setSort(true),
                                                getSearchedLeads("created_at,desc", "sort")
                                          }}
                                       >
                                          <i
                                             className="fa fa-sort-down"
                                             style={{
                                                position: "absolute",
                                                left: "0",
                                                bottom: "0",
                                                transform: "translate(867%, -3px)",
                                             }}
                                          ></i>
                                       </span>
                                    </>
                                 ) : (
                                    ""
                                 )}
                                 <DateFilter
                                    date_filter={date_filter}
                                    setdate_filter={setdate_filter}
                                    getSearchedLeads={getSearchedLeads}
                                 />
                              </div>
                           </th>
                           <th>Action</th>
                        </tr>
                        {allLogs &&
                           allLogs.map((item, obj) => (
                              <LogList
                                 key={obj}
                                 data={item}
                                 i={obj}
                                 counter={counter}
                                 getAllLogs={getAllLogs}
                                 setLeadsSelectedIds={setLeadsSelectedIds}
                                 leadsSelectedIds={leadsSelectedIds}
                              />
                           ))}
                     </tbody>
                  </table>
                  {error ? (
                     <span className="text-danger mt-3 d-table m-auto" role="alert">
                        {error}
                     </span>
                  ) : (
                     ""
                  )}
               </div>
            </div>
         </div>
         {loader}
         {allPages ? (
            <Paginations data={allPages} paginate={paginate} perPage={getAllLogs} />
         ) : (
            ""
         )}
         <Modal isOpen={filter_modal} centered={true}>
            <AvForm
               className="modal-title mt-0"
               id="myModalLabel"
               onValidSubmit={() => {
                  getSearchedLeads()
               }}
            >
               <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myLargeModalLabel">
                     Filter Logs
                  </h5>
                  <button
                     type="button"
                     className="cross__btn"
                     onClick={() => setfilter_modal(false)}
                     aria-hidden="true"
                  >
                     &times;
                  </button>
               </div>
               <div className="modal-body">
                  <div className="mb-3">
                     <AvField
                        className="select-arrow"
                        label="Username"
                        type="select"
                        name="leadholder_id"
                        value={localStorage.getItem("leadHolder") && localStorage.getItem("leadHolder")}
                        onChange={e => onFieldChange(e)}
                     >
                        <option value="">Select</option>
                        {JSON.parse(localStorage.getItem("AllUsers")) &&
                           JSON.parse(localStorage.getItem("AllUsers")).map((option, i) =>
                              <option value={option.id} key={i}>{option.name}</option>
                           )}
                     </AvField>
                  </div>
                  <div className="mb-3">
                     <AvField
                        className="select-arrow"
                        label="Log type"
                        type="select"
                        name="log_type"
                        value={localStorage.getItem("logType") && localStorage.getItem("logType")}
                        onChange={e => onFieldChange(e)}
                     >
                        <option value="">Select</option>
                        <option value="transfer">Transferred</option>
                        <option value="dead">Dead</option>
                        <option value="import">Imported</option>
                        <option value="delete">Deleted</option>
                     </AvField>
                  </div>
                  {/* <div className="mb-3">
                     <AvField
                        className="select-arrow"
                        label="Dead Reason"
                        type="select"
                        name="dead_reason_id"
                        value={localStorage.getItem("deadReason") && localStorage.getItem("deadReason")}
                        onChange={e => onFieldChange(e)}
                     >
                        <option value="">Select</option>
                        {allDead && allDead.map((option, i) =>
                           <option value={option.id} key={i}>{option.reason}</option>
                        )}
                     </AvField>
                  </div> */}

                  <div className="mb-3">
                     <AvField
                        label="Restored"
                        type="checkbox"
                        name="restored"
                        defaultChecked={
                           localStorage.getItem("leadRestored") &&
                              localStorage.getItem("leadRestored") == "1"
                              ? true
                              : false
                        }
                        onChange={e => onFieldChange(e)}
                     />
                  </div>
               </div>
               <div className="modal-footer">
                  <div className="text-center">
                     <button
                        className="btn btn-primary me-2"
                        type="button"
                        onClick={() => clearFilter()}
                     >
                        Clear Filters
                     </button>
                     {loading ? (
                        <button
                           className="btn btn-primary disabled"
                           type="button"
                           disabled
                        >
                           Loading
                        </button>
                     ) : (
                        <button className="btn btn-primary" type="submit">
                           Submit
                        </button>
                     )}
                  </div>
               </div>
            </AvForm>
         </Modal>
      </>
   )
}
export default ActivityLogTable
