import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import axios from "axios"
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"
import { getCurrentUser } from "../../helpers/Utils"
import WorkflowTable from '../../components/Admin/workflows';


const Workflow = () => {

	return (
		<React.Fragment>
		<div className="page-content">
			<MetaTags>
				<title>Workflows | KlikCRM</title>
			</MetaTags>
				<Container fluid>
					<WorkflowTable/>
				</Container>
			</div>
		</React.Fragment>
	)
}

export default Workflow;
