import PropTypes from "prop-types"
import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import { Container, Row, Col } from "reactstrap"
import ProformaSettings from "../../components/Admin/profileComps/proformaSettings"

const ProformaSetting = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Proforma Settings | KlikCRM</title>
        </MetaTags>
        <Container fluid>
          <ProformaSettings />
        </Container>
      </div>
    </React.Fragment>
  )
}
export default ProformaSetting
