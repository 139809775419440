import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import { Row, Col, Alert, Card, CardBody, Container } from "reactstrap"
import axios from "axios"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import PropTypes from 'prop-types'


// import images
import bg from "../../assets/images/bg.jpg"
import logoSm from "../../assets/images/faviconmah.png"
import { userForgetPassword } from "../../store/actions"
import { setCurrentUser } from "../../helpers/Utils"
import { useHistory } from "react-router-dom"

const Register2 = props => {
   const [loading, setLoading] = useState(false)
   const history = useHistory()
   const handleValidSubmit = (event, value) => {
      setLoading(true)
      const api = axios.create();
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      api.interceptors.request.use(config => {
         config.cancelToken = source.token;
         return config;
      });

      const timeout = 120000; // 2 minute

      setTimeout(() => {
         source.cancel('Timeout');
      }, timeout);
      axios
         .post(`${process.env.REACT_APP_API_URL}/${localStorage.getItem('company_name')}/auth/forget-password`, {
            email: value.email,
         })
         .then(res => {
            if (res.data.status) {
               alert(res.data.message)
            } else { }
         })
   }

   return (
      <React.Fragment>
         <MetaTags>
            <title>Register 2 | KlikCRM</title>
         </MetaTags>
         <div
            className="accountbg"
            style={{
               backgroundSize: "cover",
               backgroundPosition: "center",
               backgroundImage: `url(${bg})`,
            }}
         ></div>
         <div className="wrapper-page account-page-full">
            <Card className="shadow-none">
               <div className="card-block">
                  <div className="account-box">
                     <div className="card-box shadow-none p-4">
                        <div className="p-2">
                           <div className="text-center mt-4">
                              <Link to="index.html">
                                 <img src={logoSm} height="22" alt="logo" />
                              </Link>
                           </div>

                           <h4 className="font-size-18 mt-5 text-center">
                              Free Register
                           </h4>
                           <p className="text-muted text-center">
                              Get your free KlikCRM account now.
                           </p>

                           <form className="mt-4" action="#">
                              <div className="mb-3">
                                 <label htmlFor="useremail">Email</label>
                                 <input
                                    type="email"
                                    className="form-control"
                                    id="useremail"
                                    placeholder="Enter email"
                                 />
                              </div>

                              <div className="mb-3">
                                 <label htmlFor="userpassword">Password</label>
                                 <input
                                    type="password"
                                    className="form-control"
                                    id="userpassword"
                                    placeholder="Enter password"
                                 />
                              </div>

                              <Row className="mb-3">
                                 <div className="col-12 text-end">
                                    <button
                                       className="btn btn-primary w-md waves-effect waves-light"
                                       type="submit"
                                    >
                                       Register
                                    </button>
                                 </div>
                              </Row>

                              <Row className="mt-2 mb-0">
                                 <div className="col-12 mt-3">
                                    <p className="mb-0">
                                       By registering you agree to the KlikCRM{" "}
                                       <Link to="#" className="text-primary">
                                          <span className="text__color__styling">
                                             Terms of Use
                                          </span>
                                       </Link>
                                    </p>
                                 </div>
                              </Row>
                           </form>

                           <div className="mt-5 pt-4 text-center">
                              <p>
                                 Already have an account ?{" "}
                                 <Link
                                    to="/login"
                                    className="fw-medium text-primary"
                                 >
                                    {" "}
                                    <span className="text__color__styling"> Login </span>
                                 </Link>{" "}
                              </p>
                              <p>
                                 © {new Date().getFullYear()} KlikCRM.com,{" "}
                                 {/* <i className=""></i>  */}
                                 all rights reserved.
                              </p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </Card>
         </div>
      </React.Fragment>
   )
}
export default Register2;