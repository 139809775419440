import React, { useState, useEffect } from "react"
import axios from "axios"
import { Container, Row, Col, Modal, FormGroup, Input, Alert } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import Loader from "../../Loader/loader.js"
import { getCurrentUser } from "../../../helpers/Utils"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"


export default function ProductLineModal(props) {
   const [loading, setLoading] = useState(false)
   const [loader2, setLoader2] = useState('')
   const [error, setError] = useState("")
   const [service, setService] = useState(props.service)
   const [others, setOthers] = useState({ fields: [], })

   const addOtherFields = () => {
      setOthers({
         fields: [...others.fields, { field_name: "", type: "text", value: "" }],
      });
   }

   const removeOtherFields = (i) => {
      setLoader2(<Loader />)
      let fields = others.fields;
      setOthers({ fields: [], })
      fields.splice(i, 1);
      setTimeout(() => {
         setOthers({ fields });
         setLoader2('')
      }, 500)
   }

   const handleOtherFields = (i, e) => {
      let fields = others.fields;
      fields[i][e.target.name] = e.target.value;
      setOthers({ fields });
   }

   const getSingleService = (e) => {
      axios
         .get(`${process.env.REACT_APP_API_URL}/${localStorage.getItem('company_name')}/services/${e}`, {
            headers: {
               "content-type": "application/json",
               Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token }`,
            },
         })
         .then(function (res) {
            if (res.data.status) {
               setOthers({
                  fields: res.data.data.service_fields?.map((item) => (
                     { field_name: item.field_name, type: item.type, value: item.value }
                  )),
               })
            } else {

            }
         })
   }

   const submitProductLine = (e, value) => {
      setLoading(true)
      const api = axios.create();
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      api.interceptors.request.use(config => {
         config.cancelToken = source.token;
         return config;
      });

      const timeout = 120000; // 2 minute

      setTimeout(() => {
         source.cancel('Timeout');
      }, timeout);
      if (props.service !== undefined) {
         axios
            .put(
               `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
                  "company_name"
               )}/services/${props.service.id}`,
               {
                  name: value.name,
                  servicefields: others.fields,
               },
               {
                  headers: {
                     Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token }`,
                  },
               }
            )
            .then(function (res) {
               if (res.data.status) {
                  setLoading(false)
                  props.setIsOpen(false)
                  props.setmodal_standard(false)
                  props.setService(res.data.data)
                  toast.success(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               } else {
                  setLoading(false)
                  toast.error(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               }
            })
      }
      else {
         axios
            .post(
               `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
                  "company_name"
               )}/services`,
               {
                  name: value.name,
                  servicefields: others.fields,
               },
               {
                  headers: {
                     Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token }`,
                  },
               }
            )
            .then(function (res) {
               if (res.data.status) {
                  setLoading(false)
                  props.getServiceLine()
                  props.setmodal_standard(false)
                  toast.success(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               } else {
                  setLoading(false)
                  toast.error(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               }
            })
      }
   }

   useEffect(() => {
      if (props.service !== undefined) {
         getSingleService(props.service.id)
      }
   }, [])

   useEffect(() => {
      const close = (e) => {
         if (e.key === 'Escape') {
            props.setmodal_standard(false)
         }
      }
      window.addEventListener('keydown', close)
      return () => window.removeEventListener('keydown', close)
   }, [])

   return (
      <>
         <Modal isOpen={props.modal_standard} centered={true} size="lg">
            <AvForm
               className="modal-title mt-0"
               id="myModalLabel"
               onValidSubmit={(e, v) => {
                  submitProductLine(e, v)
               }}
            >
               <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myLargeModalLabel">
                     {props.service !== undefined ? "Edit Product/Service" : "Create Product/Service"}
                  </h5>
                  <button
                     type="button"
                     className="cross__btn"
                     onClick={() => props.setmodal_standard(false)}
                     aria-hidden="true"
                  >
                     &times;
                  </button>
               </div>
               <div className="modal-body">
                  <div className="mb-3">
                     <AvField
                        label="Product/Service Name"
                        type="text"
                        name="name"
                        placeholder="Name"
                        value={props.service && props.service.name}
                     />
                  </div>
                  <div className="d-flex justify-content-between mb-3 mt-2">
                     <label>Other Fields</label>
                     <div className="button-section">
                        <button
                           className="btn btn-success p-1"
                           type="button"
                           onClick={() => addOtherFields()}
                           disabled={others.fields?.length < 5 ? false : true}
                        >
                           <i className="fa fa-plus p-1"></i>
                        </button>
                     </div>
                  </div>
                  {loader2 != '' ? loader2 :
                     <div className="table-responsive mb-3">
                        <table style={{ width: "100%" }}>
                           <tbody>
                              <tr>
                                 <th>Name</th>
                                 <th>Type</th>
                                 <th>Value</th>
                              </tr>
                              {others.fields?.map((item, index) => (
                                 <tr key={index}>
                                    <td>
                                       <AvField
                                          name="field_name"
                                          type="text"
                                          placeholder="Enter name"
                                          onChange={(e) => handleOtherFields(index, e)}
                                          value={item.field_name}
                                       />
                                    </td>
                                    <td>
                                       <AvField
                                          className="select-arrow"
                                          name="type"
                                          type="select"
                                          onChange={(e) => handleOtherFields(index, e)}
                                          value={item.type}
                                       >
                                          <option value="text">Text Box</option>
                                          <option value="dropdown">Dropdown</option>
                                       </AvField>
                                    </td>
                                    {item.type == "text" ?
                                       <td></td>
                                       :
                                       <td>
                                          <AvField
                                             name="value"
                                             type="text"
                                             placeholder="Enter"
                                             value={item.value}
                                             onChange={(e) => handleOtherFields(index, e)}
                                          />
                                       </td>
                                    }
                                    <td>
                                       <span
                                          className="btn btn-danger"
                                          onClick={() => removeOtherFields(index)}
                                       >
                                          &times;
                                       </span>
                                    </td>
                                 </tr>
                              ))}
                           </tbody>
                        </table>
                     </div>
                  }
                  <div className="modal-footer">
                     <div className="text-end">
                        {loading ? (
                           <button
                              className="btn btn-primary disabled"
                              type="button"
                              disabled
                           >
                              Loading
                           </button>
                        ) : (
                           <button
                              className="btn btn-primary"
                              type="submit"
                           // onClick={() => props.setmodal_standard(false)}
                           >
                              {props.service !== undefined
                                 ? "Submit"
                                 : "Create"}
                           </button>
                        )}
                     </div>
                  </div>
               </div>
            </AvForm>
         </Modal>
      </>
   )
}
