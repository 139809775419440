import React, { useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import {
  getCurrentUser,
  getPermission,
  sendWhatsAppMessage,
} from "../../helpers/Utils"
import { Link } from "react-router-dom"
import axios from "axios"
import LocationModal from "../Admin/Location/LocationModal"
import {
  Row,
  Col,
  Modal,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import classnames from "classnames"
import Loader from "../Loader/loader.js"
import DatePicker from "react-datepicker"

const OccupiedList = props => {
  const [location, setLocation] = useState(props.data)
  const [modal2, setmodal2] = useState(false)
  const [filterModal, setFilterModal] = useState(false)
  const [loader, setLoader] = useState("")
  const [modal_summary, setmodal_summary] = useState(false)
  const [confirm_alert, setconfirm_alert] = useState(false)
  const [success_dlg, setsuccess_dlg] = useState(false)
  const [dynamic_title, setdynamic_title] = useState("")
  const [dynamic_description, setdynamic_description] = useState("")
  const [customActiveTab, setCustomActiveTab] = useState(1)
  const [error, setError] = useState("")
  const [leadData, setLeadData] = useState([])
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")

  const toggleCustom = tab => {
    setStartDate("")
    setEndDate("")
    if (customActiveTab !== tab) {
      setCustomActiveTab(tab)
    }
    if (tab === 1) {
      getLeadData("ordered-products")
    }
    if (tab === 2) {
      getLeadData("not-ordered-products")
    }
    if (tab === 3) {
      getLeadData("not-ordered-products-three-months")
    }
  }

  function formatDate(originalDateString) {
    // Parse the original date string
    const originalDate = new Date(originalDateString)

    // Format the date as YYYY-MM-DD
    const formattedDate = `${originalDate.getFullYear()}-${(
      originalDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${originalDate.getDate().toString().padStart(2, "0")}`

    return formattedDate
  }

  const getLeadData = e => {
    setLoader(<Loader />)
    setError("")
    setLeadData([])
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/${e}?customer_id=${location.leads.id}&start_date=${
          startDate ? formatDate(startDate) : ""
        }&end_date=${endDate ? formatDate(endDate) : ""}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLeadData(res.data.data)
        } else {
          setError(res.data.message)
        }
        setLoader("")
        setmodal_summary(true)
      })
  }

  const downloadLeadData = e => {
    setError("")
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/${e}?customer_id=${location.leads.id}&start_date=${
          startDate ? formatDate(startDate) : ""
        }&end_date=${endDate ? formatDate(endDate) : ""}&download=1`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          window.location.href = res.data?.file_url
        } else {
          setError(res.data.message)
        }
      })
  }

  const shareLeadData = e => {
    setError("")
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/${e}?customer_id=${location.leads.id}&start_date=${
          startDate ? formatDate(startDate) : ""
        }&end_date=${endDate ? formatDate(endDate) : ""}&download=1`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          if (res.data?.file_url) {
            window.open(
              sendWhatsAppMessage(location?.leads.number, res.data?.file_url)
            )
          }
        } else {
          setError(res.data.message)
        }
      })
  }

  const deleteLocation = e => {
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/location/${e}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLocation("")
          setdynamic_description(res.data.message)
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  const editLocation = e => {
    setmodal2(true)
  }

  if (location === "") {
    return ""
  } else
    return (
      <>
        {modal2 ? (
          <LocationModal
            modal2={modal2}
            setmodal2={setmodal2}
            location={location}
            setLocation={setLocation}
          />
        ) : null}
        <tr key={props.i}>
          <td>{props.i + 1}</td>
          <td>{location.state}</td>
          <td>
            {location.city} <br />
            {location.district}
          </td>
          <td>{location.info}</td>
          <td>{location.pincode}</td>
          {/* <td>
                  {location.lead_label_name?.map((item, i) => (
                     <div key={i}>
                        <span
                           className="badge"
                           style={{ backgroundColor: "#f39c12" }}
                        >
                           {item.name}
                        </span>{" "}
                        <br />
                     </div>
                  ))}
               </td> */}
          <td>
            {getCurrentUser()?.role_name === "Administrator" ? (
              <>
                <span className="fw-bold">Name:</span>
                {location.leads && location.leads.is_clickable === 1 ? (
                  <Link
                    className="text-info"
                    to={`/leads?id=${location.leads && location.leads.id}`}
                  >
                    {" "}
                    {location.leads && location.leads.name}
                  </Link>
                ) : (
                  <span> {location.leads && location.leads.name}</span>
                )}
                <br />
                <span className="fw-bold">Firm name: </span>
                {location.leads && location.leads.firm_name}
                <br />
              </>
            ) : (
              ""
            )}
            <span className="fw-bold">Leadholder: </span>
            {location.leads && location.leads.leadholder_name}
            <br />
            <span className="fw-bold">Phone: </span>
            {location.leads && location.leads.leadholder_phone}
            <br />
            <span className="fw-bold">Senior: </span>
            {location.leads && location.leads.senior}
          </td>
          {getCurrentUser().crmpro_permission == 1 ||
          getCurrentUser().crmpronew_permission == 1 ? (
            <td>
              <button
                className="btn btn-primary"
                onClick={() => getLeadData("ordered-products")}
              >
                {" "}
                Summary
              </button>
            </td>
          ) : (
            <td>
              <span className="fw-bold">Customer Since: </span>{" "}
              {location.leads && location.leads.created_at}
              <br />
              <span className="fw-bold">Last 3 months billing:</span>{" "}
              {location.last_three_month_avg}
              <br />
              <span className="fw-bold">Last order date:</span>{" "}
              {location.leads && location.leads.last_order_date}
              <br />
              <span className="fw-bold">Outstanding:</span>{" "}
              {location.outstanding_amount}
              <hr />
              <span className="fw-bold">Info:</span>{" "}
              {location?.lead_label_name !== null
                ? location.lead_label_name.map((item, i) => (
                    <>
                      <span
                        key={i}
                        className="badge"
                        style={{ backgroundColor: "#f39c12" }}
                      >
                        {item.name}
                      </span>{" "}
                    </>
                  ))
                : ""}
            </td>
          )}
          <td>
            {getCurrentUser()?.role_name === "Administrator" ||
            getCurrentUser().id === location?.leads?.leadholder_id ? (
              <a
                className="text-warning"
                onClick={() => {
                  editLocation(location.id)
                }}
              >
                <i className="fas fa-pen" />
              </a>
            ) : (
              ""
            )}
            {getPermission() &&
            getPermission().location.location.delete.is_checked === "yes" ? (
              <a
                className="text-danger ms-2"
                onClick={() => {
                  setconfirm_alert(true)
                }}
              >
                <i className="fas fa-trash-alt" />
              </a>
            ) : (
              ""
            )}
            {confirm_alert ? (
              <SweetAlert
                title="Are you sure?"
                warning
                showCancel
                confirmButtonText="Yes, delete it!"
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                onConfirm={() => {
                  setconfirm_alert(false)
                  setsuccess_dlg(true)
                  deleteLocation(location.id)
                  setdynamic_title("Deleted")
                  setdynamic_description("Your file has been deleted.")
                }}
                onCancel={() => setconfirm_alert(false)}
              >
                You won't be able to revert this!
              </SweetAlert>
            ) : null}
          </td>
        </tr>
        <Modal isOpen={modal_summary} centered={true} size="xl">
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myLargeModalLabel">
              {location?.leads.name}'s Summary
            </h5>
            <button
              type="button"
              className="cross__btn"
              onClick={() => setmodal_summary(false)}
              aria-hidden="true"
            >
              &times;
            </button>
          </div>
          <div className="modal-body">
            <Row
              className="m-0 p-3"
              style={{
                background: "#efefef",
              }}
            >
              <Col md={4}>
                <span>
                  {(location?.leads && location?.leads.firm_name == null) ||
                  location?.leads.firm_name == "" ? (
                    ""
                  ) : (
                    <>
                      <span>
                        <span className="fw-bold">Firm name:</span>{" "}
                        {location?.leads && location?.leads.firm_name}
                      </span>
                      <br />
                    </>
                  )}
                </span>
                <span>
                  <span className="fw-bold">Email: </span>
                  <a
                    className="text-info"
                    target="_blank"
                    href={`mailto:${location?.leads.email}`}
                  >
                    {getCurrentUser().role_name !== "Administrator" ? (
                      <>***********.gmail.com</>
                    ) : (
                      <>{location?.leads.email}</>
                    )}
                  </a>
                </span>{" "}
                <br />
                <span>
                  <span className="fw-bold">Phone:</span>{" "}
                  {location?.leads.country_code !== null ? (
                    <>{location?.leads.country_code}</>
                  ) : (
                    "+91"
                  )}{" "}
                  <a
                    className="text-info"
                    href={`tel://+91${location?.leads.number}`}
                    target="_blank"
                  >
                    {location?.leads.number}
                  </a>
                </span>{" "}
                <a
                  style={{ cursor: "pointer", color: "#08913b" }}
                  target="_blank"
                  href={sendWhatsAppMessage(location?.leads.number)}
                >
                  <i className="fab fa-whatsapp ms-2 fa-lg"></i>
                </a>{" "}
                <br />
                <span>
                  <span className="fw-bold">Alternate Number:</span>{" "}
                  {location?.leads.alternate_number !== null ? (
                    <>
                      {location?.leads.country_code !== null ? (
                        <> +{location?.leads.country_code}</>
                      ) : (
                        "+91"
                      )}{" "}
                      <a
                        className="text-info"
                        href={`tel://+91${location?.leads.alternate_number}`}
                        target="_blank"
                      >
                        {getCurrentUser().role_name == "Administrator" ? (
                          <>**********</>
                        ) : (
                          <>{location?.leads.alternate_number}</>
                        )}
                      </a>
                      <a
                        style={{ cursor: "pointer", color: "#08913b" }}
                        target="_blank"
                        href={sendWhatsAppMessage(
                          location?.leads.alternate_number
                        )}
                      >
                        <i className="fab fa-whatsapp ms-2 fa-lg"></i>
                      </a>{" "}
                    </>
                  ) : (
                    ""
                  )}
                </span>{" "}
                <br />
                <span>
                  <span className="fw-bold">Location:</span>{" "}
                  {location?.leads.city !== null ? (
                    <>{location?.leads.city},</>
                  ) : (
                    ""
                  )}{" "}
                  {location?.leads.district !== null ? (
                    <>{location?.leads.district},</>
                  ) : (
                    ""
                  )}{" "}
                  {location?.leads.state !== null ? (
                    <>{location?.leads.state},</>
                  ) : (
                    ""
                  )}{" "}
                  {location?.leads.country}
                </span>{" "}
                <br />
                <span>
                  {location?.leads.pin_code !== null ? (
                    <>
                      <span className="fw-bold">PIN:</span>{" "}
                      {location?.leads.pin_code}
                    </>
                  ) : (
                    ""
                  )}
                </span>
              </Col>
              <Col md={5}>
                <span className="fw-bold">Customer Since: </span>{" "}
                {location.leads && location.leads.created_at}
                <br />
                <span className="fw-bold">Last 3 months billing:</span>{" "}
                {location.last_three_month_avg}
                <br />
                <span className="fw-bold">Last order date:</span>{" "}
                {location.leads && location.leads.last_order_date}
                <br />
                <span className="fw-bold">Outstanding:</span>{" "}
                {location.outstanding_amount}
                <hr />
                <span className="fw-bold">Info:</span>{" "}
                {location?.lead_label_name !== null
                  ? location.lead_label_name.map((item, i) => (
                      <>
                        <span
                          key={i}
                          className="badge"
                          style={{ backgroundColor: "#f39c12" }}
                        >
                          {item.name}
                        </span>{" "}
                      </>
                    ))
                  : ""}
              </Col>
              <Col md={3}>
                <span className="fw-bold d-flex justify-content-center">
                  Business Done/Target
                </span>
                <div className="p-2" style={{ border: "gray solid 2px" }}>
                  <span>
                    <span className="fw-bold">Monthly: </span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: location?.leads.monthly_bussiness_target,
                      }}
                    />
                  </span>{" "}
                  <br />
                  <span>
                    <span className="fw-bold">Last 3 Months: </span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: location?.leads.three_months_bussiness,
                      }}
                    />
                  </span>{" "}
                  <br />
                  <span>
                    <span className="fw-bold">Overall: </span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: location?.leads.overall_bussiness_target,
                      }}
                    />
                  </span>
                </div>
              </Col>
            </Row>
            <Nav
              tabs
              style={{
                justifyContent: "center",
                background: "#efefef",
              }}
            >
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({ active: customActiveTab === 1 })}
                  onClick={() => {
                    toggleCustom(1)
                  }}
                >
                  {" "}
                  Products Ordered
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({ active: customActiveTab === 2 })}
                  onClick={() => {
                    toggleCustom(2)
                  }}
                >
                  {" "}
                  Products Not Ordered
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({ active: customActiveTab === 3 })}
                  onClick={() => {
                    toggleCustom(3)
                  }}
                >
                  {" "}
                  Product not ordered in last 3 months
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={customActiveTab} className="pt-3">
              <TabPane tabId={1}>
                <Row className="align-items-center">
                  <Col md={2}></Col>
                  <Col md={10}>
                    <h6 className="page-title float-end">
                      <button
                        className="btn btn-primary submit__button me-2 mb-2"
                        onClick={() => {
                          shareLeadData("ordered-products")
                        }}
                      >
                        <i className="fab fa-whatsapp me-2"></i>
                        Share
                      </button>
                      <button
                        className="btn btn-primary submit__button mb-2"
                        onClick={() => {
                          downloadLeadData("ordered-products")
                        }}
                      >
                        <i className="fa fa-download me-2"></i>
                        Download
                      </button>
                    </h6>
                  </Col>
                </Row>
                <div
                  className="lead_table table-responsive"
                  style={{ maxHeight: "600px", overflow: "auto" }}
                >
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <th>#</th>
                        <th>Total Orders</th>
                        <th>Last Order</th>
                        {/* {columns?.map((item, i) => (
                                       <th key={i}>{item}</th>
                                    ))} */}
                        <th>Product Name</th>
                        <th>Stock Left</th>
                        <th>Last Rate</th>
                        <th>Offer Price</th>
                        <th>Total Quantity</th>
                        <th>Expiry</th>
                      </tr>
                      {leadData &&
                        leadData.map((item, i) => (
                          <tr>
                            <td>{i + 1}</td>
                            <td>{item.product?.total_orders}</td>
                            <td>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: item.product?.last_order,
                                }}
                              />
                            </td>
                            <td>{item.product?.name}</td>
                            <td>{item.product?.stock_left}</td>
                            <td>{item.product?.last_rate}</td>
                            <td>{item.product?.offer_price}</td>
                            <td>{item.product?.stock}</td>
                            <td>{item.product?.expiry}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  {error ? (
                    <span
                      className="text-danger mt-3 d-table m-auto"
                      role="alert"
                    >
                      {error}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                {loader}
              </TabPane>
              <TabPane tabId={2}>
                <Row className="align-items-center">
                  <Col md={2}></Col>
                  <Col md={10}>
                    <h6 className="page-title float-end">
                      <button
                        className="btn btn-primary submit__button me-2 mb-2"
                        onClick={() => {
                          shareLeadData("not-ordered-products")
                        }}
                      >
                        <i className="fab fa-whatsapp me-2"></i>
                        Share
                      </button>
                      <button
                        className="btn btn-primary submit__button mb-2"
                        onClick={() => {
                          downloadLeadData("not-ordered-products")
                        }}
                      >
                        <i className="fa fa-download me-2"></i>
                        Download
                      </button>
                    </h6>
                  </Col>
                </Row>
                <div
                  className="lead_table table-responsive"
                  style={{ maxHeight: "600px", overflow: "auto" }}
                >
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <th>#</th>
                        <th>Product Name</th>
                        <th>Stock</th>
                        <th>Expiry</th>
                      </tr>
                      {leadData &&
                        leadData.map((product, i) => (
                          <tr>
                            <td>{i + 1}</td>
                            <td>{product?.name}</td>
                            <td>{product?.stock}</td>
                            <td>{product?.expiry}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  {error ? (
                    <span
                      className="text-danger mt-3 d-table m-auto"
                      role="alert"
                    >
                      {error}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                {loader}
              </TabPane>
              <TabPane tabId={3}>
                <Row className="align-items-center">
                  <Col md={2}></Col>
                  <Col md={10}>
                    <h6 className="page-title float-end">
                      <button
                        className="btn btn-primary submit__button me-2 mb-2"
                        onClick={() => {
                          setFilterModal(true)
                        }}
                      >
                        <i className="fa fa-filter me-2"></i>
                        Filter
                      </button>
                      <button
                        className="btn btn-primary submit__button me-2 mb-2"
                        onClick={() => {
                          shareLeadData("not-ordered-products-three-months")
                        }}
                      >
                        <i className="fab fa-whatsapp me-2"></i>
                        Share
                      </button>
                      <button
                        className="btn btn-primary submit__button mb-2"
                        onClick={() => {
                          downloadLeadData("not-ordered-products-three-months")
                        }}
                      >
                        <i className="fa fa-download me-2"></i>
                        Download
                      </button>
                    </h6>
                  </Col>
                </Row>
                <div
                  className="lead_table table-responsive"
                  style={{ maxHeight: "600px", overflow: "auto" }}
                >
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <th>#</th>
                        <th>Total Orders</th>
                        <th>Last Order</th>
                        {/* {columns?.map((item, i) => (
                                       <th key={i}>{item}</th>
                                    ))} */}
                        <th>Product Name</th>
                        <th>Stock Left</th>
                        <th>Last Rate</th>
                        <th>Offer Price</th>
                        <th>Total Quantity</th>
                        <th>Expiry</th>
                      </tr>
                      {leadData &&
                        leadData.map((item, i) => (
                          <tr>
                            <td>{i + 1}</td>
                            <td>{item.product?.total_orders}</td>
                            <td>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: item.product?.last_order,
                                }}
                              />
                            </td>
                            <td>{item.product?.name}</td>
                            <td>{item.product?.stock_left}</td>
                            <td>{item.product?.last_rate}</td>
                            <td>{item.product?.offer_price}</td>
                            <td>{item.product?.stock}</td>
                            <td>{item.product?.expiry}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  {error ? (
                    <span
                      className="text-danger mt-3 d-table m-auto"
                      role="alert"
                    >
                      {error}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                {loader}
                <Modal isOpen={filterModal} centered={true} size="">
                  <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myLargeModalLabel">
                      Filter Reports
                    </h5>
                    <button
                      type="button"
                      className="cross__btn"
                      onClick={() => setFilterModal(false)}
                      aria-hidden="true"
                    >
                      &times;
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="mb-3">
                      <label>From</label>
                      <DatePicker
                        className="w-100 form-control"
                        dateFormat="yyyy-MM-dd"
                        name="start_date"
                        selected={startDate}
                        // minDate={moment().toDate()}
                        onChange={date => setStartDate(date)}
                      />
                    </div>
                    <div className="mb-3">
                      <label>To</label>
                      <DatePicker
                        className="w-100 form-control"
                        dateFormat="yyyy-MM-dd"
                        name="end_date"
                        selected={endDate}
                        // minDate={moment().toDate()}
                        onChange={date => setEndDate(date)}
                      />
                    </div>
                  </div>
                  <div className="modal-footer text-center">
                    {loader ? (
                      <button
                        className="btn btn-primary disabled"
                        type="button"
                        disabled
                      >
                        Loading
                      </button>
                    ) : (
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={() => {
                          getLeadData("not-ordered-products-three-months"),
                            setFilterModal(false)
                        }}
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </Modal>
              </TabPane>
            </TabContent>
          </div>
        </Modal>
      </>
    )
}
export default OccupiedList
