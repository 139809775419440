import PropTypes from 'prop-types'
import React, { useState } from "react"
import MetaTags from 'react-meta-tags';
import {
	Container,
	Row,
	Col
} from "reactstrap"
import MyLeadsTable from '../../../components/Lead/myLeads';

const MyLeads = () => {
	return (
		<React.Fragment>
			<div className="page-content">
				<MetaTags>
					<title>My Leads | KlikCRM</title>
				</MetaTags>
				<Container fluid>
					<MyLeadsTable />
				</Container>
			</div>
		</React.Fragment>
	)
}
export default MyLeads;