import React, { useState, useEffect } from "react"
import { Container, Row, Col, Modal, FormGroup, Input } from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert"
import {
   getCurrentUser,
   getPermission,
   getFilterDate,
   setFilterDate,
} from "../../helpers/Utils"
import axios from "axios"
import { Link } from "react-router-dom"
import Paginations from "../Pagination"
import Loader from "../Loader/loader.js"
import LedgerList from "./LedgerList"
import LedgerModal from "./LedgerModal"
import MultipleChoice from "../Filter/MultipleChoice"
import InputFilter from "../Filter/SearchFilter"
import DateFilter from "../Filter/DateFilter"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const LedgerTable = () => {
   const [modal_standard, setmodal_standard] = useState(false)
   const [id_filter, setid_filter] = useState(false)
   const [name_filter, setname_filter] = useState(false)
   const [status4_filter, setstatus4_filter] = useState(false)
   const [leadholder_filter, setleadholder_filter] = useState(false)
   const [amount_filter, setamount_filter] = useState(false)
   const [date_filter, setdate_filter] = useState(false)
   const [message_filter, setmessage_filter] = useState(false)
   const [sort, setSort] = useState(false)
   const [sort2, setSort2] = useState(false)
   const [sort3, setSort3] = useState(false)
   const [sort4, setSort4] = useState(false)
   const [sort5, setSort5] = useState(false)
   const [sort6, setSort6] = useState(false)
   const [ledger, setLedger] = useState([])
   const [error, setError] = useState("")
   const [loader, setLoader] = useState(<Loader />)
   const [allPages, setAllPages] = useState([])
   const [counter, setCounter] = useState("")
   const [allUsers, setAllUsers] = useState([])
   const [searchedCount, setSearchedCount] = useState("")
   const [leadsSelectedIds, setLeadsSelectedIds] = useState([])
   const [success_dlg, setsuccess_dlg] = useState(false)
   const [dynamic_title, setdynamic_title] = useState("")
   const [dynamic_description, setdynamic_description] = useState("")
   const [confirm_alert, setconfirm_alert] = useState(false)
   const [blur, setBlur] = useState(false)

   const getAllLedgers = () => {
      setLoader(<Loader />)
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/ledger?per_page=${localStorage.getItem("PerPage") !== null
               ? localStorage.getItem("PerPage")
               : ""
            }`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setLoader("")
               setError(res.data.message)
               setCounter(res.data.data.from)
               setLedger(res.data.data.data)
               setAllPages(res.data.data)
            } else {
               setLoader("")
               setError(res.data.message)
            }
         })
   }

   const getSearchedLeads = (e, type) => {
      setError("")
      if (type == "id") {
         localStorage.setItem("leadID", e)
      }
      if (type == "name") {
         localStorage.setItem("leadName", e)
      }
      if (type == "status4") {
         localStorage.setItem("ledgerType", e)
      }
      if (type == "leadholder") {
         localStorage.setItem("leadHolder", e)
      }
      if (type == "amount") {
         localStorage.setItem("leadAmount", e)
      }
      if (type == "date") {
         setFilterDate(e)
      }
      if (type == "message") {
         localStorage.setItem("leadMessage", e)
      }
      if (type == "sort") {
         localStorage.setItem("leadSort", e)
      }
      setSearchedCount("")
      setLedger([])
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/ledger?id=${localStorage.getItem("leadID") !== null
               ? localStorage.getItem("leadID")
               : ""
            }
         &admin_id=${localStorage.getItem("leadHolder") !== null
               ? localStorage.getItem("leadHolder")
               : ""
            }
         &amount=${localStorage.getItem("leadAmount") !== null
               ? localStorage.getItem("leadAmount")
               : ""
            }
         &type=${localStorage.getItem("ledgerType") !== null
               ? localStorage.getItem("ledgerType")
               : ""
            } 
         &customer_name=${localStorage.getItem("leadName") !== null
               ? localStorage.getItem("leadName")
               : ""
            }
         &from_date=${getFilterDate() && getFilterDate().from !== null
               ? getFilterDate().from
               : ""
            }
         &to_date=${getFilterDate() && getFilterDate().to !== null
               ? getFilterDate().to
               : ""
            }
         &message=${localStorage.getItem("leadMessage") !== null
               ? localStorage.getItem("leadMessage")
               : ""
            }
         &sort=${localStorage.getItem("leadSort") !== null
               ? localStorage.getItem("leadSort")
               : ""
            }
         &per_page=${localStorage.getItem("PerPage") !== null
               ? localStorage.getItem("PerPage")
               : ""
            }
         `,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setLoader("")
               setCounter(res.data.data.from)
               setLedger(res.data.data.data)
               setAllPages(res.data.data)
               setSearchedCount(res.data.data.total)
               setid_filter(false)
               setname_filter(false)
               setstatus4_filter(false)
               setleadholder_filter(false)
               setamount_filter(false)
               setdate_filter(false)
               setmessage_filter(false)
            } else {
               setError(res.data.message)
            }
         })
   }

   const paginate = e => {
      setLedger([])
      setAllPages([])
      setCounter("")
      setLoader(<Loader />)
      axios
         .get(
            `${e}&id=${localStorage.getItem("leadID") !== null
               ? localStorage.getItem("leadID")
               : ""
            }
            &admin_id=${localStorage.getItem("leadHolder") !== null
               ? localStorage.getItem("leadHolder")
               : ""
            }
            &amount=${localStorage.getItem("leadAmount") !== null
               ? localStorage.getItem("leadAmount")
               : ""
            }
            &type=${localStorage.getItem("ledgerType") !== null
               ? localStorage.getItem("ledgerType")
               : ""
            } 
            &customer_name=${localStorage.getItem("leadName") !== null
               ? localStorage.getItem("leadName")
               : ""
            }
            &from_date=${getFilterDate() && getFilterDate().from !== null
               ? getFilterDate().from
               : ""
            }
            &to_date=${getFilterDate() && getFilterDate().to !== null
               ? getFilterDate().to
               : ""
            }
            &message=${localStorage.getItem("leadMessage") !== null
               ? localStorage.getItem("leadMessage")
               : ""
            }
            &sort=${localStorage.getItem("leadSort") !== null
               ? localStorage.getItem("leadSort")
               : ""
            }`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setLoader("")
               setCounter(res.data.data.from)
               setAllPages(res.data.data)
               setLedger(res.data.data.data)
            } else {
               setError(res.data.message)
               setLoader("")
            }
         })
   }

   const getAllUsers = () => {
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/user?per_page=5000`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setAllUsers(res.data.data.data)
            } else {
            }
         })
         .catch(err => {
            console.log("err", err)
         })
   }

   const selectAllCheck = check => {
      if (check.checked) {
         var ele = document.getElementsByName("chk")
         for (var i = 0; i < ele.length; i++) {
            if (ele[i].type == "checkbox") {
               ele[i].checked = true
               leadsSelectedIds.push(parseInt(ele[i].value))
            }
         }
      } else {
         var ele = document.getElementsByName("chk")
         for (var i = 0; i < ele.length; i++) {
            if (ele[i].type == "checkbox") {
               ele[i].checked = false
               var array = leadsSelectedIds
               var index = array.indexOf(parseInt(ele[i].value))
               if (index !== -1) {
                  array.splice(index, 1)
               }
            }
         }
      }
      if (leadsSelectedIds.length === 0) {
         setBlur(false)
      } else {
         setBlur(true)
      }
   }

   const deleteLeads = () => {
      axios
         .post(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/multiple-ledger-deleted`,
            {
               ids: leadsSelectedIds,
            },
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setLeadsSelectedIds([])
               getAllLedgers()
               setdynamic_description(res.data.message)
               toast.success(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            } else {
               toast.error(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            }
         })
   }

   useEffect(() => {
      getAllUsers()
      getAllLedgers()
   }, [])

   return (
      <>
         {modal_standard ? (
            <LedgerModal
               modal_standard={modal_standard}
               setmodal_standard={setmodal_standard}
               getAllLedgers={getAllLedgers}
            />
         ) : (
            ""
         )}
         {confirm_alert ? (
            <SweetAlert
               title="Are you sure?"
               warning
               showCancel
               confirmButtonText="Yes, delete it!"
               confirmBtnBsStyle="success"
               cancelBtnBsStyle="danger"
               onConfirm={() => {
                  setconfirm_alert(false)
                  setsuccess_dlg(true)
                  deleteLeads()
                  setdynamic_title("Deleted")
                  setdynamic_description("Your file has been deleted.")
               }}
               onCancel={() => setconfirm_alert(false)}
            >
               <span className="text-danger">You won't be able to revert this!</span>
            </SweetAlert>
         ) : null}
         <div className="page-title-box">
            <Row className="align-items-center">
               <Col md={6}>
                  <h6 className="page-title">Ledgers</h6>
               </Col>
               <Col md={6}>
                  <h6 className="page-title float-end">
                     {getPermission().ledger.ledger.delete.is_checked === "yes" ?
                        <>
                           {blur ?
                              <button
                                 className="btn btn-danger me-2"
                                 onClick={() => {
                                    setconfirm_alert(true)
                                 }}
                              >
                                 Delete Ledger
                              </button>
                              :
                              <button className="btn btn-danger me-2" disabled>
                                 Delete Ledger
                              </button>
                           }
                        </>
                        :
                        ""
                     }
                     <button
                        className="btn btn-success"
                        type="button"
                        onClick={() => {
                           setmodal_standard(true)
                        }}
                     >
                        Add New
                     </button>
                  </h6>
               </Col>
            </Row>
            <Row>
               <Col>
                  {searchedCount !== "" ? (
                     <h6 className="">
                        Searched Data
                        <span> ({searchedCount})</span>
                     </h6>
                  ) : (
                     ""
                  )}
               </Col>
            </Row>
         </div>
         <div className="card">
            <div className="card-body">
               <div className="lead_table table table-sm table-bordered table-responsive">
                  <table style={{ width: "100%" }}>
                     <tbody>
                        <tr className="static-header">
                           <th>
                              <input
                                 onClick={e => selectAllCheck(e.target)}
                                 className="p-0 d-inline-block"
                                 type="checkbox"
                              />
                           </th>
                           <th>#</th>
                           <th>
                              <div
                                 className="position-relative"
                                 style={{ whiteSpace: "nowrap" }}
                              >
                                 ID
                                 {id_filter ? (
                                    <>
                                       <span
                                          style={{ cursor: "pointer", color: "orange" }}
                                          onClick={() => setid_filter(false)}
                                       >
                                          <i className="fa fa-times fa-lg ms-2"></i>
                                       </span>
                                    </>
                                 ) : (
                                    <>
                                       {localStorage.getItem("leadID") == "" ? (
                                          <span
                                             className=""
                                             style={{ cursor: "pointer" }}
                                             onClick={() => setid_filter(true)}
                                          >
                                             <i className="fa fa-filter ms-2"></i>
                                          </span>
                                       ) : (
                                          <span
                                             className=""
                                             style={{ cursor: "pointer", color: "orange" }}
                                             onClick={() => setid_filter(true)}
                                          >
                                             <i className="fa fa-filter ms-2"></i>
                                          </span>
                                       )}
                                    </>
                                 )}
                                 {sort5 ? (
                                    <>
                                       <span
                                          className="fs-5"
                                          style={{ cursor: "pointer", color: "orange" }}
                                          onClick={() => {
                                             setSort6(true),
                                                setSort(false),
                                                setSort3(false),
                                                setSort4(false),
                                                setSort2(false),
                                                setSort5(false),
                                                getSearchedLeads("id,asc", "sort")
                                          }}
                                       >
                                          <i className="fa fa-sort-up ms-2"></i>
                                       </span>
                                       <span
                                          className="fs-5"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                             setSort6(true),
                                                setSort(false),
                                                setSort3(false),
                                                setSort4(false),
                                                setSort2(false),
                                                setSort5(false),
                                                getSearchedLeads("id,asc", "sort")
                                          }}
                                       >
                                          <i
                                             className="fa fa-sort-down"
                                             style={{
                                                position: "absolute",
                                                left: "0",
                                                bottom: "0",
                                                transform: "translate(395%, -3px)",
                                             }}
                                          ></i>
                                       </span>
                                    </>
                                 ) : (
                                    <>
                                       <span
                                          className="fs-5"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                             setSort5(true),
                                                setSort2(false),
                                                setSort3(false),
                                                setSort4(false),
                                                setSort(false),
                                                setSort6(false),
                                                getSearchedLeads("id,asc", "sort")
                                          }}
                                       >
                                          <i className="fa fa-sort-up ms-2"></i>
                                       </span>
                                       <span
                                          className="fs-5"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                             setSort6(true),
                                                setSort(false),
                                                setSort3(false),
                                                setSort4(false),
                                                setSort2(false),
                                                setSort5(false),
                                                getSearchedLeads("id,desc", "sort")
                                          }}
                                       >
                                          <i
                                             className="fa fa-sort-down"
                                             style={{
                                                position: "absolute",
                                                left: "0",
                                                bottom: "0",
                                                transform: "translate(395%, -3px)",
                                             }}
                                          ></i>
                                       </span>
                                    </>
                                 )}
                                 {sort6 ? (
                                    <>
                                       <span
                                          className="fs-5"
                                          style={{ cursor: "pointer", color: "orange" }}
                                          onClick={() => {
                                             setSort2(false),
                                                setSort5(true),
                                                setSort3(false),
                                                setSort4(false),
                                                setSort(false),
                                                setSort6(false),
                                                getSearchedLeads("id,desc", "sort")
                                          }}
                                       >
                                          <i
                                             className="fa fa-sort-down"
                                             style={{
                                                position: "absolute",
                                                left: "0",
                                                bottom: "0",
                                                transform: "translate(395%, -3px)",
                                             }}
                                          ></i>
                                       </span>
                                    </>
                                 ) : (
                                    ""
                                 )}
                                 <div className="first-filter">
                                    <InputFilter
                                       id_filter={id_filter}
                                       setid_filter={setid_filter}
                                       getSearchedLeads={getSearchedLeads}
                                    />
                                 </div>
                              </div>
                           </th>
                           <th>
                              <div
                                 className="position-relative"
                                 style={{ whiteSpace: "nowrap" }}
                              >
                                 Customer
                                 {name_filter ? (
                                    <>
                                       <span
                                          style={{ cursor: "pointer", color: "orange" }}
                                          onClick={() => setname_filter(false)}
                                       >
                                          <i className="fa fa-times fa-lg ms-2"></i>
                                       </span>
                                    </>
                                 ) : (
                                    <>
                                       {localStorage.getItem("leadName") == "" ? (
                                          <span
                                             className=""
                                             style={{ cursor: "pointer" }}
                                             onClick={() => setname_filter(true)}
                                          >
                                             <i className="fa fa-filter ms-2"></i>
                                          </span>
                                       ) : (
                                          <span
                                             className=""
                                             style={{ cursor: "pointer", color: "orange" }}
                                             onClick={() => setname_filter(true)}
                                          >
                                             <i className="fa fa-filter ms-2"></i>
                                          </span>
                                       )}
                                    </>
                                 )}
                                 <InputFilter
                                    name_filter={name_filter}
                                    setname_filter={setname_filter}
                                    getSearchedLeads={getSearchedLeads}
                                 />
                              </div>
                           </th>
                           <th>Order Id</th>
                           <th>
                              <div
                                 className="position-relative"
                                 style={{ whiteSpace: "nowrap" }}
                              >
                                 Amount
                                 {amount_filter ? (
                                    <>
                                       <span
                                          style={{ cursor: "pointer", color: "orange" }}
                                          onClick={() => setamount_filter(false)}
                                       >
                                          <i className="fa fa-times fa-lg ms-2"></i>
                                       </span>
                                    </>
                                 ) : (
                                    <>
                                       {localStorage.getItem("leadAmount") == "" ? (
                                          <span
                                             className=""
                                             style={{ cursor: "pointer" }}
                                             onClick={() => setamount_filter(true)}
                                          >
                                             <i className="fa fa-filter ms-2"></i>
                                          </span>
                                       ) : (
                                          <span
                                             className=""
                                             style={{ cursor: "pointer", color: "orange" }}
                                             onClick={() => setamount_filter(true)}
                                          >
                                             <i className="fa fa-filter ms-2"></i>
                                          </span>
                                       )}
                                    </>
                                 )}
                                 <InputFilter
                                    amount_filter={amount_filter}
                                    setamount_filter={setamount_filter}
                                    getSearchedLeads={getSearchedLeads}
                                 />
                              </div>
                           </th>
                           <th>
                              <div
                                 className="position-relative"
                                 style={{ whiteSpace: "nowrap" }}
                              >
                                 Message
                                 {message_filter ? (
                                    <>
                                       <span
                                          style={{ cursor: "pointer", color: "orange" }}
                                          onClick={() => setmessage_filter(false)}
                                       >
                                          <i className="fa fa-times fa-lg ms-2"></i>
                                       </span>
                                    </>
                                 ) : (
                                    <>
                                       {localStorage.getItem("leadMessage") == "" ? (
                                          <span
                                             className=""
                                             style={{ cursor: "pointer" }}
                                             onClick={() => setmessage_filter(true)}
                                          >
                                             <i className="fa fa-filter ms-2"></i>
                                          </span>
                                       ) : (
                                          <span
                                             className=""
                                             style={{ cursor: "pointer", color: "orange" }}
                                             onClick={() => setmessage_filter(true)}
                                          >
                                             <i className="fa fa-filter ms-2"></i>
                                          </span>
                                       )}
                                    </>
                                 )}
                                 <InputFilter
                                    message_filter={message_filter}
                                    setmessage_filter={setmessage_filter}
                                    getSearchedLeads={getSearchedLeads}
                                 />
                              </div>
                           </th>
                           <th>
                              <div
                                 className="position-relative"
                                 style={{ whiteSpace: "nowrap" }}
                              >
                                 Type
                                 {status4_filter ? (
                                    <>
                                       <span
                                          className=""
                                          style={{ cursor: "pointer", color: "orange" }}
                                          onClick={() => setstatus4_filter(false)}
                                       >
                                          <i className="fa fa-times fa-lg ms-2"></i>
                                       </span>
                                    </>
                                 ) : (
                                    <>
                                       {localStorage.getItem("ledgerType") == "" ? (
                                          <span
                                             style={{ cursor: "pointer" }}
                                             onClick={() => setstatus4_filter(true)}
                                          >
                                             <i className="fa fa-filter ms-2"></i>
                                          </span>
                                       ) : (
                                          <span
                                             style={{ cursor: "pointer", color: "orange" }}
                                             onClick={() => setstatus4_filter(true)}
                                          >
                                             <i className="fa fa-filter ms-2"></i>
                                          </span>
                                       )}
                                    </>
                                 )}
                                 <MultipleChoice
                                    status4_filter={status4_filter}
                                    setstatus4_filter={setstatus4_filter}
                                    getSearchedLeads={getSearchedLeads}
                                 />
                              </div>
                           </th>
                           <th>
                              <div
                                 className="position-relative"
                                 style={{ whiteSpace: "nowrap" }}
                              >
                                 Name
                                 {leadholder_filter ? (
                                    <>
                                       <span
                                          style={{ cursor: "pointer", color: "orange" }}
                                          onClick={() => setleadholder_filter(false)}
                                       >
                                          <i className="fa fa-times fa-lg ms-2"></i>
                                       </span>
                                    </>
                                 ) : (
                                    <>
                                       {localStorage.getItem("leadHolder") == "" ? (
                                          <span
                                             className=""
                                             style={{ cursor: "pointer" }}
                                             onClick={() => setleadholder_filter(true)}
                                          >
                                             <i className="fa fa-filter ms-2"></i>
                                          </span>
                                       ) : (
                                          <span
                                             className=""
                                             style={{ cursor: "pointer", color: "orange" }}
                                             onClick={() => setleadholder_filter(true)}
                                          >
                                             <i className="fa fa-filter ms-2"></i>
                                          </span>
                                       )}
                                    </>
                                 )}
                                 <MultipleChoice
                                    leadholder_filter={leadholder_filter}
                                    setleadholder_filter={setleadholder_filter}
                                    data={allUsers}
                                    getSearchedLeads={getSearchedLeads}
                                 />
                              </div>
                           </th>
                           <th>
                              <div
                                 className="position-relative"
                                 style={{ whiteSpace: "nowrap" }}
                              >
                                 Transaction date
                                 {date_filter ? (
                                    <>
                                       <span
                                          style={{ cursor: "pointer", color: "orange" }}
                                          onClick={() => setdate_filter(false)}
                                       >
                                          <i className="fa fa-times fa-lg ms-2"></i>
                                       </span>
                                    </>
                                 ) : (
                                    <>
                                       {getFilterDate() && getFilterDate().from == "" ? (
                                          <span
                                             className=""
                                             style={{ cursor: "pointer" }}
                                             onClick={() => setdate_filter(true)}
                                          >
                                             <i className="fa fa-filter ms-2"></i>
                                          </span>
                                       ) : (
                                          <span
                                             className=""
                                             style={{ cursor: "pointer", color: "orange" }}
                                             onClick={() => setdate_filter(true)}
                                          >
                                             <i className="fa fa-filter ms-2"></i>
                                          </span>
                                       )}
                                    </>
                                 )}
                                 {sort3 ? (
                                    <>
                                       <span
                                          className="fs-5"
                                          style={{ cursor: "pointer", color: "orange" }}
                                          onClick={() => {
                                             setSort4(true),
                                                setSort3(false),
                                                setSort(false),
                                                setSort2(false),
                                                setSort5(false),
                                                setSort6(false),
                                                getSearchedLeads("transaction_date,asc", "sort")
                                          }}
                                       >
                                          <i className="fa fa-sort-up ms-2"></i>
                                       </span>
                                       <span
                                          className="fs-5"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                             setSort4(true),
                                                setSort3(false),
                                                setSort(false),
                                                setSort2(false),
                                                setSort5(false),
                                                setSort6(false),
                                                getSearchedLeads("transaction_date,asc", "sort")
                                          }}
                                       >
                                          <i
                                             className="fa fa-sort-down"
                                             style={{
                                                position: "absolute",
                                                left: "0",
                                                bottom: "0",
                                                transform: "translate(1236%, -3px)",
                                             }}
                                          ></i>
                                       </span>
                                    </>
                                 ) : (
                                    <>
                                       <span
                                          className="fs-5"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                             setSort3(true),
                                                setSort4(false),
                                                setSort(false),
                                                setSort2(false),
                                                setSort5(false),
                                                setSort6(false),
                                                getSearchedLeads("transaction_date,asc", "sort")
                                          }}
                                       >
                                          <i className="fa fa-sort-up ms-2"></i>
                                       </span>
                                       <span
                                          className="fs-5"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                             setSort4(true),
                                                setSort3(false),
                                                setSort(false),
                                                setSort2(false),
                                                setSort5(false),
                                                setSort6(false),
                                                getSearchedLeads(
                                                   "transaction_date,desc",
                                                   "sort"
                                                )
                                          }}
                                       >
                                          <i
                                             className="fa fa-sort-down"
                                             style={{
                                                position: "absolute",
                                                left: "0",
                                                bottom: "0",
                                                transform: "translate(1236%, -3px)",
                                             }}
                                          ></i>
                                       </span>
                                    </>
                                 )}
                                 {sort4 ? (
                                    <>
                                       <span
                                          className="fs-5"
                                          style={{ cursor: "pointer", color: "orange" }}
                                          onClick={() => {
                                             setSort4(false),
                                                setSort3(true),
                                                setSort(false),
                                                setSort2(false),
                                                setSort5(false),
                                                setSort6(false),
                                                getSearchedLeads(
                                                   "transaction_date,desc",
                                                   "sort"
                                                )
                                          }}
                                       >
                                          <i
                                             className="fa fa-sort-down"
                                             style={{
                                                position: "absolute",
                                                left: "0",
                                                bottom: "0",
                                                transform: "translate(1236%, -3px)",
                                             }}
                                          ></i>
                                       </span>
                                    </>
                                 ) : (
                                    ""
                                 )}
                                 <DateFilter
                                    date_filter={date_filter}
                                    setdate_filter={setdate_filter}
                                    getSearchedLeads={getSearchedLeads}
                                 />
                              </div>
                           </th>
                           <th>Action</th>
                        </tr>
                        {ledger &&
                           ledger.map((item, obj) => (
                              <LedgerList
                                 data={item}
                                 i={obj}
                                 key={obj}
                                 setBlur={setBlur}
                                 setLeadsSelectedIds={setLeadsSelectedIds}
                                 leadsSelectedIds={leadsSelectedIds}
                                 counter={counter}
                              />
                           ))}
                     </tbody>
                  </table>
                  {error ? (
                     <span className="text-danger mt-3 d-table m-auto" role="alert">
                        {error}
                     </span>
                  ) : (
                     ""
                  )}
               </div>
            </div>
         </div>
         {loader}
         {allPages !== [] ? (
            <Paginations
               data={allPages}
               paginate={paginate}
               perPage={getAllLedgers}
            />
         ) : (
            ""
         )}
      </>
   )
}
export default LedgerTable
