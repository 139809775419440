import React, { useState, useEffect } from "react"
import { Row, Col } from "reactstrap"
import { getCurrentUser, getPermission } from "../../../helpers/Utils"
import axios from "axios"
import Paginations from "../../Pagination"
import Loader from "../../Loader/loader.js"
import WorkFlowList from "./workflowList"
import WorkFlowModal from "./workflowModal"
import SweetAlert from "react-bootstrap-sweetalert"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useHistory } from "react-router-dom"

const WorkflowTable = () => {
   const history = useHistory()
   const [modal_workflow, setmodal_workflow] = useState(false)
   const [allFlows, setAllFlows] = useState([])
   const [error, setError] = useState("")
   const [loader, setLoader] = useState("")
   const [allPages, setAllPages] = useState([])
   const [counter, setCounter] = useState("")
   const [blur, setBlur] = useState(false)
   const [messageAlert, setMessageAlert] = useState(false)
   const [leadsSelectedIds, setLeadsSelectedIds] = useState([])
   const [success_dlg, setsuccess_dlg] = useState(false)
   const [dynamic_title, setdynamic_title] = useState("")
   const [dynamic_description, setdynamic_description] = useState("")
   const [confirm_alert, setconfirm_alert] = useState(false)

   const getAllFlows = () => {
      setLoader(<Loader />)
      setAllFlows([])
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/workflow?per_page=${localStorage.getItem("PerPage") !== null
               ? localStorage.getItem("PerPage")
               : ""
            }`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setLoader("")
               setError(res.data.message)
               setCounter(res.data.data.from)
               setAllFlows(res.data.data.data)
               setAllPages(res.data.data)
            } else {
               setLoader("")
               setError(res.data.message)
            }
         })
   }

   const paginate = e => {
      setAllFlows([])
      setAllPages([])
      setCounter("")
      setLoader(<Loader />)
      axios
         .get(`${e}`, {
            headers: {
               "content-type": "application/json",
               Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
            },
         })
         .then(function (res) {
            if (res.data.status) {
               setCounter(res.data.data.from)
               setAllPages(res.data.data)
               setAllFlows(res.data.data.data)
               setLoader("")
            } else {
               setError(res.data.message)
               setLoader("")
            }
         })
   }

   const selectAllCheck = check => {
      if (check.checked) {
         var ele = document.getElementsByName("chk")
         for (var i = 0; i < ele.length; i++) {
            if (ele[i].type == "checkbox") {
               ele[i].checked = true
               leadsSelectedIds.push(parseInt(ele[i].value))
            }
         }
      } else {
         var ele = document.getElementsByName("chk")
         for (var i = 0; i < ele.length; i++) {
            if (ele[i].type == "checkbox") {
               ele[i].checked = false
               var array = leadsSelectedIds
               var index = array.indexOf(parseInt(ele[i].value))
               if (index !== -1) {
                  array.splice(index, 1)
               }
            }
         }
      }
      if (leadsSelectedIds.length === 0) {
         setBlur(false)
      } else {
         setBlur(true)
      }
   }

   const deleteMultiple = () => {
      axios
         .post(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/multiple-delete-workflow`,
            {
               ids: leadsSelectedIds,
            },
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setLeadsSelectedIds([])
               getAllFlows()
               setdynamic_description(res.data.message)
               toast.success(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            } else {
               toast.error(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            }
         })
   }

   useEffect(() => {
      if (getCurrentUser()?.role_name === "Administrator") {
         getAllFlows()
      } else {
         setMessageAlert(true)
      }
   }, [])

   return (
      <>
         {modal_workflow ?
            <WorkFlowModal
               modal_workflow={modal_workflow}
               setmodal_workflow={setmodal_workflow}
               getAllFlows={getAllFlows}
            />
            : ""
         }
         {messageAlert ? (
            <SweetAlert
               confirmButtonText="Ok"
               onConfirm={() => history.push("/")}
            >
               <h4>Only Admin can access this page.</h4>
            </SweetAlert>
         ) : null}
         {confirm_alert ? (
            <SweetAlert
               title="Are you sure?"
               warning
               showCancel
               confirmButtonText="Yes, delete it!"
               confirmBtnBsStyle="success"
               cancelBtnBsStyle="danger"
               onConfirm={() => {
                  setconfirm_alert(false)
                  setsuccess_dlg(true)
                  deleteMultiple()
                  setdynamic_title("Deleted")
                  setdynamic_description("Your file has been deleted.")
               }}
               onCancel={() => setconfirm_alert(false)}
            >
               <span className="text-danger">You won't be able to revert this!</span>
            </SweetAlert>
         ) : null}
         <div className="page-title-box">
            <Row className="align-items-center">
               <Col md={6}>
                  <h6 className="page-title">Workflows</h6>
               </Col>
               <Col md={6}>
                  <h6 className="page-title float-end">
                     {blur ? (
                        <div className="btn-group me-2">
                           <button type="button" className="btn btn-primary">
                              Action
                           </button>
                           <button
                              type="button"
                              className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                           >
                              <i className="fa fa-caret-down"></i>
                           </button>
                           <ul className="dropdown-menu">
                              <li>
                                 <span
                                    className="dropdown-item"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                       setconfirm_alert(true)
                                    }}
                                 >
                                    Batch delete
                                 </span>
                              </li>
                           </ul>
                        </div>
                     ) : (
                        <div className="btn-group me-2">
                           <button type="button" className="btn btn-primary" disabled>
                              Action
                           </button>
                           <button
                              type="button"
                              className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                              disabled
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                           >
                              <i className="fa fa-caret-down"></i>
                           </button>
                           <ul className="dropdown-menu">
                              <li>
                                 <span
                                    className="dropdown-item"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                       setconfirm_alert(true)
                                    }}
                                 >
                                    Batch delete
                                 </span>
                              </li>
                           </ul>
                        </div>
                     )}
                     <button
                        className="btn btn-success"
                        type="button"
                        onClick={() => {
                           setmodal_workflow(true)
                        }}
                     >
                        Add New
                     </button>
                  </h6>
               </Col>
            </Row>
         </div>
         <div className="card">
            <div className="card-body">
               <div className="lead_table table table-sm table-bordered table-responsive">
                  <table style={{ width: "100%" }}>
                     <tbody>
                        <tr className="static-header">
                           <th>
                              <input
                                 onClick={e => selectAllCheck(e.target)}
                                 className="p-0 d-inline-block"
                                 type="checkbox"
                              />
                           </th>
                           <th>#</th>
                           <th>Title</th>
                           <th>Event</th>
                           <th>Created at</th>
                           <th>Action</th>
                        </tr>
                        {allFlows &&
                           allFlows.map((item, obj) => (
                              <WorkFlowList
                                 key={obj}
                                 data={item}
                                 i={obj}
                                 setBlur={setBlur}
                                 setLeadsSelectedIds={setLeadsSelectedIds}
                                 leadsSelectedIds={leadsSelectedIds}
                                 counter={counter}
                                 getAllFlows={getAllFlows}
                              />
                           ))}
                     </tbody>
                  </table>
                  {error ? (
                     <span className="text-danger mt-3 d-table m-auto" role="alert">
                        {error}
                     </span>
                  ) : (
                     ""
                  )}
               </div>
            </div>
         </div>
         {loader}
         {allPages ? (
            <Paginations data={allPages} paginate={paginate} perPage={getAllFlows} />
         ) : (
            ""
         )}
      </>
   )
}
export default WorkflowTable
