import React, { useState, useEffect } from "react"
import { Container, Row, Col, Modal, FormGroup, Input } from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert"
import { getCurrentUser } from "../../../helpers/Utils"
import { Link } from "react-router-dom"
import axios from "axios"
import CledgerModal from "./CledgerModal"
import LongText from "../../MessageField"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"


const CledgerList = props => {
	const [ledger, setLedger] = useState(props.data)
	const [modal_standard, setmodal_standard] = useState(false)


	if (ledger === "") {
		return ""
	} else
		return (
			<>
				
				<tr key={props.i}>
					<td>{props.counter + props.i}</td>
					<td>{ledger.id}</td>
					<td>{ledger.order_id}</td>
					<td>{ledger.amount}</td>
					<td>{ledger.transaction_date}</td>
					<td>
               <LongText content={ledger.message} />
               </td>
					<td>{ledger.type == "paid" ? <span className="badge bg-success">Credit</span> : ""}
                  {ledger.type == "outstanding" ? <span className="badge bg-danger">Debit</span> : ""}</td>
					<td>{ledger.user_name}</td>
				</tr>
			</>
		)
}
export default CledgerList
