import React, { useState, useEffect } from "react"
import axios from "axios"
import { Container, Row, Col, Modal, FormGroup, Input, Alert } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import DatePicker from "react-datepicker"
import { getCurrentUser } from "../../../helpers/Utils"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

export default function ReminderModal(props) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [reminder, setReminder] = useState("")
  const [currentDate, setCurrentDate] = useState(new Date())
  const [users, setUsers] = useState("")
  const [leads, setLeads] = useState("")
  const [allReminder, setAllReminder] = useState([])

  const getAllUsers = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/user?per_page=5000`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token }`,
          },
        }
      )
      .then(function (res) {
        setUsers(res.data.data.data)
      })
      .catch(err => {
        console.log("err", err)
      })
  }

  const getAllLeads = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/customers?per_page=5000`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token }`,
          },
        }
      )
      .then(function (res) {
        setLeads(res.data.data.data)
      })
  }

  const submitReminder = (e, value) => {
    setLoading(true)
    const api = axios.create();
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      api.interceptors.request.use(config => {
         config.cancelToken = source.token;
         return config;
      });

      const timeout = 120000; // 2 minute

      setTimeout(() => {
         source.cancel('Timeout');
      }, timeout);
    if (props.reminder !== undefined) {
      axios
        .put(
          `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
            "company_name"
          )}/reminder/${props.reminder.id}`,
          {
            user_id: e.target.user_id.value,
            lead_id: e.target.lead_id.value,
            message: e.target.message.value,
            date: e.target.date.value,
          },
          {
            headers: {
              Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token }`,
            },
          }
        )
        .then(function (res) {
          if (res.data.status) {
            setLoading(false)
            props.setIsOpen(false)
            props.setmodal_standard(false)
            props.setReminder(res.data.data)
            toast.success(res.data.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
          } else {
            setLoading(false)
            toast.error(res.data.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
          }
        })
    } else {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
            "company_name"
          )}/reminder`,
          {
            user_id: e.target.user_id.value,
            lead_id: e.target.lead_id.value,
            message: e.target.message.value,
            date: e.target.date.value,
          },
          {
            headers: {
              Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token }`,
            },
          }
        )
        .then(function (res) {
          if (res.data.status) {
            setLoading(false)
            props.getAllReminder()
            props.setmodal_standard(false)
            toast.success(res.data.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
          } else {
            setLoading(false)
            toast.error(res.data.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
          }
        })
    }
  }

  useEffect(() => {
    const close = e => {
      if (e.key === "Escape") {
        props.setmodal_standard(false)
      }
    }
    window.addEventListener("keydown", close)
    return () => window.removeEventListener("keydown", close)
  }, [])

  useEffect(() => {
    getAllUsers()
    getAllLeads()
  }, [])

  return (
    <>
      <Modal isOpen={props.modal_standard} centered={true}>
        <AvForm
          className="mt-0"
          id="myModalLabel"
          onSubmit={(e, v) => submitReminder(e, v)}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myLargeModalLabel">
              {props.reminder !== undefined ? "Edit User" : "Create User"}
            </h5>
            <button
              className="cross__btn"
              onClick={() => props.setmodal_standard(false)}
              aria-hidden="true"
              type="button"
            >
              &times;
            </button>
          </div>
          <div className="modal-body">
            <div className="mb-3">
              <AvField
                className="select-arrow"
                type="select"
                name="user_id"
                label="User"
                value={props.reminder && props.reminder.user_id}
              >
                <option value="" hidden>
                  Select User
                </option>
                {users &&
                  users.map((item, i) => (
                    <option value={item.id} key={i}>
                      {item.name}
                    </option>
                  ))}
              </AvField>
            </div>
            <div className="mb-3">
              <AvField
                className="select-arrow"
                type="select"
                name="lead_id"
                label="Leads"
                value={props.reminder && props.reminder.lead_id}
              >
                <option value="" hidden>
                  Select Lead
                </option>
                {leads &&
                  leads.map((item, i) => (
                    <option value={item.id} key={i}>
                      {item.name}
                    </option>
                  ))}
              </AvField>
            </div>
            <div className="mb-3">
              <AvField
                label="Message"
                type="textarea"
                name="message"
                placeholder="Enter message"
                value={props.reminder && props.reminder.message}
              />
            </div>
            <div className="mb-3">
              <label className="form-group">Date</label>
              <DatePicker
                className="w-100 form-control"
                dateFormat="yyyy-MM-dd HH:mm"
                name="date"
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                selected={currentDate}
                onChange={date => setCurrentDate(date)}
                value={props.reminder && props.reminder.date}
              />
            </div>
          </div>
          <div className="modal-footer">
            <div className="text-end">
              {loading ? (
                <button
                  className="btn btn-primary disabled"
                  type="button"
                  disabled
                >
                  Loading
                </button>
              ) : (
                <button
                  className="btn btn-primary"
                  type="submit"
                  // onClick={() => props.setmodal_standard(false)}
                >
                  {props.reminder !== undefined ? "Submit" : "Create"}
                </button>
              )}
            </div>
          </div>
        </AvForm>
      </Modal>
    </>
  )
}
