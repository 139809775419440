import React, { useState, useEffect } from "react"
import { Container, Row, Col, Modal, FormGroup, Input } from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert"
import { getCurrentUser } from "../../../helpers/Utils"
import { Link } from "react-router-dom"
import axios from "axios"
import Paginations from "../../Pagination"
import Loader from "../../Loader/loader.js"
import RoleList from "./RoleList"
import RoleModal from "./RoleModal"

const RoleStaff = () => {
  const [modal_xlarge, setmodal_xlarge] = useState(false)
  const [allRoles, setAllRoles] = useState([])
  const [error, setError] = useState("")
  const [loader, setLoader] = useState(<Loader />)
  const [allPages, setAllPages] = useState([])
  const [counter, setCounter] = useState("")

  const getAllRoles = () => {
    setLoader(<Loader />)
    setAllRoles([])
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/role?per_page=${
          localStorage.getItem("PerPage") !== null
            ? localStorage.getItem("PerPage")
            : ""
        }`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setLoader("")
          setError(res.data.message)
          setCounter(res.data.data.from)
          setAllRoles(res.data.data.data)
          setAllPages(res.data.data)
        } else {
          setLoader("")
          setError(res.data.message)
        }
      })
  }

  const paginate = e => {
    setAllRoles([])
    setCounter("")
    setAllPages([])
    setLoader(<Loader />)
    axios
      .get(`${e}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${
            getCurrentUser().token == null ? "" : getCurrentUser().token
          }`,
        },
      })
      .then(function (res) {
        if (res.data.status) {
          setLoader("")
          setCounter(res.data.data.from)
          setAllPages(res.data.data)
          setAllRoles(res.data.data.data)
        } else {
          setError(res.data.message)
          setLoader("")
        }
      })
  }

  useEffect(() => {
    getAllRoles()
    // getAllPermission()
  }, [])

  return (
    <>
      <RoleModal
        modal_xlarge={modal_xlarge}
        setmodal_xlarge={setmodal_xlarge}
        getAllRoles={getAllRoles}
        // getAllPermission={getAllPermission}
      />
      <div className="page-title-box">
        <Row className="align-items-center">
          <Col md={6}>
            <h6 className="page-title">Permissions</h6>
          </Col>
          <Col md={6}>
            <h6 className="page-title float-end">
              {/* <button
									className="btn btn-primary submit__button"
									type="button"
									onClick={() => {
										setmodal_xlarge(true)
									}}
								>
									Add New
								</button> */}
            </h6>
          </Col>
        </Row>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="lead_table table table-sm table-bordered table-responsive">
            <table style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Total User</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {allRoles &&
                  allRoles.map((item, obj) => (
                    <RoleList key={obj} data={item} i={obj} counter={counter} />
                  ))}
              </tbody>
            </table>
            {error ? (
              <span className="text-danger mt-3 d-table m-auto" role="alert">
                {error}
              </span>
            ) : null}
          </div>
        </div>
      </div>
      {loader}
      {allPages !== [] ? (
        <Paginations
          data={allPages}
          paginate={paginate}
          perPage={getAllRoles}
        />
      ) : (
        ""
      )}
    </>
  )
}
export default RoleStaff
