import React, { useState, useEffect } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import { getCurrentUser } from "../../../helpers/Utils"
import { Link } from "react-router-dom"
import axios from "axios"
import WorkFlowModal from "./workflowModal"
import LongText from "../../MessageField"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

let selectedRoleId = []

const WorkFlowList = props => {
   const [flow, setFlow] = useState(props.data)
   const [confirm_alert, setconfirm_alert] = useState(false)
   const [success_dlg, setsuccess_dlg] = useState(false)
   const [dynamic_title, setdynamic_title] = useState("")
   const [dynamic_description, setdynamic_description] = useState("")
   const [isOpen, setIsOpen] = useState(false)
   const [modal_workflow, setmodal_workflow] = useState(false)

   const deleteWorkFlow = e => {
      axios
         .delete(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/workflow/${e} `,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setFlow("")
               toast.success(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            }
         })
   }

   const editFlow = () => {
      setIsOpen(true)
      setmodal_workflow(true)
   }

   useEffect(() => {
      selectedRoleId = []
   }, [])

   const getSelectedId = (e, check) => {
      if (check.target.checked) {
         selectedRoleId.push(e)
         const uniqueID = selectedRoleId.filter((val, id, array) => {
            return array.indexOf(val) == id
         })
         selectedRoleId = uniqueID
         props.setLeadsSelectedIds(selectedRoleId)
      } else {
         selectedRoleId = props.leadsSelectedIds
         var array = selectedRoleId
         var index = array.indexOf(e)
         if (index !== -1) {
            array.splice(index, 1)
         }
         props.setLeadsSelectedIds(selectedRoleId)
      }
      if (selectedRoleId.length == 0) {
         props.setBlur(false)
      } else {
         props.setBlur(true)
      }
   }

   if (flow === "") {
      return ""
   } else
      return (
         <>
            {isOpen ? (
               <WorkFlowModal
                  modal_workflow={modal_workflow}
                  setmodal_workflow={setmodal_workflow}
                  flow={flow}
                  setFlow={setFlow}
                  setIsOpen={setIsOpen}
               />
            ) : null}
            <tr key={props.i}>
               <td>
                  <input
                     onChange={e => getSelectedId(flow.id, e)}
                     name="chk"
                     value={flow.id}
                     className="p-0 d-inline-block"
                     type="checkbox"
                  />
               </td>
               <td>{props.counter + props.i}</td>
               <td>{flow.title}</td>
               <td>
                  {flow.event === "lead_create" ?
                     "Lead Create"
                     : flow.event === "lead_fetched" ?
                        "Lead Fetched"
                        :
                        flow.event === "lead_matured" ?
                           "Lead Matured"
                           :
                           flow.event === "followup_reminder" ?
                              "Followup Reminder"
                              :
                              flow.event === "new_proforma" ?
                                 "New Proforma"
                                 :
                                 flow.event === "new_order" ?
                                    "New Order"
                                    :
                                    flow.event === "brand_missed" ?
                                       "Brand missed"
                                       :
                                       flow.event === "followup_stages" ?
                                          "Followup Stages"
                                          : flow.event === "mature_stages" ?
                                             "Mature Stages"
                                             : flow.event === "repeat_order" ?
                                                "Repeat Order"
                                                :
                                                flow.event === "update_order" ?
                                                   "Update Order"
                                                   :
                                                   flow.event === "no_updation_since" ?
                                                      "No Updation since"
                                                      :
                                                      flow.event === "no_order_since" ?
                                                         "No Order since"
                                                         :
                                                         flow.event === "update_proforma" ?
                                                            "Update Proforma"
                                                            :
                                                            flow.event === "birthday" ?
                                                               "Birthday"
                                                               :
                                                               flow.event === "anniversary" ?
                                                                  "Anniversary"
                                                                  :
                                                                  flow.event === "payment_update" ?
                                                                     "Payment Update"
                                                                     :
                                                                     flow.event === "payment_request" ?
                                                                        "Payment Request"
                                                                        :
                                                                        flow.event === "balance_payment" ?
                                                                           "Balance Payment"
                                                                           : flow.event === "product/service_renewal" ?
                                                                              "Product/Service Renewal"
                                                                              :
                                                                              flow.event === "dispatch_details" ?
                                                                                 "Dispatch Details"
                                                                                 :
                                                                                 ""
                  }
               </td>
               <td>
                  {flow.created_at}
               </td>
               <td>
                  <a
                     className="text-warning"
                     onClick={() => {
                        editFlow(flow.id)
                     }}
                  >
                     <i className="fas fa-pen" />
                  </a>
                  <a
                     className="text-danger ms-2"
                     onClick={() => {
                        setconfirm_alert(true)
                     }}
                  >
                     <i className="fas fa-trash-alt" />
                  </a>
                  {confirm_alert ? (
                     <SweetAlert
                        title="Are you sure?"
                        warning
                        showCancel
                        confirmButtonText="Yes, delete it!"
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={() => {
                           setconfirm_alert(false)
                           setsuccess_dlg(true)
                           deleteWorkFlow(flow.id)
                           setdynamic_title("Deleted")
                           setdynamic_description("Your file has been deleted.")
                        }}
                        onCancel={() => setconfirm_alert(false)}
                     >
                        You won't be able to revert this!
                     </SweetAlert>
                  ) : null}
               </td>
            </tr>
         </>
      )
}
export default WorkFlowList
