export const getCurrentUser = () => {
  let user = null
  if (typeof window !== "undefined") {
    try {
      user =
        localStorage.getItem("current_user") != null
          ? JSON.parse(localStorage.getItem("current_user"))
          : null
    } catch (error) {
      console.log(
        ">>>>: src/helpers/Utils.js  : getCurrentUser -> error",
        error
      )
      user = null
    }
  }
  return user
}

export const setCurrentUser = user => {
  if (typeof window !== "undefined") {
    try {
      if (user) {
        localStorage.setItem("current_user", JSON.stringify(user))
      } else {
        localStorage.removeItem("current_user")
      }
    } catch (error) {
      console.log(">>>>: src/helpers/Utils.js : setCurrentUser -> error", error)
    }
  }
}

export const getPermission = () => {
  let user = null
  if (typeof window !== "undefined") {
    try {
      user =
        localStorage.getItem("current_permissions") != null
          ? JSON.parse(localStorage.getItem("current_permissions"))
          : null
    } catch (error) {
      console.log(">>>>: src/helpers/Utils.js  : getPermission -> error", error)
      user = null
    }
  }
  return user
}

export const setPermission = user => {
  if (typeof window !== "undefined") {
    try {
      if (user) {
        localStorage.setItem("current_permissions", JSON.stringify(user))
      } else {
        localStorage.removeItem("current_permissions")
      }
    } catch (error) {
      console.log(">>>>: src/helpers/Utils.js : setPermission -> error", error)
    }
  }
}

export const getFilterDate = () => {
  let user = null
  if (typeof window !== "undefined") {
    try {
      user =
        localStorage.getItem("filter_dates") != null
          ? JSON.parse(localStorage.getItem("filter_dates"))
          : null
    } catch (error) {
      console.log(">>>>: src/helpers/Utils.js  : getFilterDate -> error", error)
      user = null
    }
  }
  return user
}

export const setFilterDate = user => {
  if (typeof window !== "undefined") {
    try {
      if (user) {
        localStorage.setItem("filter_dates", JSON.stringify(user))
      } else {
        localStorage.removeItem("filter_dates")
      }
    } catch (error) {
      console.log(">>>>: src/helpers/Utils.js : setFilterDate -> error", error)
    }
  }
}

export const getFilterDate2 = () => {
  let user = null
  if (typeof window !== "undefined") {
    try {
      user =
        localStorage.getItem("filter_dates2") != null
          ? JSON.parse(localStorage.getItem("filter_dates2"))
          : null
    } catch (error) {
      console.log(
        ">>>>: src/helpers/Utils.js  : getFilterDate2 -> error",
        error
      )
      user = null
    }
  }
  return user
}

export const setFilterDate2 = user => {
  if (typeof window !== "undefined") {
    try {
      if (user) {
        localStorage.setItem("filter_dates2", JSON.stringify(user))
      } else {
        localStorage.removeItem("filter_dates2")
      }
    } catch (error) {
      console.log(">>>>: src/helpers/Utils.js : setFilterDate2 -> error", error)
    }
  }
}

// Send WhatsApp Message
export const sendWhatsAppMessage = (phone, message) => {
  //  add 91 to phone if phone does not start with 91 and phoen length is 10
  if (phone && phone.length === 10) phone = `91${phone}`

  // if message is undefined
  if (message === undefined)
    return `https://web.whatsapp.com/send?phone=${phone}`

  return `https://web.whatsapp.com/send?phone=${phone}&text=${message}`
}
