import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import axios from "axios"
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"
import { getCurrentUser } from "../../helpers/Utils"
import FutureFollowup from '../../components/Followups/Future';


const FollowupFuture = () => {

	return (
		<React.Fragment>
		<div className="page-content">
			<MetaTags>
				<title>Future Followups | KlikCRM</title>
			</MetaTags>
			<Container fluid>
				<FutureFollowup />
			</Container>
			</div>
		</React.Fragment>
	)
}

export default FollowupFuture;
