import React, { useState, useEffect } from "react"
import axios from "axios"
import { Modal } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import DatePicker from "react-datepicker"
import { getCurrentUser } from "../../../helpers/Utils"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Select from "react-select"
import moment from "moment"


let selectedUserId = []
let dValues = []

export default function SchemeModal(props) {
   const [loading, setLoading] = useState(false)
   const [scheme, setScheme] = useState("")
   const [allUsers, setAllUsers] = useState([])
   const [startDate, setStartDate] = useState(new Date())
   const [endDate, setEndDate] = useState(new Date())

   const getAllUsers = () => {
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/user?per_page=5000`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
               },
            }
         )
         .then(function (res) {
            setAllUsers(res.data.data.data)
         })
         .catch(err => {
            console.log("err", err)
         })
   }

   const options = []

   if (allUsers !== undefined) {
      allUsers.map(item => {
         options.push({
            value: item.id,
            label: item.name,
         })
      })
   }

   const getSelectedId = e => {
      selectedUserId = []
      e?.map(item => selectedUserId.push(item.value))
   }

   const submitScheme = (e, value) => {
      setLoading(true)
      const api = axios.create();
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      api.interceptors.request.use(config => {
         config.cancelToken = source.token;
         return config;
      });

      const timeout = 120000; // 2 minute

      setTimeout(() => {
         source.cancel('Timeout');
      }, timeout);
      if (props.scheme !== undefined) {
         axios
            .put(
               `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
                  "company_name"
               )}/scheme/${props.scheme.id}`,
               {
                  user_id: selectedUserId,
                  message: e.target.message.value,
                  start_date: e.target.start_date.value,
                  end_date: e.target.end_date.value,
               },
               {
                  headers: {
                     Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
                  },
               }
            )
            .then(function (res) {
               if (res.data.status) {
                  setLoading(false)
                  props.setIsOpen(false)
                  props.setmodal_standard(false)
                  props.setScheme(res.data.data)
                  toast.success(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               } else {
                  setLoading(false)
                  toast.error(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               }
            })
      } else {
         axios
            .post(
               `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
                  "company_name"
               )}/scheme`,
               {
                  user_id: selectedUserId,
                  message: e.target.message.value,
                  start_date: e.target.start_date.value,
                  end_date: e.target.end_date.value,
               },
               {
                  headers: {
                     Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
                  },
               }
            )
            .then(function (res) {
               if (res.data.status) {
                  setLoading(false)
                  props.getAllSchemes()
                  props.setmodal_standard(false)
                  toast.success(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               } else {
                  setLoading(false)
                  toast.error(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               }
            })
      }
   }

   useEffect(() => {
      getAllUsers()
      dValues = []
      if (props.scheme !== undefined) {
         setStartDate(moment(props.scheme?.start_date).toDate())
         setEndDate(moment(props.scheme?.end_date).toDate())
         if (props.scheme.user_name !== null) {
            selectedUserId = []
            props.scheme.user_name.map(item => {
               dValues.push({
                  value: item.id,
                  label: item.name,
               })
               selectedUserId.push(item.id)
            })
         } else {
            ; ("")
         }
      }
   }, [])

   useEffect(() => {
      const close = (e) => {
         if (e.key === 'Escape') {
            props.setmodal_standard(false)
         }
      }
      window.addEventListener('keydown', close)
      return () => window.removeEventListener('keydown', close)
   }, [])

   return (
      <>
         <Modal isOpen={props.modal_standard} centered={true}>
            <AvForm
               className="mt-0"
               id="myModalLabel"
               onSubmit={(e, v) => submitScheme(e, v)}
            >
               <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myLargeModalLabel">
                     {props.scheme !== undefined ? "Edit Scheme" : "Create Scheme"}
                  </h5>
                  <button
                     type="button"
                     className="cross__btn"
                     onClick={() => props.setmodal_standard(false)}
                     aria-hidden="true"
                  >
                     &times;
                  </button>
               </div>
               <div className="modal-body">
                  <div className="form-group mb-3">
                     <label>Select Users</label>
                     <Select
                        isMulti
                        isClearable="true"
                        name="user_id"
                        options={options}
                        onChange={e => getSelectedId(e)}
                        defaultValue={dValues}
                     />
                  </div>
                  <div className="mb-3">
                     <AvField
                        label="Message"
                        type="textarea"
                        name="message"
                        placeholder="Enter message"
                        value={props.scheme && props.scheme.message}
                     />
                  </div>
                  <div className="d-flex mt-2 mb-3 required">
                     <div className="mb-3 pe-2">
                        <label className="form-group">Start Date</label>
                        <DatePicker
                           className="w-100 form-control"
                           dateFormat="yyyy-MM-dd"
                           name="start_date"
                           selected={startDate}
                           onChange={(date) => setStartDate(date)}
                        />
                     </div>
                     <div className="mb-3 pe-2">
                        <label type="text" className="form-group">
                           End Date
                        </label>
                        <DatePicker
                           className="w-100 form-control"
                           dateFormat="yyyy-MM-dd"
                           name="end_date"
                           selected={endDate}
                           onChange={(date) => setEndDate(date)}
                        />
                     </div>
                  </div>
                  <div className="modal-footer">
                     <div className="text-end">
                        {loading ? (
                           <button
                              className="btn btn-primary disabled"
                              type="button"
                              disabled
                           >
                              Loading
                           </button>
                        ) : (
                           <button
                              className="btn btn-primary"
                              type="submit"
                           // onClick={() => props.setmodal_standard(false)}
                           >
                              {props.scheme !== undefined ? "Submit" : "Create"}
                           </button>
                        )}
                     </div>
                  </div>
               </div>
            </AvForm>
         </Modal>
      </>
   )
}
