import React, { useState, useEffect } from "react"
import axios from "axios"
import { Container, Row, Col, Modal, FormGroup, Input, Alert } from "reactstrap"
import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation"
import { getCurrentUser } from "../../helpers/Utils"
import Loader from "../Loader/loader.js"
import Select from "react-select"
import CorderModal from "../Customer-Profile/C-order/CorderModal"
import ServiceLineOrder from "../Customer-Profile/C-order/serviceLineOrder"

export default function OrderModal(props) {
  const [modal_standard, setmodal_standard] = useState(false)
  const [service_order, setservice_order] = useState(false)
  const [loader, setLoader] = useState("")
  const [error, setError] = useState("")
  const [error2, setError2] = useState("")
  const [customer, setCustomer] = useState([])
  const [customerID, setCustomerID] = useState("")

  const getCustomers = () => {
    setLoader(<Loader />)
    setCustomer([])
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/customers-min-fields`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setCustomer(res.data.data)
          setLoader("")
        } else {
          setError(res.data.message)
          setLoader("")
        }
      })
  }

  const options = []

  if (customer !== undefined) {
    customer.map(item => {
      options.push({
        value: item.id,
        label: [item.name, " ", `(${item.number})`, " ", item.firm_name],
      })
    })
  }

  const submitOrder = () => {
    if (customerID == "") {
      setError2("Please select a customer.")
    } else {
      setError2("")
      if (props.type == "order") {
        setmodal_standard(true)
      } else {
        setservice_order(true)
      }
    }
  }

  useEffect(() => {
    getCustomers()
  }, [])

  useEffect(() => {
    const close = e => {
      if (e.key === "Escape") {
        props.setmodal_order(false)
      }
    }
    window.addEventListener("keydown", close)
    return () => window.removeEventListener("keydown", close)
  }, [])

  return (
    <>
      {modal_standard ? (
        <CorderModal
          modal_standard={modal_standard}
          id={customerID}
          setmodal_standard={setmodal_standard}
          setmodal_order={props.setmodal_order}
          getAllOrders={props.getOrder}
        />
      ) : null}
      {service_order ? (
        <ServiceLineOrder
          service_order={service_order}
          id={customerID}
          setservice_order={setservice_order}
          setmodal_order={props.setmodal_order}
          getAllOrders={props.getOrder}
        />
      ) : null}
      <Modal isOpen={props.modal_order} centered={true}>
        <AvForm
          className="modal-title mt-0"
          id="myModalLabel"
          onValidSubmit={() => {
            submitOrder()
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myLargeModalLabel">
              Select Customer
            </h5>
            <button
              type="button"
              className="cross__btn"
              onClick={() => props.setmodal_order(false)}
              aria-hidden="true"
            >
              &times;
            </button>
          </div>
          <div className="modal-body">
            {loader != "" ? (
              loader
            ) : (
              <div className="mb-3 required">
                <Select
                  id="customer"
                  name="customer"
                  options={options}
                  onChange={e => setCustomerID(e.value)}
                />
              </div>
            )}
            {error ? (
              <span className="text-danger mt-3 d-table m-auto" role="alert">
                {error}
              </span>
            ) : null}
            {error2 ? (
              <span className="text-danger mt-3 d-table m-auto" role="alert">
                {error2}
              </span>
            ) : null}
          </div>
          <div className="modal-footer">
            <div className="text-center">
              <button
                className="btn btn-primary"
                type="submit"
                // onClick={() => setmodal_standard(true)}
              >
                Submit
              </button>
            </div>
          </div>
        </AvForm>
      </Modal>
    </>
  )
}
