import React, { useState, useEffect } from "react"
import { Container, Row, Col, Modal, Card, CardBody, FormGroup, Input, Nav, Alert, NavItem, NavLink, TabContent, TabPane } from "reactstrap"
import { AvForm, AvField, AvRadio, AvRadioGroup, AvCheckbox, AvCheckboxGroup } from "availity-reactstrap-validation"
import SweetAlert from "react-bootstrap-sweetalert"
import { getCurrentUser } from "../../../helpers/Utils"
import { Link } from "react-router-dom"
import axios from "axios"
import Loader from "../../Loader/loader.js"
import LongText from "../../MessageField"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

let selectedRoleId = []

const DeletedList = props => {

   const [lead, setLead] = useState(props.data)
   const [modal_standard, setmodal_standard] = useState(false)
   const [modal, setModal] = useState(false)
   const [customActiveTab, setCustomActiveTab] = useState(2)
   const [loading, setLoading] = useState(false)
   const [loader, setLoader] = useState(<Loader />)
   const [isOpen, setIsOpen] = useState(false)
   const [startDate, setStartDate] = useState(new Date())
   const [endTime, setEndTime] = useState(new Date())
   const [allFollowup, setAllFollowup] = useState([])
   const [allDead, setAllDead] = useState([])
   const [followupSec, setFollowupSec] = useState(false)
   const [matureSec, setMatureSec] = useState(false)
   const [deadSec, setDeadSec] = useState(false)
   const [historyData, setHistoryData] = useState([])


   useEffect(() => {
      selectedRoleId = []
   }, [])


   const getSelectedId = (e, check) => {
      if (check.target.checked) {
         selectedRoleId.push(e)
         const uniqueID = selectedRoleId.filter((val, id, array) => {
            return array.indexOf(val) == id
         })
         selectedRoleId = uniqueID
         props.setLeadsSelectedIds(selectedRoleId)
      } else {
         selectedRoleId = props.leadsSelectedIds
         var array = selectedRoleId
         var index = array.indexOf(e)
         if (index !== -1) {
            array.splice(index, 1)
         }
         props.setLeadsSelectedIds(selectedRoleId)
      }
      if (selectedRoleId.length == 0) {
         props.setBlur(false)
      } else {
         props.setBlur(true)
      }
   }

   if (lead === "") {
      return ""
   } else
      return (
         <>
            <tr key={props.i}>
               <td>
                  <input
                     onChange={(e) =>
                        getSelectedId(lead.id, e)
                     }
                     name="chk"
                     value={lead.id}
                     className="p-0 d-inline-block"
                     type="checkbox"
                  />
               </td>
               <td>{props.counter + props.i}</td>
               {/* <td>{lead.id}</td> */}
               <td>
                  {(lead && lead.firm_name == null) || lead.firm_name == "" ? (
                     ""
                  ) : (
                     <>
                        <span className="">({lead && lead.firm_name})</span>
                        <br />
                     </>
                  )}
                  {lead && lead.name} <br />
                  <span className="fw-bold">ID- </span>{lead.id}
               </td>
               {getCurrentUser().role_name == "Administrator" ?
                  <td>
                     <i className="fa fa-mobile"></i>
                     {" "}
                     {lead.country_code !== null ?
                        <>
                           {lead.country_code}
                        </>
                        : "+91"
                     }
                     {" "}
                     {lead.number}<br />
                     {lead.email == null || lead.email == "" ? "" : <>
                        <i className="fa fa-envelope"></i>{" "}{lead.email}</>}<br />
                     <i className="fa fa-map-marker"></i>{" "}{lead.city}{"  "}{lead.district == null ? " " : <> {lead.district}{", "} </>}{lead.state} <br />
                     {lead.pin_code == null || lead.pin_code == "" ? "" : <>
                        <i className="fa fa-map-pin"></i>{" "}{lead.pin_code}</>}
                  </td>
                  :
                  <td>
                     <i className="fa fa-mobile"></i>
                     {" "}
                     {lead.country_code !== null ?
                        <>
                           {lead.country_code}
                        </>
                        : "+91"
                     }
                     {" "}
                     ****<br />
                     {lead.email == null || lead.email == "" ? "" : <>
                        <i className="fa fa-envelope"></i>{" "}*********gmial.com</>}<br />
                     <i className="fa fa-map-marker"></i>{" "}{lead.city}{"  "}{lead.district == null ? " " : <> {lead.district}{", "} </>}{lead.state} <br />
                     {lead.pin_code == null || lead.pin_code == "" ? "" : <>
                        <i className="fa fa-map-pin"></i>{" "}{lead.pin_code}</>}
                  </td>
               }
               <td>

                  {lead.lead_source}
               </td>
               {getCurrentUser() && getCurrentUser().tag == 1 ?
                  <td>{lead.tag_name}</td>
                  : ""
               }
               <td>{lead.lead_label && lead.lead_label.map((item, i) => (
                  <>
                     <span key={i} className="badge" style={{ backgroundColor: '#f39c12' }}>{item.name}</span> <br />
                  </>
               ))}
               </td>
               {getCurrentUser().role_name == "Executive" ? "" :
                  <td>
                     <img style={{ height: '40px', width: '40px', borderRadius: '50%', objectFit: 'cover' }} src={lead.leadholder_photo}></img>
                     <br />
                     {lead.leadholder_name}<br />

                  </td>
               }
               <td>
                  {lead.type && lead.type.map((item, i) => (
                     <>
                        {item === "followup" ? <span key={i} className="badge" style={{ backgroundColor: 'orange' }}>Followup</span> : ""}
                        {item === "new" ? <span key={i} className="badge" style={{ backgroundColor: '#58a0c3' }}>New</span> : ""}
                        {item === "matured" ? <span key={i} className="badge" style={{ backgroundColor: 'green' }}>Matured</span> : ""}
                        {item === "dead" ? <span key={i} className="badge" style={{ backgroundColor: 'red' }}>Dead</span> : ""}
                        {item === "transfer request" ? <span key={i} className="badge" style={{ backgroundColor: 'pink' }}>Transfer Request</span> : ""}
                        {item === "repeated" ? <> <br /> <span key={i} className="badge" style={{ backgroundColor: 'purple' }}>Repeated</span> </> : ""}
                        {item === "dead_request" ? <span key={i} className="badge" style={{ backgroundColor: 'purple' }}>Dead Request</span> : ""}
                        {item === "matured_dead" ? <span key={i} className="badge" style={{ backgroundColor: '#D6BD42' }}>Matured Dead</span> : ""}
                     </>
                  ))}
                  <br />
                  <span className="badge" style={{ backgroundColor: '#f39c12' }}>{lead.dead_status}</span>
                  <br />
                  <span className="badge" style={{ backgroundColor: '#045fcf' }}>{lead.followup_status}</span>
                  <br />
                  <span className="badge" style={{ backgroundColor: 'green' }}>{lead.mature_status}</span>
               </td>
               <td>
                  {lead.type && lead.type.includes("dead") || lead.type && lead.type.includes("dead_request") ? ""
                     :
                     <>
                        {lead.next_followup_date}<br />{lead.next_followup_time}
                        {getCurrentUser().role_name == "Executive" ?
                           ""
                           :
                           <>
                              <hr />
                              {lead.followup_counter === 0 ? "" :
                                 <span>{lead.followup_counter}{" "}followup</span>
                              }
                           </>
                        }
                     </>
                  }
               </td>
               <td>
                  <LongText content={lead.last_message} />
                  <br />
                  {lead.last_followup_date}<br />
                  {lead.update_by}
               </td>
               {/* <td>
						<a
							className="text-warning"
							onClick={() => {
								editLead(lead.id)
							}}
						>
							<i className="fas fa-pen" />
						</a>
						<a
							className="text-danger ms-2"
							onClick={() => {
								setconfirm_alert(true)
							}}
						>
							<i className="fas fa-trash-alt" />
						</a>
						
					</td> */}
            </tr>

         </>
      )
}
export default DeletedList
