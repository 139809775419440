import React, { useState, useEffect } from "react"
import { Container, Row, Col, Modal, FormGroup, Input } from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert"
import { getCurrentUser } from "../../../helpers/Utils"
import { Link } from "react-router-dom"
import axios from "axios"
import FollowupModal from "./FollowupModal"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

let selectedRoleId = []

const FollowupList = props => {
   const [followup, setFollowup] = useState(props.data)
   const [confirm_alert, setconfirm_alert] = useState(false)
   const [leads_alert, setleads_alert] = useState(false)
   const [merge_alert, setmerge_alert] = useState(false)
   const [success_dlg, setsuccess_dlg] = useState(false)
   const [dynamic_title, setdynamic_title] = useState("")
   const [dynamic_description, setdynamic_description] = useState("")
   const [modal_followup_status, setmodal_followup_status] = useState(false)
   const [isOpen, setIsOpen] = useState(false)

   const mergeHistory = e => {
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/merge-history?followup_status_id=${e} `,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               //  props.getAllFollowup()
               toast.success(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            } else {
               toast.error(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            }
         })
   }

   const deleteLeads = e => {
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/followup-lead-deleted/${e} `,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               props.getAllFollowup()
               toast.success(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            }
         })
   }

   const deleteFollowup = e => {
      axios
         .delete(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/followup-status/${e} `,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setFollowup("")
               setdynamic_description(res.data.message)
               toast.success(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            }
         })
   }

   const editFollowup = e => {
      setIsOpen(true)
      setmodal_followup_status(true)
   }

   useEffect(() => {
      selectedRoleId = []
   }, [])

   const getSelectedId = (e, check) => {
      if (check.target.checked) {
         selectedRoleId.push(e)
         const uniqueID = selectedRoleId.filter((val, id, array) => {
            return array.indexOf(val) == id
         })
         selectedRoleId = uniqueID
         props.setLeadsSelectedIds(selectedRoleId)
      } else {
         selectedRoleId = props.leadsSelectedIds
         var array = selectedRoleId
         var index = array.indexOf(e)
         if (index !== -1) {
            array.splice(index, 1)
         }
         props.setLeadsSelectedIds(selectedRoleId)
      }
      if (selectedRoleId.length == 0) {
         props.setBlur(false)
      } else {
         props.setBlur(true)
      }
   }

   if (followup === "") {
      return ""
   } else
      return (
         <>
            {isOpen ? (
               <FollowupModal
                  modal_followup_status={modal_followup_status}
                  setmodal_followup_status={setmodal_followup_status}
                  followup={followup}
                  setFollowup={setFollowup}
                  setIsOpen={setIsOpen}
               />
            ) : null}
            <tr key={props.i}>
               <td>
                  <input
                     onChange={e => getSelectedId(followup.id, e)}
                     name="chk"
                     value={followup.id}
                     className="p-0 d-inline-block"
                     type="checkbox"
                  />
               </td>
               <td>{props.counter + props.i}</td>
               <td>{followup.id}</td>
               <td>
                  <Link className="" to={`/leads?followup_status_id=${followup.id}`}>
                     {followup.name}
                  </Link>
               </td>
               <td>{followup.total}</td>
               <td>
                  <button
                     className="btn btn-danger btn-sm"
                     onClick={e => setleads_alert(true)}
                  >
                     Delete
                  </button>
               </td>
               <td>
                  <a
                     className="text-warning"
                     onClick={() => {
                        editFollowup(followup.id)
                     }}
                  >
                     <i className="fas fa-pen" />
                  </a>
                  <a
                     className="text-danger ms-2"
                     onClick={() => {
                        setconfirm_alert(true)
                     }}
                  >
                     <i className="fas fa-trash-alt" />
                  </a>
                  {/* <button
                     className="btn btn-success btn-sm ms-2"
                     onClick={e => setmerge_alert(true)}
                  >
                     Merge History
                  </button> */}
                  {merge_alert ? (
                     <SweetAlert
                        title="Are you sure, you want to merge history of this followup stage?"
                        warning
                        showCancel
                        confirmButtonText="Yes, delete it!"
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={() => {
                           setmerge_alert(false)
                           setsuccess_dlg(true)
                           mergeHistory(followup.id)
                           setdynamic_title("Deleted")
                           setdynamic_description("Your file has been deleted.")
                        }}
                        onCancel={() => setmerge_alert(false)}
                     >
                        You won't be able to revert this!
                     </SweetAlert>
                  ) : null}
                  {leads_alert ? (
                     <SweetAlert
                        title="Are you sure to delete these leads ?"
                        warning
                        showCancel
                        confirmButtonText="Yes, delete it!"
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={() => {
                           setleads_alert(false)
                           setsuccess_dlg(true)
                           deleteLeads(followup.id)
                           setdynamic_title("Deleted")
                           setdynamic_description("Your file has been deleted.")
                        }}
                        onCancel={() => setleads_alert(false)}
                     >
                        You won't be able to revert this!
                     </SweetAlert>
                  ) : null}
                  {confirm_alert ? (
                     <SweetAlert
                        title="Are you sure?"
                        warning
                        showCancel
                        confirmButtonText="Yes, delete it!"
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={() => {
                           setconfirm_alert(false)
                           setsuccess_dlg(true)
                           deleteFollowup(followup.id)
                           setdynamic_title("Deleted")
                           setdynamic_description("Your file has been deleted.")
                        }}
                        onCancel={() => setconfirm_alert(false)}
                     >
                        You won't be able to revert this!
                     </SweetAlert>
                  ) : null}
               </td>
            </tr>
         </>
      )
}
export default FollowupList
