import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import Loader from "../../Loader/loader.js"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Modal,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardText,
  InputGroup,
  Label,
  FormGroup,
  Button,
} from "reactstrap"
import axios from "axios"
import {
  AvForm,
  AvField,
  AvCheckboxGroup,
  AvCheckbox,
} from "availity-reactstrap-validation"
import classnames from "classnames"
import { getCurrentUser, getPermission } from "../../../helpers/Utils"
import { Link, useLocation, useHistory } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

let selectedId = []
let selectedName = []

const MultipleChoice = props => {
  const removeName = () => {
    selectedName = []
  }

  const removeIds = () => {
    selectedId = []
  }

  const getname = e => {
    if (selectedName && selectedName.includes(e)) {
      var array = selectedName
      var index = array.indexOf(e)
      if (index !== -1) {
        array.splice(index, 1)
      }
    } else {
      selectedName.push(e)
    }
  }

  const getSelectedId = (e, check) => {
    if (check.target.checked) {
      selectedId.push(e)
      const uniqueID = selectedId.filter((val, id, array) => {
        return array.indexOf(val) == id
      })
      selectedId = uniqueID
    } else {
      var array = selectedId
      var index = array.indexOf(e)
      if (index !== -1) {
        array.splice(index, 1)
      }
    }
  }

  useEffect(() => {
    selectedId = []
    selectedName = []
  }, [])

  return (
    <div>
      {props.leadsource_filter ? (
        <div className="filter-2">
          <div className="mb-3 body-height">
            {JSON.parse(localStorage.getItem("AllSource")) &&
              JSON.parse(localStorage.getItem("AllSource")).map((item, i) => (
                <tr key={i}>
                  <input
                    onChange={e => getSelectedId(item.id, e)}
                    className=""
                    type="checkbox"
                    name="leadsource"
                    defaultChecked={
                      JSON.parse(
                        "[" + localStorage.getItem("leadSource") + "]"
                      ) &&
                      JSON.parse(
                        "[" + localStorage.getItem("leadSource") + "]"
                      ).includes(item.id)
                    }
                  />{" "}
                  <span className="ms-2 fw-normal text-muted">{item.name}</span>
                </tr>
              ))}
          </div>
          <button
            className="btn btn-primary"
            onClick={e => props.getSearchedLeads(selectedId, "leadsource")}
          >
            <i className="fa fa-search me-2"></i>
            Search
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={e => {
              props.getSearchedLeads("", "leadsource"), removeIds()
            }}
          >
            <i className="fa fa-undo me-2"></i>
            Refresh
          </button>
        </div>
      ) : (
        ""
      )}
      {props.af_leadsource_filter ? (
        <div className="filter-2">
          <div className="mb-3 body-height">
            {JSON.parse(localStorage.getItem("AllSource")) &&
              JSON.parse(localStorage.getItem("AllSource")).map((item, i) => (
                <tr key={i}>
                  <input
                    onChange={e => getSelectedId(item.id, e)}
                    className=""
                    type="checkbox"
                    name="leadsource"
                    defaultChecked={
                      JSON.parse(
                        "[" +
                          localStorage.getItem(
                            `leadSource-${props.customActiveTab}`
                          ) +
                          "]"
                      ) &&
                      JSON.parse(
                        "[" +
                          localStorage.getItem(
                            `leadSource-${props.customActiveTab}`
                          ) +
                          "]"
                      ).includes(item.id)
                    }
                  />{" "}
                  <span className="ms-2 fw-normal text-muted">{item.name}</span>
                </tr>
              ))}
          </div>
          <button
            className="btn btn-primary"
            onClick={e => props.getSearchedLeads(selectedId, "leadsource")}
          >
            <i className="fa fa-search me-2"></i>
            Search
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={e => {
              props.getSearchedLeads("", "leadsource"), removeIds()
            }}
          >
            <i className="fa fa-undo me-2"></i>
            Refresh
          </button>
        </div>
      ) : (
        ""
      )}
      {props.af2_leadsource_filter ? (
        <div className="filter-2">
          <div className="mb-3 body-height">
            {JSON.parse(localStorage.getItem("AllSource")) &&
              JSON.parse(localStorage.getItem("AllSource")).map((item, i) => (
                <tr key={i}>
                  <input
                    onChange={e => getSelectedId(item.id, e)}
                    className=""
                    type="checkbox"
                    name="leadsource"
                    defaultChecked={
                      JSON.parse(
                        "[" +
                          localStorage.getItem(
                            `leadSource-${props.customActiveTab}`
                          ) +
                          "]"
                      ) &&
                      JSON.parse(
                        "[" +
                          localStorage.getItem(
                            `leadSource-${props.customActiveTab}`
                          ) +
                          "]"
                      ).includes(item.id)
                    }
                  />{" "}
                  <span className="ms-2 fw-normal text-muted">{item.name}</span>
                </tr>
              ))}
          </div>
          <button
            className="btn btn-primary"
            onClick={e => props.getSearchedLeads(selectedId, "leadsource")}
          >
            <i className="fa fa-search me-2"></i>
            Search
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={e => {
              props.getSearchedLeads("", "leadsource"), removeIds()
            }}
          >
            <i className="fa fa-undo me-2"></i>
            Refresh
          </button>
        </div>
      ) : (
        ""
      )}
      {props.af3_leadsource_filter ? (
        <div className="filter-2">
          <div className="mb-3 body-height">
            {JSON.parse(localStorage.getItem("AllSource")) &&
              JSON.parse(localStorage.getItem("AllSource")).map((item, i) => (
                <tr key={i}>
                  <input
                    onChange={e => getSelectedId(item.id, e)}
                    className=""
                    type="checkbox"
                    name="leadsource"
                    defaultChecked={
                      JSON.parse(
                        "[" +
                          localStorage.getItem(
                            `leadSource-${props.customActiveTab}`
                          ) +
                          "]"
                      ) &&
                      JSON.parse(
                        "[" +
                          localStorage.getItem(
                            `leadSource-${props.customActiveTab}`
                          ) +
                          "]"
                      ).includes(item.id)
                    }
                  />{" "}
                  <span className="ms-2 fw-normal text-muted">{item.name}</span>
                </tr>
              ))}
          </div>
          <button
            className="btn btn-primary"
            onClick={e => props.getSearchedLeads(selectedId, "leadsource")}
          >
            <i className="fa fa-search me-2"></i>
            Search
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={e => {
              props.getSearchedLeads("", "leadsource"), removeIds()
            }}
          >
            <i className="fa fa-undo me-2"></i>
            Refresh
          </button>
        </div>
      ) : (
        ""
      )}
      {props.af4_leadsource_filter ? (
        <div className="filter-2">
          <div className="mb-3 body-height">
            {JSON.parse(localStorage.getItem("AllSource")) &&
              JSON.parse(localStorage.getItem("AllSource")).map((item, i) => (
                <tr key={i}>
                  <input
                    onChange={e => getSelectedId(item.id, e)}
                    className=""
                    type="checkbox"
                    name="leadsource"
                    defaultChecked={
                      JSON.parse(
                        "[" +
                          localStorage.getItem(
                            `leadSource-${props.customActiveTab}`
                          ) +
                          "]"
                      ) &&
                      JSON.parse(
                        "[" +
                          localStorage.getItem(
                            `leadSource-${props.customActiveTab}`
                          ) +
                          "]"
                      ).includes(item.id)
                    }
                  />{" "}
                  <span className="ms-2 fw-normal text-muted">{item.name}</span>
                </tr>
              ))}
          </div>
          <button
            className="btn btn-primary"
            onClick={e => props.getSearchedLeads(selectedId, "leadsource")}
          >
            <i className="fa fa-search me-2"></i>
            Search
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={e => {
              props.getSearchedLeads("", "leadsource"), removeIds()
            }}
          >
            <i className="fa fa-undo me-2"></i>
            Refresh
          </button>
        </div>
      ) : (
        ""
      )}
      {props.af5_leadsource_filter ? (
        <div className="filter-2">
          <div className="mb-3 body-height">
            {JSON.parse(localStorage.getItem("AllSource")) &&
              JSON.parse(localStorage.getItem("AllSource")).map((item, i) => (
                <tr key={i}>
                  <input
                    onChange={e => getSelectedId(item.id, e)}
                    className=""
                    type="checkbox"
                    name="leadsource"
                    defaultChecked={
                      JSON.parse(
                        "[" +
                          localStorage.getItem(
                            `leadSource-${props.customActiveTab}`
                          ) +
                          "]"
                      ) &&
                      JSON.parse(
                        "[" +
                          localStorage.getItem(
                            `leadSource-${props.customActiveTab}`
                          ) +
                          "]"
                      ).includes(item.id)
                    }
                  />{" "}
                  <span className="ms-2 fw-normal text-muted">{item.name}</span>
                </tr>
              ))}
          </div>
          <button
            className="btn btn-primary"
            onClick={e => props.getSearchedLeads(selectedId, "leadsource")}
          >
            <i className="fa fa-search me-2"></i>
            Search
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={e => {
              props.getSearchedLeads("", "leadsource"), removeIds()
            }}
          >
            <i className="fa fa-undo me-2"></i>
            Refresh
          </button>
        </div>
      ) : (
        ""
      )}
      {props.tag_filter ? (
        <div className="filter-2">
          <div className="mb-3 body-height">
            {JSON.parse(localStorage.getItem("AllTags")) &&
              JSON.parse(localStorage.getItem("AllTags")).map((item, i) => (
                <tr key={i}>
                  <input
                    onChange={e => getSelectedId(item.id, e)}
                    className=""
                    type="checkbox"
                    name="tag"
                    defaultChecked={
                      JSON.parse("[" + localStorage.getItem("leadTag") + "]") &&
                      JSON.parse(
                        "[" + localStorage.getItem("leadTag") + "]"
                      ).includes(item.id)
                    }
                  />{" "}
                  <span className="ms-2 fw-normal text-muted">{item.name}</span>
                </tr>
              ))}
          </div>
          <button
            className="btn btn-primary"
            onClick={e => props.getSearchedLeads(selectedId, "tag")}
          >
            <i className="fa fa-search me-2"></i>
            Search
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={e => {
              props.getSearchedLeads("", "tag"), removeIds()
            }}
          >
            <i className="fa fa-undo me-2"></i>
            Refresh
          </button>
        </div>
      ) : (
        ""
      )}
      {props.af_tag_filter ? (
        <div className="filter-2">
          <div className="mb-3 body-height">
            {JSON.parse(localStorage.getItem("AllTags")) &&
              JSON.parse(localStorage.getItem("AllTags")).map((item, i) => (
                <tr key={i}>
                  <input
                    onChange={e => getSelectedId(item.id, e)}
                    className=""
                    type="checkbox"
                    name="tag"
                    defaultChecked={
                      JSON.parse(
                        "[" +
                          localStorage.getItem(
                            `leadTag-${props.customActiveTab}`
                          ) +
                          "]"
                      ) &&
                      JSON.parse(
                        "[" +
                          localStorage.getItem(
                            `leadTag-${props.customActiveTab}`
                          ) +
                          "]"
                      ).includes(item.id)
                    }
                  />{" "}
                  <span className="ms-2 fw-normal text-muted">{item.name}</span>
                </tr>
              ))}
          </div>
          <button
            className="btn btn-primary"
            onClick={e => props.getSearchedLeads(selectedId, "tag")}
          >
            <i className="fa fa-search me-2"></i>
            Search
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={e => {
              props.getSearchedLeads("", "tag"), removeIds()
            }}
          >
            <i className="fa fa-undo me-2"></i>
            Refresh
          </button>
        </div>
      ) : (
        ""
      )}
      {props.af2_tag_filter ? (
        <div className="filter-2">
          <div className="mb-3 body-height">
            {JSON.parse(localStorage.getItem("AllTags")) &&
              JSON.parse(localStorage.getItem("AllTags")).map((item, i) => (
                <tr key={i}>
                  <input
                    onChange={e => getSelectedId(item.id, e)}
                    className=""
                    type="checkbox"
                    name="tag"
                    defaultChecked={
                      JSON.parse(
                        "[" +
                          localStorage.getItem(
                            `leadTag-${props.customActiveTab}`
                          ) +
                          "]"
                      ) &&
                      JSON.parse(
                        "[" +
                          localStorage.getItem(
                            `leadTag-${props.customActiveTab}`
                          ) +
                          "]"
                      ).includes(item.id)
                    }
                  />{" "}
                  <span className="ms-2 fw-normal text-muted">{item.name}</span>
                </tr>
              ))}
          </div>
          <button
            className="btn btn-primary"
            onClick={e => props.getSearchedLeads(selectedId, "tag")}
          >
            <i className="fa fa-search me-2"></i>
            Search
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={e => {
              props.getSearchedLeads("", "tag"), removeIds()
            }}
          >
            <i className="fa fa-undo me-2"></i>
            Refresh
          </button>
        </div>
      ) : (
        ""
      )}
      {props.af3_tag_filter ? (
        <div className="filter-2">
          <div className="mb-3 body-height">
            {JSON.parse(localStorage.getItem("AllTags")) &&
              JSON.parse(localStorage.getItem("AllTags")).map((item, i) => (
                <tr key={i}>
                  <input
                    onChange={e => getSelectedId(item.id, e)}
                    className=""
                    type="checkbox"
                    name="tag"
                    defaultChecked={
                      JSON.parse(
                        "[" +
                          localStorage.getItem(
                            `leadTag-${props.customActiveTab}`
                          ) +
                          "]"
                      ) &&
                      JSON.parse(
                        "[" +
                          localStorage.getItem(
                            `leadTag-${props.customActiveTab}`
                          ) +
                          "]"
                      ).includes(item.id)
                    }
                  />{" "}
                  <span className="ms-2 fw-normal text-muted">{item.name}</span>
                </tr>
              ))}
          </div>
          <button
            className="btn btn-primary"
            onClick={e => props.getSearchedLeads(selectedId, "tag")}
          >
            <i className="fa fa-search me-2"></i>
            Search
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={e => {
              props.getSearchedLeads("", "tag"), removeIds()
            }}
          >
            <i className="fa fa-undo me-2"></i>
            Refresh
          </button>
        </div>
      ) : (
        ""
      )}
      {props.af4_tag_filter ? (
        <div className="filter-2">
          <div className="mb-3 body-height">
            {JSON.parse(localStorage.getItem("AllTags")) &&
              JSON.parse(localStorage.getItem("AllTags")).map((item, i) => (
                <tr key={i}>
                  <input
                    onChange={e => getSelectedId(item.id, e)}
                    className=""
                    type="checkbox"
                    name="tag"
                    defaultChecked={
                      JSON.parse(
                        "[" +
                          localStorage.getItem(
                            `leadTag-${props.customActiveTab}`
                          ) +
                          "]"
                      ) &&
                      JSON.parse(
                        "[" +
                          localStorage.getItem(
                            `leadTag-${props.customActiveTab}`
                          ) +
                          "]"
                      ).includes(item.id)
                    }
                  />{" "}
                  <span className="ms-2 fw-normal text-muted">{item.name}</span>
                </tr>
              ))}
          </div>
          <button
            className="btn btn-primary"
            onClick={e => props.getSearchedLeads(selectedId, "tag")}
          >
            <i className="fa fa-search me-2"></i>
            Search
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={e => {
              props.getSearchedLeads("", "tag"), removeIds()
            }}
          >
            <i className="fa fa-undo me-2"></i>
            Refresh
          </button>
        </div>
      ) : (
        ""
      )}
      {props.af5_tag_filter ? (
        <div className="filter-2">
          <div className="mb-3 body-height">
            {JSON.parse(localStorage.getItem("AllTags")) &&
              JSON.parse(localStorage.getItem("AllTags")).map((item, i) => (
                <tr key={i}>
                  <input
                    onChange={e => getSelectedId(item.id, e)}
                    className=""
                    type="checkbox"
                    name="tag"
                    defaultChecked={
                      JSON.parse(
                        "[" +
                          localStorage.getItem(
                            `leadTag-${props.customActiveTab}`
                          ) +
                          "]"
                      ) &&
                      JSON.parse(
                        "[" +
                          localStorage.getItem(
                            `leadTag-${props.customActiveTab}`
                          ) +
                          "]"
                      ).includes(item.id)
                    }
                  />{" "}
                  <span className="ms-2 fw-normal text-muted">{item.name}</span>
                </tr>
              ))}
          </div>
          <button
            className="btn btn-primary"
            onClick={e => props.getSearchedLeads(selectedId, "tag")}
          >
            <i className="fa fa-search me-2"></i>
            Search
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={e => {
              props.getSearchedLeads("", "tag"), removeIds()
            }}
          >
            <i className="fa fa-undo me-2"></i>
            Refresh
          </button>
        </div>
      ) : (
        ""
      )}
      {props.label_filter ? (
        <div className="filter-2">
          <div className="mb-3 body-height">
            {JSON.parse(localStorage.getItem("AllLabels")) &&
              JSON.parse(localStorage.getItem("AllLabels")).map((item, i) => (
                <tr key={i}>
                  <input
                    onChange={e => getSelectedId(item.id, e)}
                    className=""
                    type="checkbox"
                    name="label"
                    defaultChecked={
                      JSON.parse(
                        "[" + localStorage.getItem("leadLabel") + "]"
                      ) &&
                      JSON.parse(
                        "[" + localStorage.getItem("leadLabel") + "]"
                      ).includes(item.id)
                    }
                  />{" "}
                  <span className="ms-2 fw-normal text-muted">{item.name}</span>
                </tr>
              ))}
          </div>
          <button
            className="btn btn-primary"
            onClick={() => props.getSearchedLeads(selectedId, "label")}
          >
            <i className="fa fa-search me-2"></i>
            Search
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={e => {
              props.getSearchedLeads("", "label"), removeIds()
            }}
          >
            <i className="fa fa-undo me-2"></i>
            Refresh
          </button>
        </div>
      ) : (
        ""
      )}
      {props.af_label_filter ? (
        <div className="filter-2">
          <div className="mb-3 body-height">
            {JSON.parse(localStorage.getItem("AllLabels")) &&
              JSON.parse(localStorage.getItem("AllLabels")).map((item, i) => (
                <tr key={i}>
                  <input
                    onChange={e => getSelectedId(item.id, e)}
                    className=""
                    type="checkbox"
                    name="label"
                    defaultChecked={
                      JSON.parse(
                        "[" +
                          localStorage.getItem(
                            `leadLabel-${props.customActiveTab}`
                          ) +
                          "]"
                      ) &&
                      JSON.parse(
                        "[" +
                          localStorage.getItem(
                            `leadLabel-${props.customActiveTab}`
                          ) +
                          "]"
                      ).includes(item.id)
                    }
                  />{" "}
                  <span className="ms-2 fw-normal text-muted">{item.name}</span>
                </tr>
              ))}
          </div>
          <button
            className="btn btn-primary"
            onClick={() => props.getSearchedLeads(selectedId, "label")}
          >
            <i className="fa fa-search me-2"></i>
            Search
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={e => {
              props.getSearchedLeads("", "label"), removeIds()
            }}
          >
            <i className="fa fa-undo me-2"></i>
            Refresh
          </button>
        </div>
      ) : (
        ""
      )}
      {props.af2_label_filter ? (
        <div className="filter-2">
          <div className="mb-3 body-height">
            {JSON.parse(localStorage.getItem("AllLabels")) &&
              JSON.parse(localStorage.getItem("AllLabels")).map((item, i) => (
                <tr key={i}>
                  <input
                    onChange={e => getSelectedId(item.id, e)}
                    className=""
                    type="checkbox"
                    name="label"
                    defaultChecked={
                      JSON.parse(
                        "[" +
                          localStorage.getItem(
                            `leadLabel-${props.customActiveTab}`
                          ) +
                          "]"
                      ) &&
                      JSON.parse(
                        "[" +
                          localStorage.getItem(
                            `leadLabel-${props.customActiveTab}`
                          ) +
                          "]"
                      ).includes(item.id)
                    }
                  />{" "}
                  <span className="ms-2 fw-normal text-muted">{item.name}</span>
                </tr>
              ))}
          </div>
          <button
            className="btn btn-primary"
            onClick={() => props.getSearchedLeads(selectedId, "label")}
          >
            <i className="fa fa-search me-2"></i>
            Search
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={e => {
              props.getSearchedLeads("", "label"), removeIds()
            }}
          >
            <i className="fa fa-undo me-2"></i>
            Refresh
          </button>
        </div>
      ) : (
        ""
      )}
      {props.af3_label_filter ? (
        <div className="filter-2">
          <div className="mb-3 body-height">
            {JSON.parse(localStorage.getItem("AllLabels")) &&
              JSON.parse(localStorage.getItem("AllLabels")).map((item, i) => (
                <tr key={i}>
                  <input
                    onChange={e => getSelectedId(item.id, e)}
                    className=""
                    type="checkbox"
                    name="label"
                    defaultChecked={
                      JSON.parse(
                        "[" +
                          localStorage.getItem(
                            `leadLabel-${props.customActiveTab}`
                          ) +
                          "]"
                      ) &&
                      JSON.parse(
                        "[" +
                          localStorage.getItem(
                            `leadLabel-${props.customActiveTab}`
                          ) +
                          "]"
                      ).includes(item.id)
                    }
                  />{" "}
                  <span className="ms-2 fw-normal text-muted">{item.name}</span>
                </tr>
              ))}
          </div>
          <button
            className="btn btn-primary"
            onClick={() => props.getSearchedLeads(selectedId, "label")}
          >
            <i className="fa fa-search me-2"></i>
            Search
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={e => {
              props.getSearchedLeads("", "label"), removeIds()
            }}
          >
            <i className="fa fa-undo me-2"></i>
            Refresh
          </button>
        </div>
      ) : (
        ""
      )}
      {props.af4_label_filter ? (
        <div className="filter-2">
          <div className="mb-3 body-height">
            {JSON.parse(localStorage.getItem("AllLabels")) &&
              JSON.parse(localStorage.getItem("AllLabels")).map((item, i) => (
                <tr key={i}>
                  <input
                    onChange={e => getSelectedId(item.id, e)}
                    className=""
                    type="checkbox"
                    name="label"
                    defaultChecked={
                      JSON.parse(
                        "[" +
                          localStorage.getItem(
                            `leadLabel-${props.customActiveTab}`
                          ) +
                          "]"
                      ) &&
                      JSON.parse(
                        "[" +
                          localStorage.getItem(
                            `leadLabel-${props.customActiveTab}`
                          ) +
                          "]"
                      ).includes(item.id)
                    }
                  />{" "}
                  <span className="ms-2 fw-normal text-muted">{item.name}</span>
                </tr>
              ))}
          </div>
          <button
            className="btn btn-primary"
            onClick={() => props.getSearchedLeads(selectedId, "label")}
          >
            <i className="fa fa-search me-2"></i>
            Search
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={e => {
              props.getSearchedLeads("", "label"), removeIds()
            }}
          >
            <i className="fa fa-undo me-2"></i>
            Refresh
          </button>
        </div>
      ) : (
        ""
      )}
      {props.af5_label_filter ? (
        <div className="filter-2">
          <div className="mb-3 body-height">
            {JSON.parse(localStorage.getItem("AllLabels")) &&
              JSON.parse(localStorage.getItem("AllLabels")).map((item, i) => (
                <tr key={i}>
                  <input
                    onChange={e => getSelectedId(item.id, e)}
                    className=""
                    type="checkbox"
                    name="label"
                    defaultChecked={
                      JSON.parse(
                        "[" +
                          localStorage.getItem(
                            `leadLabel-${props.customActiveTab}`
                          ) +
                          "]"
                      ) &&
                      JSON.parse(
                        "[" +
                          localStorage.getItem(
                            `leadLabel-${props.customActiveTab}`
                          ) +
                          "]"
                      ).includes(item.id)
                    }
                  />{" "}
                  <span className="ms-2 fw-normal text-muted">{item.name}</span>
                </tr>
              ))}
          </div>
          <button
            className="btn btn-primary"
            onClick={() => props.getSearchedLeads(selectedId, "label")}
          >
            <i className="fa fa-search me-2"></i>
            Search
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={e => {
              props.getSearchedLeads("", "label"), removeIds()
            }}
          >
            <i className="fa fa-undo me-2"></i>
            Refresh
          </button>
        </div>
      ) : (
        ""
      )}
      {props.leadholder_filter ? (
        <div className="filter-2">
          <div className="mb-3 body-height">
            {JSON.parse(localStorage.getItem("AllUsers")) &&
              JSON.parse(localStorage.getItem("AllUsers")).map((item, i) => (
                <tr key={i}>
                  <input
                    onChange={e => getSelectedId(item.id, e)}
                    className=""
                    type="checkbox"
                    name="leadholder"
                    defaultChecked={
                      JSON.parse(
                        "[" + localStorage.getItem("leadHolder") + "]"
                      ) &&
                      JSON.parse(
                        "[" + localStorage.getItem("leadHolder") + "]"
                      ).includes(item.id)
                    }
                  />{" "}
                  <span className="ms-2 fw-normal text-muted">{item.name}</span>
                </tr>
              ))}
          </div>
          <button
            className="btn btn-primary"
            onClick={e => props.getSearchedLeads(selectedId, "leadholder")}
          >
            <i className="fa fa-search me-2"></i>
            Search
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={e => {
              props.getSearchedLeads("", "leadholder"), removeIds()
            }}
          >
            <i className="fa fa-undo me-2"></i>
            Refresh
          </button>
        </div>
      ) : (
        ""
      )}
      {props.updatedBy_filter ? (
        <div className="filter-2">
          <div className="mb-3 body-height">
            {JSON.parse(localStorage.getItem("AllUsers")) &&
              JSON.parse(localStorage.getItem("AllUsers")).map((item, i) => (
                <tr key={i}>
                  <input
                    onChange={e => getSelectedId(item.id, e)}
                    className=""
                    type="checkbox"
                    name="updatedBy"
                    defaultChecked={
                      JSON.parse(
                        "[" + localStorage.getItem("updatedBy") + "]"
                      ) &&
                      JSON.parse(
                        "[" + localStorage.getItem("updatedBy") + "]"
                      ).includes(item.id)
                    }
                  />{" "}
                  <span className="ms-2 fw-normal text-muted">{item.name}</span>
                </tr>
              ))}
          </div>
          <button
            className="btn btn-primary"
            onClick={e => props.getSearchedLeads(selectedId, "updatedBy")}
          >
            <i className="fa fa-search me-2"></i>
            Search
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={e => {
              props.getSearchedLeads("", "updatedBy"), removeIds()
            }}
          >
            <i className="fa fa-undo me-2"></i>
            Refresh
          </button>
        </div>
      ) : (
        ""
      )}
      {props.af_leadholder_filter ? (
        <div className="filter-2">
          <div className="mb-3 body-height">
            {JSON.parse(localStorage.getItem("AllUsers")) &&
              JSON.parse(localStorage.getItem("AllUsers")).map((item, i) => (
                <tr key={i}>
                  <input
                    onChange={e => getSelectedId(item.id, e)}
                    className=""
                    type="checkbox"
                    name="leadholder"
                    defaultChecked={
                      JSON.parse(
                        "[" +
                          localStorage.getItem(
                            `leadHolder-${props.customActiveTab}`
                          ) +
                          "]"
                      ) &&
                      JSON.parse(
                        "[" +
                          localStorage.getItem(
                            `leadHolder-${props.customActiveTab}`
                          ) +
                          "]"
                      ).includes(item.id)
                    }
                  />{" "}
                  <span className="ms-2 fw-normal text-muted">{item.name}</span>
                </tr>
              ))}
          </div>
          <button
            className="btn btn-primary"
            onClick={e => props.getSearchedLeads(selectedId, "leadholder")}
          >
            <i className="fa fa-search me-2"></i>
            Search
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={e => {
              props.getSearchedLeads("", "leadholder"), removeIds()
            }}
          >
            <i className="fa fa-undo me-2"></i>
            Refresh
          </button>
        </div>
      ) : (
        ""
      )}
      {props.af2_leadholder_filter ? (
        <div className="filter-2">
          <div className="mb-3 body-height">
            {JSON.parse(localStorage.getItem("AllUsers")) &&
              JSON.parse(localStorage.getItem("AllUsers")).map((item, i) => (
                <tr key={i}>
                  <input
                    onChange={e => getSelectedId(item.id, e)}
                    className=""
                    type="checkbox"
                    name="leadholder"
                    defaultChecked={
                      JSON.parse(
                        "[" +
                          localStorage.getItem(
                            `leadHolder-${props.customActiveTab}`
                          ) +
                          "]"
                      ) &&
                      JSON.parse(
                        "[" +
                          localStorage.getItem(
                            `leadHolder-${props.customActiveTab}`
                          ) +
                          "]"
                      ).includes(item.id)
                    }
                  />{" "}
                  <span className="ms-2 fw-normal text-muted">{item.name}</span>
                </tr>
              ))}
          </div>
          <button
            className="btn btn-primary"
            onClick={e => props.getSearchedLeads(selectedId, "leadholder")}
          >
            <i className="fa fa-search me-2"></i>
            Search
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={e => {
              props.getSearchedLeads("", "leadholder"), removeIds()
            }}
          >
            <i className="fa fa-undo me-2"></i>
            Refresh
          </button>
        </div>
      ) : (
        ""
      )}
      {props.af3_leadholder_filter ? (
        <div className="filter-2">
          <div className="mb-3 body-height">
            {JSON.parse(localStorage.getItem("AllUsers")) &&
              JSON.parse(localStorage.getItem("AllUsers")).map((item, i) => (
                <tr key={i}>
                  <input
                    onChange={e => getSelectedId(item.id, e)}
                    className=""
                    type="checkbox"
                    name="leadholder"
                    defaultChecked={
                      JSON.parse(
                        "[" +
                          localStorage.getItem(
                            `leadHolder-${props.customActiveTab}`
                          ) +
                          "]"
                      ) &&
                      JSON.parse(
                        "[" +
                          localStorage.getItem(
                            `leadHolder-${props.customActiveTab}`
                          ) +
                          "]"
                      ).includes(item.id)
                    }
                  />{" "}
                  <span className="ms-2 fw-normal text-muted">{item.name}</span>
                </tr>
              ))}
          </div>
          <button
            className="btn btn-primary"
            onClick={e => props.getSearchedLeads(selectedId, "leadholder")}
          >
            <i className="fa fa-search me-2"></i>
            Search
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={e => {
              props.getSearchedLeads("", "leadholder"), removeIds()
            }}
          >
            <i className="fa fa-undo me-2"></i>
            Refresh
          </button>
        </div>
      ) : (
        ""
      )}
      {props.af4_leadholder_filter ? (
        <div className="filter-2">
          <div className="mb-3 body-height">
            {JSON.parse(localStorage.getItem("AllUsers")) &&
              JSON.parse(localStorage.getItem("AllUsers")).map((item, i) => (
                <tr key={i}>
                  <input
                    onChange={e => getSelectedId(item.id, e)}
                    className=""
                    type="checkbox"
                    name="leadholder"
                    defaultChecked={
                      JSON.parse(
                        "[" +
                          localStorage.getItem(
                            `leadHolder-${props.customActiveTab}`
                          ) +
                          "]"
                      ) &&
                      JSON.parse(
                        "[" +
                          localStorage.getItem(
                            `leadHolder-${props.customActiveTab}`
                          ) +
                          "]"
                      ).includes(item.id)
                    }
                  />{" "}
                  <span className="ms-2 fw-normal text-muted">{item.name}</span>
                </tr>
              ))}
          </div>
          <button
            className="btn btn-primary"
            onClick={e => props.getSearchedLeads(selectedId, "leadholder")}
          >
            <i className="fa fa-search me-2"></i>
            Search
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={e => {
              props.getSearchedLeads("", "leadholder"), removeIds()
            }}
          >
            <i className="fa fa-undo me-2"></i>
            Refresh
          </button>
        </div>
      ) : (
        ""
      )}
      {props.af5_leadholder_filter ? (
        <div className="filter-2">
          <div className="mb-3 body-height">
            {JSON.parse(localStorage.getItem("AllUsers")) &&
              JSON.parse(localStorage.getItem("AllUsers")).map((item, i) => (
                <tr key={i}>
                  <input
                    onChange={e => getSelectedId(item.id, e)}
                    className=""
                    type="checkbox"
                    name="leadholder"
                    defaultChecked={
                      JSON.parse(
                        "[" +
                          localStorage.getItem(
                            `leadHolder-${props.customActiveTab}`
                          ) +
                          "]"
                      ) &&
                      JSON.parse(
                        "[" +
                          localStorage.getItem(
                            `leadHolder-${props.customActiveTab}`
                          ) +
                          "]"
                      ).includes(item.id)
                    }
                  />{" "}
                  <span className="ms-2 fw-normal text-muted">{item.name}</span>
                </tr>
              ))}
          </div>
          <button
            className="btn btn-primary"
            onClick={e => props.getSearchedLeads(selectedId, "leadholder")}
          >
            <i className="fa fa-search me-2"></i>
            Search
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={e => {
              props.getSearchedLeads("", "leadholder"), removeIds()
            }}
          >
            <i className="fa fa-undo me-2"></i>
            Refresh
          </button>
        </div>
      ) : (
        ""
      )}
      {props.role_filter ? (
        <div className="filter-2">
          <div className="mb-3 body-height">
            {props.data &&
              props.data.map((item, i) => (
                <tr key={i}>
                  <input
                    onChange={e => getSelectedId(item.id, e)}
                    className=""
                    type="checkbox"
                    name="role"
                    defaultChecked={
                      JSON.parse(
                        "[" + localStorage.getItem("leadRole") + "]"
                      ) &&
                      JSON.parse(
                        "[" + localStorage.getItem("leadRole") + "]"
                      ).includes(item.id)
                    }
                  />{" "}
                  <span className="ms-2 fw-normal text-muted">{item.name}</span>
                </tr>
              ))}
          </div>
          <button
            className="btn btn-primary"
            onClick={e => props.getSearchedLeads(selectedId, "role")}
          >
            <i className="fa fa-search me-2"></i>
            Search
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={e => {
              props.getSearchedLeads("", "role"), removeIds()
            }}
          >
            <i className="fa fa-undo me-2"></i>
            Refresh
          </button>
        </div>
      ) : (
        ""
      )}
      {props.maturedby_filter ? (
        <div className="filter-2">
          <div className="mb-3 body-height">
            {props.data &&
              props.data.map((item, i) => (
                <tr key={i}>
                  <input
                    onChange={e => getSelectedId(item.id, e)}
                    className=""
                    type="checkbox"
                    name="leadmaturedby"
                    defaultChecked={
                      JSON.parse(
                        "[" + localStorage.getItem("leadmaturedby") + "]"
                      ) &&
                      JSON.parse(
                        "[" + localStorage.getItem("leadmaturedby") + "]"
                      ).includes(item.id)
                    }
                  />{" "}
                  <span className="ms-2 fw-normal text-muted">{item.name}</span>
                </tr>
              ))}
          </div>
          <button
            className="btn btn-primary"
            onClick={e => props.getSearchedLeads(selectedId, "leadmaturedby")}
          >
            <i className="fa fa-search me-2"></i>
            Search
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={e => {
              props.getSearchedLeads("", "leadmaturedby"), removeIds()
            }}
          >
            <i className="fa fa-undo me-2"></i>
            Refresh
          </button>
        </div>
      ) : (
        ""
      )}
      {props.followups_filter ? (
        <div className="filter-2">
          <div className="mb-3 body-height">
            {JSON.parse(localStorage.getItem("AllFollowups")) &&
              JSON.parse(localStorage.getItem("AllFollowups")).map(
                (item, i) => (
                  <tr key={i}>
                    <input
                      onChange={e => getSelectedId(item.id, e)}
                      className=""
                      type="checkbox"
                      name="f-status"
                      defaultChecked={
                        JSON.parse(
                          "[" + localStorage.getItem("followupStatus") + "]"
                        ) &&
                        JSON.parse(
                          "[" + localStorage.getItem("followupStatus") + "]"
                        ).includes(item.id)
                      }
                    />{" "}
                    <span className="ms-2 fw-normal text-muted">
                      {item.name}
                    </span>
                  </tr>
                )
              )}
          </div>
          <button
            className="btn btn-primary"
            onClick={e => props.getSearchedLeads(selectedId, "f-status")}
          >
            <i className="fa fa-search me-2"></i>
            Search
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={e => {
              props.getSearchedLeads("", "f-status"), removeIds()
            }}
          >
            <i className="fa fa-undo me-2"></i>
            Refresh
          </button>
        </div>
      ) : (
        ""
      )}
      {props.af_followups_filter ? (
        <div className="filter-2">
          <div className="mb-3 body-height">
            {JSON.parse(localStorage.getItem("AllFollowups")) &&
              JSON.parse(localStorage.getItem("AllFollowups")).map(
                (item, i) => (
                  <tr key={i}>
                    <input
                      onChange={e => getSelectedId(item.id, e)}
                      className=""
                      type="checkbox"
                      name="f-status"
                      defaultChecked={
                        JSON.parse(
                          "[" +
                            localStorage.getItem(
                              `followupStatus-${props.customActiveTab}`
                            ) +
                            "]"
                        ) &&
                        JSON.parse(
                          "[" +
                            localStorage.getItem(
                              `followupStatus-${props.customActiveTab}`
                            ) +
                            "]"
                        ).includes(item.id)
                      }
                    />{" "}
                    <span className="ms-2 fw-normal text-muted">
                      {item.name}
                    </span>
                  </tr>
                )
              )}
          </div>
          <button
            className="btn btn-primary"
            onClick={e => props.getSearchedLeads(selectedId, "f-status")}
          >
            <i className="fa fa-search me-2"></i>
            Search
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={e => {
              props.getSearchedLeads("", "f-status"), removeIds()
            }}
          >
            <i className="fa fa-undo me-2"></i>
            Refresh
          </button>
        </div>
      ) : (
        ""
      )}
      {props.af2_followups_filter ? (
        <div className="filter-2">
          <div className="mb-3 body-height">
            {JSON.parse(localStorage.getItem("AllFollowups")) &&
              JSON.parse(localStorage.getItem("AllFollowups")).map(
                (item, i) => (
                  <tr key={i}>
                    <input
                      onChange={e => getSelectedId(item.id, e)}
                      className=""
                      type="checkbox"
                      name="f-status"
                      defaultChecked={
                        JSON.parse(
                          "[" +
                            localStorage.getItem(
                              `followupStatus-${props.customActiveTab}`
                            ) +
                            "]"
                        ) &&
                        JSON.parse(
                          "[" +
                            localStorage.getItem(
                              `followupStatus-${props.customActiveTab}`
                            ) +
                            "]"
                        ).includes(item.id)
                      }
                    />{" "}
                    <span className="ms-2 fw-normal text-muted">
                      {item.name}
                    </span>
                  </tr>
                )
              )}
          </div>
          <button
            className="btn btn-primary"
            onClick={e => props.getSearchedLeads(selectedId, "f-status")}
          >
            <i className="fa fa-search me-2"></i>
            Search
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={e => {
              props.getSearchedLeads("", "f-status"), removeIds()
            }}
          >
            <i className="fa fa-undo me-2"></i>
            Refresh
          </button>
        </div>
      ) : (
        ""
      )}
      {props.af3_followups_filter ? (
        <div className="filter-2">
          <div className="mb-3 body-height">
            {JSON.parse(localStorage.getItem("AllFollowups")) &&
              JSON.parse(localStorage.getItem("AllFollowups")).map(
                (item, i) => (
                  <tr key={i}>
                    <input
                      onChange={e => getSelectedId(item.id, e)}
                      className=""
                      type="checkbox"
                      name="f-status"
                      defaultChecked={
                        JSON.parse(
                          "[" +
                            localStorage.getItem(
                              `followupStatus-${props.customActiveTab}`
                            ) +
                            "]"
                        ) &&
                        JSON.parse(
                          "[" +
                            localStorage.getItem(
                              `followupStatus-${props.customActiveTab}`
                            ) +
                            "]"
                        ).includes(item.id)
                      }
                    />{" "}
                    <span className="ms-2 fw-normal text-muted">
                      {item.name}
                    </span>
                  </tr>
                )
              )}
          </div>
          <button
            className="btn btn-primary"
            onClick={e => props.getSearchedLeads(selectedId, "f-status")}
          >
            <i className="fa fa-search me-2"></i>
            Search
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={e => {
              props.getSearchedLeads("", "f-status"), removeIds()
            }}
          >
            <i className="fa fa-undo me-2"></i>
            Refresh
          </button>
        </div>
      ) : (
        ""
      )}
      {props.af4_followups_filter ? (
        <div className="filter-2">
          <div className="mb-3 body-height">
            {JSON.parse(localStorage.getItem("AllFollowups")) &&
              JSON.parse(localStorage.getItem("AllFollowups")).map(
                (item, i) => (
                  <tr key={i}>
                    <input
                      onChange={e => getSelectedId(item.id, e)}
                      className=""
                      type="checkbox"
                      name="f-status"
                      defaultChecked={
                        JSON.parse(
                          "[" +
                            localStorage.getItem(
                              `followupStatus-${props.customActiveTab}`
                            ) +
                            "]"
                        ) &&
                        JSON.parse(
                          "[" +
                            localStorage.getItem(
                              `followupStatus-${props.customActiveTab}`
                            ) +
                            "]"
                        ).includes(item.id)
                      }
                    />{" "}
                    <span className="ms-2 fw-normal text-muted">
                      {item.name}
                    </span>
                  </tr>
                )
              )}
          </div>
          <button
            className="btn btn-primary"
            onClick={e => props.getSearchedLeads(selectedId, "f-status")}
          >
            <i className="fa fa-search me-2"></i>
            Search
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={e => {
              props.getSearchedLeads("", "f-status"), removeIds()
            }}
          >
            <i className="fa fa-undo me-2"></i>
            Refresh
          </button>
        </div>
      ) : (
        ""
      )}
      {props.a5f_followups_filter ? (
        <div className="filter-2">
          <div className="mb-3 body-height">
            {JSON.parse(localStorage.getItem("AllFollowups")) &&
              JSON.parse(localStorage.getItem("AllFollowups")).map(
                (item, i) => (
                  <tr key={i}>
                    <input
                      onChange={e => getSelectedId(item.id, e)}
                      className=""
                      type="checkbox"
                      name="f-status"
                      defaultChecked={
                        JSON.parse(
                          "[" +
                            localStorage.getItem(
                              `followupStatus-${props.customActiveTab}`
                            ) +
                            "]"
                        ) &&
                        JSON.parse(
                          "[" +
                            localStorage.getItem(
                              `followupStatus-${props.customActiveTab}`
                            ) +
                            "]"
                        ).includes(item.id)
                      }
                    />{" "}
                    <span className="ms-2 fw-normal text-muted">
                      {item.name}
                    </span>
                  </tr>
                )
              )}
          </div>
          <button
            className="btn btn-primary"
            onClick={e => props.getSearchedLeads(selectedId, "f-status")}
          >
            <i className="fa fa-search me-2"></i>
            Search
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={e => {
              props.getSearchedLeads("", "f-status"), removeIds()
            }}
          >
            <i className="fa fa-undo me-2"></i>
            Refresh
          </button>
        </div>
      ) : (
        ""
      )}
      {props.dead_filter ? (
        <div className="filter-2">
          <div className="mb-3 body-height">
            {props.data &&
              props.data.map((item, i) => (
                <tr key={i}>
                  <input
                    onChange={e => getSelectedId(item.id, e)}
                    className=""
                    type="checkbox"
                    name="d-status"
                    defaultChecked={
                      JSON.parse(
                        "[" + localStorage.getItem("deadStatus") + "]"
                      ) &&
                      JSON.parse(
                        "[" + localStorage.getItem("deadStatus") + "]"
                      ).includes(item.id)
                    }
                  />{" "}
                  <span className="ms-2 fw-normal text-muted">
                    {item.reason}
                  </span>
                </tr>
              ))}
          </div>
          <button
            className="btn btn-primary"
            onClick={e => props.getSearchedLeads(selectedId, "d-status")}
          >
            <i className="fa fa-search me-2"></i>
            Search
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={e => {
              props.getSearchedLeads("", "d-status"), removeIds()
            }}
          >
            <i className="fa fa-undo me-2"></i>
            Refresh
          </button>
        </div>
      ) : (
        ""
      )}
      {props.day_filter ? (
        <div className="filter-2">
          <div className="mb-3 body-height">
            <tr>
              <input
                onChange={e => getname("1")}
                className=""
                type="checkbox"
                name="day"
                defaultChecked={
                  localStorage.getItem("leadDay") &&
                  localStorage.getItem("leadDay").includes("1")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">Monday</span>
            </tr>
            <tr>
              <input
                onChange={e => getname("2")}
                className=""
                type="checkbox"
                name="day"
                defaultChecked={
                  localStorage.getItem("leadDay") &&
                  localStorage.getItem("leadDay").includes("2")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">Tuesday</span>
            </tr>
            <tr>
              <input
                onChange={e => getname("3")}
                className=""
                type="checkbox"
                name="day"
                defaultChecked={
                  localStorage.getItem("leadDay") &&
                  localStorage.getItem("leadDay").includes("3")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">Wednesday</span>
            </tr>
            <tr>
              <input
                onChange={e => getname("4")}
                className=""
                type="checkbox"
                name="day"
                defaultChecked={
                  localStorage.getItem("leadDay") &&
                  localStorage.getItem("leadDay").includes("4")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">Thrusday</span>
            </tr>
            <tr>
              <input
                onChange={e => getname("5")}
                className=""
                type="checkbox"
                name="day"
                defaultChecked={
                  localStorage.getItem("leadDay") &&
                  localStorage.getItem("leadDay").includes("5")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">Friday</span>
            </tr>
            <tr>
              <input
                onChange={e => getname("6")}
                className=""
                type="checkbox"
                name="day"
                defaultChecked={
                  localStorage.getItem("leadDay") &&
                  localStorage.getItem("leadDay").includes("6")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">Saturday</span>
            </tr>
            <tr>
              <input
                onChange={e => getname("7")}
                className=""
                type="checkbox"
                name="day"
                defaultChecked={
                  localStorage.getItem("leadDay") &&
                  localStorage.getItem("leadDay").includes("7")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">Sunday</span>
            </tr>
          </div>
          <button
            className="btn btn-primary"
            onClick={e => props.getSearchedLeads(selectedName, "day")}
          >
            <i className="fa fa-search me-2"></i>
            Search
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={e => {
              props.getSearchedLeads("", "day"), removeName()
            }}
          >
            <i className="fa fa-undo me-2"></i>
            Refresh
          </button>
        </div>
      ) : (
        ""
      )}
      {props.action_filter ? (
        <div className="filter-2">
          <div className="mb-3 body-height">
            <tr>
              <input
                onChange={e => getname("call")}
                className=""
                type="checkbox"
                name="action"
                defaultChecked={
                  localStorage.getItem("leadAction") &&
                  localStorage.getItem("leadAction").includes("call")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">Call</span>
            </tr>
            <tr>
              <input
                onChange={e => getname("meeting")}
                className=""
                type="checkbox"
                name="action"
                defaultChecked={
                  localStorage.getItem("leadAction") &&
                  localStorage.getItem("leadAction").includes("meeting")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">Meeting</span>
            </tr>
            <tr>
              <input
                onChange={e => getname("update")}
                className=""
                type="checkbox"
                name="action"
                defaultChecked={
                  localStorage.getItem("leadAction") &&
                  localStorage.getItem("leadAction").includes("update")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">Update</span>
            </tr>
            <tr>
              <input
                onChange={e => getname("whatsapp")}
                className=""
                type="checkbox"
                name="action"
                defaultChecked={
                  localStorage.getItem("leadAction") &&
                  localStorage.getItem("leadAction").includes("whatsapp")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">Whatsapp</span>
            </tr>
          </div>
          <button
            className="btn btn-primary"
            onClick={e => props.getSearchedLeads(selectedName, "action")}
          >
            <i className="fa fa-search me-2"></i>
            Search
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={e => {
              props.getSearchedLeads("", "action"), removeName()
            }}
          >
            <i className="fa fa-undo me-2"></i>
            Refresh
          </button>
        </div>
      ) : (
        ""
      )}
      {props.status_filter ? (
        <div className="filter-2">
          <div className="mb-3 body-height">
            <tr>
              <input
                onChange={e => getname("new")}
                className=""
                type="checkbox"
                name="status"
                defaultChecked={
                  localStorage.getItem("leadStatus") &&
                  localStorage.getItem("leadStatus").includes("new")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">Untouched</span>
            </tr>
            <tr>
              <input
                onChange={e => getname("followup")}
                className=""
                type="checkbox"
                name="status"
                defaultChecked={
                  localStorage.getItem("leadStatus") &&
                  localStorage.getItem("leadStatus").includes("followup")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">Followup</span>
            </tr>
            <tr>
              <input
                onChange={e => getname("matured")}
                className=""
                type="checkbox"
                name="status"
                defaultChecked={
                  localStorage.getItem("leadStatus") &&
                  localStorage.getItem("leadStatus").includes("matured")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">Matured</span>
            </tr>
            <tr>
              <input
                onChange={e => getname("repeated")}
                className=""
                type="checkbox"
                name="status"
                defaultChecked={
                  localStorage.getItem("leadStatus") &&
                  localStorage.getItem("leadStatus").includes("repeated")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">Repeated</span>
            </tr>
            <tr>
              <input
                onChange={e => getname("dead_request")}
                className=""
                type="checkbox"
                name="status"
                defaultChecked={
                  localStorage.getItem("leadStatus") &&
                  localStorage.getItem("leadStatus").includes("dead_request")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">Dead Request</span>
            </tr>
            <tr>
              <input
                onChange={e => getname("dead")}
                className=""
                type="checkbox"
                name="status"
                defaultChecked={
                  localStorage.getItem("leadStatus") &&
                  localStorage.getItem("leadStatus").includes("dead")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">Dead</span>
            </tr>
          </div>
          <button
            className="btn btn-primary"
            onClick={e => props.getSearchedLeads(selectedName, "status")}
          >
            <i className="fa fa-search me-2"></i>
            Search
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={e => {
              props.getSearchedLeads("", "status"), removeName()
            }}
          >
            <i className="fa fa-undo me-2"></i>
            Refresh
          </button>
        </div>
      ) : (
        ""
      )}
      {props.status2_filter ? (
        <div className="filter-2">
          <div className="mb-3 body-height">
            <tr>
              <input
                onChange={e => getname("1")}
                className=""
                type="checkbox"
                name="status2"
                defaultChecked={
                  localStorage.getItem("leadStatus2") &&
                  localStorage.getItem("leadStatus2").includes("1")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">Active</span>
            </tr>
            <tr>
              <input
                onChange={e => getname("0")}
                className=""
                type="checkbox"
                name="status2"
                defaultChecked={
                  localStorage.getItem("leadStatus2") &&
                  localStorage.getItem("leadStatus2").includes("0")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">Blocked</span>
            </tr>
          </div>
          <button
            className="btn btn-primary"
            onClick={e => props.getSearchedLeads(selectedName, "status2")}
          >
            <i className="fa fa-search me-2"></i>
            Search
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={e => {
              props.getSearchedLeads("", "status2"), removeName()
            }}
          >
            <i className="fa fa-undo me-2"></i>
            Refresh
          </button>
        </div>
      ) : (
        ""
      )}
      {props.status3_filter ? (
        <div className="filter-2">
          <div className="mb-3 body-height">
            <tr>
              <input
                onChange={e => getname("1")}
                className=""
                type="checkbox"
                name="status3"
                defaultChecked={
                  localStorage.getItem("leadStatus3") &&
                  localStorage.getItem("leadStatus3").includes("1")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">Yes</span>
            </tr>
            <tr>
              <input
                onChange={e => getname("0")}
                className=""
                type="checkbox"
                name="status3"
                defaultChecked={
                  localStorage.getItem("leadStatus3") &&
                  localStorage.getItem("leadStatus3").includes("0")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">No</span>
            </tr>
          </div>
          <button
            className="btn btn-primary"
            onClick={e => props.getSearchedLeads(selectedName, "status3")}
          >
            <i className="fa fa-search me-2"></i>
            Search
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={e => {
              props.getSearchedLeads("", "status3"), removeName()
            }}
          >
            <i className="fa fa-undo me-2"></i>
            Refresh
          </button>
        </div>
      ) : (
        ""
      )}
      {props.status4_filter ? (
        <div className="filter-2">
          <div className="mb-3 body-height">
            <tr>
              <input
                onChange={e => getname("outstanding")}
                className=""
                type="checkbox"
                name="status4"
                defaultChecked={
                  localStorage.getItem("ledgerType") &&
                  localStorage.getItem("ledgerType").includes("outstanding")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">Outstanding</span>
            </tr>
            <tr>
              <input
                onChange={e => getname("paid")}
                className=""
                type="checkbox"
                name="status4"
                defaultChecked={
                  localStorage.getItem("ledgerType") &&
                  localStorage.getItem("ledgerType").includes("paid")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">Paid</span>
            </tr>
          </div>
          <button
            className="btn btn-primary"
            onClick={e => props.getSearchedLeads(selectedName, "status4")}
          >
            <i className="fa fa-search me-2"></i>
            Search
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={e => {
              props.getSearchedLeads("", "status4"), removeName()
            }}
          >
            <i className="fa fa-undo me-2"></i>
            Refresh
          </button>
        </div>
      ) : (
        ""
      )}
      {props.status5_filter ? (
        <div className="filter-2">
          <div className="mb-3 body-height">
            <tr>
              <input
                onChange={e => getname("fixed")}
                className=""
                type="checkbox"
                name="status5"
                defaultChecked={
                  localStorage.getItem("offerType") &&
                  localStorage.getItem("offerType").includes("fixed")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">Fixed</span>
            </tr>
            <tr>
              <input
                onChange={e => getname("percentage")}
                className=""
                type="checkbox"
                name="status5"
                defaultChecked={
                  localStorage.getItem("offerType") &&
                  localStorage.getItem("offerType").includes("percentage")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">Percentage</span>
            </tr>
          </div>
          <button
            className="btn btn-primary"
            onClick={e => props.getSearchedLeads(selectedName, "status5")}
          >
            <i className="fa fa-search me-2"></i>
            Search
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={e => {
              props.getSearchedLeads("", "status5"), removeName()
            }}
          >
            <i className="fa fa-undo me-2"></i>
            Refresh
          </button>
        </div>
      ) : (
        ""
      )}
      {props.status6_filter ? (
        <div className="filter-2">
          <div className="mb-3 body-height">
            <tr>
              <input
                onChange={e => getname("0")}
                className=""
                type="checkbox"
                name="status6"
                defaultChecked={
                  localStorage.getItem("PrductGST") &&
                  localStorage.getItem("PrductGST").includes("0")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">0%</span>
            </tr>
            <tr>
              <input
                onChange={e => getname("0.25")}
                className=""
                type="checkbox"
                name="status6"
                defaultChecked={
                  localStorage.getItem("PrductGST") &&
                  localStorage.getItem("PrductGST").includes("0.25")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">0.25%</span>
            </tr>
            <tr>
              <input
                onChange={e => getname("5")}
                className=""
                type="checkbox"
                name="status6"
                defaultChecked={
                  localStorage.getItem("PrductGST") &&
                  localStorage.getItem("PrductGST").includes("5")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">5%</span>
            </tr>
            <tr>
              <input
                onChange={e => getname("12")}
                className=""
                type="checkbox"
                name="status6"
                defaultChecked={
                  localStorage.getItem("PrductGST") &&
                  localStorage.getItem("PrductGST").includes("12")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">12%</span>
            </tr>
            <tr>
              <input
                onChange={e => getname("18")}
                className=""
                type="checkbox"
                name="status6"
                defaultChecked={
                  localStorage.getItem("PrductGST") &&
                  localStorage.getItem("PrductGST").includes("18")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">18%</span>
            </tr>
            <tr>
              <input
                onChange={e => getname("28")}
                className=""
                type="checkbox"
                name="status6"
                defaultChecked={
                  localStorage.getItem("PrductGST") &&
                  localStorage.getItem("PrductGST").includes("28")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">28%</span>
            </tr>
          </div>
          <button
            className="btn btn-primary"
            onClick={e => props.getSearchedLeads(selectedName, "status6")}
          >
            <i className="fa fa-search me-2"></i>
            Search
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={e => {
              props.getSearchedLeads("", "status6"), removeName()
            }}
          >
            <i className="fa fa-undo me-2"></i>
            Refresh
          </button>
        </div>
      ) : (
        ""
      )}
      {props.status7_filter ? (
        <div className="filter-2">
          <div className="mb-3 body-height">
            <tr>
              <input
                onChange={e => getname("accepted")}
                className=""
                type="checkbox"
                name="status7"
                defaultChecked={
                  localStorage.getItem("OrderStatus") &&
                  localStorage.getItem("OrderStatus").includes("accepted")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">Accepted</span>
            </tr>
            <tr>
              <input
                onChange={e => getname("pending")}
                className=""
                type="checkbox"
                name="status7"
                defaultChecked={
                  localStorage.getItem("OrderStatus") &&
                  localStorage.getItem("OrderStatus").includes("pending")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">Pending</span>
            </tr>
            <tr>
              <input
                onChange={e => getname("cancelled")}
                className=""
                type="checkbox"
                name="status7"
                defaultChecked={
                  localStorage.getItem("OrderStatus") &&
                  localStorage.getItem("OrderStatus").includes("cancelled")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">Cancelled</span>
            </tr>
          </div>
          <button
            className="btn btn-primary"
            onClick={e => props.getSearchedLeads(selectedName, "status7")}
          >
            <i className="fa fa-search me-2"></i>
            Search
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={e => {
              props.getSearchedLeads("", "status7"), removeName()
            }}
          >
            <i className="fa fa-undo me-2"></i>
            Refresh
          </button>
        </div>
      ) : (
        ""
      )}
      {props.status8_filter ? (
        <div className="filter-2">
          <div className="mb-3 body-height">
            <tr>
              <input
                onChange={e => getname("paid")}
                className=""
                type="checkbox"
                name="status8"
                defaultChecked={
                  localStorage.getItem("OrderPstatus") &&
                  localStorage.getItem("OrderPstatus").includes("paid")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">Paid</span>
            </tr>
            <tr>
              <input
                onChange={e => getname("p_paid")}
                className=""
                type="checkbox"
                name="status8"
                defaultChecked={
                  localStorage.getItem("OrderPstatus") &&
                  localStorage.getItem("OrderPstatus").includes("p_paid")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">Partially paid</span>
            </tr>
            <tr>
              <input
                onChange={e => getname("unpaid")}
                className=""
                type="checkbox"
                name="status8"
                defaultChecked={
                  localStorage.getItem("OrderPstatus") &&
                  localStorage.getItem("OrderPstatus").includes("unpaid")
                }
              />{" "}
              <span className="ms-2 fw-normal text-muted">Unpaid</span>
            </tr>
          </div>
          <button
            className="btn btn-primary"
            onClick={e => props.getSearchedLeads(selectedName, "status8")}
          >
            <i className="fa fa-search me-2"></i>
            Search
          </button>
          <button
            className="btn btn-secondary float-end text-dark"
            onClick={e => {
              props.getSearchedLeads("", "status8"), removeName()
            }}
          >
            <i className="fa fa-undo me-2"></i>
            Refresh
          </button>
        </div>
      ) : (
        ""
      )}
    </div>
  )
}

export default MultipleChoice
