import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import Loader from "../../Loader/loader.js"
import {
   Container,
   Row,
   Col,
   Card,
   CardBody,
   CardTitle,
   CardSubtitle,
   Modal,
   Nav,
   NavItem,
   NavLink,
   TabContent,
   TabPane,
   CardText,
   InputGroup,
   Label,
   FormGroup,
   Button,
} from "reactstrap"
import axios from "axios"
import {
   AvForm,
   AvField,
   AvCheckboxGroup,
   AvCheckbox,
} from "availity-reactstrap-validation"
import classnames from "classnames"
import { getCurrentUser, getPermission } from "../../../helpers/Utils"
import { Link, useLocation, useHistory } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

let selectedId

const InputFilter = props => {
   const getSelectedId = e => {
      selectedId = e
   }

   return (
      <div>
         {props.id_filter ? (
            <div className="filter-1">
               <AvForm onValidSubmit={e => props.getSearchedLeads(selectedId, "id")}>
                  <div className="mb-3">
                     <input
                        onChange={e => getSelectedId(e.target.value)}
                        className="form-control"
                        type="text"
                        name="id"
                        defaultValue={
                           localStorage.getItem("leadID") &&
                           localStorage.getItem("leadID")
                        }
                     />
                  </div>

                  <button className="btn btn-primary" type="submit">
                     <i className="fa fa-search me-2"></i>
                     Search
                  </button>
                  <button
                     className="btn btn-secondary float-end text-dark"
                     type="button"
                     onClick={e => props.getSearchedLeads("", "id")}
                  >
                     <i className="fa fa-undo me-2"></i>
                     Refresh
                  </button>
               </AvForm>
            </div>
         ) : (
            ""
         )}
         {props.name_filter ? (
            <div className="filter-1">
               <AvForm
                  onValidSubmit={e => props.getSearchedLeads(selectedId, "name")}
               >
                  <div className="mb-3">
                     <input
                        onChange={e => getSelectedId(e.target.value)}
                        className="form-control"
                        type="text"
                        name="name"
                        defaultValue={
                           localStorage.getItem("leadName") &&
                           localStorage.getItem("leadName")
                        }
                     />
                  </div>

                  <button
                     className="btn btn-primary"
                     type="submit"
                  // onClick={(e) => props.getSearchedLeads(selectedId, "name")}
                  >
                     <i className="fa fa-search me-2"></i>
                     Search
                  </button>
                  <button
                     className="btn btn-secondary float-end text-dark"
                     type="button"
                     onClick={e => props.getSearchedLeads("", "name")}
                  >
                     <i className="fa fa-undo me-2"></i>
                     Refresh
                  </button>
               </AvForm>
            </div>
         ) : (
            ""
         )}
         {props.af_name_filter ? (
            <div className="filter-1">
               <AvForm
                  onValidSubmit={e => props.getSearchedLeads(selectedId, "name")}
               >
                  <div className="mb-3">
                     <input
                        onChange={e => getSelectedId(e.target.value)}
                        className="form-control"
                        type="text"
                        name="name"
                        defaultValue={
                           localStorage.getItem(`leadName-${props.customActiveTab}`) &&
                           localStorage.getItem(`leadName-${props.customActiveTab}`)
                        }
                     />
                  </div>

                  <button
                     className="btn btn-primary"
                     type="submit"
                  // onClick={(e) => props.getSearchedLeads(selectedId, "name")}
                  >
                     <i className="fa fa-search me-2"></i>
                     Search
                  </button>
                  <button
                     className="btn btn-secondary float-end text-dark"
                     type="button"
                     onClick={e => props.getSearchedLeads("", "name")}
                  >
                     <i className="fa fa-undo me-2"></i>
                     Refresh
                  </button>
               </AvForm>
            </div>
         ) : (
            ""
         )}
         {props.af2_name_filter ? (
            <div className="filter-1">
               <AvForm
                  onValidSubmit={e => props.getSearchedLeads(selectedId, "name")}
               >
                  <div className="mb-3">
                     <input
                        onChange={e => getSelectedId(e.target.value)}
                        className="form-control"
                        type="text"
                        name="name"
                        defaultValue={
                           localStorage.getItem(`leadName-${props.customActiveTab}`) &&
                           localStorage.getItem(`leadName-${props.customActiveTab}`)
                        }
                     />
                  </div>

                  <button
                     className="btn btn-primary"
                     type="submit"
                  // onClick={(e) => props.getSearchedLeads(selectedId, "name")}
                  >
                     <i className="fa fa-search me-2"></i>
                     Search
                  </button>
                  <button
                     className="btn btn-secondary float-end text-dark"
                     type="button"
                     onClick={e => props.getSearchedLeads("", "name")}
                  >
                     <i className="fa fa-undo me-2"></i>
                     Refresh
                  </button>
               </AvForm>
            </div>
         ) : (
            ""
         )}
         {props.af3_name_filter ? (
            <div className="filter-1">
               <AvForm
                  onValidSubmit={e => props.getSearchedLeads(selectedId, "name")}
               >
                  <div className="mb-3">
                     <input
                        onChange={e => getSelectedId(e.target.value)}
                        className="form-control"
                        type="text"
                        name="name"
                        defaultValue={
                           localStorage.getItem(`leadName-${props.customActiveTab}`) &&
                           localStorage.getItem(`leadName-${props.customActiveTab}`)
                        }
                     />
                  </div>

                  <button
                     className="btn btn-primary"
                     type="submit"
                  // onClick={(e) => props.getSearchedLeads(selectedId, "name")}
                  >
                     <i className="fa fa-search me-2"></i>
                     Search
                  </button>
                  <button
                     className="btn btn-secondary float-end text-dark"
                     type="button"
                     onClick={e => props.getSearchedLeads("", "name")}
                  >
                     <i className="fa fa-undo me-2"></i>
                     Refresh
                  </button>
               </AvForm>
            </div>
         ) : (
            ""
         )}
         {props.af4_name_filter ? (
            <div className="filter-1">
               <AvForm
                  onValidSubmit={e => props.getSearchedLeads(selectedId, "name")}
               >
                  <div className="mb-3">
                     <input
                        onChange={e => getSelectedId(e.target.value)}
                        className="form-control"
                        type="text"
                        name="name"
                        defaultValue={
                           localStorage.getItem(`leadName-${props.customActiveTab}`) &&
                           localStorage.getItem(`leadName-${props.customActiveTab}`)
                        }
                     />
                  </div>

                  <button
                     className="btn btn-primary"
                     type="submit"
                  // onClick={(e) => props.getSearchedLeads(selectedId, "name")}
                  >
                     <i className="fa fa-search me-2"></i>
                     Search
                  </button>
                  <button
                     className="btn btn-secondary float-end text-dark"
                     type="button"
                     onClick={e => props.getSearchedLeads("", "name")}
                  >
                     <i className="fa fa-undo me-2"></i>
                     Refresh
                  </button>
               </AvForm>
            </div>
         ) : (
            ""
         )}
         {props.af5_name_filter ? (
            <div className="filter-1">
               <AvForm
                  onValidSubmit={e => props.getSearchedLeads(selectedId, "name")}
               >
                  <div className="mb-3">
                     <input
                        onChange={e => getSelectedId(e.target.value)}
                        className="form-control"
                        type="text"
                        name="name"
                        defaultValue={
                           localStorage.getItem(`leadName-${props.customActiveTab}`) &&
                           localStorage.getItem(`leadName-${props.customActiveTab}`)
                        }
                     />
                  </div>

                  <button
                     className="btn btn-primary"
                     type="submit"
                  // onClick={(e) => props.getSearchedLeads(selectedId, "name")}
                  >
                     <i className="fa fa-search me-2"></i>
                     Search
                  </button>
                  <button
                     className="btn btn-secondary float-end text-dark"
                     type="button"
                     onClick={e => props.getSearchedLeads("", "name")}
                  >
                     <i className="fa fa-undo me-2"></i>
                     Refresh
                  </button>
               </AvForm>
            </div>
         ) : (
            ""
         )}
         {props.email_filter ? (
            <div className="filter-1">
               <AvForm
                  onValidSubmit={e => props.getSearchedLeads(selectedId, "email")}
               >
                  <div className="mb-3">
                     <input
                        onChange={e => getSelectedId(e.target.value)}
                        className="form-control"
                        type="text"
                        name="email"
                        defaultValue={
                           localStorage.getItem("leadEmail") &&
                           localStorage.getItem("leadEmail")
                        }
                     />
                  </div>

                  <button className="btn btn-primary" type="submit">
                     <i className="fa fa-search me-2"></i>
                     Search
                  </button>
                  <button
                     className="btn btn-secondary float-end text-dark"
                     type="button"
                     onClick={e => props.getSearchedLeads("", "email")}
                  >
                     <i className="fa fa-undo me-2"></i>
                     Refresh
                  </button>
               </AvForm>
            </div>
         ) : (
            ""
         )}
         {props.latestmessage_filter ? (
            <div className="filter-1">
               <AvForm
                  onValidSubmit={e => props.getSearchedLeads(selectedId, "latMsg")}
               >
                  <div className="mb-3">
                     <input
                        onChange={e => getSelectedId(e.target.value)}
                        className="form-control"
                        type="text"
                        name="latestmessage"
                        defaultValue={
                           localStorage.getItem("latestMessage") &&
                           localStorage.getItem("latestMessage")
                        }
                     />
                  </div>

                  <button className="btn btn-primary" type="submit">
                     <i className="fa fa-search me-2"></i>
                     Search
                  </button>
                  <button
                     className="btn btn-secondary float-end text-dark"
                     type="button"
                     onClick={e => props.getSearchedLeads("", "latMsg")}
                  >
                     <i className="fa fa-undo me-2"></i>
                     Refresh
                  </button>
               </AvForm>
            </div>
         ) : (
            ""
         )}
         {props.contact_filter ? (
            <div className="filter-1">
               <AvForm
                  onValidSubmit={e => props.getSearchedLeads(selectedId, "contact")}
               >
                  <div className="mb-3">
                     <input
                        onChange={e => getSelectedId(e.target.value)}
                        className="form-control"
                        type="text"
                        name="contact"
                        defaultValue={
                           localStorage.getItem("leadContact") &&
                           localStorage.getItem("leadContact")
                        }
                     />
                  </div>

                  <button className="btn btn-primary" type="submit">
                     <i className="fa fa-search me-2"></i>
                     Search
                  </button>
                  <button
                     className="btn btn-secondary float-end text-dark"
                     type="button"
                     onClick={e => props.getSearchedLeads("", "contact")}
                  >
                     <i className="fa fa-undo me-2"></i>
                     Refresh
                  </button>
               </AvForm>
            </div>
         ) : (
            ""
         )}
         {props.af_contact_filter ? (
            <div className="filter-1">
               <AvForm
                  onValidSubmit={e => props.getSearchedLeads(selectedId, "contact")}
               >
                  <div className="mb-3">
                     <input
                        onChange={e => getSelectedId(e.target.value)}
                        className="form-control"
                        type="text"
                        name="contact"
                        defaultValue={
                           localStorage.getItem(`leadContact-${props.customActiveTab}`) &&
                           localStorage.getItem(`leadContact-${props.customActiveTab}`)
                        }
                     />
                  </div>

                  <button className="btn btn-primary" type="submit">
                     <i className="fa fa-search me-2"></i>
                     Search
                  </button>
                  <button
                     className="btn btn-secondary float-end text-dark"
                     type="button"
                     onClick={e => props.getSearchedLeads("", "contact")}
                  >
                     <i className="fa fa-undo me-2"></i>
                     Refresh
                  </button>
               </AvForm>
            </div>
         ) : (
            ""
         )}
         {props.af2_contact_filter ? (
            <div className="filter-1">
               <AvForm
                  onValidSubmit={e => props.getSearchedLeads(selectedId, "contact")}
               >
                  <div className="mb-3">
                     <input
                        onChange={e => getSelectedId(e.target.value)}
                        className="form-control"
                        type="text"
                        name="contact"
                        defaultValue={
                           localStorage.getItem(`leadContact-${props.customActiveTab}`) &&
                           localStorage.getItem(`leadContact-${props.customActiveTab}`)
                        }
                     />
                  </div>

                  <button className="btn btn-primary" type="submit">
                     <i className="fa fa-search me-2"></i>
                     Search
                  </button>
                  <button
                     className="btn btn-secondary float-end text-dark"
                     type="button"
                     onClick={e => props.getSearchedLeads("", "contact")}
                  >
                     <i className="fa fa-undo me-2"></i>
                     Refresh
                  </button>
               </AvForm>
            </div>
         ) : (
            ""
         )}
         {props.af3_contact_filter ? (
            <div className="filter-1">
               <AvForm
                  onValidSubmit={e => props.getSearchedLeads(selectedId, "contact")}
               >
                  <div className="mb-3">
                     <input
                        onChange={e => getSelectedId(e.target.value)}
                        className="form-control"
                        type="text"
                        name="contact"
                        defaultValue={
                           localStorage.getItem(`leadContact-${props.customActiveTab}`) &&
                           localStorage.getItem(`leadContact-${props.customActiveTab}`)
                        }
                     />
                  </div>

                  <button className="btn btn-primary" type="submit">
                     <i className="fa fa-search me-2"></i>
                     Search
                  </button>
                  <button
                     className="btn btn-secondary float-end text-dark"
                     type="button"
                     onClick={e => props.getSearchedLeads("", "contact")}
                  >
                     <i className="fa fa-undo me-2"></i>
                     Refresh
                  </button>
               </AvForm>
            </div>
         ) : (
            ""
         )}
         {props.af4_contact_filter ? (
            <div className="filter-1">
               <AvForm
                  onValidSubmit={e => props.getSearchedLeads(selectedId, "contact")}
               >
                  <div className="mb-3">
                     <input
                        onChange={e => getSelectedId(e.target.value)}
                        className="form-control"
                        type="text"
                        name="contact"
                        defaultValue={
                           localStorage.getItem(`leadContact-${props.customActiveTab}`) &&
                           localStorage.getItem(`leadContact-${props.customActiveTab}`)
                        }
                     />
                  </div>

                  <button className="btn btn-primary" type="submit">
                     <i className="fa fa-search me-2"></i>
                     Search
                  </button>
                  <button
                     className="btn btn-secondary float-end text-dark"
                     type="button"
                     onClick={e => props.getSearchedLeads("", "contact")}
                  >
                     <i className="fa fa-undo me-2"></i>
                     Refresh
                  </button>
               </AvForm>
            </div>
         ) : (
            ""
         )}
         {props.af5_contact_filter ? (
            <div className="filter-1">
               <AvForm
                  onValidSubmit={e => props.getSearchedLeads(selectedId, "contact")}
               >
                  <div className="mb-3">
                     <input
                        onChange={e => getSelectedId(e.target.value)}
                        className="form-control"
                        type="text"
                        name="contact"
                        defaultValue={
                           localStorage.getItem(`leadContact-${props.customActiveTab}`) &&
                           localStorage.getItem(`leadContact-${props.customActiveTab}`)
                        }
                     />
                  </div>

                  <button className="btn btn-primary" type="submit">
                     <i className="fa fa-search me-2"></i>
                     Search
                  </button>
                  <button
                     className="btn btn-secondary float-end text-dark"
                     type="button"
                     onClick={e => props.getSearchedLeads("", "contact")}
                  >
                     <i className="fa fa-undo me-2"></i>
                     Refresh
                  </button>
               </AvForm>
            </div>
         ) : (
            ""
         )}
         {props.message_filter ? (
            <div className="filter-1">
               <AvForm
                  onValidSubmit={e => props.getSearchedLeads(selectedId, "message")}
               >
                  <div className="mb-3">
                     <input
                        onChange={e => getSelectedId(e.target.value)}
                        className="form-control"
                        type="text"
                        name="message"
                        defaultValue={
                           localStorage.getItem("leadMessage") &&
                           localStorage.getItem("leadMessage")
                        }
                     />
                  </div>

                  <button className="btn btn-primary" type="submit">
                     <i className="fa fa-search me-2"></i>
                     Search
                  </button>
                  <button
                     className="btn btn-secondary float-end text-dark"
                     type="button"
                     onClick={e => props.getSearchedLeads("", "message")}
                  >
                     <i className="fa fa-undo me-2"></i>
                     Refresh
                  </button>
               </AvForm>
            </div>
         ) : (
            ""
         )}
         {props.af_message_filter ? (
            <div className="filter-1">
               <AvForm
                  onValidSubmit={e => props.getSearchedLeads(selectedId, "message")}
               >
                  <div className="mb-3">
                     <input
                        onChange={e => getSelectedId(e.target.value)}
                        className="form-control"
                        type="text"
                        name="message"
                        defaultValue={
                           localStorage.getItem(`leadMessage-${props.customActiveTab}`) &&
                           localStorage.getItem(`leadMessage-${props.customActiveTab}`)
                        }
                     />
                  </div>

                  <button className="btn btn-primary" type="submit">
                     <i className="fa fa-search me-2"></i>
                     Search
                  </button>
                  <button
                     className="btn btn-secondary float-end text-dark"
                     type="button"
                     onClick={e => props.getSearchedLeads("", "message")}
                  >
                     <i className="fa fa-undo me-2"></i>
                     Refresh
                  </button>
               </AvForm>
            </div>
         ) : (
            ""
         )}
         {props.af2_message_filter ? (
            <div className="filter-1">
               <AvForm
                  onValidSubmit={e => props.getSearchedLeads(selectedId, "message")}
               >
                  <div className="mb-3">
                     <input
                        onChange={e => getSelectedId(e.target.value)}
                        className="form-control"
                        type="text"
                        name="message"
                        defaultValue={
                           localStorage.getItem(`leadMessage-${props.customActiveTab}`) &&
                           localStorage.getItem(`leadMessage-${props.customActiveTab}`)
                        }
                     />
                  </div>

                  <button className="btn btn-primary" type="submit">
                     <i className="fa fa-search me-2"></i>
                     Search
                  </button>
                  <button
                     className="btn btn-secondary float-end text-dark"
                     type="button"
                     onClick={e => props.getSearchedLeads("", "message")}
                  >
                     <i className="fa fa-undo me-2"></i>
                     Refresh
                  </button>
               </AvForm>
            </div>
         ) : (
            ""
         )}
         {props.af3_message_filter ? (
            <div className="filter-1">
               <AvForm
                  onValidSubmit={e => props.getSearchedLeads(selectedId, "message")}
               >
                  <div className="mb-3">
                     <input
                        onChange={e => getSelectedId(e.target.value)}
                        className="form-control"
                        type="text"
                        name="message"
                        defaultValue={
                           localStorage.getItem(`leadMessage-${props.customActiveTab}`) &&
                           localStorage.getItem(`leadMessage-${props.customActiveTab}`)
                        }
                     />
                  </div>

                  <button className="btn btn-primary" type="submit">
                     <i className="fa fa-search me-2"></i>
                     Search
                  </button>
                  <button
                     className="btn btn-secondary float-end text-dark"
                     type="button"
                     onClick={e => props.getSearchedLeads("", "message")}
                  >
                     <i className="fa fa-undo me-2"></i>
                     Refresh
                  </button>
               </AvForm>
            </div>
         ) : (
            ""
         )}
         {props.af4_message_filter ? (
            <div className="filter-1">
               <AvForm
                  onValidSubmit={e => props.getSearchedLeads(selectedId, "message")}
               >
                  <div className="mb-3">
                     <input
                        onChange={e => getSelectedId(e.target.value)}
                        className="form-control"
                        type="text"
                        name="message"
                        defaultValue={
                           localStorage.getItem(`leadMessage-${props.customActiveTab}`) &&
                           localStorage.getItem(`leadMessage-${props.customActiveTab}`)
                        }
                     />
                  </div>

                  <button className="btn btn-primary" type="submit">
                     <i className="fa fa-search me-2"></i>
                     Search
                  </button>
                  <button
                     className="btn btn-secondary float-end text-dark"
                     type="button"
                     onClick={e => props.getSearchedLeads("", "message")}
                  >
                     <i className="fa fa-undo me-2"></i>
                     Refresh
                  </button>
               </AvForm>
            </div>
         ) : (
            ""
         )}
         {props.af5_message_filter ? (
            <div className="filter-1">
               <AvForm
                  onValidSubmit={e => props.getSearchedLeads(selectedId, "message")}
               >
                  <div className="mb-3">
                     <input
                        onChange={e => getSelectedId(e.target.value)}
                        className="form-control"
                        type="text"
                        name="message"
                        defaultValue={
                           localStorage.getItem(`leadMessage-${props.customActiveTab}`) &&
                           localStorage.getItem(`leadMessage-${props.customActiveTab}`)
                        }
                     />
                  </div>

                  <button className="btn btn-primary" type="submit">
                     <i className="fa fa-search me-2"></i>
                     Search
                  </button>
                  <button
                     className="btn btn-secondary float-end text-dark"
                     type="button"
                     onClick={e => props.getSearchedLeads("", "message")}
                  >
                     <i className="fa fa-undo me-2"></i>
                     Refresh
                  </button>
               </AvForm>
            </div>
         ) : (
            ""
         )}
         {props.amount_filter ? (
            <div className="filter-1">
               <AvForm
                  onValidSubmit={e => props.getSearchedLeads(selectedId, "amount")}
               >
                  <div className="mb-3">
                     <input
                        onChange={e => getSelectedId(e.target.value)}
                        className="form-control"
                        type="number"
                                             onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                        min="0"
                        name="amount"
                        defaultValue={
                           localStorage.getItem("leadAmount") &&
                           localStorage.getItem("leadAmount")
                        }
                     />
                  </div>

                  <button className="btn btn-primary" type="submit">
                     <i className="fa fa-search me-2"></i>
                     Search
                  </button>
                  <button
                     className="btn btn-secondary float-end text-dark"
                     type="button"
                     onClick={e => props.getSearchedLeads("", "amount")}
                  >
                     <i className="fa fa-undo me-2"></i>
                     Refresh
                  </button>
               </AvForm>
            </div>
         ) : (
            ""
         )}
      </div>
   )
}

export default InputFilter
