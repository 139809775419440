import React, { useState, useEffect } from "react"
import axios from "axios"
import { Modal } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { getCurrentUser } from "../../../helpers/Utils"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import MultipleValueTextInput from 'react-multivalue-text-input';


export default function LabelModal(props) {
   const [loading, setLoading] = useState(false)
   const [allCheck, setAllCheck] = useState([])


   const submitLabel = (e, value) => {
      setLoading(true)
      const api = axios.create();
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      api.interceptors.request.use(config => {
         config.cancelToken = source.token;
         return config;
      });

      const timeout = 120000; // 2 minute

      setTimeout(() => {
         source.cancel('Timeout');
      }, timeout);
      if (props.label !== undefined) {
         axios
            .put(
               `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
                  "company_name"
               )}/lead/label/${props.label.id}?name=${value.name}&iver_code=${getCurrentUser()?.ivr_webhook_url !== null ? value.ivr_code : null}`,
               {},
               {
                  headers: {
                     Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
                  },
               }
            )
            .then(function (res) {
               if (res.data.status) {
                  setLoading(false)
                  props.setIsOpen(false)
                  props.setmodal_lead_label(false)
                  props.setLabel(res.data.data)
                  toast.success(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               } else {
                  setLoading(false)
                  toast.error(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               }
            })
      } else {
         axios
            .post(
               `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
                  "company_name"
               )}/lead/label`,
               {
                  name: allCheck,
                  ivr_code: getCurrentUser()?.ivr_webhook_url !== null ? value.ivr_code : null
               },
               {
                  headers: {
                     Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
                  },
               }
            )
            .then(function (res) {
               if (res.data.status) {
                  setLoading(false)
                  props.getAllLabels()
                  props.setmodal_lead_label(false)
                  toast.success(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               } else {
                  setLoading(false)
                  toast.error(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               }
            })
      }
   }

   useEffect(() => {
      const close = e => {
         if (e.key === "Escape") {
            props.setmodal_lead_label(false)
         }
      }
      window.addEventListener("keydown", close)
      return () => window.removeEventListener("keydown", close)
   }, [])

   return (
      <>
         <Modal isOpen={props.modal_lead_label} centered={true}>
            <AvForm
               className="modal-title mt-0"
               id="myModalLabel"
               onValidSubmit={(e, v) => {
                  submitLabel(e, v)
               }}
            >
               <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myLargeModalLabel">
                     {props.label !== undefined ? "Edit Label" : "Create Label"}
                  </h5>
                  <button
                     type="button"
                     className="cross__btn"
                     onClick={() => props.setmodal_lead_label(false)}
                     aria-hidden="true"
                  >
                     &times;
                  </button>
               </div>
               <div className="modal-body">
                  {props.label !== undefined ? (
                     <>
                        <div className="mb-3">
                           <AvField
                              label="Name"
                              type="text"
                              name="name"
                              value={props.label && props.label.name}
                           />
                        </div>
                        {getCurrentUser()?.ivr_webhook_url !== null ?
                           <div className="mb-3">
                              <AvField
                                 label="IVR Code"
                                 type="number"
                                 name="ivr_code"
                                 value={props.label?.ivr_code}
                              />
                           </div>
                           :
                           ""
                        }
                     </>
                  ) : (
                     <>
                        <div className="mb-3">
                           <MultipleValueTextInput
                              required
                              onItemAdded={(item, allItems) => console.log(`Item added: ${item, allItems}`,
                                 setAllCheck(allItems)
                              )}
                              onItemDeleted={(item, allItems) => console.log(`Item removed: ${item}`,
                                 setAllCheck(allItems)
                              )}
                              label="Add Lead Label(s)"
                              name="value"
                              className="form-control"
                              placeholder="Enter names you want"
                           />
                           <span className="fst-italic">Use comma (,) to insert a value.</span>
                        </div>
                        {getCurrentUser()?.ivr_webhook_url !== null ?
                           <div className="mb-3">
                              <AvField
                                 label="IVR Code"
                                 type="number"
                                 name="ivr_code"
                              // value={props.label?.ivr_code}
                              />
                           </div>
                           :
                           ""
                        }
                     </>
                  )}
                  <div className="modal-footer">
                     <div className="text-end">
                        {loading ? (
                           <button
                              className="btn btn-primary disabled"
                              type="button"
                              disabled
                           >
                              Loading
                           </button>
                        ) : (
                           <button
                              className="btn btn-primary"
                              type="submit"
                           // onClick={() => props.setmodal_lead_label(false)}
                           >
                              {props.label !== undefined ? "Submit" : "Create"}
                           </button>
                        )}
                     </div>
                  </div>
               </div>
            </AvForm>
         </Modal>
      </>
   )
}
