import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import axios from "axios"
import MetaTags from "react-meta-tags"
import {
   Container,
   Row,
   Col,
   Card,
   Alert,
   CardBody,
   Media,
   Button,
   Modal,
   Label,
   FormGroup,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { getCurrentUser, setCurrentUser } from "../../../helpers/Utils"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import file2 from "../../../assets/files/smtp.pdf"

const EmailSMTP = () => {
   const [smtpDetails, setsmtpDetails] = useState("")
   const [loading, setLoading] = useState(false)

   const downloadPDF2 = () => {
      saveAs(file2, "smtp_setup.pdf")
   }

   const smtpDetail = (e, value) => {
      setLoading(true)
      const api = axios.create()
      const CancelToken = axios.CancelToken
      const source = CancelToken.source()

      api.interceptors.request.use(config => {
         config.cancelToken = source.token
         return config
      })

      const timeout = 120000 // 2 minute

      setTimeout(() => {
         source.cancel("Timeout")
      }, timeout)
      if (smtpDetails.id !== undefined) {
         axios
            .put(
               `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
                  "company_name"
               )}/smtp-detail/${smtpDetails.id}`,
               {
                  // host: value.host,
                  // port: value.port,
                  username: value.username_smtp,
                  password: value.password_smtp,
                  // encryption: value.encryption,
                  // cc_email: value.cc_email,
               },
               {
                  headers: {
                     Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                        }`,
                  },
               }
            )
            .then(function (res) {
               if (res.data.status) {
                  setLoading(false)
                  setsmtpDetails(res.data.data)
                  toast.success(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
                  window.history.go(-1)
               } else {
                  setLoading(false)
                  toast.error(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               }
            })
      } else {
         axios
            .post(
               `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
                  "company_name"
               )}/smtp-detail`,
               {
                  // host: value.host,
                  // port: value.port,
                  username: value.username_smtp,
                  password: value.password_smtp,
                  // encryption: value.encryption,
                  // cc_email: value.cc_email,
               },
               {
                  headers: {
                     Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                        }`,
                  },
               }
            )
            .then(function (res) {
               if (res.data.status) {
                  setLoading(false)
                  setsmtpDetails(res.data.data)
                  toast.success(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
                  window.history.go(-1)
               } else {
                  setLoading(false)
                  toast.error(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               }
            })
      }
   }

   const getSMTP = () => {
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/smtp-detail/${getCurrentUser().id}`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setsmtpDetails(res.data.data)
            } else {
               setError(res.data.message)
            }
         })
   }

   useEffect(() => {
      getSMTP()
   }, [])

   return (
      <>
         <div className="page-title-box">
            <Row className="align-items-center">
               <Col md={6}>
                  <h4 className="page-title">Connect Gmail</h4>
               </Col>
               <Col md={6}>
                  <h6 className="page-title float-end"></h6>
               </Col>
            </Row>
         </div>
         <Row>
            <Col lg="12">
               {getCurrentUser().role_name === "Administrator" ? (
                  <Card>
                     <CardBody>
                        <AvForm
                           className="card-title mt-0"
                           onValidSubmit={(e, v) => {
                              smtpDetail(e, v)
                           }}
                        >
                           <h4 className="card-title mb-4">SMTP Details (Gmail only)</h4>
                           <hr />
                           <Row>
                              {/* <Col md={4}>
                                       <div className="mb-3">
                                          <AvField
                                             label="Host"
                                             type="text"
                                             name="host"
                                             placeholder="Enter Host Name"
                                             value={smtpDetails?.host}
                                          />
                                       </div>
                                       <div className="mb-3">
                                          <AvField
                                             label="Port"
                                             type="text"
                                             name="port"
                                             placeholder="Enter Port Name"
                                             value={smtpDetails?.port}
                                          />
                                       </div>
                                    </Col> */}
                              <Col md={6}>
                                 <div className="mb-3">
                                    <AvField
                                       label="Username"
                                       type="text"
                                       name="username_smtp"
                                       placeholder="Enter Username"
                                       value={smtpDetails?.username}
                                    />
                                 </div>
                              </Col>
                              <Col md={6}>
                                 <div className="mb-3">
                                    <AvField
                                       label="Password"
                                       type="password"
                                       name="password_smtp"
                                       placeholder="Enter Password"
                                       value={smtpDetails?.password}
                                    />
                                 </div>
                              </Col>
                              {/* <Col md={4}>
                                       <div className="mb-3">
                                          <AvField
                                             label="Encryption"
                                             type="text"
                                             name="encryption"
                                             placeholder="Enter encryption"
                                             value={smtpDetails?.encryption}
                                          />
                                       </div>
                                       <div className="mb-3">
                                          <AvField
                                             label="Email for CC"
                                             type="email"
                                             name="cc_email"
                                             placeholder="Enter email to add in CC"
                                             value={smtpDetails?.cc_email}
                                          />
                                       </div> 
                                    </Col> */}
                              <p>
                                 Here is the pdf, how to set smtp.{" "}
                                 <span
                                    className="text-info"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => downloadPDF2()}
                                 >
                                    click here...
                                 </span>
                              </p>
                              <hr />
                           </Row>
                           <div className="text-center">
                              {loading ? (
                                 <button
                                    className="btn btn-primary disabled"
                                    type="button"
                                    disabled
                                 >
                                    Loading
                                 </button>
                              ) : (
                                 <button
                                    className="btn btn-primary"
                                    type="submit"
                                 // onClick={() => props.setmodal_dead_reason(false)}
                                 >
                                    Submit
                                 </button>
                              )}
                           </div>
                        </AvForm>
                     </CardBody>
                  </Card>
               ) : (
                  ""
               )}
            </Col>
         </Row>
      </>
   )
}

export default EmailSMTP
