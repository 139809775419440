import PropTypes from 'prop-types'
import React, { useState } from "react"
import MetaTags from 'react-meta-tags';
import {
	Container,
	Row,
	Col
} from "reactstrap"
import DuplicatedLeadsTable from '../../../components/Lead/duplicatedLeads';

const DuplicatedLeads = () => {
	return (
		<React.Fragment>
			<div className="page-content">
				<MetaTags>
					<title>Repeated Leads | KlikCRM</title>
				</MetaTags>
				<Container fluid>
					<DuplicatedLeadsTable />
				</Container>
			</div>
		</React.Fragment>
	)
}
export default DuplicatedLeads;