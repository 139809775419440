import React, { useState, useEffect } from "react"
import axios from "axios"
import { Container, Row, Col, Modal, FormGroup, Input, Alert } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { getCurrentUser } from "../../helpers/Utils"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

export default function ComplaintModal(props) {
   const [loading, setLoading] = useState(false)
   const [error, setError] = useState("")
   const [success, setSuccess] = useState("")
   const [complaint, setComplaint] = useState("")
   const [customer, setCustomer] = useState([])


   const getAllCustomers = () => {
      axios
         .get(`${process.env.REACT_APP_API_URL}/${localStorage.getItem('company_name')}/customers?per_page=5000`, {
            headers: {
               "content-type": "application/json",
               Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token }`,
            },
         })
         .then(function (res) {
            setCustomer(res.data.data.data)
         })
   }

   const submitComplaint = (e, value) => {
      setLoading(true)
      const api = axios.create();
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      api.interceptors.request.use(config => {
         config.cancelToken = source.token;
         return config;
      });

      const timeout = 120000; // 2 minute

      setTimeout(() => {
         source.cancel('Timeout');
      }, timeout);
      axios
         .post(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/complaint`,
            {
               customer_id: value.customer_id,
               complaint: value.complaint,
            },
            {
               headers: {
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setLoading(false)
               props.getAllComplaints()
               props.setmodal_complaint(false)
               toast.success(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            } else {
               setLoading(false)
               toast.error(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            }
         })
   }

   useEffect(() => {
      getAllCustomers()
   }, [])

   useEffect(() => {
      const close = (e) => {
         if (e.key === 'Escape') {
            props.setmodal_complaint(false)
         }
      }
      window.addEventListener('keydown', close)
      return () => window.removeEventListener('keydown', close)
   }, [])

   return (
      <>

         <Modal isOpen={props.modal_complaint} centered={true}>
            <AvForm
               className="modal-title mt-0"
               id="myModalLabel"
               onValidSubmit={(e, v) => {
                  submitComplaint(e, v)
               }}
            >
               <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myLargeModalLabel">
                     Create New Complaint
                  </h5>
                  <button
                     type="button"
                     className="cross__btn"
                     onClick={() => props.setmodal_complaint(false)}
                     aria-hidden="true"
                  >
                     &times;
                  </button>
               </div>
               <div className="modal-body">
                  <div className="mb-3 required">
                     <AvField
                        className="select-arrow"
                        label="Select Customer"
                        type="select"
                        name="customer_id"
                     >
                        <option value="" hidden>Select</option>
                        {customer && customer.map((item, i) => (
                           <option value={item.id} key={i}>{item.name}</option>
                        ))}
                     </AvField>
                  </div>
                  <div className="mb-3 required">
                     <AvField
                        label="Complaint Message"
                        type="textarea"
                        name="complaint"
                        placeholder="Enter message"
                     />
                  </div>
               </div>
               <div className="modal-footer">
                  <div className="text-center">
                     {loading ? (
                        <button
                           className="btn btn-primary disabled"
                           type="button"
                           disabled
                        >
                           Loading
                        </button>
                     ) : (
                        <button
                           className="btn btn-primary"
                           type="submit"
                        // onClick={() => props.setmodal_complaint(false)}
                        >
                           Submit
                        </button>
                     )}
                  </div>
               </div>
            </AvForm>
         </Modal>
      </>
   )
}
