import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import axios from "axios"
import MetaTags from "react-meta-tags"
import {
   Container,
   Row,
   Col,
   Card,
   Alert,
   CardBody,
   Media,
   Button,
   Modal,
   Label,
   FormGroup,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { getCurrentUser, setCurrentUser } from "../../../helpers/Utils"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"

const ProformaSettings = () => {
   const [invoiceLogo, setInvoiceLogo] = useState("")
   const [tandC1, setTandC1] = useState("")
   const [tandC2, setTandC2] = useState("")
   const [bankDetails, setBankDetails] = useState(getCurrentUser().bank_details)
   const [proInvoice, setProInvoice] = useState(
      getCurrentUser().performa_invoice
   )
   const [loading, setLoading] = useState(false)
   const [invoiceSignature, setInvoiceSignature] = useState("")
   const [companyAddress, setCompanyAddress] = useState(getCurrentUser().company_address)
   const [removeSignature, setRemoveSignature] = useState(false)
   const [removeLogo, setRemoveLogo] = useState(false)
   const getFile = e => {
      setInvoiceLogo(e.target.files[0])
   }
   const getFile2 = e => {
      setInvoiceSignature(e.target.files[0])
   }
   const getTandC1 = () => {
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/term-condition?type=product_list`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setTandC1(res.data.data[0]?.term_condition)
            } else {
               setError(res.data.message)
            }
         })
   }

   const getTandC2 = () => {
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/term-condition?type=product_line`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setTandC2(res.data.data[0]?.term_condition)
            } else {
               setError(res.data.message)
            }
         })
   }


   const getProformaDetail = (e, value) => {
      var formData = new FormData()
      formData.append("performa_invoice", proInvoice)
      formData.append("bank_details", bankDetails)
      formData.append("customer_care_number", value.customer_care_number)
      // formData.append("bcc", value.bcc)
      formData.append("welcome_message", value.welcome_message)
      formData.append("invoice_logo", invoiceLogo)
      formData.append("signature", invoiceSignature)
      formData.append("company_address", companyAddress)
      formData.append("remove_signature", invoiceSignature !== "" ? 0 : removeSignature ? 1 : 0)
      formData.append("remove_logo", invoiceLogo !== "" ? 0 : removeLogo ? 1 : 0)
      const api = axios.create()
      const CancelToken = axios.CancelToken
      const source = CancelToken.source()

      api.interceptors.request.use(config => {
         config.cancelToken = source.token
         return config
      })

      const timeout = 120000 // 2 minute

      setTimeout(() => {
         source.cancel("Timeout")
      }, timeout)
      axios
         .post(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/proforma-detail`,
            formData,
            {
               headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setLoading(false)
               setCurrentUser(res.data.data)
               toast.success(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
               window.history.go(-1)
            } else {
               setLoading(false)
               toast.error(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            }
         })
         .catch(err => {
            // console.log("err", err)
         })
   }

   const submitTermCondition = (e, value) => {
      const api = axios.create()
      const CancelToken = axios.CancelToken
      const source = CancelToken.source()

      api.interceptors.request.use(config => {
         config.cancelToken = source.token
         return config
      })

      const timeout = 120000 // 2 minute

      setTimeout(() => {
         source.cancel("Timeout")
      }, timeout)
      axios
         .post(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/term-condition`,
            {
               type: "product_list",
               term_condition: tandC1,
            },
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setLoading(false)
               toast.success(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
               window.history.go(-1)
            } else {
               setLoading(false)
               toast.error(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            }
         })
   }

   const submitTermCondition2 = (e, value) => {
      const api = axios.create()
      const CancelToken = axios.CancelToken
      const source = CancelToken.source()

      api.interceptors.request.use(config => {
         config.cancelToken = source.token
         return config
      })

      const timeout = 120000 // 2 minute

      setTimeout(() => {
         source.cancel("Timeout")
      }, timeout)
      axios
         .post(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/term-condition`,
            {
               type: "product_line",
               term_condition: tandC2,
            },
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setLoading(false)
               toast.success(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
               window.history.go(-1)
            } else {
               setLoading(false)
               toast.error(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            }
         })
   }

   useEffect(() => {
      getTandC1()
      getTandC2()
   }, [])

   return (
      <>
         <div className="page-title-box">
            <Row className="align-items-center">
               <Col md={6}>
                  <h4 className="page-title">Proforma Settings</h4>
               </Col>
               <Col md={6}>
                  <h6 className="page-title float-end"></h6>
               </Col>
            </Row>
         </div>
         <Row>
            <Col lg="12">
               {getCurrentUser().role_name === "Administrator" ? (
                  <Card>
                     <CardBody>
                        <AvForm
                           className="card-title mt-0"
                           onValidSubmit={(e, v) => {
                              getProformaDetail(e, v)
                           }}
                        >
                           <h4 className="card-title mb-4">Proforma Details</h4>
                           <hr />
                           <Row>
                              <Col md={4}>
                                 {/* <div className="mb-3">
                                          <AvField
                                             label="Proforma Invoice Bcc"
                                             type="email"
                                             name="bcc"
                                             placeholder="Enter Email"
                                             value={getCurrentUser().bcc}
                                          />
                                       </div> */}
                                 <div className="mb-3">
                                    <label>Company Details</label>
                                    <ReactQuill
                                       theme="snow"
                                       value={proInvoice}
                                       onChange={e => setProInvoice(e)}
                                    />
                                 </div>
                                 <div className="mb-3">
                                    <AvField
                                       label="Customer Care Number"
                                       type="number"
                                       onFocus={e =>
                                          e.target.addEventListener(
                                             "wheel",
                                             function (e) {
                                                e.preventDefault()
                                             },
                                             { passive: false }
                                          )
                                       }
                                       name="customer_care_number"
                                       placeholder="Enter Number"
                                       value={getCurrentUser().customer_care_number}
                                    />
                                 </div>
                              </Col>
                              <Col md={4}>
                                 <div className="mb-3">
                                    <label>Bank Details</label>
                                    <ReactQuill
                                       theme="snow"
                                       value={bankDetails}
                                       onChange={e => setBankDetails(e)}
                                    />
                                 </div>
                                 <div className="d-flex justify-content-between mb-2">
                                    <div>
                                       <label>Invoice Logo</label>
                                       <AvField
                                          // label="Invoice Logo"
                                          type="file"
                                          name="invoice_logo"
                                          accept="image/jpg, image/png, image/gif, image/jpeg"
                                          onChange={e => getFile(e)}
                                       />
                                    </div>
                                 </div>
                                 <small>
                                    <b><i>Note:
                                       Make sure the dimensions of your logo is in 1:1</i>{" "}(It should be square)</b>
                                 </small>
                                 <div>
                                    {!getCurrentUser().invoice_logo_formatted ?
                                       "" : <img width={100} src={getCurrentUser().invoice_logo_formatted} alt="uploaded logo" />}
                                 </div>
                                 <div className="mt-3">
                                    {getCurrentUser().invoice_logo_formatted && !removeLogo ?
                                       <button type="button" className="btn btn-primary" onClick={() => setRemoveLogo(true)}>Remove Logo</button> : ""}
                                 </div>
                              </Col>
                              <Col md={4}>
                                 <div className="mb-3">
                                    <AvField
                                       label="Welcome Message"
                                       type="textarea"
                                       name="welcome_message"
                                       placeholder="Enter Welcome Message here"
                                       value={getCurrentUser().welcome_message}
                                    />
                                 </div>
                                 <div className="mb-3">
                                    <label>Company Address</label>
                                    <ReactQuill
                                       theme="snow"
                                       value={companyAddress}
                                       onChange={e => setCompanyAddress(e)}
                                    />
                                 </div>

                                 <div className="mb-3 ">
                                    <label>Upload your Signature or Stamp here</label>
                                    <AvField
                                       type="file"
                                       name="signature"
                                       accept="image/jpg, image/png, image/gif, image/jpeg"
                                       onChange={e => getFile2(e)}
                                    />
                                    {!getCurrentUser().signature ?
                                       "" : <img width={100} src={getCurrentUser().signature} alt="uploaded signature" className="mt-1" />}
                                 </div>
                                 {getCurrentUser().signature && !removeSignature ?
                                    <button type="button" className="btn btn-primary mb-1" onClick={() => setRemoveSignature(true)}>Remove Signature</button> : ""}
                              </Col>
                              <hr />
                           </Row>
                           <div className="text-center">
                              {loading ? (
                                 <button
                                    className="btn btn-primary disabled"
                                    type="button"
                                    disabled
                                 >
                                    Loading
                                 </button>
                              ) : (
                                 <button
                                    className="btn btn-primary"
                                    type="submit"
                                 // onClick={() => props.setmodal_dead_reason(false)}
                                 >
                                    Submit
                                 </button>
                              )}
                           </div>
                        </AvForm>
                     </CardBody>
                  </Card>
               ) : (
                  ""
               )}
            </Col>
            <Col lg="6">
               {getCurrentUser().role_name === "Administrator" ? (
                  <Card>
                     <CardBody>
                        <AvForm
                           className="card-title mt-0"
                           onValidSubmit={(e, v) => {
                              submitTermCondition(e, v)
                           }}
                        >
                           <h4 className="card-title mb-4">Product List</h4>
                           <hr />
                           <div className="mb-3">
                              <label>Terms & Conditions</label>
                              <ReactQuill
                                 theme="snow"
                                 value={tandC1}
                                 onChange={e => setTandC1(e)}
                              />
                           </div>
                           <hr />
                           <div className="text-center">
                              {loading ? (
                                 <button
                                    className="btn btn-primary disabled"
                                    type="button"
                                    disabled
                                 >
                                    Loading
                                 </button>
                              ) : (
                                 <button
                                    className="btn btn-primary"
                                    type="submit"
                                 // onClick={() => props.setmodal_dead_reason(false)}
                                 >
                                    Submit
                                 </button>
                              )}
                           </div>
                        </AvForm>
                     </CardBody>
                  </Card>
               ) : (
                  ""
               )}
            </Col>
            <Col lg="6">
               {getCurrentUser().role_name === "Administrator" ? (
                  <Card>
                     <CardBody>
                        <AvForm
                           className="card-title mt-0"
                           onValidSubmit={(e, v) => {
                              submitTermCondition2(e, v)
                           }}
                        >
                           <h4 className="card-title mb-4">Service Line</h4>
                           <hr />
                           <div className="mb-3">
                              <label>Terms & Conditions</label>
                              <ReactQuill
                                 theme="snow"
                                 value={tandC2}
                                 onChange={e => setTandC2(e)}
                              />
                           </div>
                           <hr />
                           <div className="text-center">
                              {loading ? (
                                 <button
                                    className="btn btn-primary disabled"
                                    type="button"
                                    disabled
                                 >
                                    Loading
                                 </button>
                              ) : (
                                 <button
                                    className="btn btn-primary"
                                    type="submit"
                                 // onClick={() => props.setmodal_dead_reason(false)}
                                 >
                                    Submit
                                 </button>
                              )}
                           </div>
                        </AvForm>
                     </CardBody>
                  </Card>
               ) : (
                  ""
               )}
            </Col>
         </Row>
      </>
   )
}

export default ProformaSettings
