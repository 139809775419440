import React, { useState, useEffect } from "react"
import {
   Modal,
   Card,
   CardBody,
   Nav,
   NavItem,
   NavLink,
   TabContent,
   TabPane,
} from "reactstrap"
import axios from "axios"
import {
   AvForm,
   AvField,
} from "availity-reactstrap-validation"
import classnames from "classnames"
import { getCurrentUser } from "../../helpers/Utils"
import DatePicker from "react-datepicker"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const ReminderModal = props => {
   const [customActiveTab, setCustomActiveTab] = useState(2)
   const [historyData, setHistoryData] = useState([])
   const [loading, setLoading] = useState(false)
   const [error, setError] = useState("")
   const [reminderDate, setReminderDate] = useState(new Date())

   const toggleCustom = tab => {
      if (customActiveTab !== tab) {
         setCustomActiveTab(tab)
      }
   }

   const getReminderHistory = () => {
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/reminder-history?id=${props.lead}`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setHistoryData(res.data.data)
            } else {
               setError(res.data.message)
            }
         })
   }

   const submitFollowup = (e, value) => {
      const api = axios.create();
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      api.interceptors.request.use(config => {
         config.cancelToken = source.token;
         return config;
      });

      const timeout = 120000; // 2 minute

      setTimeout(() => {
         source.cancel('Timeout');
      }, timeout);
      axios
         .post(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/reminder`,
            {
               lead_id: props.lead,
               message: value.message,
               date: e.target.date.value,
            },
            {
               headers: {
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setLoading(false)
               props.setmodal_reminder(false)
               toast.success(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            } else {
               setLoading(false)
               toast.error(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            }
         })
   }

   useEffect(() => {
      if (props.lead !== undefined) {
         getReminderHistory()
      }
   }, [])

   useEffect(() => {
      const close = e => {
         if (e.key === "Escape") {
            props.setmodal_reminder(false)
         }
      }
      window.addEventListener("keydown", close)
      return () => window.removeEventListener("keydown", close)
   }, [])

   return (
      <div>
         <Modal isOpen={props.modal_reminder} centered={true} size="lg">
            <AvForm
               className="modal-title mt-0"
               id="myModalLabel"
               onValidSubmit={(e, v) => {
                  submitFollowup(e, v)
               }}
            >
               <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myLargeModalLabel">
                     Reminder
                  </h5>
                  <button
                     type="button"
                     className="cross__btn"
                     onClick={() => props.setmodal_reminder(false)}
                     aria-hidden="true"
                  >
                     &times;
                  </button>
               </div>
               <div className="modal-body">
                  <Nav tabs>
                     <NavItem>
                        <NavLink
                           style={{ cursor: "pointer" }}
                           className={classnames({ active: customActiveTab === 1 })}
                           onClick={() => {
                              toggleCustom(1)
                           }}
                        >
                           {" "}
                           Reminder History
                        </NavLink>
                     </NavItem>
                     <NavItem>
                        <NavLink
                           style={{ cursor: "pointer" }}
                           className={classnames({ active: customActiveTab === 2 })}
                           onClick={() => {
                              toggleCustom(2)
                           }}
                        >
                           {" "}
                           Create Reminder
                        </NavLink>
                     </NavItem>
                  </Nav>
                  <TabContent activeTab={customActiveTab} className="pt-3">
                     <TabPane tabId={1}>
                        {historyData &&
                           historyData.map((item, i) => (
                              <Card className="leadhist" key={i}>
                                 <CardBody>
                                    <div className="d-flex justify-content-between">
                                       <span className="history-font">Message:</span>
                                       <span className="text-muted">{item.date}</span>
                                    </div>
                                    <hr />
                                    <span
                                       className="fs-6"
                                       dangerouslySetInnerHTML={{
                                          __html: item.message,
                                       }}
                                    />{" "}
                                    <br />
                                 </CardBody>
                              </Card>
                           ))}
                        {error ? (
                           <span
                              className="text-danger mt-3 d-table m-auto"
                              role="alert"
                           >
                              {error}
                           </span>
                        ) : (
                           ""
                        )}
                     </TabPane>
                     <TabPane tabId={2}>
                        <div className="mb-3">
                           <AvField
                              label="Message"
                              type="textarea"
                              name="message"
                              placeholder="Message"
                           />
                        </div>
                        <div className="mb-3">
                           <label className="form-group">Date</label>
                           <DatePicker
                              className="w-100 form-control"
                              dateFormat="yyyy-MM-dd HH:mm"
                              name="date"
                              showTimeSelect
                              timeFormat="HH:mm"
                              timeIntervals={15}
                              selected={reminderDate}
                              onChange={date => setReminderDate(date)}
                           />
                        </div>
                        <div className="modal-footer">
                           <div className="text-center">
                              {loading ? (
                                 <button
                                    className="btn btn-primary disabled"
                                    type="button"
                                    disabled
                                 >
                                    Loading
                                 </button>
                              ) : (
                                 <button className="btn btn-primary" type="submit">
                                    Update
                                 </button>
                              )}
                           </div>
                        </div>
                     </TabPane>
                  </TabContent>
               </div>
            </AvForm>
         </Modal>
      </div>
   )
}

export default ReminderModal
