import React, { useState, useEffect, useCallback } from "react"
import { Container, Row, Col, Modal, FormGroup, Input } from "reactstrap"
import { getCurrentUser } from "../../helpers/Utils"
import { Link } from "react-router-dom"

const ReturnRefundPolicy = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container>
          <Row>
            <Col md={12}>
              <h1 className="text-center py-4">Return & Refund Policy</h1>
            </Col>
          </Row>
          <div className="col-md-12">
            <div>
              <p>
                If, for any reason, You are not completely satisfied with a
                purchase We invite You to review our policy on refunds and
                returns.
              </p>
              <p>
                The following terms are applicable for any products that You
                purchased with Us.
              </p>
              <h2>Interpretation and Definitions</h2>
              <h3>Interpretation</h3>
              <p>
                The words of which the initial letter is capitalized have
                meanings defined under the following conditions. The following
                definitions shall have the same meaning regardless of whether
                they appear in singular or in plural.
              </p>
              <h3>Definitions</h3>
              <p>For the purposes of this Return and Refund Policy:</p>
              <ul>
                <li>
                  <p>
                    <strong>Company</strong> (referred to as either "the
                    Company", "We", "Us" or "Our" in this Agreement) refers to
                    klikcrm, #1069, sector 20B, Chandigarh, 160020.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Orders</strong> mean a request by You to purchase
                    Goods from Us.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Service</strong> refers to the Website.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Website</strong> refers to Klikcrm.com, accessible
                    from{" "}
                    <a
                      href="https://login.klikcrm.com/login"
                      rel="external nofollow noopener"
                      target="_blank"
                    >
                      https://login.klikcrm.com/login
                    </a>
                  </p>
                </li>
                <li>
                  <p>
                    <strong>You</strong> means the individual accessing or using
                    the Service, or the company, or other legal entity on behalf
                    of which such individual is accessing or using the Service,
                    as applicable.
                  </p>
                </li>
              </ul>
              <h2>Your Order Cancellation Rights</h2>
              <p>
                You are entitled to cancel Your Order within 30 days without
                giving any reason for doing so.
              </p>
              <p>
                The deadline for cancelling an Order is 30 days from the date on
                which You received the Goods or on which a third party you have
                appointed, who is not the carrier, takes possession of the
                product delivered.
              </p>
              <p>
                In order to exercise Your right of cancellation, You must inform
                Us of your decision by means of a clear statement. You can
                inform us of your decision by:
              </p>
              <ul>
                <li>By phone number: 9781239781</li>
              </ul>
              <p>
                We will reimburse You no later than 14 days from the day on
                which We receive the returned Goods. We will use the same means
                of payment as You used for the Order, and You will not incur any
                fees for such reimbursement.
              </p>
              <h2>Conditions for Returns</h2>
              <p>
                In order for the Goods to be eligible for a return, please make
                sure that:
              </p>
              <ul>
                <li>The Goods were purchased in the last 30 days</li>
                <li>The Goods are in the original packaging</li>
              </ul>
              <p>The following Goods cannot be returned:</p>
              <ul>
                <li>
                  The supply of Goods made to Your specifications or clearly
                  personalized.
                </li>
                <li>
                  The supply of Goods which according to their nature are not
                  suitable to be returned, deteriorate rapidly or where the date
                  of expiry is over.
                </li>
                <li>
                  The supply of Goods which are not suitable for return due to
                  health protection or hygiene reasons and were unsealed after
                  delivery.
                </li>
                <li>
                  The supply of Goods which are, after delivery, according to
                  their nature, inseparably mixed with other items.
                </li>
              </ul>
              <p>
                We reserve the right to refuse returns of any merchandise that
                does not meet the above return conditions in our sole
                discretion.
              </p>
              <p>
                Only regular priced Goods may be refunded. Unfortunately, Goods
                on sale cannot be refunded. This exclusion may not apply to You
                if it is not permitted by applicable law.
              </p>
              <h2>Returning Goods</h2>
              <p>
                You are responsible for the cost and risk of returning the Goods
                to Us. You should send the Goods at the following address:
              </p>
              <p>#1069, Sector 20B, Chandigarh, 160020</p>
              <p>
                We cannot be held responsible for Goods damaged or lost in
                return shipment. Therefore, We recommend an insured and
                trackable mail service. We are unable to issue a refund without
                actual receipt of the Goods or proof of received return
                delivery.
              </p>
              <h3>Contact Us</h3>
              <p>
                If you have any questions about our Returns and Refunds Policy,
                please contact us:
              </p>
              <ul>
                <li>By phone number: 9781239781</li>
                <li>By email: info@leadmanch.com</li>
                <li>
                  By operational address: #1069, Sector 20B, Chandigarh, 160020
                </li>
              </ul>
            </div>
            <hr />
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ReturnRefundPolicy
