import React, { useState } from 'react'

const PaymentList = (props) => {
   const [payment, setPayment] = useState(props.data)

   return (
      <>
         <tr key={props.i}>
            
         </tr>
      </>
   )
}

export default PaymentList
