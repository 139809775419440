import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import axios from "axios"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Media,
  Button,
  Modal,
  Label,
  FormGroup,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { getCurrentUser, setCurrentUser } from "../../../helpers/Utils"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { saveAs } from "file-saver"
import file from "../../../assets/files/ApiSteps.pdf"

const WebhookKey = () => {
  const [confirm_alert, setconfirm_alert] = useState(false)
  const [loading2, setLoading2] = useState(false)

  const downloadPDF = () => {
    saveAs(file, "Steps_to_fetch_leads.pdf")
  }

  const generateApiKey = e => {
    setLoading2(true)
    const api = axios.create()
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    api.interceptors.request.use(config => {
      config.cancelToken = source.token
      return config
    })

    const timeout = 120000 // 2 minute

    setTimeout(() => {
      source.cancel("Timeout")
    }, timeout)
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/generate-api-key`,
        {
          id: e,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setCurrentUser(res.data.data)
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          setLoading2(false)
          // window.history.go(-1)
        } else {
          setLoading2(false)
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  return (
    <>
      <div className="page-title-box">
        <Row className="align-items-center">
          <Col md={6}>
            <h4 className="page-title">Webhook API Keys</h4>
          </Col>
          <Col md={6}>
            <h6 className="page-title float-end"></h6>
          </Col>
        </Row>
      </div>
      <Row>
        <Col lg="12">
          {getCurrentUser().role_name === "Administrator" ? (
            <Card>
              <CardBody>
                <AvForm className="card-title mt-0">
                  <h4 className="card-title mb-4">
                    Webhook for websites & Apps (API key)
                  </h4>
                  <hr />
                  <Row>
                    <Col md={12}>
                      {loading2 ? (
                        <span>
                          <i className="fa fa-spinner"></i>
                        </span>
                      ) : (
                        <div className="mb-3">
                          <AvField
                            label="API key"
                            type="text"
                            name="api_key"
                            placeholder="generate API key"
                            readOnly
                            defaultValue={getCurrentUser()?.api_key}
                          />
                        </div>
                      )}
                      <p>
                        Here is the pdf to fetch leads using API.{" "}
                        <span
                          className="text-info"
                          style={{ cursor: "pointer" }}
                          onClick={() => downloadPDF()}
                        >
                          click here...
                        </span>
                      </p>
                      {/* <p>
                                          Here is the link to Postman API, check it out there. <a className="text-info" href="https://www.postman.com/teamcodingcafe/workspace/klikcrm/collection/17597812-743ecf00-db24-4fc8-b122-6a38def2ce2b?action=share&creator=17597812" target="_blank">click here...</a>
                                       </p> */}
                    </Col>
                    <hr />
                  </Row>
                  {confirm_alert ? (
                    <SweetAlert
                      title="Are you sure?"
                      warning
                      showCancel
                      confirmButtonText="Yes, delete it!"
                      confirmBtnBsStyle="success"
                      cancelBtnBsStyle="danger"
                      onConfirm={() => {
                        setconfirm_alert(false)
                        generateApiKey(getCurrentUser().id)
                      }}
                      onCancel={() => setconfirm_alert(false)}
                    >
                      <span className="text-normal">
                        Do you want to generate api key?
                      </span>
                    </SweetAlert>
                  ) : null}
                  <div className="text-center">
                    {loading2 ? (
                      <button
                        className="btn btn-primary disabled"
                        type="button"
                        disabled
                      >
                        Loading
                      </button>
                    ) : (
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={() => setconfirm_alert(true)}
                      >
                        Regenerate API key
                      </button>
                    )}
                  </div>
                </AvForm>
              </CardBody>
            </Card>
          ) : (
            ""
          )}
        </Col>
        {getCurrentUser()?.role_name === "Administrator" &&
        getCurrentUser()?.ivr_webhook_url !== null ? (
          <Col lg="12">
            <Card>
              <CardBody>
                <h4 className="card-title mb-4">IVR Webhook URL</h4>
                <hr />
                <Row>
                  <AvForm>
                    <Col md={12}>
                      <div className="mb-3">
                        <AvField
                          label="Webhook URL"
                          type="text"
                          name="ivr_webhook_url"
                          readOnly
                          defaultValue={getCurrentUser()?.ivr_webhook_url}
                        />
                      </div>
                      <p>
                        Please paste the above webhook url into the cloudshope
                        portal by enabling the POST API in Services &#62; IVR
                        Service &#62; API Section
                      </p>
                    </Col>
                  </AvForm>
                </Row>
              </CardBody>
            </Card>
          </Col>
        ) : (
          ""
        )}
      </Row>
    </>
  )
}

export default WebhookKey
