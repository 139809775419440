import React, { useState, useEffect } from "react"
import Loader from "../../Loader/loader.js"
import {
   Row,
   Col,
} from "reactstrap"
import axios from "axios"
import {
   getCurrentUser,
   getPermission,
   getFilterDate,
   setFilterDate,
} from "../../../helpers/Utils"
import Paginations from "../../Pagination"
import StaffList from "./stafflist"
import StaffModal from "./staffmodal"
import MultipleChoice from "../../Filter/MultipleChoice"
import DateFilter from "../../Filter/DateFilter"
import InputFilter from "../../Filter/SearchFilter"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const TableStaff = props => {
   const [modal_standard, setmodal_standard] = useState(false)
   const [allUsers, setAllUsers] = useState([])
   const [users, setUsers] = useState([])
   const [error, setError] = useState("")
   const [loader, setLoader] = useState(<Loader />)
   const [allPages, setAllPages] = useState([])
   const [counter, setCounter] = useState("")
   const [allRole, setAllRole] = useState([])
   const [name_filter, setname_filter] = useState(false)
   const [email_filter, setemail_filter] = useState(false)
   const [contact_filter, setcontact_filter] = useState(false)
   const [role_filter, setrole_filter] = useState(false)
   const [leadholder_filter, setleadholder_filter] = useState(false)
   const [status2_filter, setstatus2_filter] = useState(false)
   const [date_filter, setdate_filter] = useState(false)
   const [sort, setSort] = useState(false)
   const [sort2, setSort2] = useState(false)
   const [sort3, setSort3] = useState(false)
   const [sort4, setSort4] = useState(false)
   const [searchedCount, setSearchedCount] = useState("")
   const [totalUser, setTotalUser] = useState("")

   const getAllUsers = () => {
      setLoader(<Loader />)
      setAllUsers([])
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/user?per_page=${localStorage.getItem("PerPage") !== null
               ? localStorage.getItem("PerPage")
               : ""
            }`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setLoader("")
               setError(res.data.message)
               setCounter(res.data.data.from)
               setAllUsers(res.data.data.data)
               setUsers(res.data.data.data)
               setAllPages(res.data.data)
               setTotalUser(res.data.data.total)
            } else {
               setLoader("")
               setError(res.data.message)
            }
            getAllUser()
         })
   }

   const getAllUser = () => {
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/user?per_page=5000`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               localStorage.setItem("AllUsers", JSON.stringify(res.data.data.data))
            }
         })
         .catch(err => {
         })
   }

   const paginate = e => {
      setAllUsers([])
      setCounter("")
      setAllPages([])
      setLoader(<Loader />)
      axios
         .get(
            `${e}&status=${localStorage.getItem("leadStatus2") !== null
               ? localStorage.getItem("leadStatus2")
               : ""
            }
            &team_leader_id=${localStorage.getItem("leadHolder") !== null
               ? localStorage.getItem("leadHolder")
               : ""
            }
            &role_id=${localStorage.getItem("leadRole") !== null
               ? localStorage.getItem("leadRole")
               : ""
            }
            &name=${localStorage.getItem("leadName") !== null
               ? localStorage.getItem("leadName")
               : ""
            }
            &email=${localStorage.getItem("leadEmail") !== null
               ? localStorage.getItem("leadEmail")
               : ""
            }
            &phone=${localStorage.getItem("leadContact") !== null
               ? localStorage.getItem("leadContact")
               : ""
            }
            &fromdate=${getFilterDate() && getFilterDate().from !== null
               ? getFilterDate().from
               : ""
            }
            &todate=${getFilterDate() && getFilterDate().to !== null
               ? getFilterDate().to
               : ""
            }
            &sort=${localStorage.getItem("leadSort") !== null
               ? localStorage.getItem("leadSort")
               : ""
            }`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setLoader("")
               setCounter(res.data.data.from)
               setAllPages(res.data.data)
               setAllUsers(res.data.data.data)
            } else {
               setError(res.data.message)
               setLoader("")
            }
         })
   }

   const getSearchedLeads = (e, type) => {
      setError("")
      if (type == "name") {
         localStorage.setItem("leadName", e)
      }
      if (type == "email") {
         localStorage.setItem("leadEmail", e)
      }
      if (type == "contact") {
         localStorage.setItem("leadContact", e)
      }
      if (type == "leadholder") {
         localStorage.setItem("leadHolder", e)
      }
      if (type == "role") {
         localStorage.setItem("leadRole", e)
      }
      if (type == "status2") {
         localStorage.setItem("leadStatus2", e)
      }
      if (type == "date") {
         setFilterDate(e)
      }
      if (type == "sort") {
         localStorage.setItem("leadSort", e)
      }
      setSearchedCount("")
      setAllUsers([])
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/user?status=${localStorage.getItem("leadStatus2") !== null
               ? localStorage.getItem("leadStatus2")
               : ""
            }
         &team_leader_id=${localStorage.getItem("leadHolder") !== null
               ? localStorage.getItem("leadHolder")
               : ""
            }
         &role_id=${localStorage.getItem("leadRole") !== null
               ? localStorage.getItem("leadRole")
               : ""
            }
         &name=${localStorage.getItem("leadName") !== null
               ? localStorage.getItem("leadName")
               : ""
            }
         &email=${localStorage.getItem("leadEmail") !== null
               ? localStorage.getItem("leadEmail")
               : ""
            }
         &phone=${localStorage.getItem("leadContact") !== null
               ? localStorage.getItem("leadContact")
               : ""
            }
         &fromdate=${getFilterDate() && getFilterDate().from !== null
               ? getFilterDate().from
               : ""
            }
         &todate=${getFilterDate() && getFilterDate().to !== null
               ? getFilterDate().to
               : ""
            }
         &sort=${localStorage.getItem("leadSort") !== null
               ? localStorage.getItem("leadSort")
               : ""
            }
         &per_page=${localStorage.getItem("PerPage") !== null
               ? localStorage.getItem("PerPage")
               : ""
            }
         `,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setLoader("")
               setCounter(res.data.data.from)
               setAllUsers(res.data.data.data)
               setAllPages(res.data.data)
               setSearchedCount(res.data.data.total)
               setname_filter(false)
               setemail_filter(false)
               setdate_filter(false)
               setcontact_filter(false)
               setrole_filter(false)
               setleadholder_filter(false)
               setstatus2_filter(false)
            } else {
               setError(res.data.message)
            }
         })
   }

   const getAllRole = () => {
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/role?per_page=5000`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
               },
            }
         )
         .then(function (res) {
            setAllRole(res.data.data.data)
         })
   }

   const exportLead = () => {
      axios
         .post(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/user-export`,
            {},

            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               //  setLeadsSelectedIds([])
               window.location.href = res.data.file_url
               setTimeout(() => {
                  dFileName(res.data.file_name)
               }, 5000)
            } else {
               toast.error(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            }
         })
   }

   const dFileName = e => {
      axios
         .post(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/deleteuser-file`,
            {
               file_name: e,
            },
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
            } else {
            }
         })
   }

   useEffect(() => {
      getAllUsers()
      getAllRole()
   }, [])

   return (
      <>
         {modal_standard ? (
            <StaffModal
               modal_standard={modal_standard}
               setmodal_standard={setmodal_standard}
               getAllUsers={getAllUsers}
            />
         ) : (
            ""
         )}
         <div className="page-title-box">
            <Row className="align-items-center">
               <Col md={6}>
                  <h6 className="page-title">
                     Staff
                     {users[0] && users[0].user_limit !== null ? (
                        <span className="text-warning">
                           {" "}
                           (Users limit is {users[0] && users[0].user_limit}, Total{" "}
                           {totalUser})
                        </span>
                     ) : (
                        ""
                     )}
                  </h6>
               </Col>
               <Col md={6}>
                  <h6 className="page-title float-end">
                     {getPermission() &&
                        getPermission().lead_export.lead_export.view.is_checked ===
                        "yes" ? (
                        <button
                           className="btn btn-primary submit__button me-2"
                           onClick={() => {
                              exportLead()
                           }}
                        >
                           Export
                        </button>
                     ) : (
                        ""
                     )}
                     <button
                        className="btn btn-success"
                        onClick={() => {
                           setmodal_standard(true)
                        }}
                     >
                        Add New
                     </button>
                  </h6>
               </Col>
            </Row>
            <Row>
               <Col>
                  {searchedCount !== "" ? (
                     <h6 className="">
                        Searched Results
                        <span> ({searchedCount})</span>
                     </h6>
                  ) : (
                     ""
                  )}
               </Col>
            </Row>
         </div>
         {/* <div className="card">
        <div className="card-body"> */}
         <div
            className="lead_table table table-sm table-bordered table-responsive"
         // style={{ overflowX: "scroll" }}
         >
            <table style={{ width: "100%", background: "#fff" }}>
               <tbody>
                  <tr className="static-header">
                     <th>#</th>
                     <th>Live</th>
                     <th>Avatar</th>
                     <th>
                        <div
                           className="position-relative"
                           style={{ whiteSpace: "nowrap" }}
                        >
                           Name
                           {name_filter ? (
                              <>
                                 <span
                                    style={{ cursor: "pointer", color: "orange" }}
                                    onClick={() => setname_filter(false)}
                                 >
                                    <i className="fa fa-times fa-lg ms-2"></i>
                                 </span>
                              </>
                           ) : (
                              <>
                                 {localStorage.getItem("leadName") == "" ? (
                                    <span
                                       className=""
                                       style={{ cursor: "pointer" }}
                                       onClick={() => setname_filter(true)}
                                    >
                                       <i className="fa fa-filter ms-2"></i>
                                    </span>
                                 ) : (
                                    <span
                                       className=""
                                       style={{ cursor: "pointer", color: "orange" }}
                                       onClick={() => setname_filter(true)}
                                    >
                                       <i className="fa fa-filter ms-2"></i>
                                    </span>
                                 )}
                              </>
                           )}
                           {sort ? (
                              <>
                                 <span
                                    className="fs-5"
                                    style={{ cursor: "pointer", color: "orange" }}
                                    onClick={() => {
                                       setSort2(true),
                                          setSort(false),
                                          setSort3(false),
                                          setSort4(false),
                                          getSearchedLeads("name,asc", "sort")
                                    }}
                                 >
                                    <i className="fa fa-sort-up ms-2"></i>
                                 </span>
                                 <span
                                    className="fs-5"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                       setSort2(true),
                                          setSort(false),
                                          setSort3(false),
                                          setSort4(false),
                                          getSearchedLeads("name,asc", "sort")
                                    }}
                                 >
                                    <i
                                       className="fa fa-sort-down"
                                       style={{
                                          position: "absolute",
                                          left: "0",
                                          bottom: "0",
                                          transform: "translate(613%, -3px)",
                                       }}
                                    ></i>
                                 </span>
                              </>
                           ) : (
                              <>
                                 <span
                                    className="fs-5"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                       setSort(true),
                                          setSort2(false),
                                          setSort3(false),
                                          setSort4(false),
                                          getSearchedLeads("name,asc", "sort")
                                    }}
                                 >
                                    <i className="fa fa-sort-up ms-2"></i>
                                 </span>
                                 <span
                                    className="fs-5"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                       setSort2(true),
                                          setSort(false),
                                          setSort3(false),
                                          setSort4(false),
                                          getSearchedLeads("name,desc", "sort")
                                    }}
                                 >
                                    <i
                                       className="fa fa-sort-down"
                                       style={{
                                          position: "absolute",
                                          left: "0",
                                          bottom: "0",
                                          transform: "translate(613%, -3px)",
                                       }}
                                    ></i>
                                 </span>
                              </>
                           )}
                           {sort2 ? (
                              <>
                                 <span
                                    className="fs-5"
                                    style={{ cursor: "pointer", color: "orange" }}
                                    onClick={() => {
                                       setSort2(false),
                                          setSort(true),
                                          setSort3(false),
                                          setSort4(false),
                                          getSearchedLeads("name,desc", "sort")
                                    }}
                                 >
                                    <i
                                       className="fa fa-sort-down"
                                       style={{
                                          position: "absolute",
                                          left: "0",
                                          bottom: "0",
                                          transform: "translate(613%, -3px)",
                                       }}
                                    ></i>
                                 </span>
                              </>
                           ) : (
                              ""
                           )}
                           <InputFilter
                              name_filter={name_filter}
                              setname_filter={setname_filter}
                              getSearchedLeads={getSearchedLeads}
                           />
                        </div>
                     </th>
                     <th>
                        <div
                           className="position-relative"
                           style={{ whiteSpace: "nowrap" }}
                        >
                           E-mail
                           {email_filter ? (
                              <>
                                 <span
                                    style={{ cursor: "pointer", color: "orange" }}
                                    onClick={() => setemail_filter(false)}
                                 >
                                    <i className="fa fa-times fa-lg ms-2"></i>
                                 </span>
                              </>
                           ) : (
                              <>
                                 {localStorage.getItem("leadEmail") == "" ? (
                                    <span
                                       className=""
                                       style={{ cursor: "pointer" }}
                                       onClick={() => setemail_filter(true)}
                                    >
                                       <i className="fa fa-filter ms-2"></i>
                                    </span>
                                 ) : (
                                    <span
                                       className=""
                                       style={{ cursor: "pointer", color: "orange" }}
                                       onClick={() => setemail_filter(true)}
                                    >
                                       <i className="fa fa-filter ms-2"></i>
                                    </span>
                                 )}
                              </>
                           )}
                           <InputFilter
                              email_filter={email_filter}
                              setemail_filter={setemail_filter}
                              getSearchedLeads={getSearchedLeads}
                           />
                        </div>
                     </th>
                     <th>
                        <div
                           className="position-relative"
                           style={{ whiteSpace: "nowrap" }}
                        >
                           Phone/Whatsapp number
                           {contact_filter ? (
                              <>
                                 <span
                                    style={{ cursor: "pointer", color: "orange" }}
                                    onClick={() => setcontact_filter(false)}
                                 >
                                    <i className="fa fa-times fa-lg ms-2"></i>
                                 </span>
                              </>
                           ) : (
                              <>
                                 {localStorage.getItem("leadContact") == "" ? (
                                    <span
                                       className=""
                                       style={{ cursor: "pointer" }}
                                       onClick={() => setcontact_filter(true)}
                                    >
                                       <i className="fa fa-filter ms-2"></i>
                                    </span>
                                 ) : (
                                    <span
                                       className=""
                                       style={{ cursor: "pointer", color: "orange" }}
                                       onClick={() => setcontact_filter(true)}
                                    >
                                       <i className="fa fa-filter ms-2"></i>
                                    </span>
                                 )}
                              </>
                           )}
                           <InputFilter
                              contact_filter={contact_filter}
                              setcontact_filter={setcontact_filter}
                              getSearchedLeads={getSearchedLeads}
                           />
                        </div>
                     </th>
                     <th>Last seen</th>
                     <th>
                        <div
                           className="position-relative"
                           style={{ whiteSpace: "nowrap" }}
                        >
                           Team Leader
                           {leadholder_filter ? (
                              <>
                                 <span
                                    style={{ cursor: "pointer", color: "orange" }}
                                    onClick={() => setleadholder_filter(false)}
                                 >
                                    <i className="fa fa-times fa-lg ms-2"></i>
                                 </span>
                              </>
                           ) : (
                              <>
                                 {localStorage.getItem("leadHolder") == "" ? (
                                    <span
                                       className=""
                                       style={{ cursor: "pointer" }}
                                       onClick={() => setleadholder_filter(true)}
                                    >
                                       <i className="fa fa-filter ms-2"></i>
                                    </span>
                                 ) : (
                                    <span
                                       className=""
                                       style={{ cursor: "pointer", color: "orange" }}
                                       onClick={() => setleadholder_filter(true)}
                                    >
                                       <i className="fa fa-filter ms-2"></i>
                                    </span>
                                 )}
                              </>
                           )}
                           <MultipleChoice
                              leadholder_filter={leadholder_filter}
                              setleadholder_filter={setleadholder_filter}
                              data={users}
                              getSearchedLeads={getSearchedLeads}
                           />
                        </div>
                     </th>
                     <th>
                        <div
                           className="position-relative"
                           style={{ whiteSpace: "nowrap" }}
                        >
                           Role
                           {role_filter ? (
                              <>
                                 <span
                                    style={{ cursor: "pointer", color: "orange" }}
                                    onClick={() => setrole_filter(false)}
                                 >
                                    <i className="fa fa-times fa-lg ms-2"></i>
                                 </span>
                              </>
                           ) : (
                              <>
                                 {localStorage.getItem("leadRole") == "" ? (
                                    <span
                                       className=""
                                       style={{ cursor: "pointer" }}
                                       onClick={() => setrole_filter(true)}
                                    >
                                       <i className="fa fa-filter ms-2"></i>
                                    </span>
                                 ) : (
                                    <span
                                       className=""
                                       style={{ cursor: "pointer", color: "orange" }}
                                       onClick={() => setrole_filter(true)}
                                    >
                                       <i className="fa fa-filter ms-2"></i>
                                    </span>
                                 )}
                              </>
                           )}
                           <MultipleChoice
                              role_filter={role_filter}
                              setrole_filter={setrole_filter}
                              data={allRole}
                              getSearchedLeads={getSearchedLeads}
                           />
                        </div>
                     </th>
                     <th>
                        <div
                           className="position-relative"
                           style={{ whiteSpace: "nowrap" }}
                        >
                           Status
                           {status2_filter ? (
                              <>
                                 <span
                                    className=""
                                    style={{ cursor: "pointer", color: "orange" }}
                                    onClick={() => setstatus2_filter(false)}
                                 >
                                    <i className="fa fa-times fa-lg ms-2"></i>
                                 </span>
                              </>
                           ) : (
                              <>
                                 {localStorage.getItem("leadStatus2") == "" ? (
                                    <span
                                       style={{ cursor: "pointer" }}
                                       onClick={() => setstatus2_filter(true)}
                                    >
                                       <i className="fa fa-filter ms-2"></i>
                                    </span>
                                 ) : (
                                    <span
                                       style={{ cursor: "pointer", color: "orange" }}
                                       onClick={() => setstatus2_filter(true)}
                                    >
                                       <i className="fa fa-filter ms-2"></i>
                                    </span>
                                 )}
                              </>
                           )}
                           <MultipleChoice
                              status2_filter={status2_filter}
                              setstatus2_filter={setstatus2_filter}
                              getSearchedLeads={getSearchedLeads}
                           />
                        </div>
                     </th>
                     {getCurrentUser()?.crmpro_permission === 1 ||
                        getCurrentUser()?.crmpronew_permission === 1 ?
                        <th>OTP on Login</th> : ""}
                     <th>Restriction</th>
                     <th>Restrict IP</th>
                     <th>Restrict Timing</th>
                     <th>
                        <div
                           className="position-relative"
                           style={{ whiteSpace: "nowrap" }}
                        >
                           Member Since
                           {date_filter ? (
                              <>
                                 <span
                                    style={{ cursor: "pointer", color: "orange" }}
                                    onClick={() => setdate_filter(false)}
                                 >
                                    <i className="fa fa-times fa-lg ms-2"></i>
                                 </span>
                              </>
                           ) : (
                              <>
                                 {getFilterDate() && getFilterDate().from == "" ? (
                                    <span
                                       className=""
                                       style={{ cursor: "pointer" }}
                                       onClick={() => setdate_filter(true)}
                                    >
                                       <i className="fa fa-filter ms-2"></i>
                                    </span>
                                 ) : (
                                    <span
                                       className=""
                                       style={{ cursor: "pointer", color: "orange" }}
                                       onClick={() => setdate_filter(true)}
                                    >
                                       <i className="fa fa-filter ms-2"></i>
                                    </span>
                                 )}
                              </>
                           )}
                           {sort3 ? (
                              <>
                                 <span
                                    className="fs-5"
                                    style={{ cursor: "pointer", color: "orange" }}
                                    onClick={() => {
                                       setSort4(true),
                                          setSort3(false),
                                          setSort(false),
                                          setSort2(false),
                                          getSearchedLeads("created_at,asc", "sort")
                                    }}
                                 >
                                    <i className="fa fa-sort-up ms-2"></i>
                                 </span>
                                 <span
                                    className="fs-5"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                       setSort4(true),
                                          setSort3(false),
                                          setSort(false),
                                          setSort2(false),
                                          getSearchedLeads("created_at,asc", "sort")
                                    }}
                                 >
                                    <i
                                       className="fa fa-sort-down"
                                       style={{
                                          position: "absolute",
                                          left: "0",
                                          bottom: "0",
                                          transform: "translate(1101%, -3px)",
                                       }}
                                    ></i>
                                 </span>
                              </>
                           ) : (
                              <>
                                 <span
                                    className="fs-5"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                       setSort3(true),
                                          setSort4(false),
                                          setSort(false),
                                          setSort2(false),
                                          getSearchedLeads("created_at,asc", "sort")
                                    }}
                                 >
                                    <i className="fa fa-sort-up ms-2"></i>
                                 </span>
                                 <span
                                    className="fs-5"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                       setSort4(true),
                                          setSort3(false),
                                          setSort(false),
                                          setSort2(false),
                                          getSearchedLeads("created_at,desc", "sort")
                                    }}
                                 >
                                    <i
                                       className="fa fa-sort-down"
                                       style={{
                                          position: "absolute",
                                          left: "0",
                                          bottom: "0",
                                          transform: "translate(1101%, -3px)",
                                       }}
                                    ></i>
                                 </span>
                              </>
                           )}
                           {sort4 ? (
                              <>
                                 <span
                                    className="fs-5"
                                    style={{ cursor: "pointer", color: "orange" }}
                                    onClick={() => {
                                       setSort4(false),
                                          setSort3(true),
                                          setSort(false),
                                          setSort2(false),
                                          getSearchedLeads("created_at,desc", "sort")
                                    }}
                                 >
                                    <i
                                       className="fa fa-sort-down"
                                       style={{
                                          position: "absolute",
                                          left: "0",
                                          bottom: "0",
                                          transform: "translate(1101%, -3px)",
                                       }}
                                    ></i>
                                 </span>
                              </>
                           ) : (
                              ""
                           )}
                           <DateFilter
                              date_filter={date_filter}
                              setdate_filter={setdate_filter}
                              getSearchedLeads={getSearchedLeads}
                           />
                        </div>
                     </th>
                     <th>Action</th>
                  </tr>
                  {allUsers &&
                     allUsers.map((item, obj) => (
                        <StaffList key={obj} data={item} i={obj} counter={counter} getAllUsers={getAllUsers} getAllUser={getAllUser} />
                     ))}
               </tbody>
            </table>
            {error ? (
               <span className="text-danger mt-3 d-table m-auto" role="alert">
                  {error}
               </span>
            ) : (
               ""
            )}
         </div>
         {/* </div>
      </div> */}
         {loader}
         {allPages !== [] ? (
            <Paginations
               data={allPages}
               paginate={paginate}
               perPage={getAllUsers}
            />
         ) : (
            ""
         )}
      </>
   )
}
export default TableStaff
