import PropTypes from "prop-types"
import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import { Container, Row, Col } from "reactstrap"
import ReturnRefundPolicy from "../../components/Privacy-termsCondition/refund-policy"

const ReturnRefundPolicyPage = () => {
  return (
    <React.Fragment>
      <MetaTags>
        <title>Return & Refund Policy | KlikCRM</title>
      </MetaTags>
      <ReturnRefundPolicy />
    </React.Fragment>
  )
}
export default ReturnRefundPolicyPage
