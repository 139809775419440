import React, { useState, useEffect } from "react"
import { Container, Row, Col, Modal, FormGroup, Input } from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert"
import { getCurrentUser } from "../../../helpers/Utils"
import { Link } from "react-router-dom"
import axios from "axios"
import GlobalSecurityModal from "./Global-Security-Modal"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

let selectedRoleId = []

const GlobalSecurityList = props => {
  const [globalsecurity, setGlobalSecurity] = useState(props.data)
  const [modal_standard, setmodal_standard] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [confirm_alert, setconfirm_alert] = useState(false)
  const [success_dlg, setsuccess_dlg] = useState(false)
  const [dynamic_title, setdynamic_title] = useState("")
  const [dynamic_description, setdynamic_description] = useState("")

  const deleteGlobalSecurity = e => {
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/global/security/${e}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setGlobalSecurity("")
          setdynamic_description(res.data.message)
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  const editGlobalSecurity = e => {
    setIsOpen(true)
    setmodal_standard(true)
  }

  useEffect(() => {
    selectedRoleId = []
  }, [])

  const getSelectedId = (e, check) => {
    if (check.target.checked) {
      selectedRoleId.push(e)
      const uniqueID = selectedRoleId.filter((val, id, array) => {
        return array.indexOf(val) == id
      })
      selectedRoleId = uniqueID
      props.setLeadsSelectedIds(selectedRoleId)
    } else {
      selectedRoleId = props.leadsSelectedIds
      var array = selectedRoleId
      var index = array.indexOf(e)
      if (index !== -1) {
        array.splice(index, 1)
      }
      props.setLeadsSelectedIds(selectedRoleId)
    }
    if (selectedRoleId.length == 0) {
      props.setBlur(false)
    } else {
      props.setBlur(true)
    }
  }

  if (globalsecurity === "") {
    return ""
  } else
    return (
      <>
        {modal_standard ? (
          <GlobalSecurityModal
            modal_standard={modal_standard}
            setmodal_standard={setmodal_standard}
            globalSecurity={globalsecurity}
            setGlobalSecurity={setGlobalSecurity}
          />
        ) : null}
        <tr key={props.i}>
          <td>
            <input
              onChange={e => getSelectedId(globalsecurity.id, e)}
              name="chk"
              value={globalsecurity.id}
              className="p-0 d-inline-block"
              type="checkbox"
            />
          </td>
          <td>{props.counter + props.i}</td>
          <td>
            <span
              className="text-info"
              style={{ cursor: "pointer" }}
              onClick={() => {
                editGlobalSecurity(globalsecurity.id)
              }}
            >
              {globalsecurity.day}
            </span>
          </td>
          <td>{globalsecurity.start_time}</td>
          <td>{globalsecurity.end_time}</td>
          {/* <td>{globalsecurity.ip}</td> */}
          <td>
            <a
              className="text-warning"
              onClick={() => {
                editGlobalSecurity(globalsecurity.id)
              }}
            >
              <i className="fas fa-pen" />
            </a>
            <a
              className="text-danger ms-2"
              onClick={() => {
                setconfirm_alert(true)
              }}
            >
              <i className="fas fa-trash-alt" />
            </a>
            {confirm_alert ? (
              <SweetAlert
                title="Are you sure?"
                warning
                showCancel
                confirmButtonText="Yes, delete it!"
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                onConfirm={() => {
                  setconfirm_alert(false)
                  setsuccess_dlg(true)
                  deleteGlobalSecurity(globalsecurity.id)
                  setdynamic_title("Deleted")
                  setdynamic_description("Your file has been deleted.")
                }}
                onCancel={() => setconfirm_alert(false)}
              >
                You won't be able to revert this!
              </SweetAlert>
            ) : null}
          </td>
        </tr>
      </>
    )
}
export default GlobalSecurityList
