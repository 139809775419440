import React, { useState, useEffect } from "react"
import { Container, Row, Col, Modal, FormGroup, Input } from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert"
import { getCurrentUser } from "../../../helpers/Utils"
import { Link } from "react-router-dom"
import axios from "axios"
import LocationModal from "./LocationModal"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const LocationList = props => {
   const [location, setLocation] = useState(props.data)
   const [modal_standard, setmodal_standard] = useState(false)
   const [isOpen, setIsOpen] = useState(false)
   const [confirm_alert, setconfirm_alert] = useState(false)
   const [success_dlg, setsuccess_dlg] = useState(false)
   const [dynamic_title, setdynamic_title] = useState("")
   const [dynamic_description, setdynamic_description] = useState("")

   const deleteLocation = e => {
      axios
         .delete(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/location/${e}`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setLocation("")
               setdynamic_description(res.data.message)
               toast.success(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            }
         })
   }

   const editLocation = e => {
      setIsOpen(true)
      setmodal_standard(true)
   }

   if (location === "") {
      return ""
   } else
      return (
         <>

            {isOpen ? (
               <LocationModal
                  modal_standard={modal_standard}
                  setmodal_standard={setmodal_standard}
                  location={location}
                  setLocation={setLocation}
                  setIsOpen={setIsOpen}
               />
            ) : (
               ""
            )}
            <tr key={props.i}>
               <td>{props.counter + props.i}</td>
               <td>{location.state}</td>
               <td>{location.district}</td>
               <td>{location.city}</td>
               <td>{location.info}</td>
               <td>{location.pincode}</td>
               <td>
                  <a
                     className="text-warning"
                     onClick={() => {
                        editLocation(location.id)
                     }}
                  >
                     <i className="fas fa-pen" />
                  </a>
                  <a
                     className="text-danger ms-2"
                     onClick={() => {
                        setconfirm_alert(true)
                     }}
                  >
                     <i className="fas fa-trash-alt" />
                  </a>
                  {confirm_alert ? (
                     <SweetAlert
                        title="Are you sure?"
                        warning
                        showCancel
                        confirmButtonText="Yes, delete it!"
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={() => {
                           setconfirm_alert(false)
                           setsuccess_dlg(true)
                           deleteLocation(location.id)
                           setdynamic_title("Deleted")
                           setdynamic_description("Your file has been deleted.")
                        }}
                        onCancel={() => setconfirm_alert(false)}
                     >
                        You won't be able to revert this!
                     </SweetAlert>
                  ) : null}
               </td>
            </tr>
         </>
      )
}
export default LocationList
