import React, { useState, useEffect } from "react"
import axios from "axios"
import { Container, Row, Col, Modal, FormGroup, Input, Alert } from "reactstrap"
import {
   UncontrolledDropdown,
   DropdownItem,
   DropdownToggle,
   DropdownMenu,
   Spinner,
} from "reactstrap";
import { AvForm, AvField, AvRadioGroup, AvRadio, } from "availity-reactstrap-validation"
import { getCurrentUser } from "../../helpers/Utils"
import Loader from "../Loader/loader.js"
import Select from "react-select"
import CproformaModal from "../Customer-Profile/C-proforma/CproformaModal"
import ServiceLineProforma from "../Customer-Profile/C-proforma/serviceLineProforma"

export default function ProformaModal(props) {
   const [modal_proforma, setmodal_proforma] = useState(false)
   const [service_proforma, setservice_proforma] = useState(false)
   const [loader, setLoader] = useState('')
   const [error, setError] = useState("")
   const [error2, setError2] = useState("")
   const [customer, setCustomer] = useState([])
   const [customerID, setCustomerID] = useState("")
   const [selectedName, setSelectedName] = useState("")
   const [timer, setTimer] = useState(null)

   const getCustomers = () => {
      setLoader(<Loader />)
      setCustomer([])
      axios
         .get(`${process.env.REACT_APP_API_URL}/${localStorage.getItem('company_name')}/proforma-leads`, {
            headers: {
               "content-type": "application/json",
               Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token }`,
            },
         })
         .then(function (res) {
            if (res.data.status) {
               setCustomer(res.data.data)
               setLoader('')
            } else {
               setError(res.data.message)
               setLoader('')
            }
         })
   }

   const searchGlobal = e => {
      clearTimeout(timer)
      const newTimer = setTimeout(() => {
         searchHere(e)
      }, 1000)
      setTimer(newTimer)
   }

   const searchHere = e => {
      setError("")
      setCustomer([])
      setLoader(<Loader />)
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/global-search?search=${e}`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setCustomer(res.data.data)
               setLoader(false)
            } else {
               setError(res.data.message)
               setLoader(false)
            }
         })
   }

   const options = []

   if (customer !== undefined) {
      customer.map(item => {
         options.push({
            value: item.id,
            label: item.name,
         })
      })
   }

   const getCustomerID = (e) => {
      setCustomerID(e.id)
      setSelectedName(e.name)
   }

   const submitOrder = () => {
      if (customerID == "") {
         setError2("Please select a Lead/customer.")
      } else {
         setError2("")
         if (props.type == "proforma") {
            setmodal_proforma(true)
         } else {
            setservice_proforma(true)
         }
      }
   }

   useEffect(() => {
      getCustomers()
   }, [])

   useEffect(() => {
      const close = (e) => {
         if (e.key === 'Escape') {
            props.setmodal_proforma2(false)
         }
      }
      window.addEventListener('keydown', close)
      return () => window.removeEventListener('keydown', close)
   }, [])

   return (
      <>
         {modal_proforma ? (
            <CproformaModal
               modal_proforma={modal_proforma}
               id={customerID}
               setmodal_proforma={setmodal_proforma}
               setmodal_proforma2={props.setmodal_proforma2}
               getAllProformas={props.getAllProforma}
            />
         ) : (
            ""
         )}
         {service_proforma ? (
            <ServiceLineProforma
               service_proforma={service_proforma}
               id={customerID}
               setservice_proforma={setservice_proforma}
               setmodal_proforma2={props.setmodal_proforma2}
               getAllProformas={props.getAllProforma}
            />
         ) : (
            ""
         )}
         <Modal isOpen={props.modal_proforma2} centered={true}  >
            <AvForm
               className="modal-title mt-0"
               id="myModalLabel"
               onValidSubmit={() => {
                  submitOrder()
               }}
            >
               <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myLargeModalLabel">
                     Select Lead/Customer
                  </h5>
                  <button
                     type="button"
                     className="cross__btn"
                     onClick={() => props.setmodal_proforma2(false)}
                     aria-hidden="true"
                  >
                     &times;
                  </button>
               </div>
               <div className="modal-body">
                  {/* <div className="mb-3 required">
                         <Select
                            id="customer"
                            name="customer"
                            options={options}
                            onChange={e => { setCustomerID(e.value) }}
                         />
                      </div> */}
                  <UncontrolledDropdown className="dropdown-menu-right mr-2">
                     <DropdownToggle className="w-100 text-left p-0 border-0" >
                        <div className="position-relative">
                           <AvField
                              className="select-arrow"
                              type="text"
                              name="client"
                              placeholder="Select"
                              value={
                                 selectedName
                              }
                              onChange={(e) => { searchGlobal(e.target.value), setSelectedName(e.target.value) }}
                           />
                           {loader ? (
                              <div className="clientLoader">
                                 <Spinner size="sm" />
                              </div>
                           ) : (
                              ""
                           )}
                        </div>
                     </DropdownToggle>
                     <DropdownMenu
                        className="w-100 p-0"
                        style={{
                           transform: "translate3d(0px, 35px, 0px)",
                           overflow: "auto",
                           height: '160px'
                        }}
                     >
                        {customer &&
                           customer.map((item, i) => (
                              <DropdownItem
                                 key={i}
                                 className="text-dark px-3"
                                 style={{
                                    lineHeight: "15px",
                                 }}
                                 onClick={() => getCustomerID(item)}
                              >

                                 {item.name}{" "}({item.number})
                              </DropdownItem>
                           ))}
                     </DropdownMenu>
                  </UncontrolledDropdown>

                  {error ? (
                     <span className="text-danger mt-3 d-table m-auto" role="alert">
                        {error}
                     </span>
                  ) : (
                     ""
                  )}
                  {error2 ? (
                     <span className="text-danger mt-3 d-table m-auto" role="alert">
                        {error2}
                     </span>
                  ) : (
                     ""
                  )}
               </div>
               <div className="modal-footer">
                  <div className="text-center">
                     <button
                        className="btn btn-primary"
                        type="submit"
                     // onClick={() => setmodal_standard(true)}
                     >
                        Submit
                     </button>
                  </div>
               </div>
            </AvForm>
         </Modal>
      </>
   )
}
