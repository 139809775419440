import React, { useState, useEffect } from "react"
import { getCurrentUser } from "../../helpers/Utils"
import { Link } from "react-router-dom"
import axios from "axios"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import ToggleSwitch from "../Toggle-Switch"

const CusSchemeList = props => {
  const [scheme, setScheme] = useState(props.data)
  const [modal_standard, setmodal_standard] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const updateScheme = e => {
    let isActive
    if (e) {
      isActive = 1
    } else {
      isActive = 0
    }
    const api = axios.create();
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      api.interceptors.request.use(config => {
         config.cancelToken = source.token;
         return config;
      });

      const timeout = 120000; // 2 minute

      setTimeout(() => {
         source.cancel('Timeout');
      }, timeout);
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/customer-scheme/${scheme.id}`,
        {
          qualified: isActive,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setScheme(res.data.data)
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        } else {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        }
      })
  }

  if (scheme === "") {
    return ""
  } else
    return (
      <>
        {isOpen ? (
          <CschemeModal
            modal_standard={modal_standard}
            setmodal_standard={setmodal_standard}
            scheme={scheme}
            setScheme={setScheme}
            setIsOpen={setIsOpen}
          />
        ) : null}
        <tr key={props.i}>
          <td>{props.counter + props.i}</td>
          <td>
            {scheme.app_short_name}0000{scheme.id}
          </td>
          <td>
            <Link
              to={`/customers?id=${scheme.customer_id}`}
              className="text-info"
            >
              {scheme.customer_name}
            </Link>
          </td>
          <td>
            <p
              dangerouslySetInnerHTML={{
                __html: scheme.message,
              }}
            />
          </td>
          <td>{scheme.start_date}</td>
          <td>{scheme.end_date}</td>
          <td>
            <ToggleSwitch
              id={scheme.id.toString()}
              name="qualified"
              checked={scheme.qualified === 1 ? true : false}
              onChange={e => updateScheme(e)}
              value={scheme.qualified}
            />
          </td>
        </tr>
      </>
    )
}
export default CusSchemeList
