import React, { useState, useEffect } from "react"
import axios from "axios"
import { Container, Row, Col, Modal, FormGroup, Input, Alert } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import InputMask from "react-input-mask"
import { getCurrentUser } from "../../../helpers/Utils"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

export default function GlobalipModal(props) {
   const [loading, setLoading] = useState(false)
   const [error, setError] = useState("")
   const [success, setSuccess] = useState("")
   const [ip, setIp] = useState("")

   const submitIP = (e, value) => {
      setLoading(true)
      const api = axios.create();
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      api.interceptors.request.use(config => {
         config.cancelToken = source.token;
         return config;
      });

      const timeout = 120000; // 2 minute

      setTimeout(() => {
         source.cancel('Timeout');
      }, timeout);
      if (props.ip !== undefined) {
         axios
            .put(
               `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
                  "company_name"
               )}/global/ip/${props.ip.id}`,
               {
                  ip: e.target.ip.value,
               },
               {
                  headers: {
                     Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token }`,
                  },
               }
            )
            .then(function (res) {
               if (res.data.status) {
                  setLoading(false)
                  props.setIsOpen(false)
                  props.setmodal_standard(false)
                  props.setIp(res.data.data)
                  toast.success(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               } else {
                  setLoading(false)
                  toast.error(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               }
            })
      } else {
         axios
            .post(
               `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
                  "company_name"
               )}/global/ip`,
               {
                  ip: e.target.ip.value,
               },
               {
                  headers: {
                     Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token }`,
                  },
               }
            )
            .then(function (res) {
               if (res.data.status) {
                  setLoading(false)
                  props.getAllIp()
                  props.setmodal_standard(false)
                  toast.success(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               } else {
                  setLoading(false)
                  toast.error(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               }
            })
      }
   }

   useEffect(() => {
      const close = (e) => {
         if (e.key === 'Escape') {
            props.setmodal_standard(false)
         }
      }
      window.addEventListener('keydown', close)
      return () => window.removeEventListener('keydown', close)
   }, [])

   return (
      <>
         <Modal isOpen={props.modal_standard} centered={true}>
            <AvForm
               className="modal-title mt-0"
               id="myModalLabel"
               onValidSubmit={(e, v) => {
                  submitIP(e, v)
               }}
            >
               <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myLargeModalLabel">
                     {props.ip !== undefined ? "Edit IP" : "Create IP"}
                  </h5>
                  <button
                     type="button"
                     className="cross__btn"
                     onClick={() => props.setmodal_standard(false)}
                     aria-hidden="true"
                  >
                     &times;
                  </button>
               </div>
               <div className="modal-body">
                  {success ? (
                     <div className="mb-3">
                        <Alert color="success">{success}</Alert>
                     </div>
                  ) : null}

                  {error ? (
                     <div className="mb-3">
                        <Alert color="danger">{error}</Alert>
                     </div>
                  ) : null}

                  {/* <div className="mb-3">
                     <label>IP</label>
                     <InputMask
                        mask="999.999.999.999"
                        name="ip"
                        defaultValue={props.ip && props.ip.ip}
                        className="form-control input-color"
                     ></InputMask>
                  </div> */}
                  <div className="mb-3">
                     <label>IP</label>
                     <AvField
                        name="ip"
                        type="text"
                        defaultValue={props.ip && props.ip.ip}
                     ></AvField>
                  </div>
                  <div className="modal-footer">
                     <div className="text-end">
                        {loading ? (
                           <button
                              className="btn btn-primary disabled"
                              type="button"
                              disabled
                           >
                              Loading
                           </button>
                        ) : (
                           <button
                              className="btn btn-primary"
                              type="submit"
                           // onClick={() => props.setmodal_standard(false)}
                           >
                              {props.ip !== undefined ? "Submit" : "Create"}
                           </button>
                        )}
                     </div>
                  </div>
               </div>
            </AvForm>
         </Modal>
      </>
   )
}
