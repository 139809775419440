import React, { useState, useEffect } from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import { getCurrentUser } from "../../../helpers/Utils"
import axios from "axios"
import Loader from "../../Loader/loader.js"

const ChistoryTable = props => {
   const [data, setData] = useState([])
   const [error, setError] = useState("")
   const [loader, setLoader] = useState(<Loader />)
   const [currentPage, setCurrentPage] = useState(1);
   const [pageData, setPageData] = useState([]);
   const [pageLoader, setPageLoader] = useState("")

   const getAllHistory = () => {
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/lead-history?id=${props.leadId}&page=${currentPage}`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               // setData(res.data.data)
               setPageData(res.data.data)
               const newData = res.data.data.data
               setData(prevData => [...prevData, ...newData]);
               setCurrentPage(res.data.data.current_page)
               setError("")
            } else {
               setError(res.data.message)
            }
            setPageLoader("")
            setLoader("")
         })
   }

   useEffect(() => {
      getAllHistory()
   }, [currentPage])

   const loadMore = () => {
      setCurrentPage(prevPage => prevPage + 1);
      setPageLoader(<Loader />)
   };

   return (
      <>
         <div className="page-title-box">
            <Row className="align-items-center">
               <Col md={6}>
                  <h6 className="page-title">Lead History</h6>
               </Col>
            </Row>
         </div>
         {loader ? (
            loader
         ) : (
            <>
               {data &&
                  data.map((item, i) => (
                     <div className={`steps_list ${item.type}`} key={i}>
                        <Card className="leadhist m-0">
                           <CardBody>
                              <div className="d-flex justify-content-between">
                                 <span className="history-font">
                                    Message:{" "}
                                    <span
                                       className="fs-6"
                                       dangerouslySetInnerHTML={{
                                          __html: item.message,
                                       }}
                                    />
                                 </span>
                                 <span
                                    className="text-muted"
                                    style={{ position: "absolute", right: "15px", top: "5px" }}
                                 >
                                    {item.created_at}
                                 </span>
                              </div>
                              {item.order_id !== null ? (
                                 <>
                                    <span className="history-font">
                                       Order ID: {item.order_id}
                                    </span>
                                    <br />
                                 </>
                              ) : (
                                 ""
                              )}
                              {item.order_value !== null ? (
                                 <>
                                    <span className="history-font">
                                       Order Value: &#8377;{item.order_value}
                                    </span>
                                    <br />
                                 </>
                              ) : (
                                 ""
                              )}
                              {item.payment_recieved !== null ? (
                                 <>
                                    <span className="history-font">
                                       Payment Received: &#8377;{item.payment_recieved}
                                    </span>
                                    <br />
                                 </>
                              ) : (
                                 ""
                              )}
                              {item.total_balance !== null ? (
                                 <>
                                    <span className="history-font">
                                       Total Balance: &#8377;{item.total_balance}
                                    </span>
                                    <br />
                                 </>
                              ) : (
                                 ""
                              )}
                              {item.dead_status ? (
                                 <>
                                    <span className="history-font">
                                       <i className="fa fa-file me-2"></i>
                                       {item.dead_status}
                                    </span>
                                    <br />
                                 </>
                              ) : (
                                 ""
                              )}
                              <hr />
                              <span
                                 className="fs-6"
                                 dangerouslySetInnerHTML={{
                                    __html: item.text,
                                 }}
                              />
                              <br />
                              <br />
                              <span className="history-font">
                                 Action: {item.action}
                              </span>{" "}
                              <br />
                              <span className="history-font">
                                 Notification sent to: {item.notification_to}
                              </span>
                           </CardBody>
                        </Card>
                     </div>
                  ))}
            </>
         )}
         {error ? (
            <span className="text-danger mt-3 d-table m-auto" role="alert">
               {error}
            </span>
         ) : (
            ""
         )}
         {pageLoader ?
            <div className='text-center'>
               Loading...
            </div>
            :
            null
         }
         {pageData?.last_page === currentPage ?
            null
            :
            error ?
            ""
            :
            <div className='text-center'>
               <span onClick={() => loadMore()} className="btn btn-success m-auto">Load More</span>
            </div>
         }
      </>
   )
}
export default ChistoryTable
