import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import axios from "axios"
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"
import { getCurrentUser } from "../../helpers/Utils"
import CampaignTable from '../../components/Admin/Campaign-table';


const Campaigns = () => {

   return (
      <React.Fragment>
         <div className="page-content">
            <MetaTags>
               <title>Campaigns | KlikCRM</title>
            </MetaTags>
            <Container fluid>
               <CampaignTable />
            </Container>
         </div>
      </React.Fragment>
   )
}

export default Campaigns;
