import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState } from "react"
import axios from "axios"
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import { AvForm, AvField } from "availity-reactstrap-validation"
import logoSm from "../../assets/images/faviconmah.png"
import { loginUser, apiError } from "../../store/actions"
import { setCurrentUser } from "../../helpers/Utils"
import { useHistory } from "react-router-dom"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login = props => {
   const [loading, setLoading] = useState(false)
   const history = useHistory()

   const handleValidSubmit = (event, value) => {
      setLoading(true)
      const api = axios.create();
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      api.interceptors.request.use(config => {
         config.cancelToken = source.token;
         return config;
      });

      const timeout = 120000; // 2 minute

      setTimeout(() => {
         source.cancel('Timeout');
      }, timeout);
      axios
         .post(
            `${process.env.REACT_APP_API_URL}/naman/auth/login`,
            {
               email: value.email,
               password: value.password
            }
         )
         .then(res => {
            if (res.data.status) {
               setLoading(false)
               if (res.data.success) {
                  setCurrentUser(res.data.user)
                  history.push('/dashboard')
               } else {
                  alert(res.data.message)
               }
            } else { }
         })
   }

   return (
      <React.Fragment>
         <MetaTags>
            <title>Login | KlikCRM</title>
         </MetaTags>
         <div className="account-pages my-5 pt-sm-5">
            <Container>
               <Row className="justify-content-center">
                  <Col md={8} lg={6} xl={4}>
                     <Card className="overflow-hidden">
                        <div className="bg-primary">
                           <div className="text-primary text-center p-4 submit__button">
                              <h5 className="text-white font-size-20">Welcome Back !</h5>
                              <p className="text-white-50">
                                 Sign in to continue to KlikCRM.
                              </p>
                              <Link to="/" className="logo logo-admin">
                                 <img src={logoSm} height="24" alt="logo" />
                              </Link>
                           </div>
                        </div>

                        <CardBody className="p-4">
                           <div className="p-3">
                              <AvForm
                                 className="form-horizontal mt-4"
                                 onValidSubmit={(e, v) => {
                                    handleValidSubmit(e, v)
                                 }}
                              >
                                 {props.error && typeof props.error === "string" ? (
                                    <Alert color="danger">{props.error}</Alert>
                                 ) : null}

                                 <div className="mb-3">
                                    <AvField
                                       name=""
                                       label="Company Name"
                                       className="form-control"
                                       placeholder="Enter company name"
                                       type="text"
                                       required
                                    />
                                 </div>

                                 <div className="mb-3">
                                    <AvField
                                       name="email"
                                       label="Email"
                                       className="form-control"
                                       placeholder="Enter email"
                                       type="email"
                                       required
                                    />
                                 </div>

                                 <div className="mb-3">
                                    <AvField
                                       name="password"
                                       label="Password"
                                       type="password"
                                       required
                                       placeholder="Enter Password"
                                    />
                                 </div>

                                 <Row className="mb-3">
                                    <Col sm={6}>
                                       <div className="form-check">
                                          <input
                                             type="checkbox"
                                             className="form-check-input"
                                             id="customControlInline"
                                          />
                                          <label
                                             className="form-check-label"
                                             htmlFor="customControlInline"
                                          >
                                             Remember me
                                          </label>
                                       </div>
                                    </Col>
                                    <Col sm={6} className="text-end">
                                       {loading ? (
                                          <button className="btn btn-primary w-md waves-effect waves-light">
                                             Loading
                                          </button>
                                       ) : (
                                          <button
                                             className="btn btn-primary w-md waves-effect waves-light"
                                             type="submit"
                                          >
                                             Log In
                                          </button>
                                       )}
                                    </Col>
                                 </Row>
                                 <Row className="mt-2 mb-0 row">
                                    <div className="col-12 mt-4">
                                       <Link to="/forgot-password" className="text__color__styling">
                                          <i className="mdi mdi-lock"></i> Forgot your
                                          password?
                                       </Link>
                                    </div>
                                 </Row>
                              </AvForm>
                           </div>
                        </CardBody>
                     </Card>
                     <div className="mt-5 text-center">
                        <p>
                           Don&#39;t have an account ?{" "}
                           <Link to="register" className="fw-medium text-primary">
                              <span className="text__color__styling">
                                 {" "}
                                 Signup now{" "}
                              </span>
                           </Link>{" "}
                        </p>
                        <p>
                           © {new Date().getFullYear()} KlikCRM.com,{" "}
                           {/* <i className="" />  */}
                           all rights reserved.
                        </p>
                     </div>
                  </Col>
               </Row>
            </Container>
         </div>
      </React.Fragment>
   )
}

const mapStateToProps = state => {
   const { error } = state.Login
   return { error }
}

export default withRouter(
   connect(mapStateToProps, { loginUser, apiError })(Login)
)

Login.propTypes = {
   error: PropTypes.any,
   history: PropTypes.object,
   loginUser: PropTypes.func,
}