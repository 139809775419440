import React, { useState, useEffect } from "react"
import axios from "axios"
import { Container, Row, Col, Modal, FormGroup, Input, Alert } from "reactstrap"
import { AvForm, AvField, AvRadio, AvRadioGroup, AvCheckbox, AvCheckboxGroup } from "availity-reactstrap-validation"
import { getCurrentUser } from "../../../helpers/Utils"
import { Editor } from "react-draft-wysiwyg"
import DatePicker from "react-datepicker"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import ToggleSwitch from "../../Toggle-Switch"
import Switch from "react-switch"


export default function BatchModal(props) {
   const [loading, setLoading] = useState(false)
   const [error, setError] = useState("")
   const [success, setSuccess] = useState("")
   const [productDetail, setProductDetail] = useState([])
   const [mfgDate, setMfgDate] = useState("")
   const [expiryDate, setExpiryDate] = useState("")

   const productDetails = (e) => {
      axios
         .get(`${process.env.REACT_APP_API_URL}/${localStorage.getItem('company_name')}/product/${e}`, {
            headers: {
               "content-type": "application/json",
               Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token }`,
            },
         })
         .then(function (res) {
            if (res.data.status) {
               setProductDetail(res.data.data)
            } else { }
         })
   }

   const submitProductBatch = (e, value) => {
      setLoading(true)
      const api = axios.create();
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      api.interceptors.request.use(config => {
         config.cancelToken = source.token;
         return config;
      });

      const timeout = 120000; // 2 minute

      setTimeout(() => {
         source.cancel('Timeout');
      }, timeout);
      axios
         .post(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/product-batch`,
            {
               name: value.name,
               batch_no: value.batch_no,
               mrp: value.mrp,
               min_price: value.min_price,
               max_price: value.max_price,
               gst: value.gst,
               mfr: mfgDate,
               expiry_date: expiryDate,
            },
            {
               headers: {
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setLoading(false)
               props.getProducts()
               props.setmodal_batch(false)
               toast.success(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            } else {
               setLoading(false)
               toast.error(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            }
         })
   }

   useEffect(() => {
      const close = (e) => {
         if (e.key === 'Escape') {
            props.setmodal_batch(false)
         }
      }
      window.addEventListener('keydown', close)
      return () => window.removeEventListener('keydown', close)
   }, [])

   return (
      <>

         <Modal
            isOpen={props.modal_batch}
            centered={true}
         >
            <AvForm
               className="modal-title mt-0"
               id="myModalLabel"
               onValidSubmit={(e, v) => {
                  submitProductBatch(e, v)
               }}
            >
               <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myLargeModalLabel">
                     Create Product Batch
                  </h5>
                  <button
                     type="button"
                     className="cross__btn"
                     onClick={() => props.setmodal_batch(false)}
                     aria-hidden="true"
                  >
                     &times;
                  </button>
               </div>
               <div className="modal-body">
                  <Row>
                     <Col md={12}>
                        <div className="mb-3 required">
                           <AvField
                              className="select-arrow"
                              label="Select Product"
                              type="select"
                              name="name"
                              onChange={(e) => productDetails(e.target.value)}
                           // value={props.product && props.product.name}
                           >
                              <option value="" hidden>Select</option>
                              {props.product && props.product.map((item, i) => (
                                 <option value={item.id}>{item.name} {item.batch_no}</option>
                              ))}
                           </AvField>
                        </div>
                        <div className="mb-3">
                           <AvField
                              label="Batch"
                              placeholder="Enter Batch Number"
                              type="text"
                              name="batch_no"
                              value={productDetail && productDetail.batch_no}
                           />
                        </div>
                        <div className="mb-3 required">
                           <AvField
                              label="MRP"
                              placeholder="Enter MRP"
                              type="text"
                              name="mrp"
                              value={productDetail && productDetail.mrp}
                           />
                        </div>
                        <div className="d-flex mt-2">
                           <div className="mb-3 pe-2 required">
                              <AvField
                                 label="Min SP"
                                 className="w-100"
                                 placeholder="Enter amount"
                                 type="text"
                                 name="min_price"
                                 value={productDetail && productDetail.min_price}
                              />
                           </div>
                           <div className="mb-3 required">
                              <AvField
                                 label="Max SP"
                                 className="w-100"
                                 placeholder="Enter amount"
                                 type="text"
                                 name="max_price"
                                 value={productDetail && productDetail.max_price}
                              />
                           </div>
                        </div>
                        <div className="mb-3 required">
                           <AvField
                              className="select-arrow"
                              label="GST"
                              type="select"
                              name="gst"
                              value={productDetail && productDetail.gst}
                           >
                              <option value="" hidden>Select</option>
                              <option value="0">0%</option>
                              <option value="0.1">0.1%</option>
                              <option value="0.25">0.25%</option>
                              <option value="5">5%</option>
                              <option value="12">12%</option>
                              <option value="18">18%</option>
                              <option value="28">28%</option>
                           </AvField>
                        </div>
                        <div className="d-flex mt-2 mb-3">
                           <div className="mb-3 pe-2">
                              <label className="form-group">MFG</label>
                              <DatePicker
                                 className="w-100 form-control"
                                 dateFormat="yyyy-MM-dd"
                                 name="mfr"
                                 selected={mfgDate}
                                 onChange={(date) => setMfgDate(date)}
                                 value={productDetail && productDetail.mfr}
                              />
                           </div>
                           <div className="mb-3">
                              <label type="text" className="form-group">
                                 Expiry Date
                              </label>
                              <DatePicker
                                 className="w-100 form-control"
                                 dateFormat="yyyy-MM-dd"
                                 value={productDetail && productDetail.expiry_date}
                                 name="expiry_date"
                                 selected={expiryDate}
                                 onChange={(date) => setExpiryDate(date)}
                              />
                           </div>
                        </div>
                     </Col>
                  </Row>
                  <div className="modal-footer">
                     <div className="text-end">
                        {loading ? (
                           <button
                              className="btn btn-primary disabled"
                              type="button"
                              disabled
                           >
                              Loading
                           </button>
                        ) : (
                           <button
                              className="btn btn-primary"
                              type="submit"
                           // onClick={() => props.setmodal_batch(false)}
                           >
                              Create
                           </button>
                        )}
                     </div>
                  </div>
               </div>
            </AvForm>
         </Modal>
      </>
   )
}
