import React, { useState, useEffect } from "react"
import axios from "axios"
import { Container, Row, Col, Modal, FormGroup, Input, Alert } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { getCurrentUser, setCurrentUser } from "../../../helpers/Utils"
import { useHistory } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Loader from "../../Loader/loader"

let selectedRoleId = []

export default function EditRoleModal(props) {
   const [loading, setLoading] = useState(false)
   const [loader, setLoader] = useState(<Loader />)
   const [error, setError] = useState("")
   const [success, setSuccess] = useState("")
   const [role, setRole] = useState("")
   const [permissions, setPermissions] = useState([])

   const history = useHistory()

   const getSingleRole = () => {
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/role/${props.role.id}`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setPermissions(res.data.permissions)
               selectedRoleId = []
               res.data.permissions?.map(item => {
                  item.children?.map(option =>
                     option.is_checked == "yes" ? selectedRoleId.push(option.id) : ""
                  )
               })
            }
            setLoader("")
            console.log("ids", selectedRoleId)
         })
   }

   const getAllPermissions = () => {
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/permission`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setPermissions(res.data.data)
            } else {
               if (res.data.message === "Unauthenticated.") {
                  setCurrentUser("")
                  localStorage.setItem("DashboardData", "")
                  localStorage.setItem("LastUpdated", "")
                  history.push("/login")
               } else {
                  setError(res.data.message)
               }
            }
         })
   }

   const submitEditedRole = (e, value) => {
      setLoading(true)
      const api = axios.create();
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      api.interceptors.request.use(config => {
         config.cancelToken = source.token;
         return config;
      });

      const timeout = 120000; // 2 minute

      setTimeout(() => {
         source.cancel('Timeout');
      }, timeout);
      axios
         .put(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/role/${props.role.id}`,
            {
               name: value.name,
               permissions: selectedRoleId,
            },
            {
               headers: {
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setLoading(false)
               props.setIsOpen(false)
               props.setmodal_xlarge(false)
               props.setRole(res.data.data)
               toast.success(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            } else {
               setLoading(false)
               toast.error(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            }
         })
   }

   const getSelectedId = (e, check) => {
      if (check.target.checked) {
         selectedRoleId.push(e)
         const uniqueID = selectedRoleId.filter((val, id, array) => {
            return array.indexOf(val) == id
         })
         selectedRoleId = uniqueID
      } else {
         var array = selectedRoleId
         var index = array.indexOf(e)
         if (index !== -1) {
            array.splice(index, 1)
         }
      }
   }

   useEffect(() => {
      const close = e => {
         if (e.key === "Escape") {
            props.setmodal_xlarge(false)
         }
      }
      window.addEventListener("keydown", close)
      return () => window.removeEventListener("keydown", close)
   }, [])

   useEffect(() => {
      getSingleRole()
   }, [])

   return (
      <>
         <Modal size="lg" isOpen={props.modal_xlarge} centered={true}>
            <AvForm
               className="modal-title mt-0"
               id="myModalLabel"
               onValidSubmit={(e, v) => {
                  submitEditedRole(e, v)
               }}
            >
               <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myLargeModalLabel">
                     {props.role !== undefined ? "Edit Role" : "Create Role"}
                  </h5>
                  <button
                     className="cross__btn"
                     onClick={() => props.setmodal_xlarge(false)}
                     aria-hidden="true"
                     type="button"
                  >
                     &times;
                  </button>
               </div>
               <div className="modal-body pb-0">
                  {loader === "" ? (
                     <>
                        <div className="mb-3">
                           <AvField
                              label="Role Name"
                              type="text"
                              name="name"
                              value={props.role && props.role.name}
                              disabled
                           />
                        </div>
                        <div className="mb-3">
                           <div className="d-flex justify-content-between border-bottom">
                              <div className="role_name"></div>
                              <div className="role_perm">
                                 <table className="" style={{ width: "200px" }}>
                                    <tr className="text-center p-0">
                                       <td className="p-2">View</td>
                                       <td className="p-2">Edit</td>
                                       <td className="p-2">Create</td>
                                       <td className="p-2">Delete</td>
                                    </tr>
                                 </table>
                              </div>
                           </div>
                           {permissions &&
                              permissions.map((item, i) => (
                                 <div
                                    className="d-flex align-items-center justify-content-between border-bottom py-1"
                                    key={i}
                                 >
                                    <div className="role_name">
                                       <label className="m-0">{item.name}</label>
                                    </div>
                                    <div className="role_perm">
                                       {item.children[0] && item.children[0] !== 0 ? (
                                          <>
                                             <table
                                                className=""
                                                style={{
                                                   width: "200px",
                                                }}
                                             >
                                                <tr>
                                                   <td className="text-center p-2">
                                                      {item.children &&
                                                         item.children.map((option, i) => (
                                                            <div key={i}>
                                                               {option.name.indexOf("view") === 0 ? (
                                                                  <input
                                                                     onChange={e =>
                                                                        getSelectedId(option.id, e)
                                                                     }
                                                                     className="p-0 d-inline-block"
                                                                     name={`view_${option.id}`}
                                                                     type="checkbox"
                                                                     defaultChecked={
                                                                        option.is_checked === "yes"
                                                                     }
                                                                  />
                                                               ) : (
                                                                  ""
                                                               )}
                                                            </div>
                                                         ))}
                                                   </td>
                                                   <td className="text-center p-2">
                                                      {item.children &&
                                                         item.children.map((option, i) => (
                                                            <div key={i}>
                                                               {option.name.indexOf("edit") === 0 ? (
                                                                  <input
                                                                     onChange={e =>
                                                                        getSelectedId(option.id, e)
                                                                     }
                                                                     className="p-0 d-inline-block"
                                                                     name={`view_${option.id}`}
                                                                     type="checkbox"
                                                                     defaultChecked={
                                                                        option.is_checked === "yes"
                                                                     }
                                                                  />
                                                               ) : (
                                                                  ""
                                                               )}
                                                            </div>
                                                         ))}
                                                   </td>
                                                   <td className="text-center p-2">
                                                      {item.children &&
                                                         item.children.map((option, i) => (
                                                            <div key={i}>
                                                               {option.name.indexOf("create") ===
                                                                  0 ? (
                                                                  <input
                                                                     onChange={e =>
                                                                        getSelectedId(option.id, e)
                                                                     }
                                                                     className="p-0 d-inline-block"
                                                                     name={`view_${option.id}`}
                                                                     type="checkbox"
                                                                     defaultChecked={
                                                                        option.is_checked === "yes"
                                                                     }
                                                                  />
                                                               ) : (
                                                                  ""
                                                               )}
                                                            </div>
                                                         ))}
                                                   </td>
                                                   <td className="text-center p-2">
                                                      {item.children &&
                                                         item.children.map((option, i) => (
                                                            <div key={i}>
                                                               {option.name.indexOf("delete") ===
                                                                  0 ? (
                                                                  <input
                                                                     onChange={e =>
                                                                        getSelectedId(option.id, e)
                                                                     }
                                                                     className="p-0 d-inline-block"
                                                                     name={`view_${option.id}`}
                                                                     type="checkbox"
                                                                     defaultChecked={
                                                                        option.is_checked === "yes"
                                                                     }
                                                                  />
                                                               ) : (
                                                                  ""
                                                               )}
                                                            </div>
                                                         ))}
                                                   </td>
                                                </tr>
                                             </table>
                                          </>
                                       ) : (
                                          ""
                                       )}
                                    </div>
                                 </div>
                              ))}
                        </div>
                     </>
                  ) : (
                     <div className="py-4">{loader}</div>
                  )}
               </div>
               <div className="modal-footer border-0 pt-0">
                  <div className="text-end">
                     {loading ? (
                        <button
                           className="btn btn-primary disabled"
                           type="button"
                           disabled
                        >
                           Loading
                        </button>
                     ) : (
                        <button
                           className="btn btn-primary"
                           type="submit"
                        // onClick={() => props.setmodal_xlarge(false)}
                        >
                           Submit
                        </button>
                     )}
                  </div>
               </div>
            </AvForm>
         </Modal>
      </>
   )
}
