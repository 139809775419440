import React, { useState, useEffect } from "react"
import { Row, Col, Modal } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { getCurrentUser } from "../../helpers/Utils"
import { Link, useHistory } from "react-router-dom"
import axios from "axios"
import useRazorpay from "react-razorpay"
import logo from "../../assets/images/favicon2.png"
import SweetAlert from "react-bootstrap-sweetalert"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const PlanList = props => {
   const Razorpay = useRazorpay();
   const history = useHistory();
   const [plan, setPlan] = useState(props.data)
   const [loading, setLoading] = useState(false)
   const [confirm_alert, setconfirm_alert] = useState(false)
   const [success_dlg, setsuccess_dlg] = useState(false)
   const [modalOpen, setModalOpen] = useState(false)
   const [users, setUsers] = useState(props.data.users_limit !== null ? props.data.users_limit : 1)
   const [planAmount, setPlanAmount] = useState(props.data.item.amount / 100)
   const [total, setTotal] = useState(((props.data.users_limit !== null ? props.data.users_limit : 1) * (props.data.item.amount / 100)) + (props.data.additional_cost !== null ? props.data.additional_cost : 0))
   const [dynamic_title, setdynamic_title] = useState("")
   const [dynamic_description, setdynamic_description] = useState("")
   const [subscription, setSubscription] = useState("")

   const calcTotal = (e) => {
      setUsers(e)
      setTotal((e * planAmount) + plan.additional_cost)
   }

   const handlePayment = () => {
      var options = {
         "key": "rzp_test_YOtYqjzKtxjKuO",
         "subscription_id": subscription.id,
         "name": "Klikcrm.com",
         "description": plan.item.description,
         "image": logo,
         "handler": function (response, item) {
            getPaymentData(response.razorpay_payment_id)
         },
         "prefill": {
            "name": getCurrentUser().name,
            "email": getCurrentUser().email,
            "contact": getCurrentUser().phone,
         },
         "theme": {
            "color": "#40549a"
         }
      }

      var rzp = new Razorpay(options);
      rzp.open();
   }

   const getPaymentData = (payID) => {
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/get-payment?id=${payID}&plan_id=${plan.id}`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token}`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               toast.success("Payment Successful, please login again.", {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
               // history.push("/login")
               console.log("res", res.data.data)
            } else {
            }
         })
   }
   const today = new Date()
   const tomorrow = today.getDate() + 1

   const createSubscription = () => {
      setLoading(true)
      axios
         .post(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/create-subscription`, {
            plan_id: plan.id,
            quantity: users,
            start_at: Math.floor(tomorrow / 1000),
            addon_amount: (plan.additional_cost !== null ? plan.additional_cost : 0) * 100,
         },
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token}`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               if (res.data.data !== undefined) {
                  setSubscription(res.data.data)
                  // handlePayment()
                  window.open(res.data.data.short_url, "_blank")
                  setTimeout(() => {
                     history.push("/login")
                     toast.success("Please login again after starting subscription.", {
                        position: "top-center",
                        autoClose: 300000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                     })
                  }, 3000);
               }
               else {
                  toast.error("Something went wrong, please try after some time.", {
                     position: "top-center",
                     autoClose: 300000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               }
            }
            setLoading(false)
         })
   }

   const cancelPlan = (subID) => {
      axios
         .post(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/cancel-subscription`,
            {
               subscription_id: subID,
            },
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token}`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setTimeout(() => {
                  history.push("/login")
               }, 1500);
            } else {
            }
         })
   }

   return (
      <>
         {confirm_alert ? (
            <SweetAlert
               title="Are you sure?"
               warning
               showCancel
               confirmButtonText="Yes, cancel it!"
               confirmBtnBsStyle="success"
               cancelBtnBsStyle="danger"
               onConfirm={() => {
                  setconfirm_alert(false)
                  setsuccess_dlg(true)
                  cancelPlan(getCurrentUser().subscription_id)
                  setdynamic_title("Cancelled")
                  setdynamic_description("Your plan has been cancelled.")
               }}
               onCancel={() => setconfirm_alert(false)}
            >
               You won't be able to revert this!
            </SweetAlert>
         ) : null}
         {success_dlg ?
            <SweetAlert
               success
               title={dynamic_title}
               onConfirm={() => {
                  setsuccess_dlg(false)
               }}
            >
               {dynamic_description}
            </SweetAlert>
            : null}
         <div className={`card rounded pb-3 ${getCurrentUser().plan_id == plan.id ? "activeplan" : ""}`}>
            <div className="card-body">
               <div className="text-center pricing_table">
                  {getCurrentUser().plan_id == plan.id ?
                     <h3 className="fs-4 fw-bold pt-2">
                        Active Plan
                     </h3>
                     : ""
                  }
                  <h3 className="fs-1 fw-bold pt-4 text">
                     {plan.item.name}
                  </h3>
                  <p className="">Get started to live</p>
                  <span className="d-block border_hover"></span>
                  <h4 className="mb-0 pt-2">₹ {(plan.item.amount / 100)}<span>/USER</span></h4>
                  <span className="d-block fw-light">PER {plan.interval} {plan.period == "monthly" ? "MONTH" : <>{plan.period == "yearly" ? "YEAR" : "MONTH"}</>}</span>
                  <span className="d-block fw-light"> {plan.additional_cost !== null ? `One time cost of ₹ ${plan.additional_cost}` : ""}</span>
                  <p className="text-justify pt-4 pb-2">
                     {plan.item.description}
                  </p>
                  {getCurrentUser().plan_id == plan.id ?
                     <button
                        type="button"
                        className="btn btn-success card_submit px-5 py-3"
                        onClick={() => setconfirm_alert(true)}
                     >
                        Cancel
                     </button>
                     :
                     <button
                        type="button"
                        className="btn btn-primary card_submit px-5 py-3"
                        onClick={() => setModalOpen(true)}
                     >
                        {getCurrentUser()?.is_active == "1" ? "Update Plan" : "PAY NOW"}
                     </button>
                  }
               </div>
            </div>
         </div>
         {modalOpen ?
            <Modal
               isOpen={modalOpen}
               centered={true}
               size="lg"
            >
               <AvForm
                  className="modal-title mt-0"
                  id="myModalLabel"
                  onValidSubmit={() => {
                     createSubscription()
                  }}
               >
                  <div className="modal-header">
                     <h5 className="modal-title mt-0" id="myLargeModalLabel">
                     <h3 className="fs-2 fw-bold text">
                     {plan.item.name}
                  </h3>
                     </h5>
                     <button
                        type="button"
                        className="cross__btn"
                        onClick={() => setModalOpen(false)}
                        aria-hidden="true"
                     >
                        &times;
                     </button>
                  </div>
                  <div className="modal-body">
                     <h3 className="fs-4 fw-bold pt-2">
                        In this plan the amount per user is ₹ {(plan.item.amount / 100)} and the user limit is {plan.users_limit}.
                     </h3>
                     <h3 className="fs-5 fw-bold pt-2">
                        Recurring amount: {(plan.item.amount / 100)} x {plan.users_limit} = ₹ {(plan.item.amount / 100) * (plan.users_limit)} / {plan.interval} {plan.period == "monthly" ? "MONTH" : <>{plan.period == "yearly" ? "YEAR" : "MONTH"}</>}
                     </h3>
                     <h3 className="fs-5 fw-bold pt-2">
                        One time Setup fees: ₹ {plan.additional_cost}
                     </h3>
                     {/* <Row>
                        <Col md={6}>
                           <div className="mb-4">
                              <AvField
                                 type="text"
                                 name="amount"
                                 label="Amount per user"
                                 defaultValue={`₹ ${(plan.item.amount / 100)}`}
                                 readOnly
                              />
                           </div>
                           <div className="mb-3">
                              <AvField
                                 type="text"
                                 name="fees"
                                 label="Setup Fees"
                                 defaultValue={`₹ ${plan.additional_cost !== null ? plan.additional_cost : 0}`}
                                 readOnly
                              />
                           </div>
                        </Col>
                        <Col md={6}>
                           <div style={{ marginBottom: '3px' }}>
                              <AvField
                                 type="number"
                                 name="user"
                                 label="No. of Users"
                                 min={plan.users_limit}
                                 defaultValue={users}
                                 onChange={(e) => calcTotal(e.target.value)}
                              />
                              <span>Minimum user limit is {plan.users_limit}</span>
                           </div>
                           <div className="mb-3">
                              <AvField
                                 type="text"
                                 name="total"
                                 label="Total"
                                 value={`₹ ${total}`}
                                 readOnly
                              />
                           </div>
                        </Col>
                     </Row> */}
                  </div>
                  <div className="modal-footer">
                     <div className="text-center">
                        {loading ?
                           <button
                              className="btn btn-primary disabled"
                              type="button"
                           // onClick={() => setmodal_standard(true)}
                           >
                              Loading...
                           </button>
                           :
                           <button
                              className="btn btn-primary"
                              type="submit"
                           // onClick={() => setmodal_standard(true)}
                           >
                              Pay
                           </button>
                        }
                     </div>
                  </div>
               </AvForm>
            </Modal>
            : ""}
      </>
   )
   if (plan === "") {
      return ""
   } else
      return (
         <>
            <div className="card rounded pb-3">
               <div className="card-body">
                  <div className="text-center pricing_table">
                     <h3 className="fs-1 fw-bold pt-4 text">
                        {plan.item.name}
                     </h3>
                     <p className="">Get started to live</p>
                     <span className="d-block border_hover"></span>
                     <h4 className="mb-0 pt-2">₹ {(plan.item.amount / 100)}</h4>
                     <span className="d-block fw-light">PER {plan.interval} MONTH</span>
                     <p className="text-justify pt-4 pb-2">
                        {plan.item.description}
                     </p>
                     {getCurrentUser().plan_id == plan.id ?
                        <button
                           type="button"
                           className="btn btn-success card_submit px-5 py-3"
                           onClick={() => cancelPlan(getCurrentUser().subscription_id)}
                        >
                           Cancel
                        </button>
                        :
                        <button
                           type="button"
                           className="btn btn-primary card_submit px-5 py-3"
                           onClick={() => handlePayment()}
                        >
                           PAY NOW
                        </button>
                     }
                  </div>
               </div>
            </div>
         </>
      )
}
export default PlanList