import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"

let selectedRoleId = []


const NotiList = props => {
   const [notification, setNotification] = useState(props.data)

   useEffect(() => {
      selectedRoleId = []
   }, [])

   const getSelectedId = (e, check) => {
      if (check.target.checked) {
         selectedRoleId.push(e)
         const uniqueID = selectedRoleId.filter((val, id, array) => {
            return array.indexOf(val) == id
         })
         selectedRoleId = uniqueID
         props.setLeadsSelectedIds(selectedRoleId)
      } else {
         selectedRoleId = props.leadsSelectedIds
         var array = selectedRoleId
         var index = array.indexOf(e)
         if (index !== -1) {
            array.splice(index, 1)
         }
         props.setLeadsSelectedIds(selectedRoleId)
      }
      if (selectedRoleId.length == 0) {
         props.setBlur(false)
      } else {
         props.setBlur(true)
      }
   }

   if (notification === "") {
      return ""
   } else
      return (
         <>
            <tr key={props.i}>
               <td>
                  <input
                     onChange={(e) =>
                        getSelectedId(notification.id, e)
                     }
                     name="chk"
                     value={notification.id}
                     className="p-0 d-inline-block"
                     type="checkbox"
                  />
               </td>
               <td>
                  {props.counter + props.i}
               </td>
               <td>{notification.created_name}</td>
               <td>{notification.message}</td>
               {/* <td>{notification.seen == "No" ? <span className="badge" style={{ backgroundColor: 'red' }}>No</span> : <span className="badge" style={{ backgroundColor: 'green' }}>Yes</span>}</td> */}
               <td>{notification.created_at}</td>
               <td>

                  <Link className="text-info" to={notification.data?.type === "campaign_complete" ? `/campaigns?id=${notification.campaign_id}` : `/leads?id=${notification.lead_id}`}>
                  {notification.data?.type === "campaign_complete" ?
                  "View Campaign"
                  :
                     "View Lead"
                  }
                  </Link>
               </td>
            </tr>
         </>
      )
}
export default NotiList
