import React, { useState } from "react"
import { getCurrentUser } from "../../../helpers/Utils"
import SourceModal from "../LeadSource/SourceModal"


const FetchWebsite = props => {
   const [fetch, setFetch] = useState(props.data)
   const [isOpen, setIsOpen] = useState(false)
   const [modal_standard, setmodal_standard] = useState(false)

   const editFetch = () => {
      setIsOpen(true)
      setmodal_standard(true)
   }

   if (fetch === "") {
      return ""
   } else
      return (
         <>
            {isOpen ? (
               <SourceModal
                  modal_lead_source={modal_standard}
                  setmodal_lead_source={setmodal_standard}
                  source={fetch}
                  setSource={setFetch}
                  setIsOpen={setIsOpen}
                  fetch_from_web={true}
               />
            ) : null}
            <tr key={props.i}>
               <td>{props.counter + props.i}</td>
               <td>{fetch.name}</td>
               <td>{fetch.total}</td>
               <td>
                  {fetch.assigneename?.map((item, i) => (
                     <span key={i}>
                        {item.name}
                        <br />
                     </span>
                  ))}
               </td>
               <td>
                  <a
                     className="text-warning"
                     onClick={() => {
                        editFetch(fetch.id)
                     }}
                  >
                     <i className="fas fa-pen" />
                  </a>
               </td>
            </tr>
         </>
      )
}
export default FetchWebsite
