import React, { useState, useEffect } from "react"
import { Row, Col } from "reactstrap"
import { getCurrentUser, getPermission } from "../../../helpers/Utils"
import axios from "axios"
import Paginations from "../../Pagination"
import Loader from "../../Loader/loader.js"
import LabelList from "./LabelList"
import LabelModal from "./LabelModal"
import SweetAlert from "react-bootstrap-sweetalert"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const LeadLabel = () => {
   const [modal_lead_label, setmodal_lead_label] = useState(false)
   const [allLabels, setAllLabels] = useState([])
   const [error, setError] = useState("")
   const [loader, setLoader] = useState(<Loader />)
   const [allPages, setAllPages] = useState([])
   const [counter, setCounter] = useState("")
   const [blur, setBlur] = useState(false)
   const [leadsSelectedIds, setLeadsSelectedIds] = useState([])
   const [success_dlg, setsuccess_dlg] = useState(false)
   const [dynamic_title, setdynamic_title] = useState("")
   const [dynamic_description, setdynamic_description] = useState("")
   const [confirm_alert, setconfirm_alert] = useState(false)

   const getAllLabels = () => {
      setLoader(<Loader />)
      setAllLabels([])
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/lead/label?per_page=${localStorage.getItem("PerPage") !== null
               ? localStorage.getItem("PerPage")
               : ""
            }`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setLoader("")
               setError(res.data.message)
               setCounter(res.data.data.from)
               setAllLabels(res.data.data?.data)
               setAllPages(res.data.data)
            } else {
               setLoader("")
               setError(res.data.message)
            }
            setTimeout(() => {
               getAllLabel()
            }, 2000);
         })
   }

   const getAllLabel = () => {
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/lead/label?per_page=5000`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               localStorage.setItem("AllLabels", JSON.stringify(res.data.data.data))
            }
         })
         .catch(err => {
         })
   }

   const paginate = e => {
      setAllLabels([])
      setAllPages([])
      setCounter("")
      setLoader(<Loader />)
      axios
         .get(`${e}`, {
            headers: {
               "content-type": "application/json",
               Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
            },
         })
         .then(function (res) {
            if (res.data.status) {
               setLoader("")
               setCounter(res.data.data.from)
               setAllPages(res.data.data)
               setAllLabels(res.data.data.data)
            } else {
               setError(res.data.message)
               setLoader("")
            }
         })
   }

   const selectAllCheck = check => {
      if (check.checked) {
         var ele = document.getElementsByName("chk")
         for (var i = 0; i < ele.length; i++) {
            if (ele[i].type == "checkbox") {
               ele[i].checked = true
               leadsSelectedIds.push(parseInt(ele[i].value))
            }
         }
      } else {
         var ele = document.getElementsByName("chk")
         for (var i = 0; i < ele.length; i++) {
            if (ele[i].type == "checkbox") {
               ele[i].checked = false
               var array = leadsSelectedIds
               var index = array.indexOf(parseInt(ele[i].value))
               if (index !== -1) {
                  array.splice(index, 1)
               }
            }
         }
      }
      if (leadsSelectedIds.length === 0) {
         setBlur(false)
      } else {
         setBlur(true)
      }
   }

   const exportLead = () => {
      axios
         .post(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/leadlabel-export`,
            {
               ids: leadsSelectedIds,
            },

            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setLeadsSelectedIds([])
               window.location.href = res.data.file_url
               setTimeout(() => {
                  dFileName(res.data.file_name)
               }, 5000)
            } else {
               toast.error(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            }
         })
   }

   const dFileName = e => {
      axios
         .post(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/delete-leadlabel-file`,
            {
               file_name: e,
            },
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
            } else {
            }
         })
   }

   const deleteLeads = () => {
      axios
         .post(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/multiple-label-deleted`,
            {
               ids: leadsSelectedIds,
            },
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setLeadsSelectedIds([])
               getAllLabels()
               setdynamic_description(res.data.message)
               toast.success(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            } else {
               toast.error(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            }
         })
   }

   useEffect(() => {
      getAllLabels()
   }, [])

   return (
      <>
         <LabelModal
            modal_lead_label={modal_lead_label}
            setmodal_lead_label={setmodal_lead_label}
            getAllLabels={getAllLabels}
         />
         {confirm_alert ? (
            <SweetAlert
               title="Are you sure?"
               warning
               showCancel
               confirmButtonText="Yes, delete it!"
               confirmBtnBsStyle="success"
               cancelBtnBsStyle="danger"
               onConfirm={() => {
                  setconfirm_alert(false)
                  setsuccess_dlg(true)
                  deleteLeads()
                  setdynamic_title("Deleted")
                  setdynamic_description("Your file has been deleted.")
               }}
               onCancel={() => setconfirm_alert(false)}
            >
               <span className="text-danger">You won't be able to revert this!</span>
            </SweetAlert>
         ) : null}
         <div className="page-title-box">
            <Row className="align-items-center">
               <Col md={6}>
                  <h6 className="page-title">Lead Labels</h6>
               </Col>
               <Col md={6}>
                  <h6 className="page-title float-end">
                     {getPermission().lead_label.lead_label.delete.is_checked === "yes" ?
                        blur ? (
                           <div className="btn-group me-2">
                              <button type="button" className="btn btn-primary">
                                 Action
                              </button>
                              <button
                                 type="button"
                                 className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                                 data-bs-toggle="dropdown"
                                 aria-expanded="false"
                              >
                                 <i className="fa fa-caret-down"></i>
                              </button>
                              <ul className="dropdown-menu">
                                 <li>
                                    <span
                                       className="dropdown-item"
                                       style={{ cursor: "pointer" }}
                                       onClick={() => {
                                          setconfirm_alert(true)
                                       }}
                                    >
                                       Batch delete
                                    </span>
                                 </li>
                              </ul>
                           </div>
                        ) : (
                           <div className="btn-group me-2">
                              <button type="button" className="btn btn-primary" disabled>
                                 Action
                              </button>
                              <button
                                 type="button"
                                 className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                                 disabled
                                 data-bs-toggle="dropdown"
                                 aria-expanded="false"
                              >
                                 <i className="fa fa-caret-down"></i>
                              </button>
                              <ul className="dropdown-menu">
                                 <li>
                                    <span
                                       className="dropdown-item"
                                       style={{ cursor: "pointer" }}
                                       onClick={() => {
                                          setconfirm_alert(true)
                                       }}
                                    >
                                       Batch delete
                                    </span>
                                 </li>
                              </ul>
                           </div>
                        )
                        :
                        ""
                     }
                     {getPermission() &&
                        getPermission().lead_export.lead_export.view.is_checked ===
                        "yes" ? (
                        <button
                           className="btn btn-primary submit__button me-2"
                           onClick={() => {
                              exportLead()
                           }}
                        >
                           Export
                        </button>
                     ) : (
                        ""
                     )}
                     {getPermission().lead_label.lead_label.create.is_checked === "yes" ?
                        <button
                           className="btn btn-success"
                           type="button"
                           onClick={() => {
                              setmodal_lead_label(true)
                           }}
                        >
                           Add New
                        </button>
                        :
                        ""
                     }
                  </h6>
               </Col>
            </Row>
         </div>
         <div className="card">
            <div className="card-body">
               <div className="lead_table table table-sm table-bordered table-responsive">
                  <table style={{ width: "100%" }}>
                     <tbody>
                        <tr className="static-header">
                           <th>
                              <input
                                 onClick={e => selectAllCheck(e.target)}
                                 className="p-0 d-inline-block"
                                 type="checkbox"
                              />
                           </th>
                           <th>#</th>
                           <th>ID</th>
                           <th>Name</th>
                           <th>Total Leads</th>
                           {getPermission().lead.lead.delete.is_checked === "yes" ?
                              <th>Delete Leads</th>
                              :
                              ""
                           }
                           <th>Action</th>
                        </tr>
                        {allLabels &&
                           allLabels.map((item, obj) => (
                              <LabelList
                                 key={obj}
                                 data={item}
                                 i={obj}
                                 setBlur={setBlur}
                                 setLeadsSelectedIds={setLeadsSelectedIds}
                                 leadsSelectedIds={leadsSelectedIds}
                                 counter={counter}
                                 getAllLabels={getAllLabels}
                              />
                           ))}
                     </tbody>
                  </table>
                  {error ? (
                     <span className="text-danger mt-3 d-table m-auto" role="alert">
                        {error}
                     </span>
                  ) : (
                     ""
                  )}
               </div>
            </div>
         </div>
         {loader}
         {allPages !== [] ? (
            <Paginations
               data={allPages}
               paginate={paginate}
               perPage={getAllLabels}
            />
         ) : (
            ""
         )}
      </>
   )
}
export default LeadLabel
