import React, { useState, useEffect } from "react"
import axios from "axios"
import { Container, Row, Col, Modal, FormGroup, Input, Alert } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { getCurrentUser } from "../../../helpers/Utils"
import { toast } from "react-toastify"
import Select from "react-select"
import "react-toastify/dist/ReactToastify.css"
import Loader from "../../Loader/loader.js"

let productNames = []

export default function CQuotationsModal(props) {
   const [loading, setLoading] = useState(false)
   const [product, setProduct] = useState([])
   const [proDetail, setProDetail] = useState(false)
   const [showCart, setShowCart] = useState(false)
   const [productDetail, setProductDetail] = useState([])
   const [nRate, setnRate] = useState("")
   const [quan, setQuan] = useState("")
   const [loader, setLoader] = useState("")
   const [productID, setProductID] = useState("")
   const [productName, setProductName] = useState("")
   const [error, setError] = useState("")
   const [quanError, setQuanError] = useState("")
   const [cart, setCart] = useState({ products: [] })

   const getProducts = () => {
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/product?per_page=5000&permission=enable`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setProduct(res.data.data.values.data)
            }
         })
   }

   const options = []

   if (product !== undefined) {
      product.map(item => {
         item.stock_left !== "0" ?
            options.push({
               value: item.id,
               label: `${item.name} (${item.expiry ? item.expiry : ""})`,
            })
            : ""
      })
   }

   const productDetails = e => {
      setnRate("")
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/proforma-product/${e}`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setProDetail(true)
               setProductDetail(res.data.data)
               res.data.data.map(item => {
                  if (item.key == "Selling Price") {
                     setnRate(item.value)
                  }
                  if (item.key == "Id") {
                     setProductID(item.value)
                  }
                  if (item.key == "Name") {
                     setProductName(item.value)
                  }
               })
               setError("")
               setQuan("")
            }
         })
   }

   const addToCart = () => {
      if (productID == "") {
         setError("Please select a Product before adding to cart.")
      } else {
         setError("")
         if (quan == "") {
            setQuanError("You can not add product with 0 quantity.")
         } else {
            setQuanError("")
            let products = cart.products
            let index = products.findIndex(id => id.product_id === productID)
            if (index !== -1) {
               let netRate = nRate
               let qn = quan
               setLoader(<Loader />)
               products[index] = {
                  product_id: productID,
                  name: productName,
                  special_price: netRate,
                  quantity: qn,
               }
               setTimeout(() => {
                  setLoader("")
                  setCart({ products })
               }, 500)
            } else {
               setShowCart(true)
               let netRate = nRate
               let qn = quan
               setCart({
                  products: [
                     ...cart.products,
                     {
                        product_id: productID,
                        name: productName,
                        special_price: netRate,
                        quantity: qn,
                     },
                  ],
               })
            }
         }
      }
   }

   const removeCartLine = i => {
      let products = cart.products
      setLoader(<Loader />)
      setCart({ products: [] })
      products.splice(i, 1)
      setTimeout(() => {
         setLoader("")
         setCart({ products })
      }, 500)
   }

   const submitQuotation = (e, value) => {
      setLoading(true)
      if (cart.products !== "") {
         productNames = cart.products
      }
      const api = axios.create();
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      api.interceptors.request.use(config => {
         config.cancelToken = source.token;
         return config;
      });

      const timeout = 120000; // 2 minute

      setTimeout(() => {
         source.cancel('Timeout');
      }, timeout);
      axios
         .post(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/quotation`,
            {
               customer_id: props.id,
               quotationproducts: productNames,
               message: value.message,
            },
            {
               headers: {
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setLoading(false)
               props.getQuotations()
               props.setmodal_standard(false)
               toast.success(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
               setTimeout(() => {
                  setCart({ products: [] })
                  setProDetail(false)
                  setShowCart(false)
               }, 500)
            } else {
               setLoading(false)
               toast.error(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            }
         })
   }

   useEffect(() => {
      getProducts()
   }, [])

   return (
      <>
         <Modal
            isOpen={props.modal_standard}
            centered={true}
            //   size="xl"
            style={{
               maxWidth: "1270px",
               padding: "0 10px",
            }}
         >
            <AvForm
               className="modal-title "
               id="myModalLabel"
               onValidSubmit={(e, v) => {
                  submitQuotation(e, v)
               }}>
               <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myLargeModalLabel">
                     Send New Quotation
                  </h5>
                  <button
                     type="button"
                     className="cross__btn"
                     onClick={() => props.setmodal_standard(false)}
                     aria-hidden="true"
                  >
                     &times;
                  </button>
               </div>
               <div className="modal-body">
                  <div className="lead_table" style={{ minHeight: "50px" }}>
                     <table style={{ width: "100%" }}>
                        <tbody>
                           <tr>
                              <th>Product Name</th>
                              <th>MOQ</th>
                              <th>Price</th>
                              <th></th>
                           </tr>
                           <tr>
                              <td>
                                 <div>
                                    <Select
                                       id="product"
                                       name="product"
                                       options={options}
                                       onChange={e => productDetails(e.value)}
                                    />
                                 </div>
                                 {error ? (
                                    <span className="text-danger text-align-center">{error}</span>
                                 ) : (
                                    ""
                                 )}
                              </td>
                              <td>
                                 <AvField
                                    type="number"
                                             onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                    min="1"
                                    name="quantity"
                                    value={quan}
                                    onChange={e => setQuan(e.target.value)}
                                 />
                                 {quanError ? (
                                    <span className="text-danger text-align-center">{quanError}</span>
                                 ) : (
                                    ""
                                 )}
                              </td>
                              <td>
                                 <AvField
                                    type="number"
                                             onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                                    min="1"
                                    name="selling_price"
                                    value={nRate}
                                    onChange={(e) => setnRate(e.target.value)}
                                 />
                              </td>
                              <td>
                                 <button
                                    type="button"
                                    className="btn bg-success text-light float-end"
                                    onClick={() => addToCart()}
                                 // disabled={err ? true : false}
                                 >
                                    +
                                 </button>
                              </td>
                           </tr>
                        </tbody>
                     </table>
                  </div>
                  <span className="" style={{ fontSize: "24px" }}>
                     Product Details
                  </span>
                  {proDetail ? (
                     <div className="lead_table" style={{ minHeight: "20px", overflowX: "scroll" }}>
                        <table style={{ width: "100%" }}>
                           <tbody>
                              <tr>
                                 {productDetail?.map((item, i) => (
                                    <th key={i}>{item.key}</th>
                                 ))}
                              </tr>
                              <tr>
                                 {productDetail?.map((item, i) => (
                                    <td key={i}>{item.value}</td>
                                 ))}
                              </tr>
                           </tbody>
                        </table>
                     </div>
                  ) : (
                     <div style={{ height: "50px" }}></div>
                  )}{" "}
                  <br />
                  <span className="" style={{ fontSize: "24px" }}>
                     Products included in quotation
                  </span>
                  {showCart ? (
                     <div className="lead_table table-responsive mb-3" style={{ minHeight: "50px" }}>
                        {loader !== "" ? (
                           loader
                        ) : (
                           <table style={{ width: "100%" }}>
                              <tbody>
                                 <tr>
                                    <th>Product Name</th>
                                    <th>MOQ</th>
                                    <th>Price</th>
                                    <th>Action</th>
                                 </tr>
                                 {cart.products &&
                                    cart.products.map((item, i) => (
                                       <tr key={item.product_id}>
                                          <td>
                                             <AvField
                                                type="text"
                                                disabled
                                                name="name"
                                                value={item.name}
                                             />
                                          </td>
                                          <td>
                                             <AvField
                                                type="text"
                                                disabled
                                                name="quantity"
                                                value={item.quantity}
                                             />
                                          </td>
                                          <td>
                                             <AvField
                                                type="text"
                                                disabled
                                                name="special_price"
                                                value={item.special_price}
                                             />
                                          </td>
                                          <td>
                                             <a
                                                className="btn btn-sm btn-danger"
                                                onClick={() => removeCartLine(i)}
                                             >
                                                Delete
                                             </a>
                                          </td>
                                       </tr>
                                    ))}
                              </tbody>
                           </table>
                        )}
                     </div>
                  ) : (
                     <div style={{ height: "50px" }}></div>
                  )}
                  <div className="mb-3">
                     <AvField
                        label="Message"
                        type="textarea"
                        placeholder="Enter message"
                        name="message"
                     />
                  </div>
               </div>
               <div className="modal-footer">
                  <div className="text-center">
                     {loading ? (
                        <button
                           className="btn btn-primary disabled"
                           type="button"
                           disabled
                        >
                           Loading
                        </button>
                     ) : (
                        <button
                           className="btn btn-primary"
                           type="submit"
                        >
                           Send Quotation
                        </button>
                     )}
                  </div>
               </div>
            </AvForm>
         </Modal>
      </>
   )
}
