import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import axios from "axios"
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"
import { getCurrentUser } from "../../helpers/Utils"
import Accountant from "../../components/Accountant"

const Accountants = () => {

	return (
		<React.Fragment>
		<div className="page-content">
			<MetaTags>
				<title>Accounts | KlikCRM</title>
			</MetaTags>
				<Container fluid>
					<Accountant />
				</Container>
			</div>
		</React.Fragment>
	)
}

export default Accountants;
