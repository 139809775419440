import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import axios from "axios"
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"
import { getCurrentUser } from "../../helpers/Utils"
import PastReport from '../../components/DailyReport/Past-Report';


const ReportPast = () => {

	return (
		<React.Fragment>
		<div className="page-content">
			<MetaTags>
				<title>Past Report | KlikCRM</title>
			</MetaTags>
			<Container fluid>
				<PastReport />
			</Container>
			</div>
		</React.Fragment>
	)
}

export default ReportPast;
