import PropTypes from 'prop-types'
import React, { useState } from "react"
import MetaTags from 'react-meta-tags';
import {
   Container,
   Row,
   Col
} from "reactstrap"
import Tablelead from '../../components/Lead';

const AllLeads = () => {
   return (
      <React.Fragment>
         <div className="page-content">
            <MetaTags>
               <title>Leads | KlikCRM</title>
            </MetaTags>
            <Container fluid>
               <Tablelead />
            </Container>
         </div>
      </React.Fragment>
   )
}
export default AllLeads;