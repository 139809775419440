import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import axios from "axios"
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"
import { getCurrentUser } from "../../helpers/Utils"
import LeadField from '../../components/Admin/LeadFields';


const Ldfield = () => {

   return (
      <React.Fragment>
         <div className="page-content">
            <MetaTags>
               <title>Custom Fields | KlikCRM</title>
            </MetaTags>
            <Container fluid>
               <LeadField />
            </Container>
         </div>
      </React.Fragment>
   )
}

export default Ldfield;
