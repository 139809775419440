import PropTypes from 'prop-types'
import React,{useState} from "react"
import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col
} from "reactstrap"
import TableStaff from '../../components/Admin/Staff';

const Staff = () => {
	return (
		<React.Fragment>
			<div className="page-content">
				<MetaTags>
					<title>Staff | KlikCRM</title>
				</MetaTags>
				<Container fluid>
	        <TableStaff />
        </Container>
      </div>
    </React.Fragment>
	)
}
export default Staff;