import React, { useState, useEffect } from "react"
import { Container, Row, Col, Modal, FormGroup, Input } from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert"
import { getCurrentUser, getPermission } from "../../../helpers/Utils"
import { Link } from "react-router-dom"
import axios from "axios"
import LabelModal from "./LabelModal"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

let selectedRoleId = []

const LabelList = props => {
   const [label, setLabel] = useState(props.data)
   const [modal_lead_label, setmodal_lead_label] = useState(false)
   const [isOpen, setIsOpen] = useState(false)
   const [confirm_alert, setconfirm_alert] = useState(false)
   const [success_dlg, setsuccess_dlg] = useState(false)
   const [dynamic_title, setdynamic_title] = useState("")
   const [dynamic_description, setdynamic_description] = useState("")

   const deleteLeads = e => {
      axios
         .get(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/label-lead-deleted/${e} `,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               props.getAllLabels()
               toast.success(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            }
         })
   }

   const deleteLabel = e => {
      axios
         .delete(
            `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
               "company_name"
            )}/lead/label/${e}`,
            {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token
                     }`,
               },
            }
         )
         .then(function (res) {
            if (res.data.status) {
               setLabel("")
               setdynamic_description(res.data.message)
               toast.success(res.data.message, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
               })
            }
         })
   }

   const editLabel = e => {
      setIsOpen(true)
      setmodal_lead_label(true)
   }

   useEffect(() => {
      selectedRoleId = []
   }, [])

   const getSelectedId = (e, check) => {
      if (check.target.checked) {
         selectedRoleId.push(e)
         const uniqueID = selectedRoleId.filter((val, id, array) => {
            return array.indexOf(val) == id
         })
         selectedRoleId = uniqueID
         props.setLeadsSelectedIds(selectedRoleId)
      } else {
         selectedRoleId = props.leadsSelectedIds
         var array = selectedRoleId
         var index = array.indexOf(e)
         if (index !== -1) {
            array.splice(index, 1)
         }
         props.setLeadsSelectedIds(selectedRoleId)
      }
      if (selectedRoleId.length == 0) {
         props.setBlur(false)
      } else {
         props.setBlur(true)
      }
   }

   if (label === "") {
      return ""
   } else
      return (
         <>
            {isOpen ? (
               <LabelModal
                  modal_lead_label={modal_lead_label}
                  setmodal_lead_label={setmodal_lead_label}
                  label={label}
                  setLabel={setLabel}
                  setIsOpen={setIsOpen}
               />
            ) : null}
            <tr key={props.i}>
               <td>
                  <input
                     onChange={e => getSelectedId(label.id, e)}
                     name="chk"
                     value={label.id}
                     className="p-0 d-inline-block"
                     type="checkbox"
                  />
               </td>
               <td>{props.counter + props.i}</td>
               <td>{label.id}</td>
               <td>
                  <Link className="" to={`/leads?lead_label_id=${label.id}`}>
                     {label.name}
                  </Link>
               </td>
               <td>{label.total}</td>
               {getPermission().lead.lead.delete.is_checked === "yes" ?
                  <td>
                     <button
                        className="btn btn-danger btn-sm"
                        onClick={e => deleteLeads(label.id)}
                     >
                        Delete
                     </button>
                  </td>
                  :
                  ""
               }
               <td>
                  {getPermission().lead_label.lead_label.edit.is_checked === "yes" ?
                     <a
                        className="text-warning"
                        onClick={() => {
                           editLabel(label.id)
                        }}
                     >
                        <i className="fas fa-pen" />
                     </a>
                     :
                     ""
                  }
                  {getPermission().lead_label.lead_label.delete.is_checked === "yes" ?
                     <a
                        className="text-danger ms-2"
                        onClick={() => {
                           setconfirm_alert(true)
                        }}
                     >
                        <i className="fas fa-trash-alt" />
                     </a>
                     :
                     ""
                  }
                  {confirm_alert ? (
                     <SweetAlert
                        title="Are you sure?"
                        warning
                        showCancel
                        confirmButtonText="Yes, delete it!"
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={() => {
                           setconfirm_alert(false)
                           setsuccess_dlg(true)
                           deleteLabel(label.id)
                           setdynamic_title("Deleted")
                           setdynamic_description("Your file has been deleted.")
                        }}
                        onCancel={() => setconfirm_alert(false)}
                     >
                        You won't be able to revert this!
                     </SweetAlert>
                  ) : null}
               </td>
            </tr>
         </>
      )
}
export default LabelList
