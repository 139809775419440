import React, { useState, useEffect } from "react"
import axios from "axios"
import { Container, Row, Col, Modal, FormGroup, Input, Alert } from "reactstrap"
import { AvForm, AvField, AvRadio, AvRadioGroup, AvCheckbox, AvCheckboxGroup } from "availity-reactstrap-validation"
import { getCurrentUser } from "../../../helpers/Utils"
import { Editor } from "react-draft-wysiwyg"
import DatePicker from "react-datepicker"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import ToggleSwitch from "../../Toggle-Switch"
import Switch from "react-switch"
import Loader from "../../Loader/loader.js"

let productValues = {}

let fieldValues = []
let fieldCheckbox = []
let fieldText = []
let fieldSelect = []
let fieldRadio = []
let fieldFlipButton = []
let fieldTextArea = []


export default function ProductsModal(props) {
   const [loading, setLoading] = useState(false)
   const [loader, setLoader] = useState('')
   const [error, setError] = useState("")
   const [bigModal, setBigModal] = useState(false)
   const [newsletter, setNewsletter] = useState(false)
   const [productFields, setProductFields] = useState([])
   const [editFields, setEditFields] = useState([])
   const [allFields, setAllFields] = useState([])
   const [mfgDate, setMfgDate] = useState("")
   const [expiryDate, setExpiryDate] = useState("")
   const [flip_button, setFlip_button] = useState(false)

   const getProductFields = () => {
      if (props.product !== undefined) {
         setLoader(<Loader />)
         axios
            .get(`${process.env.REACT_APP_API_URL}/${localStorage.getItem('company_name')}/product/${props.product.id}`, {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
               },
            })
            .then(function (res) {
               if (res.data.status) {
                  setError(res.data.message)
                  setEditFields(res.data.data)
                  res.data.data.map(element => {
                     productValues[element.key] = element.value;
                  });
                  setLoader('')
               } else {
                  setError(res.data.message)
                  setLoader('')
               }
            })
      } else {
         setLoader(<Loader />)
         axios
            .get(`${process.env.REACT_APP_API_URL}/${localStorage.getItem('company_name')}/get-product-fields`, {
               headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
               },
            })
            .then(function (res) {
               if (res.data.status) {
                  setError(res.data.message)
                  setProductFields(res.data.data.fields)
                  setLoader('')
               } else {
                  setError(res.data.message)
                  setLoader('')
               }
            })
      }
   }

   const getAllFields = () => {
      // setLoader(<Loader />)
      axios
         .get(`${process.env.REACT_APP_API_URL}/${localStorage.getItem('company_name')}/product-field`, {
            headers: {
               "content-type": "application/json",
               Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
            },
         })
         .then(function (res) {
            if (res.data.status) {
               // setLoader('')
               setError(res.data.message)
               setAllFields(res.data.data)
            } else {
               // setLoader('')
               setError(res.data.message)
            }
         })
   }

   const onNewsletterChange = checked => {
      if (checked) {
         getAllFields()
         setNewsletter(true)
         setBigModal(true)
      } else {
         setNewsletter(false)
         setBigModal(false)
      }
   }

   const setAllFieldValues = (e, id) => {
      if (e.target && e.target.type !== undefined) {
         if (e.target.type === "checkbox") {
            if (e.target.checked === true) {
               fieldCheckbox.push({ lead_field_id: id, value: e.target.value })
               const uniqueID = fieldCheckbox.filter((val, id, array) => {
                  return array.indexOf(val) == id
               })
               fieldCheckbox = uniqueID
            } else {
               var array = fieldCheckbox
               var index = array.indexOf(e)
               if (index !== -1) {
                  array.splice(index, 1)
               }
            }
         }
         else if (e.target.type === "text") {
            fieldText.push({ lead_field_id: id, value: e.target.value })
            const lastID = fieldText.filter((obj, id, array) => {
               return id === array.findLastIndex((t) => (t.id === obj.id))
            })
            fieldText = lastID
         }
         else if (e.target.type === "textarea") {
            fieldTextArea.push({ lead_field_id: id, value: e.target.value })
            const lastID = fieldTextArea.filter((obj, id, array) => {
               return id === array.findLastIndex((t) => (t.id === obj.id))
            })
            fieldTextArea = lastID
         }
         else if (e.target.type === "radio") {
            if (id === '') {
               fieldRadio.push({ lead_field_id: id, value: e.target.value })
            } else {
               fieldRadio.pop({ lead_field_id: id, value: e.target.value })
               fieldRadio.push({ lead_field_id: id, value: e.target.value })
            }
         }
         // else if (e.target.type === "file") {
         // 	fieldValues.push({lead_field_id: id, value: e.target.files[0]})
         // }
         else if (e.target.type === "select-one") {
            if (id === '') {
               fieldSelect.push({ lead_field_id: id, value: e.target.value })
            } else {
               fieldSelect.pop({ lead_field_id: id, value: e.target.value })
               fieldSelect.push({ lead_field_id: id, value: e.target.value })
            }
         }
      } else {
         let fieldValue
         if (e == true) {
            fieldValue = "1"
         } else {
            fieldValue = "0"
         }
         if (id === '') {
            fieldFlipButton.push({ lead_field_id: id, value: fieldValue })
         } else {
            fieldFlipButton.pop({ lead_field_id: id, value: fieldValue })
            fieldFlipButton.push({ lead_field_id: id, value: fieldValue })
         }
      }
   }

   const getMfgDate = (date) => {
      const dateObject = new Date(date);

      const day = String(dateObject.getDate()).padStart(2, '0');
      const month = String(dateObject.getMonth() + 1).padStart(2, '0');
      const year = dateObject.getFullYear();

      const formattedDate = `${day}-${month}-${year}`;
      productValues["Mfg"] = formattedDate
   }
   
   const getExpiryDate = (date) => {
      const dateObject = new Date(date);

      const day = String(dateObject.getDate()).padStart(2, '0');
      const month = String(dateObject.getMonth() + 1).padStart(2, '0');
      const year = dateObject.getFullYear();

      const formattedDate = `${day}-${month}-${year}`;
      productValues["Expiry"] = formattedDate
   }

   const getFieldValues = (e) => {
      if (e.target.name == "MFG") {
         productValues[e.target.name] = mfgDate
      } else if (e.target.name == "Expiry") {
         productValues[e.target.name] = expiryDate
      } else {
         if (Object.keys(productValues).includes(e.target.name)) {
            productValues[e.target.name] = e.target.value
         } else {
            productValues[e.target.name] = e.target.value
         }
      }
   }

   const submitProduct = () => {
      fieldCheckbox.map((item) => {
         fieldValues.push(item)
      })
      fieldText.map((item) => {
         fieldValues.push(item)
      })
      fieldTextArea.map((item) => {
         fieldValues.push(item)
      })
      fieldRadio.map((item) => {
         fieldValues.push(item)
      })
      fieldSelect.map((item) => {
         fieldValues.push(item)
      })
      fieldFlipButton.map((item) => {
         fieldValues.push(item)
      })
      setLoading(true)
      const api = axios.create();
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      api.interceptors.request.use(config => {
         config.cancelToken = source.token;
         return config;
      });

      const timeout = 120000; // 2 minute

      setTimeout(() => {
         source.cancel('Timeout');
      }, timeout);
      if (props.product !== undefined) {
         axios
            .put(
               `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
                  "company_name"
               )}/product/${props.product.id}`,
               {
                  productdetails: productValues,
               },
               {
                  headers: {
                     Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
                  },
               }
            )
            .then(function (res) {
               if (res.data.status) {
                  setLoading(false)
                  props.getProducts()
                  props.setmodal_product(false)
                  toast.success(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               } else {
                  setLoading(false)
                  toast.error(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               }
            })
      } else {
         axios
            .post(
               `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
                  "company_name"
               )}/product`,
               {
                  productdetails: productValues,
                  // fieldvalues: fieldValues,
               },
               {
                  headers: {
                     Authorization: `Bearer ${getCurrentUser().token == null ? "" : getCurrentUser().token}`,
                  },
               }
            )
            .then(function (res) {
               if (res.data.status) {
                  setLoading(false)
                  props.getProducts()
                  props.setmodal_product(false)
                  toast.success(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               } else {
                  setLoading(false)
                  toast.error(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               }
            })
      }
   }

   useEffect(() => {
      getProductFields()
   }, [])

   useEffect(() => {
      const close = (e) => {
         if (e.key === 'Escape') {
            props.setmodal_product(false)
         }
      }
      window.addEventListener('keydown', close)
      return () => window.removeEventListener('keydown', close)
   }, [])

   return (
      <>
         <Modal
            isOpen={props.modal_product}
            centered={true}
            size="lg"
         >
            <AvForm
               className="modal-title mt-0"
               id="myModalLabel"
               onValidSubmit={() => {
                  submitProduct()
               }}
            >
               <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myLargeModalLabel">
                     {props.product !== undefined ? "Edit Product" : "Create Product"}
                  </h5>
                  <button
                     type="button"
                     className="cross__btn"
                     onClick={() => props.setmodal_product(false)}
                     aria-hidden="true"
                  >
                     &times;
                  </button>
               </div>
               <div className="modal-body">
                  {props.product !== undefined ?
                     <>
                        {loader != "" ? loader :
                           <Row>
                              {editFields?.map((item) => item.key !== 'Id' && item.key !== "Offer Price" ?
                                 <Col md={6}>
                                    {(item.key).toLowerCase() == "gst" ?
                                       <div className="mb-3">
                                          <AvField
                                             className="select-arrow"
                                             label={item.key}
                                             type="select"
                                             name={item.key}
                                             onChange={(e) => getFieldValues(e)}
                                             value={item.value}
                                          >
                                             <option value="" hidden>Select</option>
                                             <option value="0">0%</option>
                                             <option value="0.25">0.25%</option>
                                             <option value="5">5%</option>
                                             <option value="12">12%</option>
                                             <option value="18">18%</option>
                                             <option value="28">28%</option>
                                          </AvField>
                                       </div>
                                       // : 
                                       // (item.key).toLowerCase() == "mfg" ?
                                       //    <div className="mb-3">
                                       //       <label className="form-group">{item.key}</label>
                                       //       <DatePicker
                                       //          className="w-100 form-control"
                                       //          dateFormat="yyyy-MM-dd"
                                       //          name={item.key}
                                       //          selected={mfgDate}
                                       //          value={item.value}
                                       //          onChange={(date, e) => { setMfgDate(date), getMfgDate(date) }}
                                       //       />
                                       //    </div>
                                       //    : 
                                       //    (item.key).toLowerCase() == "expiry" ?
                                       //       <div className="mb-3">
                                       //          <label type="text" className="form-group">
                                       //             {item.key}
                                       //          </label>
                                       //          <DatePicker
                                       //             className="w-100 form-control"
                                       //             dateFormat="yyyy-MM-dd"
                                       //             name={item.key}
                                       //             selected={expiryDate}
                                       //             value={item.value}
                                       //             onChange={(date, e) => {
                                       //                setExpiryDate(date),
                                       //                getDate(date)
                                       //             }}
                                       //          />
                                       //       </div>
                                             :
                                             <div className="mb-3">
                                                <AvField
                                                   label={item.key}
                                                   placeholder="Enter"
                                                   type="text"
                                                   name={item.key}
                                                   value={item.value}
                                                   onChange={(e) => getFieldValues(e)}
                                                />
                                             </div>
                                    }
                                 </Col>
                                 : "")}
                           </Row>
                        }
                     </>
                     :
                     <>
                        {loader != "" ? loader :
                           <Row>
                              {productFields?.map((item) => (item !== "Offer Price" ?
                                 <Col md={6}>
                                    <>
                                       {item.toLowerCase() == "gst" ?
                                          <div className="mb-3">
                                             <AvField
                                                className="select-arrow"
                                                label="GST"
                                                type="select"
                                                name="GST"
                                                onChange={(e) => getFieldValues(e)}
                                             >
                                                <option value="" hidden>Select</option>
                                                <option value="0">0%</option>
                                                <option value="0.1">0.1%</option>
                                                <option value="0.25">0.25%</option>
                                                <option value="5">5%</option>
                                                <option value="12">12%</option>
                                                <option value="18">18%</option>
                                                <option value="28">28%</option>
                                             </AvField>
                                          </div>
                                          // : 
                                          // item.toLowerCase() == "mfg" ?
                                          //    <div className="mb-3">
                                          //       <label className="form-group">{item}</label>
                                          //       <DatePicker
                                          //          className="w-100 form-control"
                                          //          dateFormat="yyyy-MM-dd"
                                          //          name="{item}"
                                          //          selected={mfgDate}
                                          //          onChange={(date, e) => { setMfgDate(date), getMfgDate(date) }}
                                          //       />
                                          //    </div>
                                          //    : 
                                          //    item.toLowerCase() == "expiry" ?
                                          //       <div className="mb-3">
                                          //          <label type="text" className="form-group">
                                          //             {item}
                                          //          </label>
                                          //          <DatePicker
                                          //             className="w-100 form-control"
                                          //             dateFormat="yyyy-MM-dd"
                                          //             name={item}
                                          //             selected={expiryDate}
                                          //             onChange={(date, e) => {
                                          //                setExpiryDate(date),
                                          //                getExpiryDate(date)
                                          //             }}
                                          //          />
                                          //       </div>
                                                :
                                                <div className="mb-3">
                                                   <AvField
                                                      label={item}
                                                      placeholder="Enter"
                                                      type="text"
                                                      name={item}
                                                      onChange={(e) => getFieldValues(e)}
                                                   />
                                                </div>
                                       }
                                    </>
                                 </Col>
                                 : ""
                              ))}
                              {/* <div className="mb-3">
                                    <label htmlFor="newsletter" className="me-3">
                                       Show other Fields
                                    </label>
                                    <ToggleSwitch
                                       id="newsletter"
                                       checked={newsletter}
                                       onChange={onNewsletterChange}
                                       name="show_other_fields"
                                    />
                                 </div> */}
                              {bigModal ?
                                 <Col md={6}>
                                    {allFields && allFields.map((item, i) => (
                                       <div className="mb-3" key={i}>
                                          {item.type === "Input" ?
                                             <AvField
                                                label={item.label}
                                                type="text"
                                                name="fieldvalues"
                                                placeholder="Enter"
                                                onChange={e => setAllFieldValues(e, item.id)}
                                             />
                                             : ""}
                                          {item.type === "Dropdown" ?
                                             <AvField
                                                className="select-arrow"
                                                label={item.label}
                                                type="select"
                                                name="fieldvalues"
                                                onChange={e => setAllFieldValues(e, item.id)}
                                             >
                                                <option value="" hidden>Select</option>
                                                {item.fieldvalues.map((option, i) => (
                                                   <option value={option.id} key={i}>{option.value}</option>
                                                ))}
                                             </AvField>
                                             : ""}
                                          {item.type === "Radio" ?
                                             <>
                                                <label>{item.label}</label>
                                                <AvRadioGroup
                                                   inline
                                                   onClick={e => setAllFieldValues(e, item.id)}
                                                   name="fieldvalues"
                                                >
                                                   {item.fieldvalues.map((radio) => (
                                                      <AvRadio label={radio.value} value={radio.id} />
                                                   ))}
                                                </AvRadioGroup>
                                             </>
                                             : ""}
                                          {/* {item.type === "file" ?  */}
                                          {/* 	<> */}
                                          {/* 		<label>{item.label}</label> */}
                                          {/* 			<AvField */}
                                          {/* 				type="file" */}
                                          {/* 				name="fieldvalues" */}
                                          {/* 				accept=".xls,.xlsx" */}
                                          {/* 				onChange={(e) => setAllFieldValues(e, item.id)} */}
                                          {/* 			/> */}
                                          {/* 	</> */}
                                          {/* : ""} */}
                                          {item.type === "Checkbox" ?
                                             <>
                                                <label>{item.label}</label> <br />
                                                {item.fieldvalues.map((check) => (
                                                   <>
                                                      <input
                                                         onChange={(e) => setAllFieldValues(e, item.id)}
                                                         value={check.id}
                                                         className="p-0"
                                                         type="checkbox"
                                                      /> {" "} <span className="me-3">{check.value}</span>
                                                   </>
                                                ))}
                                             </>
                                             : ""}
                                          {item.type === "Text Area" ?
                                             <>
                                                <AvField
                                                   label={item.label}
                                                   type="textarea"
                                                   name="fieldvalues"
                                                   placeholder="Enter"
                                                   onChange={e => setAllFieldValues(e, item.id)}
                                                />
                                             </>
                                             : ""}
                                          {item.type === "Flip Button" ?
                                             <>
                                                <div className="mb-3">
                                                   <label>
                                                      <span>{item.label}</span>
                                                      <br />                                  <Switch
                                                         className="mt-2"
                                                         name="flip_button"
                                                         onChange={(e) => { setAllFieldValues(e, item.id), setFlip_button(!flip_button) }}
                                                         checked={flip_button}
                                                      />
                                                   </label>
                                                </div>
                                             </>
                                             : ""}
                                       </div>
                                    ))}
                                 </Col>
                                 : ""}
                              {error ? (
                                 <span className="text-danger mt-3 d-table m-auto" role="alert">
                                    {error}
                                 </span>
                              ) : (
                                 ""
                              )}
                           </Row>
                        }
                     </>
                  }
                  <div className="modal-footer">
                     <div className="text-end">
                        {loading ? (
                           <button
                              className="btn btn-primary disabled"
                              type="button"
                              disabled
                           >
                              Loading
                           </button>
                        ) : (
                           <button
                              className="btn btn-primary"
                              type="submit"
                           // onClick={() => props.setmodal_product(false)}
                           >
                              {props.product !== undefined
                                 ? "Submit"
                                 : "Create"}
                           </button>
                        )}
                     </div>
                  </div>
               </div>
            </AvForm>
         </Modal>
      </>
   )
}
