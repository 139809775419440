import PropTypes from 'prop-types'
import React, { useState } from "react"
import MetaTags from 'react-meta-tags';
import {
	Container,
	Row,
	Col
} from "reactstrap"
import AllStatsTable from '../../../components/Lead/allStatistics/index.jsx';

const AllStatsLeads = () => {
	return (
		<React.Fragment>
			<div className="page-content">
				<MetaTags>
					<title>All Statistics | KlikCRM</title>
				</MetaTags>
				<Container fluid>
					<AllStatsTable />
				</Container>
			</div>
		</React.Fragment>
	)
}
export default AllStatsLeads;