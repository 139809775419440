import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import React, { useState } from "react"
import { Row, Col, Alert, Card, CardBody, Container } from "reactstrap"
import axios from "axios"


// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// action
import { userForgetPassword } from "../../store/actions"
import { setCurrentUser } from "../../helpers/Utils"
import { useHistory } from "react-router-dom"

// import images
import logoSm from "../../assets/images/faviconmah.png";

const ForgetPasswordPage = props => {
   const [loading, setLoading] = useState(false)
   const history = useHistory()

   const handleValidSubmit = (event, value) => {
      setLoading(true)
      const api = axios.create();
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      api.interceptors.request.use(config => {
         config.cancelToken = source.token;
         return config;
      });

      const timeout = 120000; // 2 minute

      setTimeout(() => {
         source.cancel('Timeout');
      }, timeout);
      axios
         .post(
            `${process.env.REACT_APP_API_URL}/auth/forget-password`,
            {
               email: value.email
            }
         )
         .then(res => {
            if (res.data.status) {
               alert(res.data.message)
            } else { }
         })
   }

   return (
      <React.Fragment>
         <MetaTags>
            <title>Forget Password | KlikCRM</title>
         </MetaTags>
         <div className="account-pages my-5 pt-5">
            <Container>
               <Row className="justify-content-center">
                  <Col md={8} lg={6} xl={4}>
                     <Card className="overflow-hidden">
                        <div className="bg-primary">
                           <div className="text-primary submit__button text-center p-4">
                              <h5 className="text-white font-size-20 p-2">Forget Password</h5>
                              <a href="index.html" className="logo logo-admin">
                                 <img src={logoSm} height="24" alt="logo" />
                              </a>
                           </div>
                        </div>
                        <CardBody className="p-4">

                           {props.forgetError && props.forgetError ? (
                              <Alert color="danger" style={{ marginTop: "13px" }} className="mt-5">
                                 {props.forgetError}
                              </Alert>
                           ) : null}
                           {props.forgetSuccessMsg ? (
                              <Alert color="success" style={{ marginTop: "13px" }} className="mt-5">
                                 {props.forgetSuccessMsg}
                              </Alert>
                           ) : null}

                           <AvForm
                              className="form-horizontal mt-4"
                              onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                           >
                              <div className="mb-3">
                                 <AvField
                                    name="email"
                                    label="Email"
                                    className="form-control"
                                    placeholder="Enter email"
                                    type="email"
                                    required
                                 />
                              </div>
                              <Row className="mb-3">
                                 <Col className="text-end">
                                    <button
                                       className="btn btn-primary w-md waves-effect waves-light"
                                       type="submit"
                                    >
                                       Reset
                                    </button>
                                 </Col>
                              </Row>
                           </AvForm>
                        </CardBody>
                     </Card>
                     <div className="mt-5 text-center">
                        <p>Remember It ? <Link to="login" className="fw-medium text-primary "><span className="text__color__styling"> Sign In here </span></Link> </p>
                        <p>
                           © {new Date().getFullYear()} KlikCRM.com,{" "}
                           {/* <i className="" /> */}
                           all rights reserved.
                        </p>
                     </div>
                  </Col>
               </Row>
            </Container>
         </div>
      </React.Fragment>
   )
}

ForgetPasswordPage.propTypes = {
   forgetError: PropTypes.any,
   forgetSuccessMsg: PropTypes.any,
   history: PropTypes.object,
   userForgetPassword: PropTypes.func
}

const mapStatetoProps = state => {
   const { forgetError, forgetSuccessMsg } = state.ForgetPassword
   return { forgetError, forgetSuccessMsg }
}

export default withRouter(
   connect(mapStatetoProps, { userForgetPassword })(ForgetPasswordPage)
)
