import React, { useState, useEffect } from "react"
import { MentionsInput, Mention } from 'react-mentions'
import _escapeRegExp from 'lodash/escapeRegExp'

const Messages = (props) => {
   const [state, setState] = useState({
      name: 'React',
      value: props.data?.message ? props.data?.message : "",
      singleLineValue: props.data?.message ? props.data?.message : "",
      mentionData: null,
      variables: [
         {
            _id: '{company_name}',
            name: "{company_name}"
         },
         {
            _id: '{user_name}',
            name: "{user_name}"
         },
         {
            _id: '{user_number}',
            name: "{user_number}"
         },
         {
            _id: '{order_id}',
            name: "{order_id}"
         },
         {
            _id: '{proforma_id}',
            name: "{proforma_id}"
         },
         {
            _id: '{lead_name}',
            name: "{lead_name}"
         },
         {
            _id: '{customer_care_number}',
            name: "{customer_care_number}"
         },
         {
            _id: '{brand_name}',
            name: "{brand_name}"
         },
         {
            _id: '{order_balance}',
            name: "{order_balance}"
         },
         {
            _id: '{due_date}',
            name: "{due_date}"
         }
      ]
   });

   function swapTags(text) {
      let displayText = text;
      const tagsWithBraces = text.match(/@\[\{[^\]]+\}\]\([^\)]+\)/gi) || [];
      const tagsWithoutBraces = text.match(/@\[[^\]]+\]\([^\)]+\)/gi) || [];

      // Handle tags with curly braces
      tagsWithBraces.forEach(tag => {
         const tagData = tag.slice(3, -1); // Extract data between [{...}]({...})
         const tagDisplayValue = tagData.split('](')[0]; // Get the content before ']('
         const tagReplacement = '{' + tagDisplayValue; // Ensure { and } are retained
         displayText = displayText.replace(new RegExp(_escapeRegExp(tag), 'gi'), tagReplacement);
      });

      // Handle tags without curly braces
      tagsWithoutBraces.forEach(tag => {
         const tagData = tag.slice(2, -1); // Extract data between [...](...)
         const tagDisplayValue = tagData.split('](')[0]; // Get the content before ']('
         const tagReplacement = tagDisplayValue + ": " + tagData.split('](')[1]; // No need to add curly braces
         displayText = displayText.replace(new RegExp(_escapeRegExp(tag), 'gi'), tagReplacement);
      });

      return displayText;
   }

   const handleChangeSingle = (e, newValue) => {
      setState({
         ...state,
         singleLineValue: newValue
      });
      props.handleFields(props.index, e, props.id)
   };

   return (
      <div className="row" key={props.i}>
         <div className="col-lg-7 mb-3 required">
            {/* <textarea
               // label="Message"
               className="form-control"
               id={`message-${props.i + 1}`}
               type="textarea"
               placeholder="Write message"
               required
               defaultValue={props.data?.message}
               /> */}
            <MentionsInput
               id={`message-${props.i + 1}`}
               value={state.singleLineValue}
               onChange={handleChangeSingle}
               markup="@{{__type__||__id__||__display__}}"
               placeholder="use the @ symbol to select variables."
               className="mentions"
               required
            >
               <Mention
                  type="user"
                  trigger="@"
                  data={props?.variableData}
                  className="mentions__mention"
               />
            </MentionsInput>
            <small className="fst-italic">{`Use @ to select variables to create message.`}</small>
         </div>
         <div className=" col-lg-5 mb-3 required d-flex justify-content-between align-items-center">
            <input
               style={{ width: 'calc(100% - 40px)' }}
               // label="Enter No. of hours after which you want to send message"
               type="number"
               className="form-control"
               id={`hours-${props.i + 1}`}
               name="hours"
               placeholder="Enter hours"
               onChange={(e) => props.handleFields(props.index, e, props.id)}
               required
               defaultValue={props.data?.hours}
            />
            {props.index !== 0 ? (
               <span
                  className="btn btn-danger"
                  onClick={() => props.removeFields(props.index, props.id)}
               >
                  &times;
               </span>
            ) : null}
         </div>
      </div>
   )
}

export default Messages
