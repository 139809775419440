import React, { useState, useEffect } from "react"
import { Container, Row, Col, Modal, FormGroup, Input } from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert"
import { getCurrentUser } from "../../../helpers/Utils"
import { Link } from "react-router-dom"
import axios from "axios"
import DeadModal from "./DeadModal"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

let selectedRoleId = []

const DeadList = props => {
  const [dead, setDead] = useState(props.data)
  const [confirm_alert, setconfirm_alert] = useState(false)
  const [leads_alert, setleads_alert] = useState(false)
  const [success_dlg, setsuccess_dlg] = useState(false)
  const [dynamic_title, setdynamic_title] = useState("")
  const [dynamic_description, setdynamic_description] = useState("")
  const [isOpen, setIsOpen] = useState(false)
  const [modal_dead_reason, setmodal_dead_reason] = useState(false)

  const deleteLeads = e => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/dead-lead-deleted/${e} `,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          props.getAllDead()
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        } else {
        }
      })
  }

  const deleteDead = e => {
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/${localStorage.getItem(
          "company_name"
        )}/dead-status/${e} `,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${
              getCurrentUser().token == null ? "" : getCurrentUser().token
            }`,
          },
        }
      )
      .then(function (res) {
        if (res.data.status) {
          setDead("")
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        }
      })
  }

  const editDead = () => {
    setIsOpen(true)
    setmodal_dead_reason(true)
  }

  useEffect(() => {
    selectedRoleId = []
  }, [])

  const getSelectedId = (e, check) => {
    if (check.target.checked) {
      selectedRoleId.push(e)
      const uniqueID = selectedRoleId.filter((val, id, array) => {
        return array.indexOf(val) == id
      })
      selectedRoleId = uniqueID
      props.setLeadsSelectedIds(selectedRoleId)
    } else {
      selectedRoleId = props.leadsSelectedIds
      var array = selectedRoleId
      var index = array.indexOf(e)
      if (index !== -1) {
        array.splice(index, 1)
      }
      props.setLeadsSelectedIds(selectedRoleId)
    }
    if (selectedRoleId.length == 0) {
      props.setBlur(false)
    } else {
      props.setBlur(true)
    }
  }

  if (dead === "") {
    return ""
  } else
    return (
      <>
        {isOpen ? (
          <DeadModal
            modal_dead_reason={modal_dead_reason}
            setmodal_dead_reason={setmodal_dead_reason}
            dead={dead}
            setDead={setDead}
            setIsOpen={setIsOpen}
          />
        ) : null}
        <tr key={props.i}>
          <td>
            <input
              onChange={e => getSelectedId(dead.id, e)}
              name="chk"
              value={dead.id}
              className="p-0 d-inline-block"
              type="checkbox"
            />
          </td>
          <td>{props.counter + props.i}</td>
          <td>{dead.id}</td>
          <td>
            <Link className="" to={`/leads?dead_reason_id=${dead.id}`}>
              {dead.reason}
            </Link>
          </td>
          <td>{dead.total}</td>
          <td>
            <button
              className="btn btn-danger btn-sm"
              onClick={e => setleads_alert(true)}
            >
              Delete
            </button>
          </td>
          <td>
            <a
              className="text-warning"
              onClick={() => {
                editDead(dead.id)
              }}
            >
              <i className="fas fa-pen" />
            </a>
            <a
              className="text-danger ms-2"
              onClick={() => {
                setconfirm_alert(true)
              }}
            >
              <i className="fas fa-trash-alt" />
            </a>
            {leads_alert ? (
              <SweetAlert
                title="Are you sure to delete these leads ?"
                warning
                showCancel
                confirmButtonText="Yes, delete it!"
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                onConfirm={() => {
                  setleads_alert(false)
                  setsuccess_dlg(true)
                  deleteLeads(dead.id)
                  setdynamic_title("Deleted")
                  setdynamic_description("Your file has been deleted.")
                }}
                onCancel={() => setleads_alert(false)}
              >
                You won't be able to revert this!
              </SweetAlert>
            ) : null}
            {confirm_alert ? (
              <SweetAlert
                title="Are you sure?"
                warning
                showCancel
                confirmButtonText="Yes, delete it!"
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                onConfirm={() => {
                  setconfirm_alert(false)
                  setsuccess_dlg(true)
                  deleteDead(dead.id)
                  setdynamic_title("Deleted")
                  setdynamic_description("Your file has been deleted.")
                }}
                onCancel={() => setconfirm_alert(false)}
              >
                You won't be able to revert this!
              </SweetAlert>
            ) : null}
          </td>
        </tr>
      </>
    )
}
export default DeadList
