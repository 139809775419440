import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"
import "./i18n"
import { Provider } from "react-redux"

import store from "./store"

// Sentry.init({
//    dsn: process.env.REACT_APP_SENTRY_URL,
//    integrations: [new BrowserTracing()],

//    // We recommend adjusting this value in production, or using tracesSampler
//    // for finer control
//    tracesSampleRate: 1.0,
// });

ReactDOM.render((
   <Provider store={store}>
      <App />
   </Provider>
),
   document.getElementById("root"))
serviceWorker.unregister()